import React, { Component } from 'react';
import '../styles/dataModeling.css';
import Tabulator from "tabulator-tables";
import { cleanUpTabulatorColumns, getSummationOfAttribute, toggleLoader} from '../class/common.js';
import { copyObjectValues, getTranslationFile } from '../class/utils.js';
import { VECTOR_ANALYSIS, YEAR_OVER_YEAR, PREVIOUS_ADJACENT, ORDER_VALUES, comparison_suffixes, VECTOR_STAGING_ATTRIBUTES, IDENTIFIERS, FormatTypes, PSL_RETURN_NAMES, UI_ACTIONS, NONE } from '../class/constants.js';
import { exportToExcel } from '../class/tabulatorExport.js';
import { formatValString, formatValHTML } from '../class/format';
import { getVectorAllowedAttributes } from '../sections/manageAccess/ManageAccessUtils';
import PeriodsDropDown from '../sections/buildData/PeriodsDropDown';
import ExcelDetailsTable from './/ExcelDetailsTable.js';
import { getActualYear, getPeriodTextFromMonth } from '../class/date';
import { convertViewportToPx } from '../class/formatting';
import { linearizeHierarchy } from '../class/array';
import { getTableIcon } from '../newComponents/tabulatorComponents';

const $ = require('jquery');
const lang = getTranslationFile();
const _cnt = "cnt";
const price = "Price";
const cogs = "COGS";
const mix = "Mix";
const volume = "Volume";
const returns = "Returns";
const NEW_SALES = "New sales";
const NO_SALES = "No sales";
const price_returnname = "price";
const cogs_returnname = "COGS";
const volume_returnname = "volume";
const mix_returnname = "mix";
const returns_returnname = "returns_bridge";
const DESC="desc";

var VECTOR_ANALYSIS_COLUMNS = [
];

const drillCellObject = {
    title: "Drill",
    field: "drill",
    visible: true,
    headerSort: false,
    width: '1%', 
    download:false
}

class BridgeDrillTable extends Component {
    constructor(props) {
		super(props);
		this.state = {
			tableColumns: [],
            tableData: [],
		};
        this.getColumnFormatter = this.getColumnFormatter.bind(this);
      
        this.toggleColumns = this.toggleColumns.bind(this);
        this.getTabulator = this.getTabulator.bind(this);
        this.exportTableToExcel = this.exportTableToExcel.bind(this);
        this.compareObjects = this.compareObjects.bind(this);
        this.expandedColumns = [];
    }
    
 

    toggleColumns(columns, show) {
        for (var e in columns) {
            if (show) {
                this.tabulator.showColumn(columns[e])
            } else{
                this.tabulator.hideColumn(columns[e])

            }
        }
    }

    renderPeriodsDropDown=(e, cell)=>{
        let obj = this;
        obj.state.cell = cell;
         $('[id^="periods_comp_bridge_drillTable_"]').each(function() { // hiding previous opened components in cards component
            $(this).addClass("uk-hidden");
        });
        obj.showVectorsComp(e, cell);
    }

    showVectorsComp=(evt,cell)=> {
        let _this = this;
        $("#"+this.props.parentId+" .div-periods-drop-down").animate({ scrollTop: 0 }, "fast")
        $("#periods_comp_bridge_drill"+this.props.id).removeClass("uk-hidden");
        
          const clickedCell = evt.currentTarget;
          const cellRect = clickedCell.getBoundingClientRect();
          const topRelativeToWindow = cellRect.top;
          const leftRelativeToWindow = cellRect.left;

          let left = leftRelativeToWindow + 40 + 'px';
          let top = topRelativeToWindow;
          if (this.calculateSpace() < 100) {
              top = topRelativeToWindow - 130 + 'px';
          }
        $("#periods_comp_bridge_drill"+this.props.id).css({
            top: top,
            left: left
        });
        
        if(_this.periodRef){
            _this.periodRef.refreshSearch();
        }
        if(document.getElementById("filterText")){
            document.getElementById("filterText").focus();
        }
        if(_this.rowToDrillOnNumber >=0){// hide the previous drill icon -if exists-
            $("#drill_icon_"+_this.props.parentId+"_"+_this.rowToDrillOnNumber).css("display", "none");
        }
        let rowNumber = cell.getRow().getPosition();
        $("#drill_icon_"+_this.props.parentId+"_"+rowNumber).css("display", "block");// show drill icon on clicked cell
        _this.rowToDrillOnNumber = rowNumber;// save click position
    }

    calculateSpace=()=> {
      // Get the div element
      const divElement = document.getElementById('periods_comp_bridge_drill' + this.props.id);
    
      // Get the bottom position of the div relative to the viewport
      const divBottomPosition = divElement.getBoundingClientRect().bottom;
    
      // Get the inner height of the window
      const windowHeight = window.innerHeight;
    
      // Calculate the space between the div and the bottom of the window
      const space = windowHeight - divBottomPosition;
            
      return space
    }

    getColumnAccessor(colField, groupCol, colTitle) {
        let columnFormatter;
        columnFormatter = function (value, data) {
            if(!Array.isArray(value)){
                return formatValString(value, groupCol.format_type);
            }
            return value;
        }
        return columnFormatter;
    }

    getColumnFormatter(colField, colType, title) {
        let columnFormatter;
        let obj = this;
        switch (colField) {
            case "drill":
                columnFormatter = function(cell, formatterParams) {
                    let rowNumber = cell.getRow().getPosition();
                    cell.getElement().classList.add("dt-body-center","to-hide-on-drill","no-sort");
                    cell.getColumn().getElement().classList.add("hidden-column-title"); //keep column title, just hide it from UI
                    let drillIcon = getTableIcon(["uk-button-icon", "far", "fa-level-down-alt","fa-lg","transparent-bg"]);
                    let drillIconContainer = document.createElement("div");
                    drillIcon.id = "drill_icon_"+obj.props.parentId+"_"+(obj.props.index >-1? obj.props.index+"_":"") +rowNumber;
                    drillIconContainer.id = "period-drop-down"+obj.props.parentId+(obj.props.idex>-1? obj.props.index : "");
                    if (obj.props.isDrillBridgeReport) {
                        drillIcon.setAttribute('uk-tooltip',lang.reset_and_drill_again);
                    }
                    if(obj.props.allowDrill && !(cell.getRow().getData()[PSL_RETURN_NAMES.NAME] === undefined && cell.getRow().getData()[PSL_RETURN_NAMES.NUMBER] === undefined
                        && (cell.getRow().getData()[PSL_RETURN_NAMES.QUADRANT] === "N/A" || cell.getRow().getData()[PSL_RETURN_NAMES.QUADRANT_TIER] === "N/A")) 
                        && (cell.getRow().getData()[PSL_RETURN_NAMES.NAME] !== undefined || cell.getRow().getData()[PSL_RETURN_NAMES.NUMBER] !== undefined|| cell.getRow().getData()[PSL_RETURN_NAMES.QUADRANT] !== undefined|| cell.getRow().getData()[PSL_RETURN_NAMES.QUADRANT_TIER] !== undefined)) {
                        
                        drillIconContainer.onclick = (e) => obj.renderPeriodsDropDown(e, cell);//render the dropdown
                        drillIcon.classList.remove("disabled-with-events");
                        drillIconContainer.setAttribute('uk-tooltip',lang.COMMON.DRILL)
                    } else {
                        drillIcon.classList.add("disabled-with-events");
                        if ((cell.getRow().getData()[PSL_RETURN_NAMES.NAME] === undefined && cell.getRow().getData()[PSL_RETURN_NAMES.NUMBER] === undefined && (cell.getRow().getData()[PSL_RETURN_NAMES.QUADRANT] === "N/A" || cell.getRow().getData()[PSL_RETURN_NAMES.QUADRANT_TIER] === "N/A"))) {
                            drillIconContainer.title = lang.COMMON.drill_not_supported_list_q;
                        }else if ((cell.getRow().getData()[PSL_RETURN_NAMES.NAME]=== undefined && cell.getRow().getData()[PSL_RETURN_NAMES.NUMBER] === undefined && cell.getRow().getData()[PSL_RETURN_NAMES.QUADRANT]=== undefined && cell.getRow().getData()[PSL_RETURN_NAMES.QUADRANT_TIER] === undefined)) {
                            drillIconContainer.title = lang.COMMON.drill_not_supported_main;
                        }else {
                            drillIconContainer.title = lang.COMMON.drill_not_supported;
                        }
                    }

                    if(!obj.props.isDrilling) {
                        drillIcon.setAttribute("ui_tracking", UI_ACTIONS.PRE_DRILL);   //add tracking for pre_drill from list
                        drillIcon.classList.add("navigableTop","drillItem");
                    } else {
                        drillIconContainer.classList.add("navigableTop","drillItemDTable");
                    }
                    if (!obj.props.isDrillBridgeReport && obj.props.index === undefined) {
                        drillIcon.style.display = 'none';
                    }

                    drillIconContainer.appendChild(drillIcon);
                    drillIconContainer.addEventListener("mouseover",event=>{
                        $("#drill_icon_"+obj.props.parentId+"_"+rowNumber).css("display", "block");
                    })
                    drillIconContainer.addEventListener("mouseout",event=>{
                        $("#drill_icon_"+obj.props.parentId+"_"+rowNumber).css("display", "none");
                    })
                    return drillIconContainer;
                };
                break;
                default:
                    columnFormatter = function (cell, formatterParams) {
                        let p = document.createElement("p");
                        if (colField && (colField.toLowerCase() === VECTOR_ANALYSIS.FIELDS.NUMBER.toLowerCase() || colField.toLowerCase() === VECTOR_ANALYSIS.FIELDS.NAME.toLowerCase())) {
                            p.innerHTML = cell.getValue();
                        } else {
                            p.style.width = "100%";
                            p.style.textAlign = "right";
                            if (colField && (colField.includes(obj.props.bridge) || title.endsWith("Effect"))) {
                                p.innerHTML = formatValHTML(cell.getValue(), colType);
                                p.classList.add("bold-text");
                            } else if (colField) {
                                p.innerHTML = formatValHTML(cell.getValue(), colType);
                            }
                        }
                        return p;
                    }
                break;
        }
        return columnFormatter;
    }
    
     /**
     * add listener on hover of rows, to show and hide the checkbox and the drill icon on hover
     * @param {*} rowNumber 
     */
    addListenersOnHover=(rowNumber)=>{
        let obj = this;
        if(obj.props.index === undefined){
            let drillElement = document.getElementById("drill_icon_"+obj.props.parentId+"_"+ rowNumber);
            if(drillElement && drillElement.parentElement){
                drillElement.parentElement.parentElement.parentElement.addEventListener("mouseover", event => {
                        $("#drill_icon_"+obj.props.parentId+"_"+rowNumber).css("display", "block");

                });
                drillElement.parentElement.parentElement.parentElement.addEventListener("mouseout", event => {
                    if(rowNumber !== obj.rowToDrillOnNumber){// only hide the drill icon on mouseover if it's not the line we clicked on
                        $("#drill_icon_"+obj.props.parentId+"_"+rowNumber).css("display", "none");
                    }
                });
            }
        }
    }

    /**
     * function display the column name as empty in drill and checkbox columns 
     * @param {*} cell 
     * @param {*} params 
     * @returns 
     */
     addEmptyTitleFormatter=(cell)=>{
        let div = document.createElement("div");
        div.innerHTML = "";
        return div;
    }

    showHideTexts = (label)=>{
        let arr=[];
        let inSwitch = false;
        switch(label){
            case price:
                inSwitch = true;
                arr = ["average_units_price","alpha_average_units_price","beta_average_units_price","delta_average_units_price","beta_units","price"]
            break;
            case cogs:
                inSwitch = true;
                arr = ["average_units_cogs","alpha_average_units_cogs","beta_average_units_cogs","delta_average_units_cogs","beta_units","COGS"];
            break;
            case volume:
                inSwitch = true;
                arr = ["alpha_percentage_total_units","beta_total_units","alpha_units_at_beta_volume","alpha_average_units_selling_margin","alpha_selling_margin_at_beta_mix","alpha_selling_margin","volume"]
            break;
            case mix:
                inSwitch = true;
                arr = ["alpha_percentage_total_units","beta_total_units","alpha_units_at_beta_volume","beta_units","alpha_average_units_selling_margin","mix_effect_on_units","mix"];
            break;
            case returns:
                inSwitch = true;
                arr = ["returns_alpha", "returns_beta", "returns","returns_bridge"];
            break;
        }
        if (!inSwitch) {
            arr.push((label.replaceAll(' ','_').replaceAll('&','_'))+"_alpha");
            arr.push((label.replaceAll(' ','_').replaceAll('&','_'))+"_beta");
            arr.push(label.replaceAll(' ','_').replaceAll('&','_'));
        }
        arr.forEach(att=>{
            $('[class^=levers_'+att+']').each(function() { 
                    $(this).removeClass("uk-hidden");
            });
        })
    }

    setColumns(columns, data, barLabel, bridgeName) {
        var obj = this;
        let className = "";
        let counter = 0;
        columns.forEach(col => {
            if(col && col.field !== 'drill'){
                counter++;
            }
            if (col && col.field.toLowerCase().includes(VECTOR_ANALYSIS.FIELDS.NAME)) {
                // col.cssClass = "name-cell";
                // col.width='12%';
            }
            if (col && col.field.toLowerCase().includes(VECTOR_ANALYSIS.FIELDS.NUMBER)) {
                // col.width = '10%'
            } 
            if(col && ["alpha_selling_margin_at_beta_mix", "alpha_average_units_selling_margin"].includes(col.field)) {
                col.width='12%';
            } else if(col && col.field === "drill"){
                col.width='3%';
                col.cssClass='unsetBack';
                col.titleFormatter = obj.addEmptyTitleFormatter;
            }else {
                col.width='8%';
            }
            if (counter === 1) {//add the legend in the header of the first column
                col.alphaLegend = obj.getLegendSpan(VECTOR_ANALYSIS.FIELDS.ALPHA_SYMBOL);
                col.betaLegend = obj.getLegendSpan(VECTOR_ANALYSIS.FIELDS.BETA_SYMBOL);
                col.width = obj.props.isDrillBridgeReport? convertViewportToPx(350) : obj.props.DontAddDrill?'20%' : '18%';
            }else {
                col.alphaLegend = obj.getLegendSpan();
                col.betaLegend = obj.getLegendSpan();
            }
            if(col && !col.columns){
                col.cssClass='align-left';
            }
           

            if (col.field !== VECTOR_ANALYSIS.FIELDS.KEY && col.field !== VECTOR_ANALYSIS.FIELDS.NAME && col.field !== VECTOR_ANALYSIS.FIELDS.NUMBER && col.field !== VECTOR_ANALYSIS.FIELDS.PQS) {
                if (col.columns) {
                    col.columns.forEach(c => {
                        if(c?.field?.includes(PSL_RETURN_NAMES.QUADRANT_TIER) || c?.field?.includes(PSL_RETURN_NAMES.QUADRANT)){
                            c.accessor = obj.getColumnAccessor(c.field, c, c.title);
                        }
                        c.formatter = obj.getColumnFormatter(c.field, c.format_type, col.title);
                        if (c.field.toUpperCase().includes(VECTOR_ANALYSIS.TITLES.COMPARISON.toUpperCase()) || c.field.toUpperCase().split("_")[c.field.toUpperCase().split("_").length-1].includes((VECTOR_ANALYSIS.TITLES.SELECTION.toUpperCase()))) {
                            c.visible = false;
                        }
                        c.title = c.title.replaceAll("_"," ");
                        c.width='4%';
                        if (col.field === obj.props.bridge) {
                            className="bold-text";
                            col.cssClass = "bold-text";
                        }else{
                            className = "";
                        }
                        if(this.props.isDrillBridgeReport){
                            c.headerSort = false;
                            c.dontFilter = true;
                        }else{
                            c.headerSort = true;
                            c.dontFilter = false;
                        }
                        obj.props.getSymbolsFormatter(c, obj);
                    });
                   
                }
            }else if (obj.props.comparisonValue !== YEAR_OVER_YEAR && obj.props.comparisonValue !== PREVIOUS_ADJACENT && col.field === VECTOR_ANALYSIS.FIELDS.PQS) {
                col.visible = true;
            }
            col.title = col.title.replaceAll("_"," ");
            if (!col.columns) {
                col.bold = col.title.endsWith("Effect");
                col.formatter = this.getColumnFormatter(col.field, col.format_type, col.title)

            }
            if(this.props.isDrillBridgeReport){
                col.headerSort = false;
                col.dontFilter = true;
            }else{
                col.headerSort = true;
                col.dontFilter = false;
            }
        });
        if(!columns.includes(drillCellObject) && !this.props.DontAddDrill){
            columns.unshift(drillCellObject);       //add the drill column as first column
        }
        columns = cleanUpTabulatorColumns(columns, data,this.refreshFilterDivs, this.getTabulator, {id: "bridge_drill_table"+(this.props.isDrillBridgeReport?"_card":"")});
        columns.forEach(col => {
            if(col?.field?.includes(PSL_RETURN_NAMES.QUADRANT_TIER) || col?.field?.includes(PSL_RETURN_NAMES.QUADRANT)){
                col.accessor = obj.getColumnAccessor(col.field, col, col.title);
            }
            if (col.field !== VECTOR_ANALYSIS.FIELDS.KEY && col.field !== VECTOR_ANALYSIS.FIELDS.NAME && col.field !== VECTOR_ANALYSIS.FIELDS.NUMBER && col.field !== VECTOR_ANALYSIS.FIELDS.PQS) {
                if (col.columns) {
                    col.columns.forEach(c => {
                        c.formatter = obj.getColumnFormatter(c.field, c.format_type, col.title);
                    });
                }
            }
            if (!col.columns) {
                col.bold = col.title.endsWith("Effect");
                col.formatter = obj.getColumnFormatter(col.field, col.format_type, col.title);
            }
            if(col.field === "drill"){
                col.width='3%';
                col.cssClass='unsetBack';
                col.titleFormatter = obj.addEmptyTitleFormatter;
            }
            if(col.columns){
                col.headerClick = function(e, column){
                    obj.tabulator.replaceData(obj.tabulator.getData());
                    let isExpanding = e.currentTarget.children && e.currentTarget.children[1] && !e.currentTarget.children[1].children[1].innerText.includes("ClearApply")? true: false;
                    let alpha = column.getField()+"_"+VECTOR_ANALYSIS.FIELDS.ALPHA;
                    let beta = column.getField()+"_"+VECTOR_ANALYSIS.FIELDS.BETA;
                    if(isExpanding){
                        if(!obj.expandedColumns.includes(column.getField())){
                            obj.expandedColumns.push(column.getField());
                        }
                        $('[class^= '+alpha+' ]').each(function() { 
                            $(this).removeClass("uk-hidden");
                        });
                        $('[class^= '+beta+' ]').each(function() { 
                            $(this).removeClass("uk-hidden");
                        });
                        $('[class^= levers_'+VECTOR_ANALYSIS.FIELDS.ALPHA+"_"+column.getField()+' ]').each(function() { 
                            $(this).removeClass("uk-hidden");
                        });
                        $('[class^= levers_'+VECTOR_ANALYSIS.FIELDS.BETA+"_"+column.getField()+' ]').each(function() { 
                            $(this).removeClass("uk-hidden");
                        });
                    }else{
                        let index = obj.expandedColumns.indexOf(column.getField());
                        if (index > -1) {
                            obj.expandedColumns.splice(index, 1);
                        }
                        $('[class^= '+alpha+' ]').each(function() { 
                            if(!$(this).hasClass("uk-hidden")){
                                $(this).addClass("uk-hidden");
                            }
                        });
                        $('[class^= '+beta+' ]').each(function() { 
                            if(!$(this).hasClass("uk-hidden")){
                                $(this).addClass("uk-hidden");
                            }
                        });
                        $('[class^= levers_'+VECTOR_ANALYSIS.FIELDS.ALPHA+"_"+column.getField()+' ]').each(function() { 
                            if(!$(this).hasClass("uk-hidden")){
                                $(this).addClass("uk-hidden");
                            }
                        });
                        $('[class^= levers_'+VECTOR_ANALYSIS.FIELDS.BETA+"_"+column.getField()+' ]').each(function() { 
                            if(!$(this).hasClass("uk-hidden")){
                                $(this).addClass("uk-hidden");
                            }
                        });
                    }

                }
            }
        });
        this.tabulator.setColumns(columns);
        this.showHideColumns(barLabel, bridgeName);
        if(this.props.isTableFullScreen && !this.props.DontAddDrill && !this.props.isDrillBridgeReport){           // to avoid rerendering the table when entering or exiting the full screen mode
            if(!$('.legend').hasClass("uk-hidden")){
                $('.legend').addClass("uk-hidden");
            }
        }else{
            $('.legend').removeClass("uk-hidden");
        }
    }

    showHideColumns=(label, bridgeName)=>{
        let obj = this;
        let columns = obj.tabulator.getColumnDefinitions();
        columns.forEach(col => {
            if (col.field !== VECTOR_ANALYSIS.FIELDS.KEY && col.field !== VECTOR_ANALYSIS.FIELDS.NAME && col.field !== VECTOR_ANALYSIS.FIELDS.NUMBER && col.field !== VECTOR_ANALYSIS.FIELDS.PQS) {
                if (col.columns) {
                    col.columns.forEach(c => {
                        if(!label.includes(bridgeName) && ![NO_SALES,NEW_SALES].includes(label)){
                            obj.tabulator.hideColumn(c.field);
                        }
                    });
                }
            }
            if (!col.field.toLowerCase().includes("name") && !col.field.toLowerCase().includes("number") && label&& !label.includes(bridgeName) && ![NO_SALES,NEW_SALES].includes(label)) {
                if (label !== "" && !label.includes(bridgeName) && ![NO_SALES,NEW_SALES].includes(label)){
                    obj.tabulator.hideColumn(col.field);
                }else{
                    obj.tabulator.showColumn(col.field);
                }
            }
        });
        if (columns.length > 0 && label && !label.includes(bridgeName) && ![NO_SALES,NEW_SALES].includes(label)) {
            let inSwitch = false;
            switch(label){
                case price:
                    this.tabulator.showColumn("average_units_price");
                    this.tabulator.showColumn("alpha_average_units_price");
                    this.tabulator.showColumn("beta_average_units_price");
                    this.tabulator.showColumn("delta_average_units_price");
                    this.tabulator.showColumn("beta_units");
                    this.tabulator.showColumn("price");
                    inSwitch  = true;
                break;
                case cogs:
                    this.tabulator.showColumn("average_units_cogs");
                    this.tabulator.showColumn("alpha_average_units_cogs");
                    this.tabulator.showColumn("beta_average_units_cogs");
                    this.tabulator.showColumn("delta_average_units_cogs");
                    this.tabulator.showColumn("beta_units");
                    this.tabulator.showColumn("COGS");
                    inSwitch  = true;
                break;
                case volume:
                    this.tabulator.showColumn("alpha_percentage_total_units");
                    this.tabulator.showColumn("beta_total_units");
                    this.tabulator.showColumn("alpha_units_at_beta_volume");
                    this.tabulator.showColumn("alpha_average_units_selling_margin");
                    this.tabulator.showColumn("alpha_selling_margin_at_beta_mix");
                    this.tabulator.showColumn("alpha_selling_margin");
                    this.tabulator.showColumn("volume");
                    inSwitch  = true;
                break;
                case mix:
                    this.tabulator.showColumn("alpha_percentage_total_units");
                    this.tabulator.showColumn("beta_total_units");
                    this.tabulator.showColumn("alpha_units_at_beta_volume");
                    this.tabulator.showColumn("beta_units");
                    this.tabulator.showColumn("alpha_average_units_selling_margin");
                    this.tabulator.showColumn("mix_effect_on_units");
                    this.tabulator.showColumn("mix");
                    inSwitch  = true;
                break;
                case returns:
                    this.tabulator.showColumn("returns");
                    this.tabulator.showColumn("returns_bridge");
                    inSwitch  = true;
                break;
            }
            if (!inSwitch) {
                this.tabulator.showColumn(label.replaceAll(' ','_').replaceAll('&','_'));
                this.tabulator.showColumn(label.replaceAll(' ','_').replaceAll('&','_'));
            }
            obj.expandedColumns = [];
        }
        
    }
    
    getTabulator() {
		if(this.tabulator)
			return this.tabulator;
		return null;
    }
    
    compareObjects(columns1, columns2) {
        if(columns1.length !== columns2.length) {
            return false;
        }
        for (var e in columns1) {
            if (columns1[e].title !== columns2[e].title) {
                return false;
            }
        }
        return true;
    }

  
    toggleForbiddenIdentifierColumns() {
        let columns = copyObjectValues(VECTOR_ANALYSIS_COLUMNS);
        let vectorGroup = this.props.vectorObj[VECTOR_STAGING_ATTRIBUTES.GROUP]
        let allowedAttrs = getVectorAllowedAttributes(this.props.mixVector, vectorGroup, this.props.user);

        if(allowedAttrs.includes(IDENTIFIERS.NAME)) {
            this.tabulator.showColumn(VECTOR_ANALYSIS.FIELDS.NAME);
        } else {
            this.tabulator.hideColumn(VECTOR_ANALYSIS.FIELDS.NAME);
        }
        if(allowedAttrs.includes(IDENTIFIERS.NUMBER)) {
            this.tabulator.showColumn(VECTOR_ANALYSIS.FIELDS.NUMBER);
        } else {
            this.tabulator.hideColumn(VECTOR_ANALYSIS.FIELDS.NUMBER);
        }

        return columns;
    }
	setSortForLabel=(label)=>{
        let inSwitch = false;
        if (this.props.barLabel.includes(this.props.bridgeName) || [NO_SALES,NEW_SALES].includes(this.props.barLabel)){
            this.tabulator.setSort(this.props.bridge + comparison_suffixes.difference, DESC);
            inSwitch = true;
        }
        switch(label){
            case price:
                this.tabulator.setSort(price_returnname, DESC);
                inSwitch  = true;
            break;
            case cogs:
                this.tabulator.setSort(cogs_returnname, DESC);
                inSwitch  = true;
            break;
            case volume:
                this.tabulator.setSort(volume_returnname, DESC);
                inSwitch  = true;
            break;
            case mix:
                this.tabulator.setSort(mix_returnname, DESC);
                inSwitch  = true;
            break;
            case returns:
                this.tabulator.setSort(returns_returnname, DESC);
                inSwitch  = true;
            break;
        }
        if (!inSwitch) {
            this.tabulator.setSort(label.replaceAll(' ','_').replaceAll('&','_'), DESC);
        }
    }

    groupHeaderFunction =(groupName, count, data, cell)=> {
        let _this = this;
        var parentDiv = document.createElement("div");
        var div = document.createElement("div");
        var div2 = document.createElement('div');

        let dataObj = [];
        cell.getTable().getColumns().forEach(col=>{
            let field = col.getField();
            if(field && ![PSL_RETURN_NAMES.NAME, PSL_RETURN_NAMES.NUMBER, PSL_RETURN_NAMES.QUADRANT_TIER, PSL_RETURN_NAMES.QUADRANT, 'drill'].includes(field)){
                dataObj.push({field:field , value:getSummationOfAttribute(data,field), formatType:col.getDefinition().format_type});
            }
        })
        parentDiv.classList.add("uk-flex", "uk-flex-middle");
        div2.id = "groupHeaderDiv";
        div2.classList.add("uk-flex", "uk-flex-middle", "uk-margin-small-left", "right-0");
        let html = _this.addHeaderGroupText(_this.props.barLabel, dataObj);
        div2.innerHTML = html;
        div.innerHTML = data[0][_this.props.groupBy.value + "Name"];
        parentDiv.appendChild(div);
        parentDiv.appendChild(div2);
        return parentDiv;
    }

    /**
     * reshow the totals of expanded columns when expanding or collapsing a group
     */
    onExpandGroup=()=>{
        let obj = this;
        if(obj.expandedColumns.length>0){
            obj.expandedColumns.forEach(column=>{
                let alpha = column+"_"+VECTOR_ANALYSIS.FIELDS.ALPHA;
                let beta = column+"_"+VECTOR_ANALYSIS.FIELDS.BETA;
                $('[class^= '+alpha+' ]').each(function() { 
                    $(this).removeClass("uk-hidden");
                });
                $('[class^= '+beta+' ]').each(function() { 
                    $(this).removeClass("uk-hidden");
                });
                $('[class^= levers_'+VECTOR_ANALYSIS.FIELDS.ALPHA+"_"+column+' ]').each(function() { 
                    $(this).removeClass("uk-hidden");
                });
                $('[class^= levers_'+VECTOR_ANALYSIS.FIELDS.BETA+"_"+column+' ]').each(function() { 
                    $(this).removeClass("uk-hidden");
                });
            })
        }
    }

    groupHeaderFunctionDownload =(value, count, data, group)=> {
        let _this = this;
        var parentDiv = document.createElement("div");
        var div = document.createElement("div");
        var div2 = document.createElement('div');

        let dataObj = [];
        _this.getTabulator().getColumns().forEach(col=>{
            let field = col.getField();
            if(field && ![PSL_RETURN_NAMES.NAME, PSL_RETURN_NAMES.NUMBER, PSL_RETURN_NAMES.QUADRANT_TIER, PSL_RETURN_NAMES.QUADRANT, 'drill'].includes(field)){
                dataObj.push({field:field , value:getSummationOfAttribute(data,field), formatType:col.getDefinition().format_type});
            }
        })
        parentDiv.classList.add("uk-flex", "uk-flex-middle");
        div2.classList.add("uk-flex", "uk-flex-middle", "uk-margin-small-left");
        let html = _this.addHeaderGroupText(_this.props.barLabel, dataObj, true);
       
        return ""+data[0][_this.props.groupBy.value + "Name"]+"  "+html+"";
    }

    hideAllAndShowWanted=()=>{
        $('[class^= "levers_" ]').each(function() { // hide all groupHeader texts
            if(!$(this).hasClass("uk-hidden")){
                $(this).addClass("uk-hidden");
            }
        });
        if(this.props.groupBy && this.props.groupBy.value !== NONE.value && !(this.props.barLabel && this.props.barLabel.includes(this.props.bridgeName) || [NO_SALES,NEW_SALES].includes(this.props.barLabel))){
            this.showHideTexts(this.props.barLabel);// show groupHeader texts based on the barlabel
        }
    }

	componentDidUpdate(prevProps, prevState) {
        const _this = this;
        if(this.props.isTableFullScreen && !this.props.DontAddDrill && !this.props.isDrillBridgeReport){           // to avoid rerendering the table when entering or exiting the full screen mode
            if(!$('.legend').hasClass("uk-hidden")){
                $('.legend').addClass("uk-hidden");
            }
        }else{
            $('.legend').removeClass("uk-hidden");
        }
        if (this.props.data && !prevProps.data || this.props.data && (this.props.data.id !== prevProps.data.id || JSON.stringify(this.props.groupBy) !== JSON.stringify(prevProps.groupBy))) {
            let options = this.tabulator.options;
            if (this.props.groupBy && this.props.groupBy.value !== NONE.value) {
                options.groupBy = [this.props.groupBy.value+"Number"];
                options.groupStartOpen = false;
                options.groupToggleElement = "header";
                options.groupHeader = this.groupHeaderFunction;
                options.groupHeaderDownload = _this.groupHeaderFunctionDownload;
            }else{
                options.groupBy=false
            }
            this.tabulator = new Tabulator(this.props.isDrillBridgeReport? "#bridge_drill_table_card":  "#bridge_drill_table", options);//CHANGE
            var order = this.props.data && this.props.data.order? this.props.data.order.toLowerCase() : ORDER_VALUES.DESC.toLowerCase();
            if (this.props.data && prevState.data && this.props.data.columns && prevState.data.columns) {
                this.setColumns(this.props.data.columns, this.props.data.data, this.props.barLabel, this.props.bridgeName);
                this.tabulator.replaceData(this.props.data.data);
                this.toggleForbiddenIdentifierColumns();
                this.setState({
                    data: this.props.data
                },function(){
                    _this.tabulator.clearFilter(true);
                    prevProps.barLabel.includes(prevProps.bridgeName) || [NO_SALES,NEW_SALES].includes(prevProps.barLabel)
                    if (this.props.barLabel.includes(this.props.bridgeName) || [NO_SALES,NEW_SALES].includes(this.props.barLabel)) {
                        _this.tabulator.setSort(this.props.bridge + comparison_suffixes.difference, order);
                    }else{
                        _this.setSortForLabel(this.props.barLabel);
                    }
                    _this.addFooterText(this.props.barLabel);
                    _this.hideAllAndShowWanted();
                })
            } else {
                let options = this.tabulator.options;
                if (this.props.groupBy && this.props.groupBy.value !== NONE.value) {
                    options.groupBy = [this.props.groupBy.value+"Number"];
                    options.groupStartOpen = false;
                    options.groupToggleElement = "header";
                    options.groupHeader = this.groupHeaderFunction;

                }else{
                    options.groupBy=false
                }
                this.tabulator = new Tabulator(this.props.isDrillBridgeReport? "#bridge_drill_table_card":  "#bridge_drill_table", options);//CHANGE
                this.setColumns(this.props.data.columns,this.props.data.data, this.props.barLabel, this.props.bridgeName);//CHANGE
                this.setState({
                    data: this.props.data
                },function(){
                    this.tabulator.replaceData(this.props.data.data);
                    _this.tabulator.clearFilter(true);
                    //CHANGE
                    this.toggleForbiddenIdentifierColumns();
                     if (this.props.barLabel.includes(this.props.bridgeName) || [NO_SALES,NEW_SALES].includes(this.props.barLabel)) {
                        _this.tabulator.setSort(this.props.bridge + comparison_suffixes.difference, order);
                    }else{
                        _this.setSortForLabel(this.props.barLabel);
                    }
                    _this.addFooterText(this.props.barLabel);
                    _this.hideAllAndShowWanted();
                });
            }
            if (![YEAR_OVER_YEAR, PREVIOUS_ADJACENT].includes(prevProps.comparisonValue)) {
                this.tabulator.showColumn(VECTOR_ANALYSIS.FIELDS.PQS);
            } else {
                this.tabulator.hideColumn(VECTOR_ANALYSIS.FIELDS.PQS);
            }
        }
        _this.tabulator.clearFilter(true);
        _this.addFooterText(this.props.barLabel);
    }

  
    
    onTabulatorRenderComplete=()=> {
        let _this = this;
        if(_this.tabulator && _this.tabulator.getRows('active')){// don't add listener to the not rendered rows (not rendered row don't have parent element)
            _this.tabulator.getRows('active').forEach(row =>{
                if(!_this.props.isDrillBridgeReport && !_this.props.DontAddDrill){
                    _this.addListenersOnHover(row.getPosition());
                }
            });
        }
        _this.hideAllAndShowWanted();
        _this.onExpandGroup();
        let visibleGroups =[];
        $('[id=groupHeaderDiv]').each(function() { 
            let arr = $(this) && $(this)[0]? $(this)[0].children:[];// arr contains all the group texts
            for (var e in arr){
                if(arr[e].className!==undefined && !arr[e].className.includes("uk-hidden")){
                    visibleGroups.push(arr[e]);// only visible group texts
                }
            }
            if(visibleGroups.length>0){
                visibleGroups[visibleGroups.length-1].innerHTML = visibleGroups[visibleGroups.length-1].innerHTML.replaceAll("|","");// remove the last | from the group texts
            }
        });
    }

    addFooterText=(barLabel, data=this.tabulator.getData("active"))=>{
        if(this.props.isDrillBridgeReport){
            return;
        }
        let _this = this;
        let chartData = this.props.chartData || [];
        let alphaAmount = 0;
        let betaAmount = 0;
        let deltaAmount = 0;
        let amount = 0;
        let totalsText = "";
        if (barLabel && barLabel.includes(this.props.bridgeName) || [NO_SALES,NEW_SALES].includes(barLabel)) {
            if (data.length > 0 && chartData.length === 0) {
                for (var e in data ) {
                    let val1 = data[e][_this.props.bridge+comparison_suffixes.comparison]  || (data[e]._row && data[e]._row.data[_this.props.bridge+comparison_suffixes.comparison]) || 0;
                    let val2 = data[e][_this.props.bridge+comparison_suffixes.selection] || (data[e]._row && data[e]._row.data[_this.props.bridge+comparison_suffixes.selection]) || 0;
                    alphaAmount += parseFloat(val1);
                    betaAmount += parseFloat(val2);
                }
                deltaAmount =  betaAmount - alphaAmount;
            }else{
                alphaAmount = chartData.length > 0 && chartData.filter(e=>e.returnName === comparison_suffixes.comparison.replace("_","")+"_"+_this.props.bridge) && chartData.filter(e=>e.returnName === comparison_suffixes.comparison.replace("_","")+"_"+_this.props.bridge)[0] ? chartData.filter(e=>e.returnName === comparison_suffixes.comparison.replace("_","")+"_"+_this.props.bridge)[0].value : "0";
                betaAmount = chartData.length > 0 && chartData.filter(e=>e.returnName === comparison_suffixes.selection.replace("_","")+"_"+_this.props.bridge) && chartData.filter(e=>e.returnName === comparison_suffixes.selection.replace("_","")+"_"+_this.props.bridge)[0]? chartData.filter(e=>e.returnName === comparison_suffixes.selection.replace("_","")+"_"+_this.props.bridge)[0].value : "0";
                deltaAmount = betaAmount - alphaAmount; 
            }
        }else{
            if (data.length > 0 && chartData.length === 0) {
                for (var e in data) {
                    let row = (data[e]._row && data[e]._row.data) || data[e];
                    let value = row[barLabel.toLowerCase().replace(/ /g,'_').replace(/&/g,'_')+"_bridge"] || row[barLabel.toLowerCase().replace(/ /g,'_').replace(/&/g,'_')] || row[barLabel.replace(/ /g,'_').replace(/&/g,'_')] || 0;
                    amount += parseFloat(value);
                }
            }else{
                amount = chartData.length > 0 ? chartData.filter(e=>e.name === barLabel).length > 0 ? chartData.filter(e=>e.name ===barLabel)[0].value :"0": "0";
            }
        }
        if (data.length >0){
            totalsText ="<span class=\"uk-margin-small-right\">" + formatValString(data[0][_cnt] || data.length,FormatTypes.NUMERIC) +" records "+ 
            "</span>" + " " + "<span class='uk-text-bold'> Totals </span>" 
            +(barLabel.includes(this.props.bridgeName) || [NO_SALES,NEW_SALES].includes(barLabel) ?"<span class='uk-text-bolder fs-14 blueText uk-margin-small-left'>α </span>"+"<span>" + this.props.bridgeName + ": " +formatValString(alphaAmount, FormatTypes.AMOUNT)+ " </span>":"") 
            + (barLabel.includes(this.props.bridgeName) || [NO_SALES,NEW_SALES].includes(barLabel)?"<span class='uk-text-bolder fs-14 redText uk-margin-small-left'>β </span>"+"<span>" + this.props.bridgeName + ": " +formatValString(betaAmount, FormatTypes.AMOUNT)+ " </span>":"")
            + (barLabel.includes(this.props.bridgeName) || [NO_SALES,NEW_SALES].includes(barLabel)?"<span class='uk-text-bolder fs-14 black uk-margin-small-left'>Δ </span>"+"<span>" + this.props.bridgeName + ": " +formatValString(deltaAmount, FormatTypes.AMOUNT)+ " </span>":"") ;
            if (!barLabel.includes(this.props.bridgeName) && ![NO_SALES,NEW_SALES].includes(barLabel)) {
                totalsText+= "<span>"+barLabel + (["COGS","Price","Volume","Mix"].includes(barLabel) ? " Effect" : "")+"</span><span>: "+formatValString(amount, FormatTypes.AMOUNT)+"</span>"
            }
        }else{
            totalsText = "<span class=\"uk-margin-small-right\">" + "- records "+ 
            "</span>" + " " + "<span class='uk-text-bold'> Totals </span>" 
            +(barLabel.includes(this.props.bridgeName) || [NO_SALES,NEW_SALES].includes(barLabel) ?"<span class='uk-text-bolder fs-14 blueText uk-margin-small-left'>α </span>"+"<span>" + this.props.bridgeName + ": - </span>":"") 
            + (barLabel.includes(this.props.bridgeName) || [NO_SALES,NEW_SALES].includes(barLabel)?"<span class='uk-text-bolder fs-14 redText uk-margin-small-left'>β </span>"+"<span>" + this.props.bridgeName + ": - </span>":"")
            + (barLabel.includes(this.props.bridgeName) || [NO_SALES,NEW_SALES].includes(barLabel)?"<span class='uk-text-bolder fs-14 black uk-margin-small-left'>Δ </span>"+"<span>" + this.props.bridgeName + ": - </span>":"") ;
            if (!barLabel.includes(this.props.bridgeName) && ![NO_SALES,NEW_SALES].includes(barLabel)) {
                totalsText+= "<span>"+barLabel + (["COGS","Price","Volume","Mix"].includes(barLabel) ? " Effect" : "")+"</span><span>: - </span>"
            } 
        }
        document.getElementById('footerElement'+(this.props.isDrillBridgeReport?"_card":"")).innerHTML ="<p class='uk-margin-xlarge-right'>" + totalsText + "</p>";
        document.getElementById('footerElement'+(this.props.isDrillBridgeReport?"_card":"")).classList.add("uk-flex", "uk-flex-middle");  
    }

    /**
     * add the text next to the group title
     * @param {*} barLabel 
     * @param {*} data 
     * @returns 
     */
    addHeaderGroupText=(barLabel, data, isExport)=>{
        if(this.props.isDrillBridgeReport){
            return;
        }
        let _this = this;
        let alphaAmount = 0;
        let betaAmount = 0;
        let deltaAmount = 0;
        let amount = 0;
        let totalsText = "";
        let totalsTextLeveres = "";
        let formatType="";
        let columns = _this.props.data.columns;
        let bar = " | ";
        let originalCols = copyObjectValues(columns);
        columns = linearizeHierarchy(columns,"columns",true);
        let columnLenght = columns.length;
        for(var column in columns){
            // if(Number(column) === columnLenght-1){
            //     bar =""
            // }
            if (barLabel && barLabel.includes(_this.props.bridgeName) || [NO_SALES,NEW_SALES].includes(barLabel)) {
                if (data.length > 0) {
                        let field = originalCols.filter(e=>e.field.replaceAll("alpha_","").replaceAll("beta_","").replaceAll("delta_","").replaceAll("_bridge","") === columns[column].field.replaceAll("alpha_","").replaceAll("beta_","").replaceAll("delta_","").replaceAll("_bridge","")).length>0? originalCols.filter(e=>e.field.replaceAll("alpha_","").replaceAll("beta_","").replaceAll("delta_","").replaceAll("_bridge","") === columns[column].field.replaceAll("alpha_","").replaceAll("beta_","").replaceAll("delta_","").replaceAll("_bridge",""))[0].field :"";
                        let val1 = data.filter(e=>e.field === field+comparison_suffixes.comparison).length>0? data.filter(e=>e.field === field+comparison_suffixes.comparison)[0].value:0;
                        let val2 = data.filter(e=>e.field === field+comparison_suffixes.selection).length>0? data.filter(e=>e.field === field+comparison_suffixes.selection)[0].value:0;
                        alphaAmount = parseFloat(val1);
                        betaAmount = parseFloat(val2);
                    deltaAmount =  betaAmount - alphaAmount;
                }
            }
            else{
                if (data.length > 0) {
                    let filteredLine = data.filter(e=>e.field === columns[column].field);
                    amount = filteredLine.length>0 ? filteredLine[0].value : "";
                    formatType = filteredLine.length>0 ? filteredLine[0].formatType :""
                }
            }
            if(![PSL_RETURN_NAMES.QUADRANT_TIER,PSL_RETURN_NAMES.QUADRANT,PSL_RETURN_NAMES.NAME,PSL_RETURN_NAMES.NUMBER,'drill'].includes(columns[column].field) && !columns[column].field.includes(PSL_RETURN_NAMES.QUADRANT)  && !columns[column].field.includes(PSL_RETURN_NAMES.NAME) && !columns[column].field.includes(PSL_RETURN_NAMES.NUMBER)){
                let title = originalCols.filter(e=>e.field.replaceAll("alpha_","").replaceAll("beta_","").replaceAll("delta_","").replaceAll("_bridge","") === columns[column].field.replaceAll("alpha_","").replaceAll("beta_","").replaceAll("delta_","").replaceAll("_bridge","")).length>0? originalCols.filter(e=>e.field.replaceAll("alpha_","").replaceAll("beta_","").replaceAll("delta_","").replaceAll("_bridge","") === columns[column].field.replaceAll("alpha_","").replaceAll("beta_","").replaceAll("delta_","").replaceAll("_bridge",""))[0].title :"";
                if (data.length >0){
                    totalsText += [VECTOR_ANALYSIS.FIELDS.ALPHA_SYMBOL,VECTOR_ANALYSIS.FIELDS.BETA_SYMBOL,VECTOR_ANALYSIS.FIELDS.DELTA_SYMBOL].includes(columns[column].title)?"":(barLabel.includes(this.props.bridgeName) || [NO_SALES,NEW_SALES].includes(barLabel) ?
                    (!isExport ? "<div class='"+columns[column].field+"_alpha uk-hidden"+"'> <span class='uk-text-bolder fs-14 blueText uk-margin-small-left'>α </span>"+"<span>" + columns[column].title + ": " +formatValString(alphaAmount, FormatTypes.AMOUNT)+ " </span></div>"
                    : $($('[class^='+columns[column].field+'_alpha]')[0]).hasClass("uk-hidden") ? " " :
                    "α "+"" + columns[column].title + ": " +formatValString(alphaAmount, FormatTypes.AMOUNT)+ ""):"") 
                    + (barLabel.includes(this.props.bridgeName) || [NO_SALES,NEW_SALES].includes(barLabel)?
                    (!isExport ? "<div class='"+columns[column].field+"_beta uk-hidden"+"'> <span class='uk-text-bolder fs-14 redText uk-margin-small-left'>β </span>"+"<span>" + columns[column].title + ": " +formatValString(betaAmount, FormatTypes.AMOUNT)+ " </span> </div>" 
                    :$($('[class^='+columns[column].field+'_beta]')[0]).hasClass("uk-hidden") ? " " :
                    " β "+"" + columns[column].title + ": " +formatValString(betaAmount, FormatTypes.AMOUNT)+ " "):"")
                    + (barLabel.includes(this.props.bridgeName) || [NO_SALES,NEW_SALES].includes(barLabel)?
                    (!isExport ? "<span class='uk-text-bolder fs-14 black uk-margin-small-left'> Δ </span>"+"<span>" + columns[column].title + ": " +formatValString(deltaAmount, FormatTypes.AMOUNT)+ bar+ " </span>":
                    " Δ "+"" + columns[column].title + ": " +formatValString(deltaAmount, FormatTypes.AMOUNT)+ " "+bar):"") ;
                    if (!barLabel.includes(this.props.bridgeName) && ![NO_SALES,NEW_SALES].includes(barLabel)) {
                        // let className = columns[column].title.includes("α")?" redText":columns[column].title.includes("β")?" blueText": columns[column].title.includes("Δ")? " uk-text-bolder":"";
                        if(!columns[column].hasChildren){
                            totalsTextLeveres +=
                            (!isExport ? ("<div class='levers_"+columns[column].field+"'> <span class='"+(columns[column].title.includes("α") ? " blueText uk-text-bolder" : columns[column].title.includes("β") ? " redText uk-text-bolder" : " uk-text-bolder")+"'>" + (columns[column].title !== title?columns[column].title :"") + "</span><span> "+ title  +": </span><span> "+formatValString(amount, formatType || FormatTypes.AMOUNT)+bar+"  </span></div>")
                            : ($($('[class^=levers_'+columns[column].field+']')[0]).hasClass("uk-hidden") ? " " :
                            ((columns[column].title !== title?columns[column].title :"") + " "+ title  +":  "+formatValString(amount, formatType || FormatTypes.AMOUNT)+bar)))
                        }
                    }
                }else{
                    totalsText += (barLabel.includes(this.props.bridgeName) || [NO_SALES,NEW_SALES].includes(barLabel) ? 
                    (!isExport ? "<div class='"+columns[column].field+"_alpha uk-hidden"+"'> <span class='uk-text-bolder fs-14 blueText uk-margin-small-left'>α </span>"+"<span>" + columns[column].title + ": - </span></div>" :
                    $($('[class^='+columns[column].field+'_alpha]')[0]).hasClass("uk-hidden") ? " " :
                    "α " + columns[column].title + ": -"):"") 
                    + (barLabel.includes(this.props.bridgeName) || [NO_SALES,NEW_SALES].includes(barLabel)?
                    (!isExport ? "<div class='"+columns[column].field+"_beta uk-hidden"+"'> <span class='uk-text-bolder fs-14 redText uk-margin-small-left'>β </span>"+"<span>" + columns[column].title + ": - </span></div>"
                    :$($('[class^='+columns[column].field+'_beta]')[0]).hasClass("uk-hidden") ? " " :
                    " β"+"" + columns[column].title + ": - "):"")
                    + (barLabel.includes(this.props.bridgeName) || [NO_SALES,NEW_SALES].includes(barLabel)?
                    (!isExport ? "<span class='uk-text-bolder fs-14 black uk-margin-small-left'>Δ </span>"+"<span>" + columns[column].title +bar+ ": -  </span>"
                    :"Δ" + columns[column].title + ": - "+bar):"") ;
                    if (!barLabel.includes(this.props.bridgeName) && ![NO_SALES,NEW_SALES].includes(barLabel)) {
                        if(!columns[column].hasChildren){
                            totalsTextLeveres+= amount!=""? (!isExport ? 
                                "<div class='levers_"+columns[column].field+"'> <span>" +(columns[column].title !== title?columns[column].title
                                :$($('[class^=levers_'+columns[column].field+']')[0]).hasClass("uk-hidden") ? " " :
                                (columns[column].title !== title?columns[column].title :"") + " "+ title +": - ") :"") + " "+ title +(!isExport ? "</span><span>: -  </span></div>" :":-") + bar:"";
                        }
                    } 
                }
            }
        }

        if(totalsText.lastIndexOf("|")>0){
            totalsText = totalsText.slice(0, totalsText.lastIndexOf("|")) + totalsText.slice(totalsText.lastIndexOf("|") + 1);
        }
        if(totalsTextLeveres.lastIndexOf("|")>0){
            totalsTextLeveres = totalsTextLeveres.slice(0, totalsTextLeveres.lastIndexOf("|")) + totalsTextLeveres.slice(totalsTextLeveres.lastIndexOf("|") + 1);
        }    
        return barLabel.includes(_this.props.bridgeName) || [NO_SALES,NEW_SALES].includes(barLabel)? totalsText : totalsTextLeveres;
    }

	componentDidMount() {
        var obj = this;
        
		var options = {
			layout: "fitDataTable",      //fit columns to width of table
			responsiveLayout: false,  //hide columns that dont fit on the table
			addRowPos: "top",          //when adding a new row, add it to the top of the table
			history: true,             //allow undo and redo actions on the table
			// pagination: false, //"local",       //paginate the data
            pagination: this.props.isDrillBridgeReport? false :"local",       //paginate the data
            paginationSize: 50,
            paginationSizeSelector: [10,50,100],
            dataFiltered: function(filters, rows) {
                if(obj.tabulator && obj.tabulator.getData()) {
                    obj.addFooterText(obj.props.barLabel, obj.tabulator.getData()); // if we want the filtered data -> use rows
                }
            },
            movableColumns: false,     //allow column order to be changed
            autoResize: true,
			resizableRows: false,       //allow row order to be changed
			selectable: false,
			invalidOptionWarnings: false,
            initialSort: [
                {column: VECTOR_ANALYSIS.FIELDS.PROFIT_DIFFERENCE, dir:"desc"},
            ],            // columnHeaderSortMulti:true,
            reactiveData: true,      //tabulator listens to any change in the data array and updates the table
            virtualDomBuffer: 4000,
            columnHeaderVertAlign:"bottom",
			placeholder: lang.no_data_available,
            footerElement: "<div id=footerElement"+(this.props.isDrillBridgeReport?"_card":"")+"></div>",
			height: "100%",
            width: "100%",
            // virtualDom:false,
            renderComplete: obj.onTabulatorRenderComplete,

            rowMouseEnter: function(e,row) {
                obj.addListenersOnHover(row.getPosition());
            },
            downloadReady:function(fileContents, blob){
                toggleLoader(false, "tablesToExcel");
                return blob; //must return a blob to proceed with the download, return false to abort download
            }
        }
        if (this.props.groupBy && this.props.groupBy.value !== NONE.value) {//CHANGE
            options.groupBy = [this.props.groupBy.value];
			options.groupStartOpen = false;
            options.groupToggleElement = "header";
            options.groupHeader = this.groupHeaderFunction;

        }else{
            options.groupBy=false
        }
        obj.tabulator = new Tabulator(this.props.isDrillBridgeReport? "#bridge_drill_table_card":  "#bridge_drill_table", options);

        var tableColumns = cleanUpTabulatorColumns(copyObjectValues(VECTOR_ANALYSIS_COLUMNS), null, this.refreshFilterDivs, this.tabulator, {id: "bridge_drill_table"+(this.props.isDrillBridgeReport?"_card":"")});
      
        tableColumns.forEach(col => {
            if (col.field !== VECTOR_ANALYSIS.FIELDS.PQS) {
                col.formatter = obj.getColumnFormatter(col.field, col.format_type,col.title);
            }
        });
        this.tabulator.setColumns(tableColumns);
        this.tabulator.setData([]);
        if (this.props.data) {
            this.setColumns(VECTOR_ANALYSIS_COLUMNS.concat(this.props.data.columns),this.props.data.data, this.props.barLabel, this.props.bridgeName);
            this.tabulator.setData(this.props.data.data);  
        }
        if (![YEAR_OVER_YEAR, PREVIOUS_ADJACENT].includes(this.props.comparisonValue)) {
            this.tabulator.showColumn(VECTOR_ANALYSIS.FIELDS.PQS);
        }else{
            this.tabulator.hideColumn(VECTOR_ANALYSIS.FIELDS.PQS);
        }
        this.toggleForbiddenIdentifierColumns();
        obj.addFooterText(this.props.barLabel);
    }

    exportTableToExcel(exportOpts) {
        var sheets = {};
        sheets["Description"] = "#excel_details_table";
        sheets["Data"] = true;
        
        exportOpts.sheets = sheets;
        exportOpts.dataStartRow = 1;
        exportOpts.skipColumn = 0;
        exportOpts.fileName =  exportOpts.drillTitle.replaceAll(" ","_") + "_Drill";
        exportOpts.data = this.tabulator.getData();
        exportOpts.isProfitStack = false;
        exportOpts.dataStartRow = exportOpts.drillTitle === mix || exportOpts.drillTitle === volume ? 1 : 2;

        exportToExcel(exportOpts, this.tabulator);
	}

    exportTableToExcelDrill(exportOpts) {
        let _this = this;
        var sheets = {};
        sheets["Description"] = this.excelDetailsDrill.tabulator
        let mixVector = _this.props.vectorOptions && _this.props.vectorOptions.filter(e=>e.value === _this.props.mixVector) ?_this.props.vectorOptions.filter(e=>e.value === _this.props.mixVector)[0].label:"";
        sheets[mixVector] = this.props.mixVectorTabulator.current.tabulator;
        let drillVector = _this.props.vectorOptions && _this.props.vectorOptions.filter(e=>e.value === _this.props.drillVector) ?_this.props.vectorOptions.filter(e=>e.value === _this.props.drillVector)[0].label:"";
        sheets[drillVector] = this.tabulator
        
        sheets[drillVector] = true;
        exportOpts.sheets = sheets;
        exportOpts.dataStartRow = 1;
        exportOpts.skipColumn = 0;
        exportOpts.fileName =  exportOpts.drillTitle.replaceAll(" ","_") + "_Drill";
        exportOpts.data = this.tabulator.getData();
        exportOpts.Description = this.tabulator.getData();
        exportOpts.isProfitStack = false;
        exportOpts.dataStartRow = exportOpts.drillTitle === mix || exportOpts.drillTitle === volume ? 1 : 2;
        exportToExcel(exportOpts, this.tabulator);
	}

     /**
     * Returns the tooltip message based on the colValue
     * @param colValue
     * @returns {string}
     */

    getLegendSpan = (colValue) => {
        if(!colValue){
            return "<span></span>"
        }
        let _this = this;
        let legend = "<span> </span><span class='legend "+(this.props.DontAddDrill? "margin-13-left":"")+"'> <span class='uk-text-bolder'>" + colValue + ": &nbsp</span> <span >" + _this.props.getToolTipMessage(colValue) + "</span> </span>";
        return legend;
    }

    drill =(drillVector)=>{
        let obj = this;
        $("#period-drop-down"+obj.props.parentId+(obj.props.idex>-1? obj.props.index : "")).addClass("uk-hidden");
        if(obj.periodRef){
            obj.periodRef.refreshSearch();
        }
        this.rowToDrillOnNumber = -1;
        obj.props.setIsDrilling(true);
        obj.props.addNewCard(obj.state.cell.getRow().getData(),drillVector);
        if(this.props.isTableFullScreen && !this.props.DontAddDrill && !this.props.isDrillBridgeReport){           // to avoid rerendering the table when entering or exiting the full screen mode
            if(!$('.legend').hasClass("uk-hidden")){
                $('.legend').addClass("uk-hidden");
            }
        }else{
            $('.legend').removeClass("uk-hidden");
        }
        if($("#periods_comp_bridge_drill"+obj.props.id)){
            $("#periods_comp_bridge_drill"+obj.props.id).addClass("uk-hidden");
        }
    }
    
    hideVectorComp=(container, e, _this)=>{
        let container2 = $("#period-drop-down"+_this.props.parentId+(_this.props.idex>-1? _this.props.index : ""));
        // if the target of the click isn't the container nor a descendant of the container
        if ((!container.is(e.target) && container.has(e.target).length === 0) && (container2[0] && e.target && e.target.parentElement && container2[0].id !== e.target.parentElement.id)) {
            container.addClass("uk-hidden");
            if(_this.periodRef){
                _this.periodRef.refreshSearch();
            }
            if(this.rowToDrillOnNumber >=0){
                if(!_this.props.isDrillBridgeReport){
                    $("#drill_icon_"+this.props.parentId+"_" +this.rowToDrillOnNumber).css("display", "none");// hide drill icon when closing the dropdown
                }
                this.rowToDrillOnNumber = -1;
            }
        }
    }


	render() {
        let _this = this;
        var container =  $("#periods_comp_bridge_drill"+this.props.id);
        $(document).click(function(e) {
            _this.hideVectorComp(container, e, _this);
        });
		return(
            <div id={"bridge-drill-table-container"} className={!this.props.isDrillTableFullScreen ? "drill-container-full-screen" : ""}>
                <div id={"periods_comp_bridge_drill"+this.props.id} className={"hide-columns-dropdown uk-hidden custom-height"}>
                    <PeriodsDropDown  ref={el=>this.periodRef = el} funkName={_this.drill} periods={_this.props.vectorOptions? _this.props.vectorOptions.filter(e=>e.value !== NONE.value):""} 
                        placeHolderText={lang.search_vectors_placeholder} 
                        report = {lang.COMMON.LIST} 
                        />
                </div>
                {this.props.DontAddDrill?
                    <div className="uk-hidden">
                        <ExcelDetailsTable ref={el=>this.excelDetailsDrill= el} params = {this.props.getDownloadFormatterParams()}/>
                    </div>
                    :""
                }
                <div id={"bridge_drill_table"+(this.props.isDrillBridgeReport?"_card" : "")} className={"vector_analysis has_grouping " + (this.props.isDrilling ? "bridge_drill_drilling_table" : "")} ref="bridge_drill_table"/>
            </div>
		);
	}

}

export default BridgeDrillTable;