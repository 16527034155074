import { Box, Skeleton } from "@mui/material";
import { BUTTON_TYPE, BUTTON_VARIANT, SIZES } from "../../class/constants";
import Button from "../../newComponents/Button";
import PropTypes from "prop-types";
import { CheckableListSkeleton } from "../querySearchableList/CheckableListSkeleton";

const EntitiesSkeletonLoader = ({ backClick }) => {
  return (
    <div id="searchable-checkable-list">
      <Box sx={{ paddingX: "0.78125vw" }}>
        {/* Search Bar Skeleton */}
        <Skeleton variant="rounded" height={30} />

        <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
          <Button
            id="quick-filter-back-btn"
            variant={BUTTON_VARIANT.TERTIARY}
            size={SIZES.ICON}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={backClick}
            leftIcon={<i className={"far fa-chevron-left"} />}
          />
          <Skeleton variant="text" width="50%" />
        </Box>
      </Box>
      <CheckableListSkeleton numberOfItems={12} />
    </div>
  );
};
EntitiesSkeletonLoader.propTypes = {
  backClick: PropTypes.func.isRequired,
};

export { EntitiesSkeletonLoader };
