import { useEffect, useState } from "react";
import { getSectionExists, parseBoolean, tryParse } from "../class/utils";
import { APPLY_SCOPE, SCOPE_FILTERS } from "../class/constants";
import { formatAdvancedFilter, formatBasicFilter } from "../class/common";

/**
 * This hook allows me to know when we have a loader.
 * We created a listener that listens when the loader is toggled
 * if window._loading has changed we setDisabled = true
 * this was done because window._loading does not allow the component to rerender so we wont get the expected behavior.
 */
export function useLoading() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Add event listener
    const handleLoadingChange = () => {
      // Update your state or do other tasks
      setLoading((!!window._loading && Object.keys(window._loading).length > 0)  );
    };

    window.addEventListener("loadingChange", handleLoadingChange);

    // Cleanup: Remove event listener i-e unmount
    return () => {
      window.removeEventListener("loadingChange", handleLoadingChange);
    };
  }, []);
  
  return loading;
}

export function useAuthenticated(allowedLinks) {
  const[isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
      let section = window.location.pathname.split("/")[window.location.pathname.split("/").length-1];
      let authenticated = getSectionExists(allowedLinks, section);
      setIsAuthenticated(authenticated);
  },[]);

  return isAuthenticated;
}

/**
 * This hooks opens/closes the calendar dropdown
 * @returns 
 */
export function useCalendarToggle() {
  const hideCalendar = () => {
    $("#selection-range-container").removeClass("active-selection-range");
    $("#period_range_table").hide();
  }
  
  useEffect(() => {
    const hideCalendarOnOutsideClick = (e) => {
      if (
        $(e.target).parents().length !== 0 &&
        $(e.target).parents("button.calendar-quarter").length === 0 &&
        $(e.target).parents("#selection-range-container").length === 0 &&
        $("#period_table_container #period_range_table").length > 0 &&
        $(e.target).parents("button.arrows-container-quarter").length === 0 &&
        !e.target.classList.contains("arrows-container-quarter") &&
        !e.target.classList.contains("calendar-quarter")
      ) {
        $("#selection-range-container").removeClass("active-selection-range");
        $("#period_range_table").hide();
      }
    };
    $(document).on("click", hideCalendarOnOutsideClick);

    return () => {
      $(document).off("click", hideCalendarOnOutsideClick);
    };
  }, []);

  const toggleCalendar = () => {
    $("#selection-range-container").addClass("active-selection-range");
    $("#period_range_table").show();
  };

  return { toggleCalendar, hideCalendar };
}

export const useExportScopeFilter = (userSettings, datasetOptions, vectorOptions, psLinesOptions) => {
  const exportScopeFilter = () => {
    const useScope = userSettings.addScope; // scope feature flag

    if(!useScope) {
      return undefined;
    }

    const scopeFilter = tryParse(sessionStorage.getItem(SCOPE_FILTERS)) || [];
    const isScopeApplied = parseBoolean(sessionStorage.getItem(APPLY_SCOPE));

    if (!isScopeApplied || scopeFilter.length === 0) {
      return "None";
    }

    const hasAdvancedFilter = scopeFilter.some(f => !f.isBasicFilter);
    const userAllowedVectors = userSettings.user?.user_allowed_vectors;

    return hasAdvancedFilter
      ? formatAdvancedFilter(
          scopeFilter,
          userAllowedVectors,
          datasetOptions,
          vectorOptions,
          psLinesOptions
        )
      : formatBasicFilter(scopeFilter, userAllowedVectors);
  };

  return exportScopeFilter;
};