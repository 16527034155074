import React, { Component } from 'react';
import shortid from 'shortid';
import { ALL_WIDGETS, API_URL, BP_QUADRANT_TIER, BUTTON_TYPE, BUTTON_VARIANT, COLUMN_PROFILE, DIALOG_SIZE, FILTER, FY_VALUES, FormatTypes, HEADER_ELEMENT, MENU_ITEM, NONE, ORDER_VALUES, PREVIOUS_ADJACENT, ROLLING_SEGMENTS, SEGMENTS, SEGMENTS_TIERS, SELECTED_PROFILE_EROSION, SIZES, TOP_QUADRANT, TOP_QUADRANT_TIER, VECTOR_ANALYSIS, YEAR_OVER_YEAR, comparison_suffixes, header_comparison_values} from './class/constants';
import { extractFromFullQuarter, generatePeriods, generateQuarter, getActualYear, getFullQuarterFromStartEndQuarters, getGeneratedQuarterRange, getLastBuiltPeriodForSegments, getMonthName, getPeriodFromDate, getPeriodQuarter, getPeriodTextFromMonth, getQuarterFromDate, isValidDate, monthDiff} from './class/date';
import { checkBuiltPeriodAvailability, getDateFromPeriod, getValidPeriods, isPeriodBuilt } from './templateLayout/functions/periodFunctions';
import { formatValHTML } from './class/format';
import { convertPxToViewport } from './class/formatting';
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from "./class/networkUtils";
import { copyObjectValues, findOptionByKey, findOptionByKeyValue, getTranslationFile, removeAttributes, tryParse } from './class/utils';
import { Segment } from './components/Segment';
import Button from './newComponents/Button';
import Modal from './newComponents/Modal';
import ExcelDetailsTable from './tables/ExcelDetailsTable';
import VectorAnalysisTable from './tables/VectorAnalysisTable.js';
import BridgeReport from './sections/vectorAnalysis/BridgeReport';

import './styles/common.css';
import './styles/tabulatorFormattingStyles.css';
import './styles/vectorAnalysis.css';
const _yes = "YES";
const $ = require('jquery');
const lang = getTranslationFile();
const mainHeaderElements = {
    erosion_growth:  [HEADER_ELEMENT.VECTOR, HEADER_ELEMENT.COMPARISON, HEADER_ELEMENT.SELECTION_RANGE, HEADER_ELEMENT.GO, HEADER_ELEMENT.XLS, HEADER_ELEMENT.ADD_COLUMNS],
    bridge_report: [HEADER_ELEMENT.VECTOR, HEADER_ELEMENT.COMPARISON, HEADER_ELEMENT.SELECTION_RANGE, HEADER_ELEMENT.BRIDGE, HEADER_ELEMENT.MIX_VECTOR, HEADER_ELEMENT.GO, HEADER_ELEMENT.NAVIGATION_BACK]
};
const NEW_SALES = "New sales";
const NO_SALES = "No sales";
class ErosionAndGrowth extends Component  {
    constructor(props) {
        super(props);
		    this.initialState = {
          quadrantFilterDisabled: false,
          // reportTitle: ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.VECTOR_ANALYSIS,
          // hasDefaultHeaderValues: true,
          // displayFilter: false,
          showExtraColumns: [false, false, false],
          columnOriginalLength: 3,
          // filterFinal:"[]",
          legendItems: [],
          periods: [],
          isDrilling: false,
          // secondaryReportSettings: {
          //     shown: true,
          //     disabled: true,
          //     displayFilter: !this.props.isDashBoards,
          //     disabledMessage: this.props.comparisonValue === BP_QUADRANT_TIER ? lang.select_entity_to_view  : lang.select_entities_to_view 
          // }
        };
        this.state = Object.assign({}, this.state, this.initialState);
        this.isMainReport = { isVectorAnalysis: true}
        this.showExtraColumns = this.showExtraColumns.bind(this);
        this.setComparisonValue = this.setComparisonValue.bind(this);
        this.fetchReportData = this.fetchReportData.bind(this);
        this.computePriorPeriods = this.computePriorPeriods.bind(this);
        this.setSelectedItems = this.setSelectedItems.bind(this);
    }

    componentDidMount(){
        // super.componentDidMount();
        if (this.props.fetchDataOnMount) {
            this.fetchReportData();
            if(this.props.setShowGreyOverLay){
                this.props.setShowGreyOverLay(false);
            }
        }
    }

    showExtraColumns(show, columns, e) {
        var target = e;
        var index = target.currentTarget.parentElement.id.split("_")[1];
        this.state.showExtraColumns[index] = show;
        if (show) {
            $("#"+target.currentTarget.parentElement.id).css('width',convertPxToViewport(390))
        } else{
            $("#"+target.currentTarget.parentElement.id).css('width', convertPxToViewport(130))
        }
        this.pageComponent.toggleColumns(columns, show);
        this.forceUpdate();
    }

    fetchListsCallback() {
        var headers = this.props.isBridgeReport ? mainHeaderElements.bridge_report : mainHeaderElements.erosion_growth;
        this.setState({
            headerElements: headers
        },function(){
            if(this.headerRef.state.callOnMount){
                this.fetchReportData();
            }
        });
    }

    setComparisonValue(option) {
        var _this = this;
        if(!this.pageComponent) {
            return;
        } else {
          _this.props.setSelectReportDisabledMsg(option.value === BP_QUADRANT_TIER ? lang.select_entity_to_view  : lang.select_entities_to_view)
          _this.props.setSelectReportDisabled(true);
        }
    }

    /**
     * function that returns the legend items used in the vector analysis table and in the bridge report
     * @returns {*[]}
     */
    getLegendItems = () => {
        let _this = this;
        let legendItems = [];

        let alphaLegend = this.getLegendMessage(VECTOR_ANALYSIS.FIELDS.ALPHA_SYMBOL);
        let betaLegend = this.getLegendMessage(VECTOR_ANALYSIS.FIELDS.BETA_SYMBOL);
        let data = _this.bridgeRef && _this.bridgeRef.state.chartData ? _this.bridgeRef.state.chartData : [];
        let value = Number(data.filter(e => e.returnName.includes("selection_")).length > 0 ?
              data.filter(e => e.returnName.includes("selection_"))[0].value : "0") - Number(data.filter(e => e.returnName.includes("comparison_")).length > 0 ?
            data.filter(e => e.returnName.includes("comparison_"))[0].value : "0");

        let formattedValue = formatValHTML(value, FormatTypes.AMOUNT);
        let variance = "<span class='uk-text-bolder'>"+VECTOR_ANALYSIS.FIELDS.DELTA_SYMBOL + " " + findOptionByKey(this.props.bridgeOptionsState?.bridgeOptions, this.props.bridgeOptionsState?.bridge)["label"] + ":</span> " + formattedValue;

        legendItems.push(alphaLegend);
        legendItems.push(betaLegend);
        legendItems.push(variance);

        return legendItems;
    }

    getPeriodsObject = (customStartDate, customEndDate) => {
        let _this = this;
        let periods = [];
        let periods_yoy = [];
        let periods_pa = [];
        let quarter = "";
        let quarterPre = "";
        let quarterPreYOY = "";
        let months = FY_VALUES.M3;
        let datasetOptions = this.props.datasetState?.datasetOptions;
        let startDate = getValidPeriods(this.props.periodsStatusState, this.props.clientPeriodsState, customStartDate, customEndDate).startDate;
        let endDate = getValidPeriods(this.props.periodsStatusState, this.props.clientPeriodsState, customStartDate, customEndDate).endDate;
        let segmentPeriod = "";

       if (isValidDate(startDate) && isValidDate(endDate) && startDate && endDate) {
           let periodsCount = monthDiff(startDate, endDate) + 1;
           periods = generatePeriods(startDate, periodsCount);
           periods_yoy = generatePeriods(startDate, periodsCount,false);
           periods_pa = generatePeriods(startDate, periodsCount,true);
       }
       if (!!_this.props.clientPeriodsState?.periods && isValidDate(startDate) && isValidDate(endDate)) {
           let firstQuarter = getQuarterFromDate(startDate);
           let endQuarter = getQuarterFromDate(endDate);

           let firstPreQuarter = getPeriodQuarter(periods_pa[0]);
           let endPreQuarter = getPeriodQuarter(periods_pa[periods_pa.length-1]);

           let firstPreQuarterYOY = getPeriodQuarter(periods_yoy[0]);
           let endPreQuarterYOY = getPeriodQuarter(periods_yoy[periods_yoy.length-1]);

           let generatedRange = getGeneratedQuarterRange(datasetOptions, firstQuarter, endQuarter);
           let generatedRangePre = getGeneratedQuarterRange(datasetOptions, firstPreQuarter, endPreQuarter);
           let generatedRangePreYOY = getGeneratedQuarterRange(datasetOptions, firstPreQuarterYOY, endPreQuarterYOY);

           let fullQuarter = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRange[0], generatedRange[1]));
           let fullQuarterPre = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRangePre[0], generatedRangePre[1]));
           let fullQuarterPreYOY = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRangePreYOY[0], generatedRangePreYOY[1]));
           
           quarter = fullQuarter.quarter;
           quarterPre = fullQuarterPre.quarter;
           quarterPreYOY = fullQuarterPreYOY.quarter
           months = fullQuarter.months;

            let lastSelectedPeriod = periods[periods.length - 1];
            let builtPeriods = _this.props.periodsStatusState?.actuallyBuiltPeriods?.map(m => m.label);
            segmentPeriod = getLastBuiltPeriodForSegments(builtPeriods, lastSelectedPeriod, 12);
       }
       return {periods: periods, segmentPeriod:segmentPeriod, quarter:quarter, months:months,periods_yoy:periods_yoy,periods_pa:periods_pa,preQuarter:quarterPre, preQuarterYOY:quarterPreYOY};
   }

    computePriorPeriods(startQuarter, endQuarter, customStartDate, customEndDate) {
        let comparisonValue =  this.props.comparisonValue; //this.props.isDashBoards ?  this.props.comparisonValue : this.props.comparisonValue;
        
        // let headerRef = this.props.isDashBoards ? this.props.headerRef : this.headerRef;

        if (!this.props.isDashBoards) {
            let startDate = getDateFromPeriod(this.props?.periodsStatusState?.customStartDate, this.props?.periodsStatusState?.builtPeriods);
            let endDate = getDateFromPeriod(this.props?.periodsStatusState?.customEndDate, this.props?.periodsStatusState?.builtPeriods);
            startDate = startDate.start_date ? new Date(startDate.start_date) : startDate;
            endDate = endDate.end_date ? new Date(endDate.end_date) : endDate;
            let isStartPeriodAvailable = isPeriodBuilt(getPeriodFromDate(startDate), this.props?.periodsStatusState?.builtPeriods);
            let isEndPeriodAvailable = isPeriodBuilt(getPeriodFromDate(endDate), this.props?.periodsStatusState?.builtPeriods);

            if (!isStartPeriodAvailable && !isEndPeriodAvailable) {
                let customStartDate = checkBuiltPeriodAvailability(startDate, this.props?.periodsStatusState?.builtPeriods, true);
                let customEndDate = checkBuiltPeriodAvailability(endDate, this.props?.periodsStatusState?.builtPeriods, true);
                this.setState({
                    customStartDate: typeof customStartDate !== 'string' ? customStartDate : this.props?.periodsStatusState?.builtPeriods?.find(f => f.period === customStartDate),
                    customEndDate: typeof customEndDate !== 'string' ? customEndDate : this.props?.periodsStatusState?.builtPeriods?.find(f => f.period === customEndDate)
                })
            }

            var currStartDate = customStartDate ? customStartDate : this.props.isDashBoards ? this.props.periodsStatusState.customStartDate : startDate;
            var currEndDate = customEndDate ? customEndDate : this.props.isDashBoards ? this.props.periodsStatusState.customEndDate : endDate;

            var periodsCount = monthDiff(currStartDate, currEndDate) + 1;

            var currentPeriods = generatePeriods(currStartDate, periodsCount);
            var previousPeriods = generatePeriods(currStartDate, periodsCount, ![YEAR_OVER_YEAR,PREVIOUS_ADJACENT].includes(comparisonValue)? undefined : comparisonValue === PREVIOUS_ADJACENT);
        } else {
            let startDate = this.props.isDashBoards ? this.props.customStartDate : customStartDate;
            let endDate = this.props.isDashBoards ? this.props.customEndDate : customEndDate;
            let periods = this.getPeriodsObject(startDate, endDate);
            var currentPeriods = periods.periods;
            var previousPeriods = comparisonValue === PREVIOUS_ADJACENT ? periods.periods_pa : periods.periods_yoy;
        }
        
        var currPeriods = currentPeriods.join("','");
        var prePeriods = "" + previousPeriods.join("','");
        // this.setState({
        //     periods: [currPeriods, prePeriods]
        // })
        return [currPeriods, prePeriods];
    }

    go() {
        if (this.props.bridgeOptionsState?.bridge && this.bridgeRef) {
            this.bridgeRef.closeDrill();
            this.bridgeRef.fetchBridgeTableData();
            this.bridgeRef.fetchBridgeData(undefined, undefined, undefined, undefined, undefined, true);
        }else{
            this.fetchReportData(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true);
        }
    }

    /**
     * function returns last column to be displayed
     * @param {*} arr 
     * @returns 
     */
    getMaxOrder=(arr)=> {
        let max = 0;
        for (var e in arr) {
            if (arr[e].column_order > max) {
                max = arr[e].column_order
            }
        }
        return max;
    }

    /**
     * function generates drill columns for each line selected on the barchart
     */
    generateDrillColumns=(columns, profile)=>{
        let _this = this;
        if (columns.filter(e=>e.machine_name === "name").length === 0 ) {
            columns.push({column_order: 0,
                column_profile_id: profile.column_profile_id,
                column_return_name: "selected_vector",
                column_view_option_id: 0,
                machine_name: "name",
                type: "selected_vector",
                view_order: 0,
            });
        }
        if (columns.filter(e=>e.machine_name === "number").length === 0 ) {
            columns.push({column_order: 1,
                column_profile_id: profile.column_profile_id,
                column_return_name: "selected_vector",
                column_view_option_id: 0,
                machine_name: "number",
                type: "selected_vector",
                view_order: 0,
            });
        }
        if (columns.filter(e=>e.column_return_name === _this.props.bridgeOptionsState.bridge).length == 0 ) {
            columns.push({column_order: _this.getMaxOrder(columns) +1,
                column_profile_id: profile.column_profile_id,
                column_return_name: _this.props.bridgeOptionsState.bridge,
                column_view_option_id: 1,
                machine_name: "value",
                type: "psl",
                view_order: _this.getMaxOrder(columns) +1,
            });
        }
        return columns;
    }

    setNoIOPSelectedDialogOpen = (isOpen) => {
        let _this = this;
        _this.setState({
            openNoIOPSelectedDialog: isOpen,
        })
    }
    

    fetchReportData(isMixVector, barLabel, startQuarter=this.props.startQuarter, endQuarter=this.props.endQuarter, customStartDate=this.props.customStartDate, customEndDate=this.props.customEndDate, scenario, isBridgeReportDrill, drillVector, key, fromGo) {
        const _this  = this;

        // If no period is generated for this scenario, don't send a request
        if(!_this.props?.checkIfAtLeastOnePeriodIsGenerated()) {
          _this.setState({
            data: {
                data: [],
                columns: [],
                bestPracticeGrid: [],
                id: shortid.generate(),
                profit: "",
                order: ""
            }
          }, () => {
            //when from dashoards, remove the loader
            typeof _this.props.loaderCallback === 'function' && _this.props.loaderCallback(false);       
          });
          
          return;
        }

        let groupBy = !fromGo && _this.bridgeRef && _this.bridgeRef.state && _this.bridgeRef.state.groupedByVector? _this.bridgeRef.state.groupedByVector.value:"";
        if(groupBy === NONE.value){
            groupBy ="";
        }

        // let headerRef = _this.props.isDashBoards ? _this.props.headerRef : _this.headerRef;

        let periods = _this.computePriorPeriods(startQuarter, endQuarter, customStartDate, customEndDate);
        let periodObject = {};
        let isBP = false;
        let comparisonValue = _this.props.comparisonValue; //_this.props.isDashBoards ? _this.props.comparisonValue : _this.props.comparisonValue; 
        let isYOY = comparisonValue === YEAR_OVER_YEAR;
        periodObject = _this.getPeriodsObject(customStartDate, customEndDate);
        let order =  _this.props.isDashBoards ? _this.props.order : _this.state.order ? _this.state.order : ORDER_VALUES.DESC;
        let sorter =  _this.props.isDashBoards ? _this.props.sorter : _this.state.sorter ? _this.state.sorter  : "";
        let columns = [];
        if (isMixVector) {
            columns = _this.generateDrillColumns(copyObjectValues(removeAttributes(_this.props.manageColumnsDefaultProfile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS])), _this.props.manageColumnsDefaultProfile);
        }
        let customStartDateVar = customStartDate || _this.props.periodsStatusState?.customStartDate;
        let customEndDateVar= customEndDate || _this.props.periodsStatusState?.customEndDate;
        let selectedPeriods = "";
        let selectedPrePeriods = "";
        var startYear =  _this.props.isDashBoards  ? startQuarter ? startQuarter.split("Q")[0] : _this.props.datasetState.nextStartQuarter.split("Q")[0] : "";
        var endYear =  _this.props.isDashBoards  ? endQuarter ? endQuarter.split("Q")[0] : _this.props.datasetState.nextEndQuarter.split("Q")[0] : "";

        // var currentQuarter = _this.props.isDashBoards  ? startQuarter ? generateQuarter(Number(startQuarter.split("Q")[1]), startYear.split("Q")[0], Number(endQuarter.split("Q")[1]), endYear.split("Q")[0]) : generateQuarter(Number(_this.props.datasetState.nextStartQuarter.split("Q")[1]), startYear.split("Q")[0], Number(_this.props.datasetState.nextEndQuarter.split("Q")[1]), endYear.split("Q")[0]) : "";
        if (_this.props.isDashBoards && comparisonValue !== PREVIOUS_ADJACENT && comparisonValue !== YEAR_OVER_YEAR) {
            var periodsCount = monthDiff(customStartDateVar, customEndDateVar) + 1;
            var currentPeriods = generatePeriods(customStartDateVar, periodsCount);
            var previousPeriods = generatePeriods(customStartDateVar, periodsCount, comparisonValue === PREVIOUS_ADJACENT);
            selectedPeriods = currentPeriods.join("','");
            selectedPrePeriods = "" + previousPeriods.join("','");
        }
        typeof _this.props.loaderCallback === 'function' && _this.props.loaderCallback(true);
        let concatenatedFilter = this.props.filter ? copyObjectValues(tryParse(this.props.filter)):"";
        let dashboardFilterObj = "";
        let dashBoardFilter = tryParse(this.props.dashboardFilter);
        if(this.props.dashboardFilter && dashBoardFilter.length>0){
            if(concatenatedFilter.filter.length>0){
                concatenatedFilter.filter[0][FILTER.KEYS.PARENTHESIS_BEFORE] += "(";
                concatenatedFilter.filter[concatenatedFilter.filter.length -1][FILTER.KEYS.PARENTHESIS_AFTER] += ")";
            }
            dashBoardFilter[0][FILTER.KEYS.PARENTHESIS_BEFORE] += "(";
            dashBoardFilter[0].logical_operator = "AND";
            dashBoardFilter[dashBoardFilter.length-1][FILTER.KEYS.PARENTHESIS_AFTER] += ")";
            dashBoardFilter.forEach(e=>{
                dashboardFilterObj = e;
                concatenatedFilter.filter.push(dashboardFilterObj);
            })
        }

        concatenatedFilter = JSON.stringify(concatenatedFilter);
        
        let rollingPeriod = periodObject.segmentPeriod;
        if(_this.props.isDashBoards) {
          let periods = selectedPeriods.replaceAll("'","").split(",");
          let lastSelectedPeriod = periods[periods.length - 1];
          let builtPeriods = _this.props.periodsStatusState?.actuallyBuiltPeriods?.map(m => m.label);
          rollingPeriod = getLastBuiltPeriodForSegments(builtPeriods, lastSelectedPeriod, 12);
        }

        // Removing tier/segment from column views, if the selected vector is not generated
        let selectedVector = this.props.vectorState?.vectorOptions.find(f => f.value === this.props.vectorState?.vectors[0]);
        let columnsViews = this.props.isDashBoards ? removeAttributes(this.props.simplifiedColumns) : isMixVector ? columns :this.props.manageColumnsProfile ? removeAttributes(this.props.manageColumnsProfile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS]) || [] : [];

        var query =  {
            action: "getProfitVectors",
            vector:  this.props.isDashBoards ? this.props.vector : this.props.vectorState.vectors[0],
            previousTimePeriod: selectedPrePeriods || periods[1],
            currentTimePeriod: selectedPeriods || periods[0],
            comparison: comparisonValue,
            filter: this.props.isDashBoards ? (dashboardFilterObj? concatenatedFilter : this.props.filter) :encodeURIComponent(JSON.stringify({'filter': tryParse(this.props.filterFinal ? this.props.filterFinal : "[]")})),
            scenario_id: this.props.isDashBoards ? this.props.scenario_id || scenario: this.props.scenarioState.scenario,
            limit: this.props.isDashBoards ? 10 : this.props.userSettingsState.records_limit,
            profitFormat: MENU_ITEM.FIELDS.EROSION_AND_GROWTH,
            order: order,
            sorter: sorter,
            columns: this.props.isDashBoards ? this.props.columns : isMixVector ? columns : this.props.manageColumnsProfile ? this.props.manageColumnsProfile[COLUMN_PROFILE.COLUMNS] || [] : [],
            columnsViews: columnsViews,
            barLabel: isMixVector ? barLabel : "",
            headerVector: isMixVector ? this.props.vectorState.vectors[0]: "",
            selectedEntities: _this.state.selectedItems,
            bridge: this.props.isDashBoards ? this.props.bridge : _this.props.bridgeOptionsState?.bridge,
            mixVector: isMixVector ? this.props.mixVector : "",
            fy: !isBP ? periodObject.months : "",
            quarter: !isBP ? periodObject.quarter : "",
            preQuarter: !isBP ? isYOY ?  periodObject.preQuarterYOY : periodObject.preQuarter :"",
            selectedPeriods: _this.props.isDashBoards ? selectedPeriods : "",
            selectedPrePeriods: _this.props.isDashBoards ? selectedPrePeriods : "",
            isBridgeReportDrill: isBridgeReportDrill,
            drillVector: drillVector,
            vectorKey: key,
            rollingPeriod: rollingPeriod || periodObject.segmentPeriod,
            rollingSegment: ROLLING_SEGMENTS.R_12,
            groupByVector: isBridgeReportDrill ? "" : groupBy
        };
        if (this.props?.setDataLength && typeof this.props.setDataLength === 'function' ) {
            this.props.setDataLength("");
        }
        _this.sentRequests = _this.sentRequests || 0;
        _this.sentRequests += 1;
        var onThenCallback = (data)=>{
      
            _this.sentRequests -= 1;
            
            let hasInvalidAccess = _this.props.checkForLimitAccessMessage(data, _this.sentRequests === 0);
            if(hasInvalidAccess) {
                _this.setState({
                    data: {
                        data: [],
                        columns: [],
                        bestPracticeGrid: [],
                        id: shortid.generate(),
                        profit: "",
                        order: ""
                    }
                });
                typeof _this.props.handleAPIError === 'function' && _this.props.handleAPIError(lang.dashboards.messages.no_permission);
                return;
            }
            if(data.ERROR) {
                typeof _this.props.handleAPIError === 'function' && _this.props.handleAPIError(data.ERROR);
            }
            if (isMixVector) {
                if (data && data.columns) {
                    if(isBridgeReportDrill){
                        _this.setState({
                            barLabel: barLabel,
                            drill_bridge_data: {
                                data: data.data,
                                columns: data.columns,
                                bestPracticeGrid: JSON.parse(data.bpgrid.opportunity_practice_segment_grid),
                                id: shortid.generate(),
                                profit: data.profit,
                                order: order, 
                            }
                        },function(){
                            let drillVector = _this.props.vectorState?.vectorOptions && _this.props.vectorState?.vectorOptions.filter(e=>e.value === _this.bridgeRef.state.drillVector).length>0 ?_this.props.vectorState?.vectorOptions.filter(e=>e.value === _this.bridgeRef.state.drillVector)[0].label:"";
                            let cols = [];
                            let nameObj = {
                                "isDefaultSorter": false,
                                "format_type": "text",
                                "field": _this.bridgeRef.state.drillVector+"Name",
                                "minWidth": 150,
                                "title": drillVector,
                                "profitListDisplay": false,
                                "titleDownload": drillVector,
                                "column_order": 1
                            }
                            let numberObj = {
                                "isDefaultSorter": false,
                                "format_type": "text",
                                "field": _this.bridgeRef.state.drillVector+"Number",
                                "minWidth": 200,
                                "title": drillVector+" #",
                                "profitListDisplay": false,
                                "titleDownload": drillVector+" #",
                                "column_order": 0
                            }
                            cols = data.columns.filter(e=>!['drill','name','number','quadrant','quadranttier'].includes(e.field));
                            cols.unshift(numberObj);
                            cols.unshift(nameObj);
                            if(_this.bridgeRef && _this.bridgeRef.pageComponent2){
                                let bridgeObj = this.props.bridgeOptionsState.bridgeOptions.filter(e=>e.value === _this.props.bridgeOptionsState.bridge);
                                _this.bridgeRef.pageComponent2.setColumns(cols, data.data, _this.state.barLabel, bridgeObj.length> 0 ? bridgeObj[0].label:"");
                                _this.bridgeRef.pageComponent2.tabulator.setData(data.data);
                                _this.bridgeRef.pageComponent2.addFooterText(barLabel);
                                _this.bridgeRef.pageComponent2.setSortForLabel(barLabel)
                            }
                        });
                    }else{
                        _this.setState({
                            barLabel: barLabel,
                            drill_data: {
                                data: data.data,
                                columns: data.columns,
                                bestPracticeGrid: JSON.parse(data.bpgrid.opportunity_practice_segment_grid),
                                id: shortid.generate(),
                                profit: data.profit,
                                order: order, 
                            }
                        });
                    }
                }
            }else{
                if (data && data.columns) {
                    _this.setState({
                        barLabel: "",
                        reportTitle: _this.isBridgeReport ? ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.BRIDGE_REPORT : _this.props.manageColumnsProfile ? _this.props.manageColumnsProfile.label :ALL_WIDGETS.TITLES.COMPANY_DEFAULT,
                        data: {
                            data: data.data,
                            columns: data.columns,
                            bestPracticeGrid: JSON.parse(data.bpgrid.opportunity_practice_segment_grid),
                            id: shortid.generate(),
                            profit: data.profit,
                            order: order,
                            
                        }
                    },function(){
                        if (!_this.props.isDashBoards) {
                            let tableColumns = [];
                            data.columns.forEach(col=>{
                                tableColumns = tableColumns.concat(col.columns);
                            })
                            _this.props.setSelectReportDisabledMsg(_this.props.comparisonValue === BP_QUADRANT_TIER ? lang.select_entity_to_view  : lang.select_entities_to_view)
                            _this.props.setSelectReportDisabled(true);

                        }
                        if(_this.pageComponent){
                            if (!_this.props.isDashBoards) {
                                _this.pageComponent.state.checked = 0;
                                if(_this.props.comparisonValue === BP_QUADRANT_TIER){
                                    $("#counter_title").text("0 of 1");
                                } else {
                                    $("#counter_title").text("0 of 10");
                                }
                            }
                        }
                        if (!_this.props.isDashBoards) {
                            // _this.setLimit(data.data.length);
                        }
                        if (data.data && data.data.length > 0 && this.props.setDataLength && typeof this.props.setDataLengt === 'function') {
                            this.props.setDataLength(data.data[0]['cnt']);
                            
                        }
                        if (!_this.props.isDashBoards) {
                            if([header_comparison_values.TOP_QUAD, header_comparison_values.TOP_QUAD_TIER].includes(_this.props.comparisonValue)) {
                                let hasIOP = data.data[0] && (!!data.data[0]["quadranttier"] || !!data.data[0]["quadrant"]) && data.data.filter(e=>e.quadrant === SEGMENTS.PEAK.abv || e.quadranttier === SEGMENTS_TIERS.PEAK_1.value.toUpperCase()).length > 0;
                                if(!hasIOP) {
                                  _this.setNoIOPSelectedDialogOpen(true)
                                }
                            }
                        }
                        if(_this.pageComponent && !_this.props.isDashBoards){
                            _this.pageComponent.tabulator.clearFilter(true);
                        }
                    })
                }
            }
            typeof _this.props.loaderCallback === 'function' && _this.props.loaderCallback(false);       
        };

        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getProfitVectors",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: !this.props.isDashBoards, // && !(this.headerRef.state.headerGoEnabled && !this.headerRef.state.goButtonDisabled),
            [FETCHAPI_PARAMS.path]: API_URL.PROFIT_VECTORS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: this.props.isDashBoards? lang.observability.output.dashboards.screen_name : isMixVector? lang.observability.output.bridge_report.screen_name : lang.observability.output.erosion_and_growth.screen_name,
            [FETCHAPI_PARAMS.requestDescription]: isBridgeReportDrill? lang.observability.output.bridge_report.requests_description.drill_from_drill : isMixVector? lang.observability.output.bridge_report.requests_description.drill : (lang.observability.output.erosion_and_growth.requests_description.apply + (_this.props.isDashBoards? lang.observability.output.dashboards.widget:"")),
            [FETCHAPI_PARAMS.vector]: isBridgeReportDrill? drillVector : this.props.isDashBoards ? this.props.vector : isMixVector? this.props.mixVector : this.props.vectorState.vectors[0],
            [FETCHAPI_PARAMS.periods]: "Current Periods: '" + (selectedPeriods || periods[0]) + "', Previous Periods: '" + (selectedPrePeriods || periods[1]) + "'",
            [FETCHAPI_PARAMS.scopeFilterApplicable]: true,
            isDashBoards: _this.props.isDashBoards
        }
        //if there is a tracking obj in the session, action is two_dimension_heatmap, else generate_heatmap
        fetchAPI(fetchOptions);
    }

    setDrillData=(data, columns, barLabel,isBridgeReportDrill)=>{
        let _this = this;
        let cols = [];
        if(isBridgeReportDrill){
            let drillVector = _this.props.vectorState?.vectorOptions && _this.props.vectorState?.vectorOptions.filter(e=>e.value === _this.bridgeRef.state.drillVector) ?_this.props.vectorState?.vectorOptions.filter(e=>e.value === _this.bridgeRef.state.drillVector)[0].label:"";
            let nameObj = {
                "isDefaultSorter": false,
                "format_type": "text",
                "field": _this.bridgeRef.state.drillVector+"Name",
                "minWidth": 150,
                "title": drillVector,
                "profitListDisplay": false,
                "titleDownload": drillVector,
                "column_order": 1
            }
            let numberObj = {
                "isDefaultSorter": false,
                "format_type": "text",
                "field": _this.bridgeRef.state.drillVector+"Number",
                "minWidth": 200,
                "title": drillVector+" #",
                "profitListDisplay": false,
                "titleDownload": drillVector+" #",
                "column_order": 0
            }
            cols = columns.filter(e=>!['drill','name','number','quadrant','quadranttier'].includes(e.field));
            cols.unshift(numberObj);
            cols.unshift(nameObj);
            _this.setState({
                barLabel: barLabel,
                drill_bridge_data: {
                    data: data,
                    columns: columns,
                    id: shortid.generate(),
                    profit: "",
                    order: "", 
                    drilled_item: barLabel
                }
            },function(){
                if(_this.bridgeRef && _this.bridgeRef.pageComponent2){
                    let bridgeObj = _this.props.bridgeOptionsState.bridgeOptions.filter(e=>e.value === _this.props.bridgeOptionsState.bridge);
                    _this.bridgeRef.pageComponent2.setColumns(cols, data, _this.state.barLabel, bridgeObj.length> 0 ? bridgeObj[0].label:"");
                    _this.bridgeRef.pageComponent2.tabulator.setData(data);
                    _this.bridgeRef.pageComponent2.addFooterText(barLabel);
                    _this.bridgeRef.pageComponent2.setSortForLabel(barLabel)
                }
            })
        }else{
            columns?.filter(f => [f.title?.replace("#","").trim(), f.title].includes(_this.state?.mixVector))
                    ?.map(m => m.title = _this.props.vectorState?.vectorOptions?.filter(e=>e.value === _this.state?.mixVector)[0].label)

            _this.setState({
                barLabel: barLabel,
                drill_data: {
                    data: data,
                    columns: columns,
                    id: shortid.generate(),
                    profit: "",
                    order: "", 
                    drilled_item: barLabel
                }
            })
        }
        
    }

    clearSelectedItems = () => {
      const _this = this;
      if(_this.pageComponent) {
        _this.pageComponent.selectedItems = [];
        _this.pageComponent.selectedNames = [];
      }

      _this.setState({
          selectedItems: [],
          selectedNames : []
      });
    }

    setSelectedItems(selectedItems, selectedNames){
        const _this = this;
        this.setState({
            selectedItems: selectedItems,
            selectedNames : selectedNames
        }, ()=>{
          if(_this.props.setSelectReportDisabledMsg) {
            _this.props.setSelectReportDisabledMsg(_this.props.comparisonValue === BP_QUADRANT_TIER ? lang.select_entity_to_view  : lang.select_entities_to_view)
            _this.props.setSelectReportDisabled(selectedItems.length === 0);
          }
        });
    }

    // handleHeaderElementChangeCallback(elem, e) {
    //     const _this = this;
    //     let tempState = {}
    //     switch(elem) {
    //         case HEADER_ELEMENT.COMPARISON:
    //             this.setComparisonValue(e);
    //             break;

    //         case HEADER_ELEMENT.SECONDARY_REPORT: {
    //             this.isBridgeReport = true;
    //             this.setState({
    //                 originalComparisonValue:  _this.props.comparisonValue,
    //                 originalFilterFinal: _this.headerRef.state.filterFinal,
    //                 originalCustomStartDate: _this.state.nextCustomStartDate,
    //                 originalCustomEndDate: _this.state.nextCustomEndDate,
    //                 originalNextStartQuarter:_this.state.nextStartQuarter,
    //                 originalManageColumnsProfile: _this.props.manageColumnsProfile,
    //                 originalPeriods: _this.props.clientPeriodsState?.periods,
    //                 originalNextEndQuarter:_this.state.nextEndQuarter,
    //                 savedFilterDataState: this.headerRef.filterDialRef.state.savedFilterData,
    //                 headerElements: mainHeaderElements.bridge_report,
    //                 profitFormat: ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.BRIDGE_REPORT,
    //                 reportTitle: ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.BRIDGE_REPORT,
    //                 isBridgeReport: true,
    //             }, ()=>{
    //                 _this.forceUpdate();
    //                 sessionStorage.setItem(SELECTED_PROFILE_EROSION, JSON.stringify(_this.props.manageColumnsProfile));
    //                 _this.headerRef.updateSecondaryReportSettings({
    //                     shown: false
    //                 });
    //                 let comparisonOptions = this.props.isSingleFact && this.props.useNewSegmentation ? lang.header.options.comparison : lang.header.options.comparisonOld;
    //                 let comparisonObj = comparisonOptions.filter(e=>e.value === BP_QUADRANT_TIER)[0];
    //                 if(this.state.selectedItems.length > 1){
    //                     comparisonObj.isDisabled = true;
    //                     comparisonObj.disableOption = true; // this option is to differentiate between isDisabled option. We were using isDisabled for option grouping.
    //                     comparisonObj.tooltipText = lang.cannot_choose_bp;
    //                     this.setState({
    //                         comparisonOptions:comparisonOptions
    //                     })
    //                 }
    //             });
    //             break;
    //         }
    //     }
    //     if (this.isBridgeReport) {
    //         tempState.reportTitle = ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.BRIDGE_REPORT;
    //     }
    //     this.setState(tempState);
    // }


    exitDrill = () => {
      let _this = this;

      _this.props.setIsDrilling(false);
      setTimeout(() => {

      if(_this.bridgeRef?.pageComponent){
        _this.bridgeRef?.setState({
          isTableFullScreen: true
        })
          var order = _this.state.data && _this.state.data.order? _this.state.data.order.toLowerCase() : ORDER_VALUES.DESC.toLowerCase();
          let bridgename = _this.props.bridgeOptionsState.bridgeOptions.filter(e=>e.value === _this.props.bridgeOptionsState.bridge).length> 0 ? _this.props.bridgeOptionsState.bridgeOptions.filter(e=>e.value === _this.props.bridgeOptionsState.bridge)[0].label:"";
          if (_this.state.barLabel.includes(bridgename) || [NO_SALES,NEW_SALES].includes(_this.state.barLabel)) {
              _this.bridgeRef.pageComponent.tabulator.setSort(_this.props.bridgeOptionsState.bridge + comparison_suffixes.difference, order);
          }else{
              _this.bridgeRef.pageComponent.setSortForLabel(_this.state.barLabel);
          }
      }
      }, 200);

    }
    onBackNavigation(isScopeFilterChanged) {
      let _this = this;
      if(this.props.isDrilling){
        this.exitDrill();
        if (!isScopeFilterChanged) return; // when we change scope we want to exit drill and go back to erosion
      }
      
      let tempState = {};
      let tableData = copyObjectValues(this.state.data);
      tableData.id = shortid.generate();
      if(tableData.data.length > 0) {
          tableData.data = tableData.data.map(function(item){
              item.checked = false;
              return item;
          });
      }
      tempState.data = tableData;
      this.setState(tempState, ()=>{
        _this.props.onBridgeBackClick();
      });
    }

    changeOrderCallback(order) {
        this.pageComponent.state.checked = 0;
        this.setState({order:order},function(){
            this.fetchReportData();
        });
    }

    /**
     * Function that returns the legend message based on the comparison value and and the symbol in colValue
     * @param colValue
     * @returns {string}
     */
    getLegendMessage = (colValue) => {
        let legendMessage = "";
        let comparisonValue = this.props.comparisonValue //this.props.isDashBoards ? this.props.comparisonValue : this.props.comparisonValue;
        let periods = this.computePriorPeriods();
        let comparisonArray = [];
        let selectionArray = [];


        if (periods !== undefined && periods.length !== 0) {
            comparisonArray = periods[1].replaceAll("\'", "").split(",");
            selectionArray = periods[0].replaceAll("\'", "").split(",");
        }

        if (comparisonValue === YEAR_OVER_YEAR || comparisonValue === PREVIOUS_ADJACENT) {
            if (colValue === VECTOR_ANALYSIS.FIELDS.ALPHA_SYMBOL) {
                if (comparisonArray[0] === comparisonArray[comparisonArray.length - 1]) {
                    legendMessage = "<span class='uk-text-bolder'>α:</span> " + comparisonArray[0];
                } else {
                    legendMessage = "<span class='uk-text-bolder'>α:</span> " + comparisonArray[0] + " to " + comparisonArray[comparisonArray.length - 1];
                }
            } else if (colValue === VECTOR_ANALYSIS.FIELDS.BETA_SYMBOL) {
                if (selectionArray[0] === selectionArray[selectionArray.length - 1]) {
                    legendMessage = "<span class='uk-text-bolder'>β:</span> " + selectionArray[0];
                } else {
                    legendMessage = "<span class='uk-text-bolder'>β:</span> " + selectionArray[0] + " to " + selectionArray[selectionArray.length - 1];
                }
            }
        } else {
            let selectedPeriods;

            if (this.props.isDashBoards) {
              let customStartDateVar = this.props.periodsStatusState?.customStartDate;
              let customEndDateVar= this.props.periodsStatusState?.customEndDate;
              var periodsCount = monthDiff(customStartDateVar, customEndDateVar) + 1;
              selectedPeriods = generatePeriods(customStartDateVar, periodsCount);
            }

            selectionArray = selectedPeriods || periods[0].replaceAll("\'", "").split(",");

            if (colValue === VECTOR_ANALYSIS.FIELDS.ALPHA_SYMBOL) {
                if (comparisonValue === BP_QUADRANT_TIER && !this.props.isBridgeReport) {
                    legendMessage = "<span class='uk-text-bolder'>α:</span> " + VECTOR_ANALYSIS.FIELDS.BP_NAME;
                } else if (comparisonValue === BP_QUADRANT_TIER && this.props.isBridgeReport) {
                    legendMessage = "<span class='uk-text-bolder'>α:</span> " + this.getBestPractice();
                } else if (comparisonValue === TOP_QUADRANT) {
                    legendMessage = "<span class='uk-text-bolder'>α:</span> " + (new Segment().getSegmentObject(SEGMENTS.PEAK.label)?.label);
                } else if (comparisonValue === TOP_QUADRANT_TIER) {
                    legendMessage = "<span class='uk-text-bolder'>α:</span> " + (new Segment().getSegmentObject(SEGMENTS_TIERS.PEAK_1.label)?.label);
                }
            } else if (colValue === VECTOR_ANALYSIS.FIELDS.BETA_SYMBOL) {
                if (selectionArray[0] === selectionArray[selectionArray.length - 1]) {
                    legendMessage = "<span class='uk-text-bolder'>β:</span> " + selectionArray[0];
                } else {
                    legendMessage = "<span class='uk-text-bolder'>β:</span> " + selectionArray[0] + " to " + selectionArray[selectionArray.length - 1];
                }
            }
        }
        if (colValue === VECTOR_ANALYSIS.FIELDS.DELTA_SYMBOL) {
            legendMessage = VECTOR_ANALYSIS.FIELDS.DELTA_FORMULA;
        }
        return legendMessage;
    }

    /**
     * Function that returns the tooltip message based on the colValue
     * @param colValue
     * @returns {string}
     */
    getToolTipMessage = (colValue) => {
        let tooltipMessage = "";

        if (colValue === VECTOR_ANALYSIS.FIELDS.ALPHA_SYMBOL || VECTOR_ANALYSIS.FIELDS.BETA_SYMBOL) {
            let fullText = this.getLegendMessage(colValue);
            tooltipMessage = fullText.substring(fullText.lastIndexOf(">") + 1);
        } else {
            tooltipMessage = VECTOR_ANALYSIS.FIELDS.DELTA_FORMULA;
        }
        return tooltipMessage;
    }

    setOrder=(order, sorter = this.state.sorter)=> {
		let _this = this;
        _this.setState({
            order: order,
			sorter: sorter || ""
        }, function () {
			_this.fetchReportData();
		});
	}

    /**
     * function that returns the symbol color for the alpha beta delta symbols
     * @param colValue
     * @returns {string}
     */
    getSymbolColor = (colValue) => {

        let color = "black uk-text-bolder";

        if (colValue === VECTOR_ANALYSIS.FIELDS.ALPHA_SYMBOL) {
            color = "alpha_text uk-text-bolder";
        } else if (colValue === VECTOR_ANALYSIS.FIELDS.BETA_SYMBOL) {
            color = "beta_text uk-text-bolder";
        }
        return color;
    }

    /**
     * column formatter to add the tooltip message and symbol color without removing the filterable formatter
     * @param c
     * @param obj
     */
    getSymbolsFormatter = (c, obj) => {
        if (c.field !== undefined) {
            if (c.field.toUpperCase().includes(VECTOR_ANALYSIS.TITLES.COMPARISON.toUpperCase())
                || c.field.toUpperCase().includes(VECTOR_ANALYSIS.TITLES.SELECTION.toUpperCase())
                || c.field.toUpperCase().includes(VECTOR_ANALYSIS.TITLES.DIFFERENCE.toUpperCase())
                || c.field.toUpperCase().includes("ALPHA_") || c.title === VECTOR_ANALYSIS.FIELDS.DELTA_SYMBOL || c.field.toUpperCase().includes("BETA_") || c.field.toUpperCase().includes("OTHER_")){
                c.hideIcon = true;
                c.tooltipMessage = obj.props.getToolTipMessage(c.title, obj.props.comparisonValue, obj);
                c.symbolColor = obj.props.getSymbolColor(c.title);
            }
        }
    }

    /**
     * column formatter to add the tooltip message and symbol color without filter
     * @param col
     * @param tooltipMessage
     * @param showTooltip
     * @returns {HTMLDivElement}
     */
    getSymbolsTooltipFormatter = (col,tooltipMessage,showTooltip = true) => {
        let div = document.createElement("div");
        let p = document.createElement("p");
        let colValue = col.getValue();

        p.innerHTML = col.getValue();

        if (colValue === VECTOR_ANALYSIS.FIELDS.ALPHA_SYMBOL) {
            p.classList.add("alpha_text", "fs-14", "text-blue");
        } else if (colValue === VECTOR_ANALYSIS.FIELDS.BETA_SYMBOL) {
            p.classList.add("beta_text", "fs-14");
        } else if (colValue === VECTOR_ANALYSIS.FIELDS.DELTA_SYMBOL) {
            p.classList.add("uk-text-bolder", "fs-14", "black");
        }

        if (showTooltip){
            p.setAttribute("uk-tooltip", tooltipMessage);
        }

        div.appendChild(p);
        return div;
    }

    /**
     * function that returns the best practice of the selected row
     * @returns {*}
     */
    getBestPractice = () => {
        let bpGrid = this.state.data? this.state.data.bestPracticeGrid:"";
        let data = this.state.data? this.state.data.data:"";
        let comparisonValue = this.props.comparisonValue //this.props.isDashBoards ? this.props.comparisonValue : this.props.comparisonValue;
        let selectedItems = this.state.selectedItems;
        let quadrantTier;
        let bestPractice = "";

        if (comparisonValue === BP_QUADRANT_TIER && data && data[0] && data[0].quadranttier && selectedItems && bpGrid) {
            let filteredData = data.filter(e => e.number === selectedItems[0]);
            if (filteredData.length === 0) {
                bestPractice = VECTOR_ANALYSIS.FIELDS.NA_NAME;
            } else {
                quadrantTier = filteredData[0].quadranttier;
                bestPractice = new Segment().getSegmentObject(bpGrid.filter(e => e.quad === quadrantTier)[0].bp).label.replace(":","_");
            }
        }
        return bestPractice;
    }

    // getExportFilter = () => {
    //     let filter = typeof this.props.filterFinal === "string" ? JSON.parse(this.props.filterFinal) : this.props.filterFinal;
    //     filter =
    //       filter && filter.length
    //         ? filter.find((f) => !f.isBasicFilter)
    //           ? formatAdvancedFilter(
    //               filter,
    //               this.state?.user?.user_allowed_vectors,
    //               this.state.datasetOptions,
    //               this.props.vectorState?.vectorOptions,
    //               this.headerRef?.filterDialRef?.state?.psLinesOptions
    //             )
    //           : formatBasicFilter(
    //               filter,
    //               this.state?.user?.user_allowed_vectors
    //             )
    //         : undefined;
    //     return filter;
    // }

    getParams=()=>{
        let obj = this;
        // let headerRef = obj.props.isDashBoards ? obj.props.headerRef : obj.headerRef;
        let exportQueryFilter = this.props?.exportQueryFilter? this.props?.exportQueryFilter() : undefined;
        let scopeFilter = this.props.exportScopeFilter ? this.props.exportScopeFilter() : "";

        if(obj.props.profitFormat && obj.props.userSettingsState.user && obj.props.scenarioState.scenarioObjects[0] &&  obj.props.comparisonValue && (obj.props.periodsStatusState.customStartDate && obj.props.periodsStatusState.customEndDate) || (obj.props.datasetState?.nextStartQuarter && obj.props.datasetState?.nextEndQuarter)){
            let params = {
                "Report": obj.props.profitFormat === MENU_ITEM.FIELDS.EROSION_AND_GROWTH ? ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.VECTOR_ANALYSIS : obj.props.profitFormat,
                "User": obj.props.userSettingsState.user.first_name + " " + obj.props.userSettingsState.user.last_name,
                "Date Run": new Date().getDate() + '-' + getMonthName(new Date().getMonth()) + '-' + new Date().getFullYear(),
                "Filter": exportQueryFilter || "None",
                // "Scenario": obj.props.scenarioState.scenarioObjects[0]["scenario_number"],
            }
            if(scopeFilter) { // if fetaure flag is on
				params["Scope"] = scopeFilter;
			}
            params["Scenario"] =  obj.props.scenarioState.scenarioObjects[0]["scenario_number"]; // TODO: added this way instead of directly in params, because scope should be before scenario in excel. return it to params when feature flag is removed

            // if(useNewSegmentation || [YEAR_OVER_YEAR, PREVIOUS_ADJACENT].includes(obj.props.comparisonValue)) {
                params["Start Period"] = getActualYear(obj.props.periodsStatusState.customStartDate) + getPeriodTextFromMonth(obj.props.periodsStatusState.customStartDate);
                params["End Period"] = getActualYear(obj.props.periodsStatusState.customEndDate) + getPeriodTextFromMonth(obj.props.periodsStatusState.customEndDate);
            // } else {
            //     params["Start Quarter"] = obj.props.datasetState.nextStartQuarter;
            //     params["End Quarter"] = obj.props.datasetState.nextEndQuarter;
            // }
            params = this.formatParams(params);
            return params;
        }
    }

    formatParams = (params)=>{
        let data = []
        for(const property in params){
            let obj = {title:property,detail:params[property]}
            data.push(obj)
        }
        return data;
    }

    render() {
        let comp = null;
        let _this = this;
        let vectorObjs =  this.props.vectorState?.vectors ? this.props.vectorState.vectors.map(v=>{
          return findOptionByKeyValue(this.props.vectorState.vectorOptions, "value", v);
        }) : {};

        if(_this.props.isBridgeReport){
            comp =
              <BridgeReport
                additionalFilter={[]}
                barLabel={this.state.barLabel}
                bridge={this.props.bridgeOptionsState.bridge}
                bridgeCustomViewId={this.props.bridgeOptionsState.bridgeCustomViewId}
                bridgeLabel={findOptionByKey(this.props.bridgeOptionsState.bridgeOptions, this.props.bridgeOptionsState.bridge)["label"]}
                bridgeName={this.props.bridgeOptionsState.bridgeOptions?.filter((e) => e.value === _this.props.bridgeOptionsState.bridge)[0].label}
                bridgeReportId={this.props.bridgeOptionsState?.secondaryReport}
                checkForLimitAccessMessage={this.props.checkForLimitAccessMessage}
                comparisonValue={this.props.comparisonValue} //{this.props.isDashBoards ? this.props.comparisonValue : this.state.comparisonValue}
                computePriorPeriods={this.computePriorPeriods}
                displayVector={vectorObjs}
                drill_data={this.state.drill_data}
                endPeriod={this.props.periodsStatusState.customEndDate}
                endQuarter={this.props.datasetState?.nextEndQuarter}
                exportQueryFilter={this.props?.exportQueryFilter}
                fetchDataOnMount={true}
                fetchReportData={this.fetchReportData}
                filter={this.props.filterFinal}
                filterBasic={this.props.filterFinalBasic}
                getLegendItems={this.getLegendItems}
                getPeriodsObject={this.getPeriodsObject}
                getSymbolColor={this.getSymbolColor}
                getSymbolsFormatter={this.getSymbolsFormatter}
                getSymbolsTooltipFormatter={this.getSymbolsTooltipFormatter}
                getToolTipMessage={this.getToolTipMessage}
                isDrilling={this.props.isDrilling}
                isPV={_yes}
                limit={this.props.userSettingsState.records_limit}
                mixVector={this.props.mixVector}
                peerGroup={""}
                peerKeys={""}
                periodsList={this.props.periodsStatusState.builtPeriods}
                profitFormat={this.props.reportTitle}
                ref={(el) => (this.bridgeRef = el)}
                scenario={this.props.scenarioState?.scenario}
                scenarioObject={this.props.scenarioState?.scenarioObjects[0]}
                selectedItems={this.state.selectedItems}
                selectedNames={this.state.selectedNames}
                setDrillData={this.setDrillData}
                setIsDrilling={this.props.setIsDrilling}
                showLoader={true}
                startPeriod={this.props.periodsStatusState.customStartDate}
                startQuarter={this.props.datasetState?.nextStartQuarter}
                user={this.props.userSettingsState.user}
                userAllowedMenuLinks={this.props.userAllowedMenuLinks}
                vector={this.props.vectorState.vectors[0]}
                vectorObj={this.props.vectorState?.vectorObjects ? this.props.vectorState.vectorObjects[0] || {} : {}}
                vectorOptions={[{ value: NONE.value, label: NONE.label }].concat(this.props.vectorState?.vectorOptions)}
                overwritePopupSize = {this.props.userSettingsState.overwritePopupSize}
                exportScopeFilter={this.props.exportScopeFilter}
              />
        }else{
            let params = this.getParams();
            

            comp = (
                <div className="vector_analysis_container">
                  <VectorAnalysisTable
                    ref={(el) => (this.pageComponent = el)}
                    data={this.state.data}
                    columns={this.state.columns}
                    setSelectedItems={this.setSelectedItems}
                    comparisonValue={this.props.comparisonValue}
                    user={this.props.userSettingsState?.user}
                    scenarioObject={this.props.scenarioState?.scenarioObjects[0]}
                    vector={this.props.vectorState?.vectors[0]}
                    vectorObj={vectorObjs.length > 0 ? vectorObjs[0] : {}}
                    customStartDate={this.props.periodsStatusState?.customStartDate}
                    isPaginated={this.props.isDashBoards ? false : true}
                    customEndDate={this.props.periodsStatusState?.customEndDate}
                    startQuarter={this.props.datasetState?.nextStartQuarter}
                    endQuarter={this.props.datasetState?.nextEndQuarter}
                    profitFormat={this.props.reportTitle}
                    exportQueryFilter={this.props.exportQueryFilter}//{this.headerRef.filterDialRef ? this.headerRef.filterDialRef.state.exportQueryFilter : ""}
                    selectedItems={this.state.selectedItems}
                    getLegendItems={this.getLegendItems}
                    getSymbolColor={this.getSymbolColor}
                    getSymbolsTooltipFormatter={this.getSymbolsTooltipFormatter}
                    getSymbolsFormatter={this.getSymbolsFormatter}
                    getToolTipMessage={this.getToolTipMessage}
                    limit={this.props.userSettingsState?.records_limit}
                    setOrder={this.setOrder}
                    sorter={this.props.isDashBoards ? this.props.sorter : this.state.sorter}
                    isDashboards={this.props.isDashBoards}
                    index={this.props.index}
                    filterBasic={this.props.filterFinalBasic}
                    height={this.props.height}
                  />
                  <div className="uk-hidden">
                    <ExcelDetailsTable ref={el=>this.excelDetails= el} params = {params}/>
                  </div>
                </div>
            )
        }
        
        return (
        <> 
          {comp}

          <Modal 
              id={"no-iop-selected-dialog"}
              openDialog={this.state.openNoIOPSelectedDialog}
              bodyContent={() => <h4>{lang.no_iop_selected_vector.replace(SEGMENTS.PEAK.label, new Segment().getSegmentObject(SEGMENTS.PEAK.label)?.label)}</h4>}
              dialogActions={() => 
                (<Button 
                  label={lang.modal.buttons.ok}
                  variant={BUTTON_VARIANT.PRIMARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  onBtnClick={() => this.setNoIOPSelectedDialogOpen(false)}
                />)
              }
              closeClick={() => this.setNoIOPSelectedDialogOpen(false)}
              size={DIALOG_SIZE.SMALL}
          />
        </>
        )
    }

}

export default ErosionAndGrowth;
