import React from "react";
import { Route, BrowserRouter as Router, Switch, withRouter } from "react-router-dom";

import { BuildDetailsWrapper } from "./wrappers/BuildDetailsWrapper";
import _setPassword from "../components/signInUp/SetPassword";
import _CustomReport from "../CustomReport";
import _ErosionAndGrowth from "../ErosionAndGrowth";
import ResetPassword from "../ResetPassword";
import _tableauHolder from "../sections/tableauHolder/TableauHolder";
import { GeographyConcentrationWrapper } from "./wrappers/GeographyConcentrationWrapper";
import { ListWrapper } from "./wrappers/ListWrapper";

import Login from "../Login";
import { DashboardWrapper } from "./wrappers/DashboardWrapper";
import { ManageAccessWrapper } from "./wrappers/ManageAccessWrapper";
import { ManageCustomReportsWrapper } from "./wrappers/ManageCustomReportsWrapper";
import { ManageStacksWrapper } from "./wrappers/ManageStacksWrapper";
import { ProfitStackLineDefinitionsWrapper } from "./wrappers/ProfitStackLineDefinitionsWrapper";
import { ModelCoherenceWrapper } from "./wrappers/ModelCoherenceWrapper";
import { StackWrapper } from "./wrappers/StackWrapper";
import { UploadDataWrapper } from "./wrappers/UploadDataWrapper";

import { ALL_WIDGETS, MENU_ITEM_URLS } from "../class/constants";

import "../../node_modules/react-grid-layout/css/styles.css";
import "../../node_modules/react-resizable/css/styles.css";
import "../styles/colors.css";
import "../styles/dropdownTree.css";
import "../styles/skeletonLoader.css";
import { LandingPage } from "./LandingPage";
import { Template } from "./Template";
import { CacheManagerWrapper } from "./wrappers/CacheManagerWrapper";
import { ConfigureBridgeReportWrapper } from "./wrappers/ConfigureBridgeReportWrapper";
import { ConfigureBuildWrapper } from "./wrappers/ConfigureBuildWrapper";
import { ConfigureGlAccountRangesWrapper } from "./wrappers/ConfigureGlAccountRangesWrapper";
import { CustomReportsWrapper } from "./wrappers/CustomReportsWrapper";
import { ErosionAndGrowthWrapper } from "./wrappers/ErosionAndGrowthWrapper";
import { HeatmapWrapper } from "./wrappers/HeatmapWrapper";
import { ManageAccrualsWrapper } from "./wrappers/ManageAccrualsWrapper.js";
import { ManageCostCentersWrapper } from "./wrappers/ManageCostCentersWrapper.js";
import { ManageScenariosWrapper } from "./wrappers/ManageScenariosWrapper.js";
import { ManageSettingsWrapper } from "./wrappers/ManageSettingsWrapper";
import { ModelingSettingsWrapper } from "./wrappers/ModelingSettingsWrapper";
import { MonthlyBuildWrapper } from "./wrappers/MonthlyBuildWrapper";
import { ProfitLandscapeWrapper } from "./wrappers/ProfitLandscapeWrapper";
import { StageWrapper } from "./wrappers/StageWrapper";
import { SupplyChainWrapper } from "./wrappers/SupplyChainSetupWrapper.js";
import { VectorDefinitionsWrapper } from "./wrappers/VectorDefinitionsWrapper";
import { TLCLandscapeWrapper } from "./wrappers/TLCLandscapeWrapper.js";
import {CompareScenariosWrapper} from "./wrappers/CompareScenariosWrapper";

let BuildDetailsWrapperRouter = withRouter(BuildDetailsWrapper);
let SetPassword = withRouter(_setPassword);
let GeographyConcentrationWrapperRouter = withRouter(GeographyConcentrationWrapper);
let ProfitStackLineDefinitionsWrapperRouter = withRouter(ProfitStackLineDefinitionsWrapper);
let ModelCoherenceWrapperRouter = withRouter(ModelCoherenceWrapper);
let VectorDefinitionsWrapperRouter = withRouter(VectorDefinitionsWrapper);
let ManageCustomReportsWrapperRouter = withRouter(ManageCustomReportsWrapper);
let CustomReportsWrapperRouter = withRouter(CustomReportsWrapper);
let ListWrapperRouter = withRouter(ListWrapper);
let StackWrapperRouter = withRouter(StackWrapper);
let DashboardWrapperRouter = withRouter(DashboardWrapper);
let ErosionWrapperRouter = withRouter(ErosionAndGrowthWrapper);
let HeatmapWrapperRouter = withRouter(HeatmapWrapper);
let TemplateRouter = withRouter(Template);
let LandingPageRouter = withRouter(LandingPage);
let ManageStacksWrapperRouter = withRouter(ManageStacksWrapper);
let ManageAccessRouter = withRouter(ManageAccessWrapper);
let ManageSettingsWrapperRouter = withRouter(ManageSettingsWrapper);
let ProfitLandscapeWrapperRouter = withRouter(ProfitLandscapeWrapper);
let SupplyChainSetupWrapperRouter = withRouter(SupplyChainWrapper);
let TLCLandscapeWrapperRouter = withRouter(TLCLandscapeWrapper);
/** Profit modeling screens */
let UploadDataWrapperRouter= withRouter(UploadDataWrapper); 
let ModelingSettingWrapperRouter = withRouter(ModelingSettingsWrapper);
let ConfigureGlAccountRangesWrapperRouter = withRouter(ConfigureGlAccountRangesWrapper);
let MonthlyBuildWrapperRouter = withRouter(MonthlyBuildWrapper);
let CacheManagerWrapperRouter = withRouter(CacheManagerWrapper);
let StageWrapperRouter = withRouter(StageWrapper);
let ConfigureBuildWrapperRouter = withRouter(ConfigureBuildWrapper);
let ManageAccrualsWrapperRouter = withRouter(ManageAccrualsWrapper);
let CompareScenariosWrapperRouter= withRouter(CompareScenariosWrapper);



let ConfigureBridgeReportWrapperRouter = withRouter(ConfigureBridgeReportWrapper);

let ManageCostCentersRouter = withRouter(ManageCostCentersWrapper);
let ManageScenariosRouter = withRouter(ManageScenariosWrapper);

const UPLOAD = ALL_WIDGETS.UPLOAD;
const STAGE = ALL_WIDGETS.FIELDS.STAGE;
const CONFIGURE = ALL_WIDGETS.CONFIGURE;
const PS_MAPPING_MENU = ALL_WIDGETS.PS_MAPPING_MENU;
const BUILD = ALL_WIDGETS.BUILD;
const MANAGE_SCENARIOS = ALL_WIDGETS.FIELDS.MANAGE_SCENARIOS;
const MANAGE_ACCRUALS = ALL_WIDGETS.MANAGE_ACCRUALS;
const MODELING_SETTINGS = ALL_WIDGETS.MODELING_SETTINGS;
const MANAGE_COST_CENTERS = ALL_WIDGETS.MANAGE_COST_CENTERS;
const COMPARE_SCENARIOS = ALL_WIDGETS.FIELDS.COMPARE_SCENARIOS;
const MONTHLY_BUILD = ALL_WIDGETS.MONTHLY_BUILD;
const CONFIGURE_GL_ACCOUNT_RANGES = ALL_WIDGETS.CONFIGURE_GL_ACCOUNT_RANGES;
const CONFIGURE_AND_BUILD = ALL_WIDGETS.CONFIGURE_AND_BUILD;

export default () => (
  <>
    <Router>
      {/* Login Screens */}
      <Route path="/" exact component={Login} />
      <Route path="/login" exact component={Login} />
      <Route path="/resetPassword" exact render={() => <ResetPassword />} />
      <Route path={"/set_password/:hash?"} exact render={() => <SetPassword />} />

      {/* Profit Isle Start */}
      <Route
        path={"/profit_isle_start"}
        exact
        render={() => <TemplateRouter passedComponent={LandingPageRouter} showLeftMenu={false} trackBuildOnLoad={true} isLandingPage showChatbot={true}/>}
      />
      <Route path={"/blank"} exact render={() => <div></div>} />
      <Switch>
        {/* Customer Management */}
        <Route
          path="/sales_customer_management/:report"
          exact
          render={() => (
            <TemplateRouter
              passedComponent={GeographyConcentrationWrapperRouter}
              passedCustomReportComponent={CustomReportsWrapperRouter}
              showChatbot={true}
            />
          )}
        />
      </Switch>
      {/* Dashboards */}
      <Route
        path="/dashboards"
        exact
        render={() => <TemplateRouter passedComponent={DashboardWrapperRouter} showLeftMenu showChatbot={true}/>}
      />
      <Switch>
        {/* Financial Analytics */}
        <Route
          path="/financial_planning_analysis/list"
          exact
          render={() => <TemplateRouter passedComponent={ListWrapperRouter} removeBackground showChatbot={true}/>}
        />
        <Route
          path="/financial_planning_analysis/stacks/ytd_stacks"
          exact
          render={() => <TemplateRouter passedComponent={StackWrapperRouter} additionalProps={{ isYTD: true }} showChatbot={true}/>}
        />
        <Route
          path="/financial_planning_analysis/stacks/yoy_stacks"
          exact
          render={() => <TemplateRouter passedComponent={StackWrapperRouter} additionalProps={{ isYOY: true }} showChatbot={true}/>}
        />
        <Route
          path="/financial_planning_analysis/stacks/total_stacks"
          exact
          render={() => (
            <TemplateRouter passedComponent={StackWrapperRouter} additionalProps={{ isTotal: true, isRange: true }} showChatbot={true}/>
          )}
        />
        <Route
          path={MENU_ITEM_URLS.LIST_ENTITY_STACKS}
          exact
          render={() => (
            <TemplateRouter passedComponent={StackWrapperRouter} additionalProps={{ isEntity: true, isRange: true }} showChatbot={true}/>
          )}
        />
        <Route
            path="/financial_planning_analysis/stacks/mom_stacks"
            exact
            render={() => <TemplateRouter passedComponent={StackWrapperRouter} additionalProps={{ isMoM: true }} showChatbot={true}/>}
        />
        <Route
          path={"/financial_planning_analysis/erosion_and_growth"}
          exact
          render={() => <TemplateRouter passedComponent={ErosionWrapperRouter} showChatbot={true}/>}
        />
        <Route
          path="/financial_planning_analysis/profit_patterns/profit_landscape"
          exact
          render={() => <TemplateRouter passedComponent={ProfitLandscapeWrapperRouter} showChatbot={true}/>}
        />
        <Route
          path="/financial_planning_analysis/profit_patterns/geography_concentration"
          exact
          render={() => <TemplateRouter passedComponent={GeographyConcentrationWrapperRouter} showChatbot={true}/>}
        />
        <Route
          path={MENU_ITEM_URLS.PROFIT_PATTERNS_ENTITY_STACKS}
          exact
          render={() => (
            <TemplateRouter
              passedComponent={StackWrapperRouter}
              additionalProps={{ isEntity: true, isRange: true }}
              isScenarioDisabledProps={true}
              showChatbot={true}
            />
          )}
        />
        <Route
          path={"/financial_planning_analysis/profit_patterns/heatmap"}
          exact
          render={() => <TemplateRouter passedComponent={HeatmapWrapperRouter} showChatbot={true}/>}
        />

        <Route
          path="/financial_planning_analysis/stacks/profit_stacks/:sectionId?"
          render={() => <TemplateRouter passedComponent={StackWrapperRouter} showLeftMenu showChatbot={true}/>}
        />
        <Route
              path="/financial_planning_analysis/stacks/:report"
              exact
              render={() => (
                <TemplateRouter passedComponent={ListWrapperRouter} passedCustomReportComponent={CustomReportsWrapperRouter} showChatbot={true}/>
              )}
        />
        <Route
              path="/financial_planning_analysis/profit_patterns/:report"
              exact
              render={() => (
                <TemplateRouter passedComponent={ListWrapperRouter} passedCustomReportComponent={CustomReportsWrapperRouter} showChatbot={true}/>
              )}
        /> 
        <Route
            path="/financial_planning_analysis/:report"
            exact
            render={() => (
                <TemplateRouter passedComponent={ListWrapperRouter} passedCustomReportComponent={CustomReportsWrapperRouter} showChatbot={true}/>
        )}
        />
      </Switch>
      <Switch>
        <Route
            path="/supply_chain_management/network_execution/tlc_landscape"
            exact
            render={() => (
                <TemplateRouter passedComponent={TLCLandscapeWrapperRouter} isScenarioDisabledProps={true} isScenarioPublished={true} showChatbot={true}/>
            )}
        />
        <Route
            path="/supply_chain_management/network_execution/:report"
            exact
            render={() => (
              <TemplateRouter passedCustomReportComponent={CustomReportsWrapperRouter} showChatbot={true}/>
            )}
        />
        <Route
            path="/supply_chain_management/inventory_performance/:report"
            exact
            render={() => (
              <TemplateRouter passedCustomReportComponent={CustomReportsWrapperRouter} showChatbot={true}/>
            )}
        />
        <Route
            path="/supply_chain_management/:report"
            exact
            render={() => (
                <TemplateRouter passedCustomReportComponent={CustomReportsWrapperRouter} showChatbot={true}/>
            )}
        />
      </Switch>
      {/* Custom Reporting */}
      <Route
          path="/tailored_reports/:report?"
          exact
          render={() => <TemplateRouter passedCustomReportComponent={CustomReportsWrapperRouter} showChatbot={true}/>}
        />
      {/* Configuration */}
      {/* Report Configurations */}

      <Route
        path="/general_settings/manage_access"
        exact
        render={() => <TemplateRouter passedComponent={ManageAccessRouter} isScenarioDisabledProps={true} independantFromScenario />}
      ></Route>
      <Route
        path="/general_settings/manage_settings"
        exact
        render={() => <TemplateRouter passedComponent={ManageSettingsWrapperRouter} isScenarioDisabledProps={true} hideSettings hideInfo isScenarioHidden removeBackground />}
      ></Route>

      {/* <Route path="/financial_reporting/manage_stacks/profitStack/:sectionId/:customStackId/:cookieName?" exact render={() => <ExtendedProfitStack />} /> */}
      <Switch>
        <Route
            path="/report_configurations/manage_stacks"
            exact
            render={() => (
                <TemplateRouter
                    passedComponent={ManageStacksWrapperRouter}
                    isConfiguration
                    isScenarioDisabledProps={true}
                    isScenarioPublished={true}
                />
            )}
        ></Route>
        <Route
            path={"/report_configurations/configure_bridge_report"}
            exact
            render={() => <TemplateRouter passedComponent={ConfigureBridgeReportWrapperRouter} isScenarioHidden hideInfo hideSettings removeBackground />}
        />
        <Route
            path="/report_configurations/total_stacks"
            exact
            render={() => (
                <TemplateRouter passedComponent={StackWrapperRouter} additionalProps={{ isTotal: true, isRange: true }} />
            )}
        />
        <Route
            path="/report_configurations/manage_custom_reports"
            exact
            render={() => <TemplateRouter passedComponent={ManageCustomReportsWrapperRouter} isScenarioDisabledProps={true} isScenarioPublished={true} />}
        />
        <Route
          path="/report_configurations/supply_chain_setup"
          exact
          render={() => (
            <TemplateRouter
              passedComponent={SupplyChainSetupWrapperRouter}
              isConfiguration
              isScenarioDisabledProps={true}
              isScenarioPublished={true}
            />
          )}
        />
      </Switch>

      {/* Data Modeling */}
      <Switch>
        <Route
          path={"/profit_modeling/" + MANAGE_SCENARIOS}
          exact
          render={() => <TemplateRouter passedComponent={ManageScenariosRouter} isDataModeling hideInfo isScenarioHidden removeBackground />}
        />

        <Route
          path={"/profit_modeling/" + MANAGE_COST_CENTERS}
          exact
          render={() => <TemplateRouter passedComponent={ManageCostCentersRouter} isDataModeling hideInfo isScenarioHidden isConfiguration removeBackground />}
        />
        <Route
          path={"/profit_modeling/" + MANAGE_ACCRUALS}
          exact
          render={() => <TemplateRouter passedComponent={ManageAccrualsWrapperRouter} isDataModeling hideInfo isScenarioHidden removeBackground/>}
        />
        <Route 
            path={"/profit_modeling/" + UPLOAD} 
            exact 
            render={() => <TemplateRouter passedComponent={UploadDataWrapperRouter} isDataModeling isScenarioHidden hideInfo removeBackground/>}
        />        
        <Route
            path={"/profit_modeling/" + MODELING_SETTINGS}
            exact
            render={() => <TemplateRouter passedComponent={ModelingSettingWrapperRouter} isDataModeling = {true} isConfiguration isScenarioHidden hideInfo removeBackground />}
        />
        <Route
          path={"/profit_modeling/" + MONTHLY_BUILD}
          exact
          render={() => <TemplateRouter passedComponent={MonthlyBuildWrapperRouter} isDataModeling={true} isConfiguration
                                        isScenarioHidden hideInfo hideSettings removeBackground/>}
        />
  
        <Route
            path={"/profit_modeling/" + CONFIGURE_GL_ACCOUNT_RANGES}
            exact
            render={() => <TemplateRouter passedComponent={ConfigureGlAccountRangesWrapperRouter} isDataModeling = {true} isScenarioHidden hideInfo removeBackground />}
        />
         <Route
         path={"/profit_modeling/" + STAGE }
         exact
            render={() => <TemplateRouter passedComponent={StageWrapperRouter} isDataModeling isScenarioHidden hideInfo removeBackground isConfiguration />}
        />
        <Route
           path={"/profit_modeling/" + CONFIGURE_AND_BUILD }
           exact
          render={() => <TemplateRouter passedComponent={ConfigureBuildWrapperRouter} isDataModeling isScenarioHidden hideInfo removeBackground isConfiguration showTemplateToast={false} />}
          />
        <Route path={"/profit_modeling/" + COMPARE_SCENARIOS}
               exact
               render={() => <TemplateRouter passedComponent={CompareScenariosWrapperRouter} isScenarioHidden isDataModeling/>}
        />
      </Switch>
        <Route
          path={"/build_details"}
          exact
          render={() => (
            <TemplateRouter passedComponent={BuildDetailsWrapperRouter} noMenuReport={true} hideInfo hideSettings byPassAccess/>
          )}
        />

      {/* Administration */}
      {/* <Route path={"/manage_access/:sectionId?"} exact render={() => <ManageAccess />} /> */}
      {/*<Route path="/settings/:sectionId?" exact render={() => <Settings />} />*/}
      {/* <Route path={"/" + ALL_WIDGETS.SYSTEM.SYSTEM + "/:report/:sectionId?"} exact render={() => <System />} /> */}
      <Switch>
        <Route
            path={"/system_settings/manage_caching"}
            exact
            render={() => <TemplateRouter passedComponent={CacheManagerWrapperRouter} isScenarioHidden hideInfo independantFromScenario/>}
        />
      </Switch>

      {/* Other */}
      <Route
        path={"/vector_definitions"}
        exact
        render={() => (
          <TemplateRouter passedComponent={VectorDefinitionsWrapperRouter} noMenuReport={true} hideInfo hideSettings showChatbot={true}/>
        )}
      />
      <Route
        path={"/profit_stack_line_definitions"}
        exact
        render={() => (
          <TemplateRouter passedComponent={ProfitStackLineDefinitionsWrapperRouter} noMenuReport={true} hideInfo hideSettings showChatbot={true}/>
        )}
      />
      <Route
          path={"/model_coherence"}
          exact
          render={() => (
              <TemplateRouter passedComponent={ModelCoherenceWrapperRouter} noMenuReport={true} hideInfo hideSettings showChatbot={true}/>
          )}
      />
    </Router>
  </>
);
