import React, { useCallback, useEffect, useState } from "react";
import { Section } from "./Section";
import "./expandableSections.css";
import PropTypes from "prop-types";

// Main ExpandableSections component
const ExpandableSections = ({ title, titleClassName = "", sections, onSectionItemClick }) => {
  // Using Set for state management for better performance
  const initialExpandedSet = new Set(sections.map((section) => section.title));
  const [expandedSections, setExpandedSections] = useState(initialExpandedSet);

  useEffect(() => {
    setExpandedSections(initialExpandedSet);
  }, [sections])

  // Toggle function using useCallback to avoid unnecessary re-renders
  const toggleSection = useCallback((section) => {
    setExpandedSections((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(section)) {
        newSet.delete(section); // Collapse section
      } else {
        newSet.add(section); // Expand section
      }
      return newSet;
    });
  }, []);

  return (
    <div id="expandable-sections-container">
      {title && <label className={titleClassName}>{title}</label>}
      <div id="expandable-sections">
        {sections.map(
          (section) =>
            section?.items?.length > 0 && (
              <Section
                key={section.title}
                title={section.title}
                items={section.items}
                expanded={expandedSections.has(section.title)}
                onToggle={() => toggleSection(section.title)}
                onItemClick={onSectionItemClick}
              />
            )
        )}
      </div>
    </div>
  );
};

ExpandableSections.propTypes = {
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.object).isRequired,
    })
  ).isRequired,
  onSectionItemClick: PropTypes.func,
};

export { ExpandableSections };
