import React, {forwardRef, useEffect, useImperativeHandle, useReducer, useRef, useState} from "react";
import {ReactComponent as CalendarsIcon} from "../../../../styles/images/icons/calendars.svg";
import {ReactComponent as FlagIcon} from "../../../../styles/images/icons/flag.svg";
import NewBanner from "../../../NewBanner";
import {formatDateMMDDYYY, getTranslationFile, parseBoolean } from "../../../../class/utils";

import StepLabel from "@mui/material/StepLabel";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import BuildProgress from "./BuildProgress";
import { BUILD_STEPS, INITIAL_BUILD_SUB_STEPS } from "../../../../class/constants";
import PublishPeriod from "./PublishPeriod";
import MonthlyBuildCurrentPeriodBreadcrumbs from "../../MonthlyBuildCurrentPeriodBreadCrumps";
import { FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS } from "../../../../class/networkUtils";
const lang = getTranslationFile();

const BuildStep = (props, ref) => {

    
    const [activeSubStep, setActiveSubStep] = useState(BUILD_STEPS.BUILD_PROGRESS);
    const [subStepProgressBarClickable, setSubStepProgressBarClickable] = useState(true);
    const [activeSubStepIndex, setActiveStepIndex] = useState(0);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const [buildSteps, setBuildSteps] = useState(INITIAL_BUILD_SUB_STEPS);
    const [firstUnCompletedIndex, setFirstUnCompletedIndex] = useState(0);
    const [periodsAffected, setPeriodsAffected] = useState([]);
    const STEP_STATUS = lang.monthly_build.steps.status;
    const BuildProgressRef = useRef();


    const {
        endDate,
        period,
        startDate,
        hideCostCenters,
        publishedScenarioId,
        lastMonthlyBuildStatus,
        setStepChecked,
        getStepsState,
        renderMonthlyBuildBanner,
        setForceRebuild,
        setCancelRevert,
        setProgressBarClickable,
        forceRebuild,
        isBuildInitiated,
        setIsBuildInitiated,
        callbackAfterCancelBuild
    } = props;

    useImperativeHandle(ref, () => ({
        handleSubStepBack: () =>{
            handleSubStepBack()
        }, 
        currentSubStep: () =>{
            return activeSubStepIndex;
        },
        buildSubStepsStatusInitializer: (steps) => {
            buildSubStepsStatusInitializer(steps);
        },
        setActivebuildStep: (step) => {
            BuildProgressRef?.current.setActivebuildStep(step);
        },
        setOpenCancelBuildModal: (open, callback) => {
            BuildProgressRef?.current.setOpenCancelBuildModal(open, callback);
        },
        getCurrBuildStatus: () => {
            return BuildProgressRef?.current?.getCurrBuildStatus();
        }
    }));

    useEffect(() => {
        getStepsState(lang.monthly_build.steps.value.build_scenario);
        getMonthlyBuildPeriods();
    },[])

    useEffect(() => {
        if(activeSubStep){
            setActiveStepIndex(buildSteps.findIndex((step) => step.id === activeSubStep))
            setFirstUnCompletedIndex(buildSteps.findIndex(step => !step.complete))
        }
    },[activeSubStep])

    const buildSubStepsStatusInitializer = (buildSubStepsStatusParam) => {
        const buildSubStepsStatus = buildSubStepsStatusParam.sort((a,b) => (a.step_order > b.step_order ? 1 : -1));
        let foundUncompletedStep = -1; // must be false to change it on the first uncompleted step
        for (let step in buildSubStepsStatus) {
            let stepComplete = buildSubStepsStatus[step]['status'] == STEP_STATUS.COMPLETED;
//                 !!!!!!!!!!!!!!!!!!!!!!!!CHECK BEFORE MERGE !!!!!!!!!!!!!!!!!!!!!!!!
            if(!stepComplete && foundUncompletedStep == -1 && buildSubStepsStatus[step]['name']!=="BUILD_VALIDATION"){ // if the step is not completed and we didn't find the first uncompleted step yet
                foundUncompletedStep = step; // set the firstuncompleted step so we can redirect to it, and the condition above will be always false
            }
            handleStepCompletionStatus(buildSubStepsStatus[step]['name'], stepComplete)
        }
        setActiveSubStep(buildSubStepsStatus[foundUncompletedStep]['name']);
    }

    const handleStepCompletionStatus = (targetId, status) => {
        setBuildSteps(prevState => prevState.map(item => 
            item.id === targetId ? { ...item, complete: status } : item
        ));
    };
    
    const handleSubStepBack = () => {
        const prevIndex = activeSubStepIndex - 1;
        if (prevIndex >= 0) {
            setActiveSubStep(buildSteps[prevIndex].id);
        }
    };
    const handleSubStepNext = () => {
        const nextIndex = activeSubStepIndex + 1;
        if (nextIndex < buildSteps.length) {
            setActiveSubStep(buildSteps[nextIndex].id);
        }
    };
    const setRunningRequests = (scenarioId,value) => {
        sessionStorage.setItem("runningRequests_"+scenarioId,value)
        forceUpdate();
    }

    const setIsBuildRunning = (scenarioId,value) => {
        sessionStorage.setItem("isBuildRunning_"+scenarioId,value);
        forceUpdate();
    }

    const setPercentage = (scenarioId,value) => {
        sessionStorage.setItem("buildPerc_"+scenarioId,value)
        forceUpdate();
    }
    

    const redirectTostep = (stepIndex) => {
        
        if(subStepProgressBarClickable){ // a condition to check if the progress bar can be clickable before performing any logic, this condition can be affected if there is a build or a publish running

            // Check if the index matches any of the conditions
            if (stepIndex === firstUnCompletedIndex || buildSteps[stepIndex].complete) {
                setActiveSubStep(buildSteps[stepIndex].id)
            }
        }
    } 

        const getMonthlyBuildPeriods = () => {
            var query = {
                action: "getMonthlyBuildPeriods", //It's still required by networkUtils.js
                period: period,
            };
            let onThenCallback = (data) => {
                if(data?.periodsAffected?.length > 1){ 
                    let periodsAffected = data.periodsAffected.filter(f => f !== period); // don't include the currnet period in the list
                    setPeriodsAffected(periodsAffected);
                }
            };
    
            let fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "getMonthlyBuildAffectedPeriods",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
                [FETCHAPI_PARAMS.showLoader]: false,
                [FETCHAPI_PARAMS.path]: "/get-monthly-build-affected-periods",
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: query,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
                [FETCHAPI_PARAMS.periods]: period,
                [FETCHAPI_PARAMS.screenName]:lang.observability.monthly_build.build.screen_name,
                [FETCHAPI_PARAMS.requestDescription]:lang.observability.monthly_build.build.requests_description.get_monthly_build_affected_periods
            };
            fetchAPI(fetchOptions);
        };

    const setProgressBarClickableCombined = (clickable) => { // build stepper and the monthgly build stepper are disabled under the same circumstances, so we call both function in this function 
        setSubStepProgressBarClickable(clickable)
        setProgressBarClickable(clickable)
    }

    let buildBody;
    switch (activeSubStep){
        case BUILD_STEPS.BUILD_PROGRESS:
            buildBody = <BuildProgress ref={BuildProgressRef} period={period} publishedScenarioId={publishedScenarioId} setRunningRequests={setRunningRequests} setIsBuildRunning={setIsBuildRunning}
                            setPercentage={setPercentage} handleSubStepNext={handleSubStepNext} lastMonthlyBuildStatus={lastMonthlyBuildStatus}
                            handleStepCompletionStatus={handleStepCompletionStatus} setStepChecked={setStepChecked} setForceRebuild={setForceRebuild} 
                            setProgressBarClickable={setProgressBarClickableCombined} setCancelRevert={setCancelRevert} forceRebuild={forceRebuild}
                            setIsBuildInitiated={setIsBuildInitiated} isBuildInitiated={isBuildInitiated} callbackAfterCancelBuild = {callbackAfterCancelBuild}/>
        break;
        case BUILD_STEPS.PUBLISH_PERIOD:
            buildBody = <PublishPeriod period = {period} dispatch = {props.dispatch} publishedScenarioId={publishedScenarioId}
            setProgressBarClickable={setProgressBarClickableCombined}/>
        break;

    }
   

   
    return (
    <div style={{display:"flex", flexDirection:"row"}}>
        <div className="uk-flex pi-vertical-tab uk-padding-large-top uk-padding-large-right uk-padding-xsmall-bottom">
            <div className="uk-padding-large-right main-stepper">
                <Stepper activeStep={activeSubStepIndex} orientation="vertical" >
                    {buildSteps.map((step, index) => (
                        <Step key={index} completed = {step.complete}>
                            <StepLabel  onClick = { () => redirectTostep(index) }
                                        className={subStepProgressBarClickable && (firstUnCompletedIndex == index || step.complete) ? "uk-cursor-pointer" : "cursorNotAllowed"}
                                        icon={index === buildSteps.length - 1 ? <FlagIcon /> : index + 1}>
                                {step.label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>
        </div>
        <div style={{width:"75%"}}>
            <div className="small-gap-flex">
                <span className="p-text small-padding-bottom">
                    {lang.monthly_build.build_scenario.step.replace("[s]", hideCostCenters ? "5":"6")}
                </span>
                <span className="h1-text">
                    {lang.monthly_build.build_scenario.title}
                </span>
                <MonthlyBuildCurrentPeriodBreadcrumbs currentPeriod={period} periods={periodsAffected} listTitle={lang.monthly_build.build_scenario.period_list_title}/>
                {renderMonthlyBuildBanner()}
            </div>
            <div>{buildBody}</div>
        </div>
    </div>
    );
};

export default forwardRef(BuildStep);
