import { useImperativeHandle } from "react";
import { React, useState, forwardRef ,useEffect} from "react";
import { getLocalStorageValueByParameter } from "./class/common";
import { CLIENT_ID_STORAGE, DROPDOWN_TYPE, LISTENER } from "./class/constants";
import { convertPxToViewport } from "./class/formatting";
import { findOptionByKey, parseBoolean } from "./class/utils";
import { CustomSelect } from "./form/elements";
import { lang } from "./language/messages_en";
import Dropdown from "./newComponents/DropDown";
import { ReactComponent as Tick } from "./styles/images/icons/tick.svg";
import CheckBox from "./newComponents/CheckBox";
import { emitEvent, useListener } from "./actions/CustomEventHandler";


const SelectClientDropDown = forwardRef((props,ref) => {
    const clientIdFromLocalStorage = getLocalStorageValueByParameter(CLIENT_ID_STORAGE)? Number(getLocalStorageValueByParameter(CLIENT_ID_STORAGE)):"";
    const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
    const [client, setClient] = useState(props.isLogin ? "" : props.defaultClientId ? props.defaultClientId : !props.isLogin && !props.isFromAvatar && !props.defaultClientId ? "" : clientIdFromLocalStorage);
    const [tempDefaultClient, setTempDefaultClient] = useState(props.defaultClientId);

    
    useListener(LISTENER.PORFILE.CHANGE_CLIENT_END, (event) => {
        changeClient(event.detail);
    });
    
    const onCheckBoxChecked = () => {
        if(!isCheckBoxChecked){
            setTempDefaultClient(client);
        }else{
            setTempDefaultClient("");
        }
        setIsCheckBoxChecked(!isCheckBoxChecked);
    }

    useImperativeHandle(ref, () => ({
        isCheckBoxChecked:()=>{
            return isCheckBoxChecked;
        },
        selectedClientId:()=>{
            return client;
        },
        tempDefaultClient:()=>{
            return tempDefaultClient;
        }
    }));

    const onClientChange = (e) => {
        if(document.getElementById("save-before-leave")){
            emitEvent(LISTENER.PORFILE.CHANGE_CLIENT_START, e);
       } else {
            changeClient(e);
       }
    }

    const changeClient = (e) => {
        if(props.setErrorMessage){
            props.setErrorMessage("");
        }
        let checkForUnsavedChanges = props.checkForUnsavedChanges?props.checkForUnsavedChanges():false;
        if(!(props.isProfile && checkForUnsavedChanges)){
            setClient(e.client_id);
            if(isCheckBoxChecked || props.isFromPreferences){
                setTempDefaultClient(e.client_id);
                setClient(e.client_id);
            }
        }
        if(props.isProfile){
            props.signIn(e.client_id);
        }
        
        if(props.clientChangeCallback) {
            props.clientChangeCallback();
        }
    }

    const formatData = (data) => {
        data.map(e=>{
            e.value = e.client_id;
            e.label = e.client_name;
        })
        return data;
    }

    const addNoneOptionToListTop = (list) => {
        return [{ label: lang.no_description, value: 0, client_id: 0 }, ...list];
    };

    let data = formatData(props.clients);
    if(props.isFromPreferences){
        data = addNoneOptionToListTop(data);
    }
    return (
        <div className="select__client__dropdown__container">
            <h5>{props.textMessage}</h5>
            <div>
                <Dropdown
                    id={"selectClient"}
                    className={"input__dropdown max_width " + (props.isProfile ? "" : "uk-cursor-pointer uk-margin-small-top") + (props.isDisabled ? " uk-disabled disabled" : "")}
                    value={findOptionByKey(data, (client || 0))}
                    options={data}
                    onChange={(e) => onClientChange(e)}
                    placeholder={lang.switch_to_client}
                    type={DROPDOWN_TYPE.INPUT}
                />
            </div>
            {props.isLogin?
                <div>
                    <span className="error-message red">{props.errorMessage}</span>
                    <div className="uk-margin-small-top">
                        <CheckBox id={"makeDefault"} onChange={onCheckBoxChecked} checked={isCheckBoxChecked} labelText={lang.manage_columns.text.default_client_selection} divAddedClassName={"checkbox_default_client_selection"}
                        />
                    </div>
                </div>
            :""
            }
        </div>
    );
})

export default SelectClientDropDown;
