import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { AuthErrorCodes, signInWithEmailAndPassword } from "firebase/auth";
import React from 'react';
import Popup from 'react-popup';
import { connect } from "react-redux";

import { checkIfSectionIdExists, formatAdvancedFilter, formatBasicFilter, getCurrency, getOutputScreenName, setLocalStorageValueByParameter, toggleLoader, triggerExportData } from "./class/common";
import {ALL_REPORTS, ALL_WIDGETS, API_URL, COLORS_PALETTE, COLUMN_PROFILE, DROPDOWN_TYPE, FILTER, FY_VALUES, FormatTypes, LANDSCAPE_BUBBLE, MENU_ITEM, MENU_ITEM_URLS, PSS, ROLLING_SEGMENTS, SEGMENTS_TITLES, UI_ACTIONS, PSL_RETURN_NAMES} from './class/constants';
import { extractFromFullQuarter, generatePeriods, getFullQuarterFromStartEndQuarters, getGeneratedQuarterRange, getLastBuiltPeriodForSegments, getPeriodFromDate, getPeriodQuarter, getQuarterFromDate, monthDiff } from './class/date';
import { formatValString } from './class/format';
import {getLimit, getPSTier} from "./class/jqueries";
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from './class/networkUtils';
import { getItemFromStore } from "./class/reduxStoreUtils";
import { copyObjectValues, findOptionByKey, getSectionExists, getTranslationFile, isValidDate, parseBoolean, returnObjectsInArray, tryParse, updateAllData, updateData} from './class/utils';
import Configure from './components/configure/Configure';
import ConfirmPasswordModal from "./components/ConfirmPasswordModal";
import ExpandingList from './components/ExpandingList';
import Container from './components/manageColumns/Container';
import { Segment } from "./components/Segment";
import { auth } from "./firebase/firebase";
import ButtonDropdown from "./newComponents/ButtonDropdown";
import Dropdown from "./newComponents/DropDown";
import { getNewEntityFilter } from "./sections/filter/FilterHelperFunctions";
import ProfitMapList from './sections/ProfitMapList.js';
import SessionTimeout from './SessionTimeout';
import './styles/landscapeBubble.css';
import { updateLandscapeBubbleListData, updateLandscapeBubbleObjBeforeBack, updateLandscapeCostHierarchy } from "./actions/landscapeBubbleActions";
import { lang } from './language/messages_en';
import Button from "./newComponents/Button";
import { Component } from "react";
import { checkPeriodAvailability, getDateFromPeriod, getValidPeriods } from "./templateLayout/functions/periodFunctions";
import {atLeastOneVectorHasNAInData} from "./templateLayout/functions/componentFunctions";


const MESSAGES = getTranslationFile();

const defaultPath = API_URL.PROFIT_LANDSCAPE;
const _LIST = "List";

let initialLandscapeConfObj = {
  xAxis: {},
  yAxis: {},
  bubbleSize: {}
}
let confObjPSLines = {
  xAxisPSLines: {},
  yAxisPSLines: {},
}
let selectedConfObjPSLines = {
  xAxisPSLines: {},
  yAxisPSLines: {},
}
const USER_ACTIVITY_USER_NOT_AUTHENTICATED = "User was not authenticated - client side.";

let profitStackSelectedEntities = [];

let isActiveBubble = false;
class LandscapeBubble extends Component {
  constructor(props) {
    super(props);
      this.initialState = {
        hasDefaultHeaderValues: true,
        FY: props.history.location.state && props.isRange ? props.history.location.state.FY : undefined,
        nextProfitStackViewId: props.history.location.state && props.isRange ? props.history.location.state.profitStackViewId : props?.PSViewsState?.nextProfitStackViewId || 0,
        profitFormat: props.history.location.state && props.isRange ? props.history.location.state.profitFormat : MENU_ITEM.FIELDS.PROFIT_LANDSCAPE,
        profitStackViewId: props.history?.location?.state && props.isRange ? props.history?.location?.state.profitStackViewId : props?.PSViewsState?.nextProfitStackViewId || 0,
        scenario: props.history.location.state && props.history.location.state.scenarios ? props.history.location.state.scenarios[0] : "",
        displayFilter: true,
        data: [],
        landscapeConfigObj: initialLandscapeConfObj,
        chartData: [],
        compareMode: false,
        selectedBubble: [],
        listTitle: _LIST,
        isListExpanded: false,
        addModified: false,
        isListFullScreen: false,
        bubbleListExpanded:false,
        filterDisplayEditable: false,
        psFilterDisabled: false,
        readfromStore:false
    };

    this.state = Object.assign({}, this.state, this.initialState);
    this.isMainReport = { isLandscapeBubble: true }
    this.expandingDefaultListRef = React.createRef();
    this.expandingSelectedListRef = React.createRef();
    this.configDropdown = React.createRef();
  }

  setExportOptions = () => {
    let _this = this;
    let exportOptions = [{ value:"export_excel" ,label:"Export Excel", tooltip: lang.header.tooltips.export_all_excel}];
    var hasExportCSVAccess = getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.EXPORT_DATA_TO_CSV);    
    if (hasExportCSVAccess) {
        exportOptions.push({value:ALL_WIDGETS.EXPORT_ALL , label:MESSAGES.header.titles.csv, tooltip: lang.header.tooltips.csv} );
        exportOptions.push({value:ALL_WIDGETS.EXPORT_CSV_PSL , label:MESSAGES.header.titles.csv_all_psl, tooltip: lang.header.tooltips.csv_all});
    }
    _this.setState({
      exportOptions: exportOptions
    })
  }

  componentDidUpdate(prevProps, prevState) {
    let _this = this;
    if (_this.state.callGo !== prevState.callGo && _this.state.callGo && !_this.props.showGreyOverLay) {
      _this.getListData();
    }

    // when changing scope, we need to refetch chart and list data afte recalling profitStackHierarchy request
    if(JSON.stringify(this.state.landscapeConfigObj) !== JSON.stringify(prevState.landscapeConfigObj) && _this.props.history?.location?.state?.isScopeFilterChanged) {
      let state = this.props.history?.location?.state || {};
      delete state.isScopeFilterChanged;
      this.props.history.push({
        state: state
      });

      this.props.setShowGreyOverLay(false);
      this.go();
    }
  }
  componentDidMount() {
    // super.componentDidMount();
    
    let _this = this;
    _this.setExportOptions();
    if(!_this.props.history?.location?.state?.isRedirectionFromStacks) { 
      // clear store when comming from menu and not from back in entity stacks
      _this.props.dispatch(updateLandscapeBubbleListData([], "landscapeBubbleListData", true));
      _this.props.dispatch(updateLandscapeBubbleObjBeforeBack([], "landscapeBubbleObjBeforeBack", true));
      _this.props.dispatch(updateLandscapeCostHierarchy([], "landscapeCostHierarchy", true));
      // on refresh, set headerOptionCHnaged to true to display gray overlay
      let state = this.props.history?.location?.state || {};
      state.isRedirectionFromStacks = false;
      this.props.history.push({
        state: state
      });
      _this.setState({
        readfromStore:false
      },()=>{
        this.getProfitStackHierarchy(0);
      })
    }
    // after redirection from stacks to landscpae, set isRedirection false so that when refreshing, we set headerOptionCHnaged to true
    if (_this.props.history?.location?.state?.isRedirectionFromStacks && !_this.props.history?.location?.state?.isScopeFilterChanged) {
      this.props.history.push({
        state: {
          isRedirectionFromStacks: false,
          scenarioState: this.props.history.location.state.scenarioState,
          useFilterCookies: this.props.history.location?.state?.useFilterCookies,
        }
      });
      _this.setState({
        readfromStore:true
      },() => {
        this.getProfitStackHierarchy(0);
        this.go();
        this.props.setShowGreyOverLay(false);
      })
    }

    // when changing scope we need to reftech the cost hierarchy - isScopeFilterChanged is true when in drill or entity stacks screen
    if(_this.props.history?.location?.state?.isScopeFilterChanged) {
      _this.setState({
        readfromStore:false
      },()=>{
        this.getProfitStackHierarchy(0);
      })
    }

    // _this.closeConfigureDropdown();
    $(window).on("resize", () => {
      let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
      // $("#configure-dropdown .configure-container").css("height", zoom > 124 ? "35vh" : zoom > 109 ? "32vh" : "16vw");
      // $(".bubble-chart-div").height(zoom > 124 ? "72vh" : zoom > 109 ? "75vh" : "78vh");
    })
    // this.fetchListsCallback()
  }

  closeConfigureDropdown = () => {
    let _this = this;
    $(document).on("mouseover click", function (event) {
      if (($("#configure-dropdown:hover").length === 0) && ($("#landscape-configure-btn:hover").length === 0)) {
        if (event.type === "click") {
          _this.setState({
            showLandscapeConfigure: false
          })
        }
      }
    });
  }

  go(){
    let _this = this;
    let dataFromStore = _this.state.readfromStore ? getItemFromStore("landscapeBubbleObjBeforeBack", this, "landscapeBubbleObjBeforeBack") : undefined;
 
    if (dataFromStore) {
      let tempState = _this.getLandscapeBubbleChartDataBeforeBackObj(dataFromStore);
      if (tempState.isListExpanded) { 
        if(_this.expandingDefaultListRef.current) {
          _this.expandingDefaultListRef.current.expandClick(true); 
        } 
      }
      this.setState(tempState, () => {
        _this.renderChart();
        _this.getListData(); // setSize will trigger ajaxRequestFunc, which will call fetchProfitInfo()
        this.props.dispatch(updateLandscapeBubbleObjBeforeBack([], "landscapeBubbleObjBeforeBack", true)); // remove heatmapBeforeBackObj from store after redirection from entity stacks to heatmap
      })
    } else {
      selectedConfObjPSLines = copyObjectValues(confObjPSLines);
      _this.getBubbleChartData(_this.props.scenarioState.scenarios[0]);
      isActiveBubble = false;
      _this.setState({
        bubbleSizeSelection: _this.state.landscapeConfigObj.bubbleSize,
        bubbleListFilter: "",
        bubbleListExpanded: false,
      },()=>{
      })
    }
  }

  getLandscapeBubbleChartDataBeforeBackObj = (data) => {
    let tempState = {};
    tempState.chartData = data.chartData;
    tempState.expandedListTitle = data.expandedListTitle;
    tempState.isListExpanded = data.isListExpanded;
    tempState.listTitle = data.listTitle;
    tempState.addModified = data.addModified;
    tempState.manageColumnsProfile = data.manageColumnsProfile;
    tempState.tableColumns = data.tableColumns;
    tempState.vectorObjects = data.vectorObjects;
    tempState.vectorOptions = data.vectorOptions;
    tempState.filterFinal = data.filterFinal;
    return tempState;
  }

  formatPslLines = (pslLines) => {// move to landscapebubbleHelpers
    let _this = this;
    let className = "heatmap-configure";
    return pslLines.map(psl => {
      className = "heatmap-configure ";
      if (psl.children) {
        let pslObj = _this.formatPslObject(psl, className);
        pslObj.children = _this.formatPslLines(psl.children)
        return pslObj;
      } else {
        return _this.formatPslObject(psl, className);
      }
    })
  }

  defaultCheck = (pslLines, pslQuadrant, isXAxis) => { // tODO rename function name to checkDEfaultPSLs 
    let _this = this;
    return pslLines.map(psl => {
      if (psl.value === pslQuadrant || !pslQuadrant) {
        updateData(pslLines, psl);
        if (isXAxis) {
          _this.setState({
            xSelectedPSLine: psl,
          })
        } else {
          _this.setState({
            ySelectedPSLine: psl,
          })
        }
      }
      if (psl.children) {
        _this.defaultCheck(psl.children, pslQuadrant, isXAxis)
      }
    })
  }

  formatAttributes = (attributes) => {// move to landscapebubbleHelpers
    let _this = this;
    return attributes.map(attr => {
      return _this.formatAttributeObject(attr);
    })
  }

  formatBubbleChartData = (data) => {// move to landscapebubbleHelpers
    let _this = this
    let finalData = [];
    data.map(row => {
      let segment = new Segment();
      let segmentObject = segment.getSegmentObject(row.title);

      let xFormat = formatValString(row.x, _this.state.landscapeConfigObj.xAxis.format);
      let yFormat = formatValString(row.y, _this.state.landscapeConfigObj.yAxis.format);
      let title = segmentObject?.label;
      let originalLabel = segmentObject?.originalLabel;
      if (xFormat.length == 1) {
        xFormat = xFormat.replace("-","0") //replace "-" by "0" after formatValString converts small values into "-" causing errors
      }
      if (yFormat.length == 1) {
        yFormat = yFormat.replace("-","0") //replace "-" by "0" after formatValString converts small values into "-" causing errors
      }
      
      let formatData = {
        title: title, xLabel: row.xLabel, yLabel: row.yLabel, rowX: xFormat, rowY: yFormat, x: row.x, y:row.y, value: formatValString(row.value, FormatTypes.NUMERIC).replace("-","0"), originalValue:row.value,originalLabel: originalLabel,
        color: segmentObject?.bubbleColor, sizeLabel: _this.state.landscapeConfigObj.bubbleSize.label, selected: "false", tooltip: title.includes(lang.N_A) ? lang.profit_landscape.tooltips.not_supported :lang.profit_landscape.tooltips.show_entities
      }
      finalData.push(formatData)
    })
    return finalData;
  }

  formatPslObject = (psl, className) => {// move to landscapebubbleHelpers
    return { label: psl[PSS.NAME], value: psl[PSS.RETURN_NAME], path: psl.path, format: psl.format, nameInFact: psl.name_in_fact, costtype: psl.costtype, className: className }
  }

  formatAttributeObject = (psl) => { // move to landscapebubbleHelpers
    return { label: psl[PSS.NAME], value: psl[PSS.RETURN_NAME], nameInFact: psl.name_in_fact }
  }

  // getValidPeriods = () => {
  //   let _this = this;
  //   let startDate = _this.props?.periodsStatusState?.customStartDate;
  //   let endDate = _this.props?.periodsStatusState?.customEndDate;

  //   // checks if the customDate has a built period or not
  //   if (_this.props?.periodsStatusState?.customStartDate && _this.props.periodsStatusState && _this.props?.periodsStatusState?.builtPeriods) {
  //     startDate = getDateFromPeriod(_this.props?.periodsStatusState?.customStartDate, _this.props.periodsStatusState);
  //     endDate = getDateFromPeriod(_this.props?.periodsStatusState?.customEndDate, _this.props.periodsStatusState);
  //     startDate = startDate.start_date ? new Date(startDate.start_date) : startDate;
  //     endDate = endDate.end_date ? new Date(endDate.end_date) : endDate;
  //     let isStartPeriodAvailable = isPeriodBuilt(getPeriodFromDate(startDate));
  //     let isEndPeriodAvailable = isPeriodBuilt(getPeriodFromDate(endDate));

  //     // if the period is available, no need to put it in a state
  //     if (!isStartPeriodAvailable && !isEndPeriodAvailable) {
  //       startDate = checkPeriodAvailability(startDate);
  //       endDate = checkPeriodAvailability(endDate);
  //       startDate = typeof startDate !== 'string' ? startDate : _this.props.periodsStatusState.find(f => f.label === startDate) ? new Date(_this.props.periodsStatusState.find(f => f.label === startDate).start_date) : this.props.periodsStatusState.customStartDate; // if startDate is a period -> find its start_date
  //       endDate = typeof endDate !== 'string' ? endDate : _this.props.periodsStatusState.find(f => f.label === endDate) ? new Date(_this.props.periodsStatusState.find(f => f.label === endDate).end_date) : this.props.periodsStatusState.customEndDate;
  //     }

  //   }

  //   return { startDate: startDate, endDate: endDate }
  // }

  getPeriodsObject = () => { // move to Date 
    let _this = this;
    let periods = [];
    let periods_yoy = [];
    let periods_pa = [];
    let quarter = "";
    let quarterPre = "";
    let segmentPeriod = "";
    let months = FY_VALUES.M3;
    // let startDate = (_this.props.location.state && _this.props.location.state.startDate) || this.state.customStartDate;
    // let endDate = (_this.props.location.state && _this.props.location.state.endDate) || this.state.customEndDate;

    let startDate = getValidPeriods(_this.props.periodsStatusState, _this.props.clientPeriodsState).startDate;
    let endDate = getValidPeriods(_this.props.periodsStatusState, _this.props.clientPeriodsState).endDate;

    if (isValidDate(startDate) && isValidDate(endDate) && startDate && endDate) {
      let periodsCount = monthDiff(startDate, endDate) + 1;
      periods = generatePeriods(startDate, periodsCount);
      periods_yoy = generatePeriods(startDate, periodsCount, false);
      periods_pa = generatePeriods(startDate, periodsCount, true);

      let firstQuarter = getQuarterFromDate(startDate);
      let endQuarter = getQuarterFromDate(endDate);

      let firstPreQuarter = getPeriodQuarter(periods_pa[0]);
      let endPreQuarter = getPeriodQuarter(periods_pa[periods_pa.length - 1]);

      let generatedRange = getGeneratedQuarterRange(_this.props.datasetState.datasetOptions, firstQuarter, endQuarter);
      let generatedRangePre = getGeneratedQuarterRange(_this.props.datasetState.datasetOptions, firstPreQuarter, endPreQuarter);

      let fullQuarter = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRange[0], generatedRange[1]));
      let fullQuarterPre = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRangePre[0], generatedRangePre[1]));

      quarter = fullQuarter.quarter;
      quarterPre = fullQuarterPre.quarter;

      months = fullQuarter.months;

      let lastSelectedPeriod = periods[periods.length - 1];
      let builtPeriods = _this.props?.periodsStatusState?.actuallyBuiltPeriods?.map(m => m.label)
      segmentPeriod = getLastBuiltPeriodForSegments(builtPeriods, lastSelectedPeriod, 12);
    }

    return { periods: periods, segmentPeriod: segmentPeriod, quarter: quarter, 
      months: months, periods_yoy: periods_yoy, periods_pa: periods_pa, preQuarter: quarterPre };
  }

  getProfitStackHierarchy = (viewId, scenario) => {
    let _this = this;
    var query = {
      action: "getCostHierarchy",
      scenario_id: this.props.scenarioState.scenario || scenario,
      view_id: viewId
    }

    let dataFromStore = _this.state.readfromStore ? getItemFromStore("landscapeCostHierarchy", _this, "landscapeCostHierarchy") : undefined;

    var onThenCallback = (data) => {
      let tempState = {};
      if(dataFromStore) {
        tempState.landscapeConfigObj = dataFromStore.landscapeConfigObj;
        tempState.bubbleSize = dataFromStore.bubbleSize;
        tempState.bubbleSizeSelection = dataFromStore.bubbleSizeSelection;
        confObjPSLines.xAxisPSLines = dataFromStore.confObjPSLines.xAxisPSLines;
        confObjPSLines.yAxisPSLines = dataFromStore.confObjPSLines.yAxisPSLines;
        selectedConfObjPSLines = copyObjectValues(dataFromStore.confObjPSLines);

      } else {
        let tempConfObj = copyObjectValues(_this.state.landscapeConfigObj);
        if (data.firstLevelPsl) {
          let xPSLines = _this.formatPslLines(data.firstLevelPsl);
          this.defaultCheck(xPSLines, data.pslQuadrant[0] || undefined , true);
          let yPSLines = _this.formatPslLines(data.firstLevelPsl);
          this.defaultCheck(yPSLines, data.pslQuadrant[1] || undefined, false);
          tempConfObj.xAxis = _this.state.xSelectedPSLine;
          tempConfObj.yAxis = _this.state.ySelectedPSLine;
          confObjPSLines.xAxisPSLines = xPSLines;
          confObjPSLines.yAxisPSLines = yPSLines;
          selectedConfObjPSLines = copyObjectValues(confObjPSLines)
  
        }
        if (data.attributes) {
          let attributes = _this.formatAttributes(data.attributes)
          let entityCount = { label: LANDSCAPE_BUBBLE.CONFIGURE.ENTITY_COUNT, value: LANDSCAPE_BUBBLE.CONFIGURE.ENTITY_COUNT_VALUE, nameInFact: "COUNT(DISTINCT f.$vector$Key)" }
          let attributesArray = [];
          attributesArray = attributes.unshift(entityCount);
          tempState.bubbleSize = attributes;
          tempState.bubbleSizeSelection = attributes[0];
          tempConfObj.bubbleSize = attributes[0];
        }
        tempState.landscapeConfigObj = tempConfObj
      }

      this.setState(tempState,()=>{if (_this.state.readfromStore)_this.renderChart();
      });

    };

    var fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "getCostHierarchy",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: defaultPath,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.email]: this.props.userSettingsState.user.email,
      [FETCHAPI_PARAMS.machine_name]: this.props.userSettingsState.machine_name,
      [FETCHAPI_PARAMS.profitFormat]: this.state.profitFormat,
      [FETCHAPI_PARAMS.useStore]: dataFromStore !== undefined,
      [FETCHAPI_PARAMS.scenarioId]: "landscapeCostHierarchy",
			[FETCHAPI_PARAMS.dataKey]: "landscapeCostHierarchy",
      [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.configurations.get_cost_hierarchy,
      [FETCHAPI_PARAMS.screenName]: getOutputScreenName(window.location.href),

    }
    fetchAPI(fetchOptions);
  }

  getBubbleChartData = (scenario) => {
    let _this = this;
    let periodsObject = _this.getPeriodsObject();
    let selectedPeriods = periodsObject.periods;
    let quarter = periodsObject.quarter;
    let months = periodsObject.months;
    let generatedVectors = _this.props.vectorState?.vectorOptions?.filter(f=>f.generated).length
    if (generatedVectors > 0) {
    var query = {
      action: "getBubbleChartData",
      landscapeConfObj: _this.state.landscapeConfigObj,
      landscapeBubbleVector: _this.props.vectorState.vectors[0],
      quarter: quarter,
      scenario_id: _this.props.scenarioState.scenario,
      selectedPeriods: selectedPeriods,
      filter: "{'filter':" + encodeURIComponent(_this.props.filterFinal?.length > 0 ? _this.props.filterFinal : "[]") + "}",
      FY: months,
      rollingPeriod: periodsObject.segmentPeriod,
      rollingSegment: ROLLING_SEGMENTS.R_12,
    }

    var onThenCallback = (data) => {
      
    data.data.sort(function(a, b) {
        return b.value - a.value;
    });
   
    
      let finalData = _this.formatBubbleChartData(data.data);
      let ref = _this.expandingDefaultListRef.current ? _this.expandingDefaultListRef.current : _this.expandingSelectedListRef.current
      let profile = ref.getDefaultManageColumnsRef()?.state.selectedProfile ? ref.getDefaultManageColumnsRef().simplifyProfileReturnNames(ref.getDefaultManageColumnsRef().state.selectedProfile) : _this.state.manageColumnsProfile
      let listTitle = findOptionByKey(_this.props?.vectorState?.vectorObjects, _this.props?.vectorState?.vectors[0])?.label + " - " + (profile?.label || _LIST);
      
      _this.setState({
        chartData: finalData,
        fullCompareSelection: false,
        compareMode: false,
        expandedListTitle: listTitle,
        listTitle: _this.state.isListExpanded ? listTitle : _LIST,
        addModified: _this.state.manageColumnsProfile?.is_applied || _this.state.manageColumnsProfile?.is_modified,
        manageColumnsProfile: profile,
        selectedBubble: [],
      }, () => {
        this.props.history.push({
          state: {
            isRedirectionFromStacks: false,
            scenarioState: this.props.history?.location?.state?.scenarioState,
            outsideFilter:this.props.history.location?.state?.outsideFilter,
            mainFilterFinalBasic:this.props.history.location?.state?.mainFilterFinalBasic,
          }
        });
        if (_this.state.compareMode) {
          _this.setCompareMode(true, true);
        }
        _this.props.setfullCompareSelection(false)
        _this.renderChart();
        _this.getListData(); // setSize will trigger ajaxRequestFunc, which will call fetchProfitInfo()
      })

    };

    var fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "getBubbleChartData",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: defaultPath,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.email]: this.props.userSettingsState.user.email,
      [FETCHAPI_PARAMS.machine_name]: this.props.userSettingsState.machine_name,
      [FETCHAPI_PARAMS.profitFormat]: this.state.profitFormat,
      [FETCHAPI_PARAMS.screenName]: lang.observability.output.landscape.screen_name,
      [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.landscape.requests_description.apply,
      [FETCHAPI_PARAMS.vector]: _this.props.vectorState.vectors[0],
      [FETCHAPI_PARAMS.scopeFilterApplicable]: true,
      [FETCHAPI_PARAMS.periods]: selectedPeriods
    }
    fetchAPI(fetchOptions);
    } else {
      _this.renderChart();
    }
  }



  handleAxisChange = (node, xAxis) => {
    let _this = this
    let xProfitStackLines = copyObjectValues(selectedConfObjPSLines.xAxisPSLines);
    let yProfitStackLines = copyObjectValues(selectedConfObjPSLines.yAxisPSLines);
    let tempState = {};
    if (xAxis) {
      updateAllData(xProfitStackLines, false); // uncheck all checked lines
      updateData(xProfitStackLines, node);
      tempState.xSelectedPSLine = node;
      selectedConfObjPSLines.xAxisPSLines = xProfitStackLines
    } else {
      updateAllData(yProfitStackLines, false); // uncheck all checked lines
      updateData(yProfitStackLines, node);
      tempState.ySelectedPSLine = node;
      selectedConfObjPSLines.yAxisPSLines = yProfitStackLines
    }
    _this.setState(tempState);
  };

  handleBubbleSizeChange = (selected) => {
    let _this = this
    _this.setState({
      bubbleSizeSelection: selected
    },()=>{
      _this.props.renderHeader()
    })
  };

  onApplyClick = () => {
    let _this = this
    let tempConfObj = copyObjectValues(_this.state.landscapeConfigObj);
    if(_this.state.xSelectedPSLine){
      tempConfObj.xAxis = _this.state.xSelectedPSLine;
    }
    if(_this.state.ySelectedPSLine){
      tempConfObj.yAxis = _this.state.ySelectedPSLine;
    }
    tempConfObj.bubbleSize = _this.state.bubbleSizeSelection;

    confObjPSLines.xAxisPSLines = selectedConfObjPSLines.xAxisPSLines;
    confObjPSLines.yAxisPSLines = selectedConfObjPSLines.yAxisPSLines;

    _this.setState({
      landscapeConfigObj: tempConfObj,
      bubbleListFilter:"",
      compareMode: false,
    }, () => {
      isActiveBubble = false;
      _this.closeClick(true)
      if (!_this.props.showGreyOverLay) {
        _this.getBubbleChartData(_this.props.scenarioState.scenarios[0]);
      }
      // _this.showLandscapeConfigure();
      this.props.configDropdownRef.current.setConfigDropdownOpen(false);
    })
  }

  renderConfigureBody = () => {
    return (
      <div className='configure-dropdown'>
        <div className='label-configure-landscape'>X Axis</div>
        <Container
          data={selectedConfObjPSLines.xAxisPSLines}
          onChange={(e) => this.handleAxisChange(e, true)}
          mode={"radioSelect"}
          className={
            "x-axis landscape-configure heatmap-configure dropdown-tree uk-margin-small-top"
          }
        />
        <div className='label-configure-landscape uk-margin-small-top'>Y Axis</div>
        <Container
          data={selectedConfObjPSLines.yAxisPSLines}
          onChange={(e) => this.handleAxisChange(e, false)}
          mode={"radioSelect"}
          className={
            "y-axis landscape-configure heatmap-configure dropdown-tree uk-margin-small-top"
          }
        />
        <div className='label-configure-landscape uk-margin-small-top'>Bubble Size</div>
        <Dropdown
          id="select-set"
          className="landscape-configure heatmap-configure uk-cursor-pointer uk-margin-small-top input__dropdown"
          value={findOptionByKey(this.state.bubbleSize, this.state.bubbleSizeSelection)}
          onChange={(e) => this.handleBubbleSizeChange(e)}
          options={this.state.bubbleSize}
          class={"heatmap-configure configure-table-data-select"}
          type={DROPDOWN_TYPE.INPUT}
        />
      </div>
    )
  }

  showLandscapeConfigure = () => {
    let _this = this;
    _this.setState({
      showLandscapeConfigure: !_this.state.showLandscapeConfigure
    })
  }

  setCompareMode = (fromStop, fromWrapper) => {
    let _this = this;
    let tempState = {};
    if(_this.state.fullCompareSelection && !fromStop){
      _this.compareSegments();
    }
    if (fromStop) {
      tempState.compareMode = false;
      tempState.fullCompareSelection = false;
      _this.props.setfullCompareSelection(false);
      isActiveBubble = false;
      tempState.selectedBubble = [];
      
    } else {
      tempState.compareMode = true;
    }
    let ref = _this.expandingDefaultListRef.current ? _this.expandingDefaultListRef.current : _this.expandingSelectedListRef.current
    let profile = ref?.getDefaultManageColumnsRef()?.state?.selectedProfile ? ref.getDefaultManageColumnsRef().simplifyProfileReturnNames(ref.getDefaultManageColumnsRef().state.selectedProfile) : _this.state.manageColumnsProfile
    let listTitle = findOptionByKey(_this.props?.vectorState?.vectorObjects, _this.props?.vectorState?.vectors[0])?.label + " - " + (profile?.label || _LIST);
    
    tempState.manageColumnsProfile = profile;
    // tempState.listTitle =  listTitle;
    tempState.isListExpanded = false;
    tempState.listTitle = tempState.isListExpanded ? listTitle : _LIST;

    tempState.isListFullScreen = false;
    tempState.bubbleListExpanded = false;
    tempState.bubbleListFilter = "";
    let data = _this.state.chartData;
    data.map(row => {
      row.tooltip = row.title.includes(lang.N_A) ?lang.profit_landscape.tooltips.not_supported :fromStop ? lang.profit_landscape.tooltips.show_entities : lang.profit_landscape.tooltips.select;
      row.selected = "false";
    })
    tempState.chartData = data;
    _this.setState(tempState, () => {
      if (!fromWrapper) {
        _this.getListData();
      }
      _this.props.setCompareMode(false);
      _this.props.renderHeader();
      _this.renderChart();
      _this.expandingDefaultListRef.current.collapseClick();
      _this.props.setHideCompare(false)
    })
  }

  renderConfigureComponent = () => {
    let _this = this
    // if (_this.state.showLandscapeConfigure) {
      let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
      return (
        <div id='configure-dropdown'>
          <Configure id={"configure-landscape-bubble-container"}className={"configure-container configure_body"} fromLandscape={true} body={this.renderConfigureBody()} buttonText={lang.modal.buttons.apply} classNameButtonText={"button-configure-landscape pointer-event-auto"} onButtonClick={this.onApplyClick} 
          // style={{height: zoom > 124 ? "35vh" : zoom > 109 ? "32vh" : "16vw"}}
          />
        </div>
      )
    // } else {
    //   return (
    //     <>
    //     </>
    //   )
    // }
  }

  renderChart = () => { // move to seperate component
    let _this = this;
    let signX = _this.state.landscapeConfigObj.xAxis.format === lang.psl_tooltip.amount.toLowerCase() ? "("+getCurrency()+")" : ""
    let signY = _this.state.landscapeConfigObj.yAxis.format === lang.psl_tooltip.amount.toLowerCase() ? "("+getCurrency()+")" : ""
    var chart = am4core.create("bubble-chart-div", am4charts.XYChart);
    if (chart.logo) {
      chart.logo.disabled = true;
    }
    chart.responsive.enabled = true;
    chart.responsive.rules.push({
      relevant: function(target) {
        if (target.pixelWidth <= 1138) { //min-width 1200px
          return true;
        }
        
        return false;
      },
      state: (target, stateId) => {
        if (target instanceof am4core.Container) {
            const state = target.states.create(stateId);
            state.properties.scale = 1; // Shrink to 100% size of screen
            return state;
        }
        return null;
    }
    });
    chart.maskBullets = false;
    var valueAxisX = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxisX.title.text = "[bold]" + _this.state.landscapeConfigObj.xAxis.label + " " + signX + "[/]";
    valueAxisX.cursorTooltipEnabled = false;
    // valueAxisX.extraMin = 0.1;
    // valueAxisX.extraMax = 0.1;
    var valueAxisY = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxisY.paddingRight = "0.8vw";
    valueAxisY.skipRangeEvent = true
    // valueAxisY.extraMin = 0.1;
    // valueAxisY.extraMax = 0.1;

    valueAxisY.title.text = "[bold]" + _this.state.landscapeConfigObj.yAxis.label + " " + signY + "[/]"
    valueAxisY.cursorTooltipEnabled = false;

    var series = chart.series.push(new am4charts.LineSeries());
    // Adjust zIndex of the bubbles
    series.bulletsContainer.zIndex = 100; // make sure the bullets are above other elements
    series.bullets.template.zIndex = 10; 
    series.dataFields.valueX = "x";
    series.dataFields.valueY = "y";
    series.dataFields.value = "value";
    series.strokeOpacity = 0;
    series.sequencedInterpolation = true;
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.getFillFromObject = false;
    series.tooltip.autoTextColor = false;
    series.tooltip.background.fill = am4core.color("#ffff");
    series.tooltip.label.fill = am4core.color("black");

    var bullet = series.bullets.push(new am4core.Circle());
    bullet.propertyFields.fill = "color";
    bullet.strokeOpacity = 0;
    bullet.strokeWidth = 2;
    bullet.fillOpacity = 1;
    bullet.stroke = am4core.color("#0E5FFA");
    bullet.hiddenState.properties.opacity = 0;
    bullet.tooltipHTML = `
      <table>
      <tr>
        <th align="left">Segment:&nbsp</th>
        <td>{title}</td>
      </tr>
      <tr>
        <th align="left">{sizeLabel}:&nbsp</th>
        <td>{value}</td>
      </tr>
      <tr>
        <th align="left">{xLabel}:&nbsp</th>
        <td>{rowX}</td>
      </tr>
      <tr>
        <th align="left">{yLabel}:&nbsp</th>
        <td>{rowY}</td>
      </tr>
      </table>
      <hr class="hr-tooltip-bubble" />
      <div class="hr-tooltip-bubble uk-hidden">{selected}</div>
      <div class="tooltip-text-bottom">{tooltip}</div>`;

    var outline = chart.plotContainer.createChild(am4core.Circle);
    outline.fillOpacity = 0;
    outline.strokeOpacity = 0;
    outline.stroke = am4core.color("black");
    outline.strokeWidth = 0;
    outline.hide(0);

    var blurFilter = new am4core.BlurFilter();
    outline.filters.push(blurFilter);
    series.heatRules.push({ target: bullet, min: 10, max:50, property: "radius" });

    bullet.adapter.add("tooltipY", function (tooltipY, target) {
      return target.radius / 4;
    })


    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarY = new am4core.Scrollbar();
    bullet.events.on("hit", highlightColumn);
    bullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    function highlightColumn(ev) {
      let tempState = {};
      tempState.selectedBubble = _this.state.selectedBubble;
      if (ev.target.dataItem.dataContext.title !== lang.N_A) {
        ev.target.dataItem.dataContext.selected = _this.state.compareMode ? (!(ev.target.dataItem.dataContext.selected === "true")) + "" : !isActiveBubble + "";
        ev.target.dataItem.dataContext.tooltip = (ev.target.dataItem.dataContext.selected === "true") ? lang.profit_landscape.tooltips.unselect : 
          _this.state.compareMode ? lang.profit_landscape.tooltips.select : lang.profit_landscape.tooltips.show_entities;

        if (!_this.state.compareMode && !_this.state.fullCompareSelection) {
          isActiveBubble = !isActiveBubble

        }

        chart.series.values[0].bulletsContainer.children._values.forEach(function (bullet) {
          if (bullet instanceof am4core.Circle) {
            if (_this.state.compareMode) {
              if (bullet === ev.target) {
                bullet.compareSegments = bullet.compareSegments === undefined ? true : !bullet.compareSegments;
                bullet.group.node.style.strokeOpacity = bullet.compareSegments ? "1" : "0";
                if (bullet.compareSegments) {
                  tempState.selectedBubble.push(ev.target.dataItem.dataContext);
                }else {
                  tempState.selectedBubble = tempState.selectedBubble.filter(e=>e.title !== ev.target.dataItem.dataContext.title);
                }
              }
              bullet.group.node.style.strokeOpacity = bullet.compareSegments ? "1" : "0";
            } else {
              if (bullet !== ev.target) {
                // bullet.disabled = !bullet.disabled;
                bullet.group.node.style.strokeOpacity = "0";
                $(bullet.dom).toggleClass("disable-bubble");
              } else {
                bullet.group.node.style.strokeOpacity = isActiveBubble ? "1" : "0";
                if(isActiveBubble){

                  _this.setState({
                    bubbleListExpanded: true,
                    bubbleSegment: new Segment().getSegmentObject(ev.target.dataItem.dataContext.originalLabel.replace(":","_"))?.value,
                    manageColumnsProfile: _this.expandingDefaultListRef?.current?.getSelectedManageColumnsRef()?.simplifyProfileReturnNames(_this.expandingDefaultListRef.current.getSelectedManageColumnsRef().state.selectedProfile),
                  },()=>{
                    if(!_this.state.isListExpanded){
                      // _this.expandingSelectedListRef.current.expandClick();
                      _this.expandingDefaultListRef.current ? _this.expandingDefaultListRef.current.expandClick(false,true) : _this.expandingSelectedListRef.current.expandClick(false,true)
                    }
                    _this.setClickedBubble();
                  })
                }else if(!isActiveBubble){
                  let ref = _this.expandingDefaultListRef.current ? _this.expandingDefaultListRef.current : _this.expandingSelectedListRef.current
                  let profile = ref.getDefaultManageColumnsRef()?.state.selectedProfile ? ref.getDefaultManageColumnsRef().simplifyProfileReturnNames(ref.getDefaultManageColumnsRef().state.selectedProfile) : _this.state.manageColumnsProfile
                  // let profile = _this.expandingSelectedListRef.current.getDefaultManageColumnsRef().simplifyProfileReturnNames(_this.expandingSelectedListRef.current.getDefaultManageColumnsRef().state.selectedProfile);
                  let listTitle = findOptionByKey(_this.props?.vectorState?.vectorObjects, _this.props?.vectorState?.vectors[0])?.label + " - " + (profile?.label || _LIST);

                  _this.setState({
                    bubbleListFilter:"",
                    bubbleListExpanded:false,
                    manageColumnsProfile: profile,
                    listTitle: listTitle,
                    expandedListTitle: listTitle,
                    addModified: false
                  },()=>{
                    _this.getListData();
                  })
                } 

              }
            }
          }
        })
        $(".disable-bubble g").toggleClass("disable-bubble")
        if (_this.state.compareMode) {
          if (chart.series.values[0].bulletsContainer.children._values.filter(f => f.compareSegments).length > 1) {
            chart.series.values[0].bulletsContainer.children._values.filter(m => !m.compareSegments).forEach(bullet => {
              // bullet.disabled = true;
              $(bullet.dom).addClass("disable-bubble");
            });
            tempState.fullCompareSelection = true;
            _this.props.setfullCompareSelection(true)
          } else {
            chart.series.values[0].bulletsContainer.children._values.forEach(bullet => {
              // bullet.disabled = false;
              $(bullet.dom).removeClass("disable-bubble");
            });
            tempState.fullCompareSelection = false;
            _this.props.setfullCompareSelection(false);
          }
        }
        _this.setState(tempState);
      }else{
        ev.target.dataItem.dataContext.tooltip = lang.profit_landscape.tooltips.not_supported;
      }
    }
    if(isActiveBubble === false && !_this.state.compareMode && !_this.state.fullCompareSelection){
      for(let e in _this.state.chartData){
        _this.state.chartData[e].tooltip = lang.profit_landscape.tooltips.show_entities;
      }
    }
    chart.data = _this.state.chartData;
    // chart.data =[
    //   {
    //     "title": "IOP-1",
    //     "xLabel": "Net Sales",
    //     "yLabel": "Profit",
    //     "id": "AF",
    //     "color": "green",
    //     "x": 1349.69694,
    //     "y": 60.524,
    //     "value": 33395,
    //   }
    // ];
    chart.cursor = new am4charts.XYCursor();
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.startGrip.disabled = true;
    chart.scrollbarX.endGrip.disabled = true;

    chart.scrollbarY = new am4core.Scrollbar();
    chart.scrollbarY.startGrip.disabled = true;
    chart.scrollbarY.endGrip.disabled = true;

    chart.cursor.lineX.strokeWidth = 0;
    chart.cursor.lineX.strokeOpacity = 0;
    chart.cursor.lineX.strokeDasharray = "";
    chart.cursor.behavior = "zoomXY";

    let scrollbarX = new am4core.Scrollbar();
    scrollbarX.marginBottom = 20;
    chart.scrollbarX = scrollbarX;
    scrollbarX.interactionsEnabled = false;
    chart.scrollbarX.disabled = true;
    chart.scrollbarX.startGrip.disabled = true;
    chart.scrollbarX.endGrip.disabled = true;

    let scrollbarY = new am4core.Scrollbar();
    scrollbarY.marginBottom = 20;
    chart.scrollbarY = scrollbarY;
    scrollbarY.interactionsEnabled = false;
    chart.scrollbarY.disabled = true;
    chart.scrollbarY.startGrip.disabled = true;
    chart.scrollbarY.endGrip.disabled = true;

    chart.cursor.lineY.strokeWidth = 0;
    chart.cursor.lineY.strokeOpacity = 0;
    chart.cursor.lineY.strokeDasharray = "";

    // let hasBanner = _this.props.scenarioState.scenarioObjects && _this.props.scenarioState.scenarioObjects.scenario_status === SCENARIO_STATUS.REVIEW && _this.headerRef.state.showScenarioBanner;
    // let filter = typeof _this.props.filterFinalBasic === "string" ? JSON.parse(_this.props.filterFinalBasic) : _this.props.filterFinalBasic;
    // let filterFinal = typeof _this.props.filterFinal === "string" ? JSON.parse(_this.props.filterFinal) : _this.props.filterFinal;
    // if (hasBanner && (filter.length > 0 || filterFinal.length > 0)) {
    //   $(".bubble-chart-div").height("67vh");
    // } else if (hasBanner) {
    //   $(".bubble-chart-div").height("73vh");
    // } else if (filter.length > 0 || filterFinal.length > 0) {
    //   $(".bubble-chart-div").height("72vh");
    // } else {
    //   $(".bubble-chart-div").height("78vh");
    // }
  }

  runExtendedProfitStack = (selectedBubbles) => {
    let _this = this;
    if (this.state.FY === FY_VALUES.ON && this.checkIfMonthsValid(this.props.datasetState.dataset) === false) {
      var url = this.pageComponent.state.latestListParameters["url"];
      var urlParams = new URLSearchParams(url);
      this.resetFYOnInvalid(urlParams.get('quarter'), urlParams.get('fy'), urlParams.get('tier'));
      Popup.alert(MESSAGES.invalid_mo_selection);
      $(".chosenEntity").prop("checked", false);
      let profitStackSelectedEntities = [];
      return;
    }
    let isDrilling = false;
    var profitStackItems = [];
    const profitStackTier = this.props.vectorState.vector;
    const profitStackDataSet = this.props.datasetState.dataSet;
    const profitFormat = this.state.profitFormat;
    var drilledPath = [];
    var entities = selectedBubbles;
    for (let i = 0; i < entities.length; i++) {
      let entity = entities[i];
      var loopedItem = {};
      loopedItem.lines = entity.lines;
      loopedItem.segment = new Segment().getSegmentObject(entity.value)?.value;
      loopedItem.profittier = new Segment().getSegmentObject(entity.value)?.value;
      loopedItem.tier = getPSTier(profitStackTier, isDrilling);
      loopedItem.dataset = profitStackDataSet;
      loopedItem.profitFormat = profitFormat;
      loopedItem.color = COLORS_PALETTE[i]; // get the correspondant color based on entities order
      profitStackItems.push(loopedItem);
    }
    let selectedStackVisibility = this.props.PSViewsState.profitStackViews.find(e => e.value === this.state.profitStackViewId).visibility;
    let access = checkIfSectionIdExists(this.props.userAllowedMenuLinks,
      !selectedStackVisibility ? MENU_ITEM.FIELDS.MANAGE_STACKS // for default profit stack
        : selectedStackVisibility === COLUMN_PROFILE.VISIBILITY_OPTIONS.USER ? MENU_ITEM.FIELDS.MANAGE_STACKS : MENU_ITEM.FIELDS.VIEW_COMPANY_STACKS); // user or company stacks
    
    if (!access) {
      alert("No access");
      return;
    }

    if(this.pageComponent) {
        this.props.dispatch(updateLandscapeBubbleListData([this.fillLandscapeBubbleDefaultListBeforeBackObj()], "landscapeBubbleListData", false));
    }
    this.props.dispatch(updateLandscapeBubbleObjBeforeBack([this.fillLandscapeBubbleChartBeforeBackObj()], "landscapeBubbleObjBeforeBack", false));
    this.props.dispatch(updateLandscapeCostHierarchy([this.fillLandscapeBubbleConfigureBeforeBackObj()], "landscapeCostHierarchy", false));

    sessionStorage.setItem("selectedProfile_list", JSON.stringify(this.state.manageColumnsProfile)); // save the selected list for back navigation
    let startDate = getValidPeriods(_this.props.periodsStatusState, _this.props.clientPeriodsState).startDate;
    let endDate = getValidPeriods(_this.props.periodsStatusState, _this.props.clientPeriodsState).endDate;
    var pathName = MENU_ITEM_URLS.PROFIT_PATTERNS_ENTITY_STACKS;
    this.props.history.push({
      pathname: pathName,
      search: "?",
      hash: "",
      state: {
        origin: window.location.href,
        origin_name: this.props.profitFormat,
        profitFormat: this.props.profitFormat,
        profitStackItems: profitStackItems,
        drilledPath: drilledPath,
        drillFilter: this.state.drillFilter,
        drilling: isDrilling,
        useFilterCookies: false,
        savedFilterDataState: this.props.filterDialRef.state.savedFilterData,
        FY: this.state.FY,
        scenarioState: this.props.history.location.state.scenarioState,
        scenarios: this.props.scenarioState.scenarios,
        originalScenarios: this.props.scenarioState.scenarios,
        dataset: this.props.datasetState.dataSet,
        mainVector: this.props.vectorState.vectors[0], // main vector - from vector dropdown
        vectorOptions: this.props.vectorState.vectorOptions,
        filter: this.props.filterFinal,
        mainFilter: this.props.filterFinal,
        outsideFilter: JSON.stringify({ filter: copyObjectValues(tryParse(this.props.filterFinal)) }),
        mainFilterId: this.props.filterDialRef.state.savedFilterData.saved_filter_id,
        order: this.props.location.state ? this.props.location.state.order : undefined,
        startDate: startDate,
        endDate: endDate,
        profitStackViewId: this.props.PSViewsState.profitStackViewId,
        headerOptionChanged: false,
        datasetOptions: this.props.datasetState.datasetOptions,
        periods: this.props.clientPeriodsState.periods,
        isRedirectionFromBubble: true, // if it is a redirection, we won't remove the selected profile from session
        // cardsData: cardsData,
        bubbleReportTitle: lang.compare_stacks+" - " + this.props.vectorState.vectorOptions.find(e=>!e.isGroupTitle && e.value === _this.props.vectorState.vectors[0])?.label,
        drillRows: this.state.drillRows,
        // drillProfile: drillProfile,
        submitDrill: this.pageComponent?.state?.submitDrill,
        mainFilterFinalBasic: copyObjectValues(this.props.filterFinalBasic),
      }
    });
  }

   /**
   * fill object of Configure Dialog states and data that are needed when pressing on back from entity stacks
   * @returns 
   */
  fillLandscapeBubbleConfigureBeforeBackObj = () => {
    let _this = this;
    let landscapeBubbleObj = {};
    landscapeBubbleObj.landscapeConfigObj = _this.state.landscapeConfigObj;
    landscapeBubbleObj.bubbleSize = _this.state.bubbleSize;
    landscapeBubbleObj.bubbleSizeSelection = _this.state.bubbleSizeSelection;
    landscapeBubbleObj.confObjPSLines = {
      xAxisPSLines: confObjPSLines.xAxisPSLines,
      yAxisPSLines: confObjPSLines.yAxisPSLines,
    }
    landscapeBubbleObj.selectedConfObjPSLines = confObjPSLines;
    return landscapeBubbleObj;
  }

  /**
   * fill object of Chart states and data that are needed when pressing on back from entity stacks
   * @returns 
   */
  fillLandscapeBubbleChartBeforeBackObj = () => {
    let _this = this;
    let landscapeBubbleObj = {};
    landscapeBubbleObj.chartData = _this.state.chartData;
    landscapeBubbleObj.isListExpanded = _this.state.isListExpanded;
    landscapeBubbleObj.expandedListTitle = _this.state.expandedListTitle;
    landscapeBubbleObj.listTitle = _this.state.listTitle;
    landscapeBubbleObj.addModified = _this.state.addModified;
    landscapeBubbleObj.manageColumnsProfile = _this.state.manageColumnsProfile;
    landscapeBubbleObj.tableColumns = _this.state.tableColumns;
    landscapeBubbleObj.vectorObjects = _this.state.vectorObjects;
    landscapeBubbleObj.vectorOptions = _this.props.vectorState.vectorOptions;
    landscapeBubbleObj.filterFinal = _this.props.filterFinal;
    return landscapeBubbleObj;
  }

  /**
   * fill object of List states and data that are needed when pressing on back from entity stacks
   * @returns 
   */
  fillLandscapeBubbleDefaultListBeforeBackObj = () => {
    let _this = this;
    let listData = copyObjectValues(_this.pageComponent.state.dataFinal);
    listData.orderer = _this.pageComponent?.state?.sorter;
    listData.order = _this.pageComponent?.state?.order.toLowerCase();
    listData.columnsWidths = _this.pageComponent?.state.columnsWidths;
    return listData;
  }
  

  compareSegments = () => {
    let selectedBubbles = copyObjectValues(this.state.selectedBubble);
    selectedBubbles.map(e => {
      e.label = e.title;
      e.value = new Segment().getSegmentObject(e.originalLabel)?.originalLabel;
    });
    this.runExtendedProfitStack(selectedBubbles);
  }

  minimizeMaximizeList = () => {
    let _this = this;
    _this.setState({
      isListFullScreen:! _this.state.isListFullScreen,
    },()=>{
      _this.props.setHideCompare(_this.state.isListFullScreen);
    })

  }

  expandCollapseList = (forceRender) => {
    let _this = this;

    _this.setState({
      listTitle: !_this.state.isListExpanded ? _this.state.expandedListTitle : _LIST,
      isListExpanded: !_this.state.isListExpanded,
      isListFullScreen: false,
    },()=>{
      if (!forceRender && !_this.state.compareMode) {
        _this.renderChart()
      }
      _this.props.setHideCompare(false)
    })
  }

  onApplyManageColumnsProfileChanges(profile) {
    let _this = this;
    let ref = _this.expandingDefaultListRef.current ? _this.expandingDefaultListRef.current : _this.expandingSelectedListRef.current
    let listTitle = findOptionByKey(_this.props?.vectorState?.vectorObjects, _this.props?.vectorState?.vectors[0])?.label + " - " + (_this.state.manageColumnsProfile?.label || _LIST);

    _this.setState({
      listTitle: listTitle,
      expandedListTitle: listTitle,
      manageColumnsProfile: _this.expandingDefaultListRef.current ? ref.getDefaultManageColumnsRef().simplifyProfileReturnNames(ref.getDefaultManageColumnsRef().state.selectedProfile) : _this.expandingSelectedListRef.current ? ref.getSelectedManageColumnsRef().simplifyProfileReturnNames(ref.getSelectedManageColumnsRef().state.selectedProfile) : _this.state.manageColumnsProfile,
      addModified: _this.expandingDefaultListRef.current ? ref.getDefaultManageColumnsRef().simplifyProfileReturnNames(ref.getDefaultManageColumnsRef().state.selectedProfile).is_modified : _this.expandingSelectedListRef.current ? ref.getSelectedManageColumnsRef().simplifyProfileReturnNames(ref.getSelectedManageColumnsRef().state.selectedProfile).is_modified : _this.state.manageColumnsProfile.is_modified,
    }, () => {
      if (!_this.props.showGreyOverLay) {
        _this.getListData();
      }
    })

}

  setClickedBubble = () => {
    let _this = this;
      let bubbleFunction = MESSAGES.ui_filter.dropdowns.functions.equals.value;
      let logOperator = FILTER.VALUES.LOGICAL_OPERATOR.AND;
      let tierFieldName = SEGMENTS_TITLES.PROFIT_TIERS.value;
      var bubbleFilter = getNewEntityFilter(_this.props.vectorState.vectors[0], tierFieldName, _this.state.bubbleSegment, FILTER.VALUES.FILTER_ROW_TYPE.HEATMAP, logOperator, bubbleFunction);
      var arr = [bubbleFilter];
      _this.setState({
        bubbleFilter: arr,
        addModified: false
      }, () => {
        _this.setBubbleFilter(arr);
        _this.props.resetSelectedEntitiesOnExitDrill();
      })
}

  getListData = () =>{
    let _this = this;
    let generatedVectors = _this.props.vectorState?.vectorOptions?.filter(f=>f.generated).length
    if (generatedVectors > 0) {
      _this.pageComponent?.tabulatorList?.current?.tabulator?.setPageSize(100);
      _this.pageComponent.tabulatorList.current.tabulator.fromProfitInfo = true
    }
    _this.setState({
      callGo: false
    })
  }

  closeClick = (fromApply) =>{
    let _this = this;
    let ref = _this.expandingDefaultListRef.current ? _this.expandingDefaultListRef.current : _this.expandingSelectedListRef.current
    let profile = ref?.getDefaultManageColumnsRef()?.state.selectedProfile ? ref.getDefaultManageColumnsRef().simplifyProfileReturnNames(ref.getDefaultManageColumnsRef().state.selectedProfile) : _this.state.manageColumnsProfile;
    let listTitle = findOptionByKey(_this.props?.vectorState?.vectorObjects, _this.props?.vectorState?.vectors[0])?.label + " - " + (profile?.label || _LIST);
    _this.setState({
      bubbleListExpanded: false,
      bubbleListFilter:"",
      manageColumnsProfile: profile,
      expandedListTitle: listTitle,
      addModified: false
    },()=>{
      isActiveBubble = false;
      if (!fromApply) {
        _this.expandingDefaultListRef.current.expandClick();
      }
      if (!_this.props.showGreyOverLay) {
      _this.renderChart();
      _this.getListData();
      }
      this.props.resetSelectedEntitiesOnExitDrill();
    })
  }

  setBubbleFilter(filterString) {
    let _this = this
    let listTitle = findOptionByKey(_this.props?.vectorState?.vectorObjects, _this.props?.vectorState?.vectors[0])?.label + " - " + (_this.state.manageColumnsProfile?.label || _LIST);
    var filter = filterString ? filterString : [];
    filter = filter.filter(e=>!(e.function === "NEQ" && e.entities.length ===0));// if we removed all the entities and we still have the grouped entities toggle turned on
    var filterFinal = tryParse(copyObjectValues(_this.props.filterFinal));
    filter.forEach(e => filterFinal.push(e));
    _this.setState({
    bubbleListFilter : JSON.stringify(filterFinal),
    listTitle: listTitle,
    expandedListTitle: listTitle,
    },()=>{
      // $("#filter-display-chip").append(this.expandingListRef.current.renderFilterDisplay());
    // _this.expandingSelectedListRef.current?.getSelectedManageColumnsRef().init();
      _this.getListData();
    });

    //update the filter so that the new value is displayed over the list
    // if (_this.expandingListRef) {
    //   _this.expandingListRef.current.bubbleFilterDialRef().onChangeSavedFilter(filterFinal, false);
    //     // .onChangeSavedFilter(filterFinal, false);
    // }

}

onSelectDefaultProfile = (profile, callback) => {
  let _this = this;
  profile.is_modified = false;
  _this.updateManageColumnsProfile(profile, callback);
}

onChangeProfileData = (profile, callback) => { 
  let _this = this;
  _this.updateManageColumnsProfile(profile, callback);
  _this.setState({
    callGo: true
  })
}

updateManageColumnsProfile = (profile, callback) => {
  let _this = this;
    let manageColumnsProfile = copyObjectValues(profile);
    manageColumnsProfile.is_modified = profile.is_modified;
    manageColumnsProfile.is_applied = profile.is_applied;
    manageColumnsProfile.name = profile.name;
    manageColumnsProfile.label = profile.name;
    manageColumnsProfile.simplified_columns = profile.simplified_columns || profile.columns;
    let vector = findOptionByKey(_this.props?.vectorState?.vectorObjects, _this.props?.vectorState?.vectors[0])?.label;
    let profileLabel = _this.state.manageColumnsProfile
    let listTitle = vector + " - " + (profileLabel?.label || _LIST); 
    _this.setState({
      manageColumnsProfile:manageColumnsProfile,
      listTitle: _this.state.isListExpanded ? listTitle : _LIST,
      expandedListTitle: listTitle,
      addModified:manageColumnsProfile.is_modified
    },()=>{
      if (callback) callback();
    })
  _this.props.resetSelectedEntitiesOnExitDrill();
}

approveBulkChange = (key, element) => {
  if(key === PROFILE_COLUMN.IS_CHECKED) {
      let elementCosttype = element[PSS.DUPLICATE_KEYS.COSTTYPE];
      if(elementCosttype === costtype.calculated) {
          //if calculated, allow only if not percentage
          return ALL_FORMATS[element[PSS.FORMAT_TYPE_ID]] !== FormatTypes.PERCENTAGE;
      } else {
          return ![costtype.attribute, costtype.medians, costtype.ratios, costtype.totalratios, costtype.count].includes(elementCosttype);
      }
  }
}

  manageColumnsDefaultParams = () => {
    let _this = this;

    return {
      scenarioId: _this.props.scenarioState?.scenario,
      profitFormat: ALL_WIDGETS.FIELDS.LIST,
      approveBulkChange: _this.approveBulkChange,
      user: _this.props.userSettingsState?.user,
      onSelectProfile: _this.updateManageColumnsProfile,
      onSelectDefaultProfile: _this.onSelectDefaultProfile,
      onChangeProfileData: _this.onChangeProfileData,
      manageColsAccess: _this.props?.manageColsAccess,
      vectorObjects: _this.props.vectorState?.vectorObjects,
      vector: _this.props.vectorState?.vectors[0],
      sectionsProfilesAreValidFor: _this.manageColumnsProps?.manageColumnsUpdateSections,
      hideDropdown: false,
      vectorOptions: _this.props.vectorState?.vectorOptions,
      updateManageColumnsProfile: _this.updateManageColumnsProfile,
      dispatch: _this.props?.dispatch,
      profileColumns: _this.props?.profileColumns,
      customGroups: _this.props?.customGroups,
      profiles: _this.props?.profiles,
      stateProfiles: _this.props?.stateProfiles,
      columnProfiles: _this.props?.columnProfiles,
      manageColumnsSelectionLimit: _this.props.userSettingsState?.manageColumnsSelectionLimit,
      isDefaultProfileQT: true,
      characterSizeLimit: _this.props?.userSettingsState.characterSizeLimit,
      useAppearanceList: _this.props?.userSettingsState.useAppearanceList
    };
  }

  onSelectDefaultProfileSelected = (profile, callback) => {
    let _this = this;
    profile.is_modified = false;
    _this.updateManageColumnsProfileSelected(profile, callback);
  }
  
  onChangeProfileDataSelected = (profile, callback) => { 
    let _this = this;
    _this.updateManageColumnsProfileSelected(profile, callback);
    _this.setState({
      callGo: true
    })
  }
  
  updateManageColumnsProfileSelected = (profile, callback) => {
    let _this = this;
      let manageColumnsProfile = copyObjectValues(profile);
      manageColumnsProfile.is_modified = profile.is_modified;
      manageColumnsProfile.is_applied = profile.is_applied;
      manageColumnsProfile.name = profile.name;
      manageColumnsProfile.label = profile.name;
      manageColumnsProfile.simplified_columns = profile.simplified_columns || profile.columns;
      let vector = findOptionByKey(_this.props?.vectorState?.vectorObjects, _this.props?.vectorState?.vectors[0])?.label;
      let profileLabel = _this.state.manageColumnsProfile
      let listTitle = vector + " - " + (profileLabel?.label || _LIST); 
      _this.setState({
        manageColumnsProfile:manageColumnsProfile,
        listTitle: listTitle,
        expandedListTitle: listTitle,
      },()=>{
        if (callback) callback();
      })
  }

  approveBulkChangeSelected = (key, element) => {
    if(key === PROFILE_COLUMN.IS_CHECKED) {
        let elementCosttype = element[PSS.DUPLICATE_KEYS.COSTTYPE];
        if(elementCosttype === costtype.calculated) {
            //if calculated, allow only if not percentage
            return ALL_FORMATS[element[PSS.FORMAT_TYPE_ID]] !== FormatTypes.PERCENTAGE;
        } else {
            return ![costtype.attribute, costtype.medians, costtype.ratios, costtype.totalratios, costtype.count].includes(elementCosttype);
        }
    }
  }

  manageColumnsSelectedParams = () => {
    let _this = this;

    return {
      scenarioId: _this.props.scenarioState?.scenario,
      profitFormat: ALL_WIDGETS.FIELDS.LIST,
      approveBulkChange: _this.approveBulkChangeSelected,
      user: _this.props.userSettingsState?.user,
      onSelectProfile: _this.updateManageColumnsProfileSelected,
      onSelectDefaultProfile: _this.onSelectDefaultProfileSelected,
      onChangeProfileData: _this.onChangeProfileDataSelected,
      manageColsAccess: _this.props?.manageColsAccess,
      vectorObjects: _this.props.vectorState?.vectorObjects,
      vector: _this.props.vectorState?.vectors[0],
      sectionsProfilesAreValidFor: _this.manageColumnsPropsBubbleList?.manageColumnsUpdateSections,
      hideDropdown: false,
      vectorOptions: _this.props.vectorState?.vectorOptions,
      updateManageColumnsProfile: _this.updateManageColumnsProfileSelected,
      dispatch: _this.props?.dispatch,
      profileColumns: _this.props?.profileColumns,
      customGroups: _this.props?.customGroups,
      profiles: _this.props?.profiles,
      stateProfiles: _this.props?.stateProfiles,
      columnProfiles: _this.props?.columnProfiles,
      manageColumnsSelectionLimit: _this.props?.userSettingsState?.manageColumnsSelectionLimit,
      isDefaultProfileQT: false ,
      characterSizeLimit: _this.props?.userSettingsState.characterSizeLimit,
      useAppearanceList: _this.props?.userSettingsState.useAppearanceList,
    };
  }

  filterParams = () => {
    let _this = this;

    return {
      section_id:_this.props.match.params.sectionId,
      tier:_this.props.vectorState.vectors[0],
      vectorOptions:_this.props.vectorState.vectorOptions,
      // filter: _this.props.location.state ? _this.props.location.state.filter : _this.state.bubbleListFilter,
      filter: _this.state.bubbleListFilter ? _this.state.bubbleListFilter : tryParse(copyObjectValues(_this.props.filterFinal)),
      REPORT_FORMAT_LIST_TOP:ALL_REPORTS.LIST_TOP,
      REPORT_FORMAT_LIST_QUADRANT_TIER:ALL_REPORTS.LIST_QUADRANT_TIER,
      psFilterDisabled:_this.state.psFilterDisabled,
      quadrantsDisabled:_this.state.quadrantsDisabled,
      // requestFormatChange:_this.changeFormat
      // onChangeFilter:_this.setClickedBubble,
      refresh:_this.refreshFilter,
      useCookies:false,
      compNumber:1 , //changes the id of the filter comp because in heatmap we have two filters
      filterLastUpdate:_this.state.dimensionFilterLastUpdate,
      filterDisplayEditable:_this.state.filterDisplayEditable,
      quarterOptions:_this.state.optionsListDataSet,
      profitFormat:_this.state.profitFormat,
      fromOptions:_this.state.optionsListDataSet,
      dataSet:_this.props.datasetState.dataSet,
      checkIfMonthsValid:_this.checkIfMonthsValid,
      user:_this.props.userSettingsState.user,
      psLinesOptions: _this.props.psLinesOptions,
    };
  }

  getExportFilter = () => {
    let _this = this;
    let bubbleListFilter = _this.state.bubbleListFilter? typeof _this.state.bubbleListFilter === "string" ? (_this.state.bubbleListFilter === "" ? [] : JSON.parse(_this.state.bubbleListFilter)) : _this.state.bubbleListFilter:"";
    let exportQueryFilter = _this.props?.getExportQueryFilter(bubbleListFilter);
    let filter = typeof _this.props.filterFinal === "string" ? (_this.props.filterFinal === "" ? [] : JSON.parse(_this.props.filterFinal)) : _this.props.filterFinal;
		if((!exportQueryFilter || exportQueryFilter === "None") && filter?.length > 0) {
			exportQueryFilter = filter.find(f => !f.isBasicFilter) ? formatAdvancedFilter(filter, _this.props.userSettingsState.user.user_allowed_vectors, _this.props.datasetState.datasetOptions, _this.props.vectorState.vectorOptions, _this.props.filterDialRef?.state?.psLinesOptions) : formatBasicFilter(filter, _this.props.userSettingsState.user.user_allowed_vectors);
		}

    return exportQueryFilter;
  }
  setExportOptionsForUser = (hideExportOnList) => {
    let _this = this;
    let exportOptions = hideExportOnList ? [] : [{
      value: "export_excel",
      label: lang.header.titles.export_to_excel,
      tooltip: lang.header.tooltips.export_all_excel,
      description:lang.header.titles.export_selected_visible_rows,
      click: () => {_this.props.tablesToExcel(); _this.setExportAll(false, true);}
    }];
    var hasExportCSVAccess = getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.EXPORT_DATA_TO_CSV);  ;
    if (hasExportCSVAccess) {
      exportOptions.push({
        value: ALL_WIDGETS.EXPORT_ALL,
        label: lang.header.titles.export_to_csv,
        tooltip: lang.header.tooltips.csv,
        description:lang.header.titles.export_selected_rows_csv,
        click: () => {_this.setExportAll(false)}
      });
      exportOptions.push({
        value: ALL_WIDGETS.EXPORT_CSV_PSL,
        label: lang.header.titles.csv_all_psl,
        tooltip: lang.header.tooltips.csv_all,
        description:lang.header.titles.export_all_csv,
        click: () => {_this.setExportAll(true)}
      });
    }
    _this.setState({
      exportOptions: exportOptions
    })}

  saveChosenEntities = (clearFlag, cell, isChecked) => {
    let _this = this;
    if (clearFlag) {
      profitStackSelectedEntities = [];
      return;
    }

    let entities = profitStackSelectedEntities;
    let currEntity = cell.getRow().getData();
    let ents = copyObjectValues(entities);
    let matchingEntity = returnObjectsInArray(ents, currEntity)
    if (!!matchingEntity) {
      entities.splice(matchingEntity.index, 1);
    }

    if (isChecked) {
      delete currEntity.checked;
      entities.push(currEntity);
    }

    profitStackSelectedEntities = entities;
    _this.props.saveChosenEntities(entities);
    _this?.pageComponent?.tabulatorList?.current?.addFooterText(_this?.pageComponent?.tabulatorList?.current?.tabulator?.getData(), undefined, entities);
    _this?.pageComponent?.tabulatorDrill?.current?.addFooterText(_this?.pageComponent?.tabulatorDrill?.current?.tabulator?.getData(), undefined, entities);
  }

  refreshDataLenght = (length) => {
    let _this = this;
    if (_this.confirmPassRef) {
      _this.confirmPassRef.setState({
        dataLength: length,
      });
    }
  };


  renderListComponent = () => {
    let _this = this; 
    let periodsObject = _this.getPeriodsObject();
    let rollingPeriod = periodsObject?.segmentPeriod;

    return (
          <ProfitMapList ref={r => _this.pageComponent = r} key={"bubble-default-list"}
            headerRef={_this.headerRef}
            manageColumnsRef={_this.state.bubbleListExpanded ? _this.expandingSelectedListRef.current?.getSelectedManageColumnsRef() : _this.expandingDefaultListRef.current?.getDefaultManageColumnsRef()}
            filter={_this.state.bubbleListExpanded? _this.state.bubbleListFilter :copyObjectValues(_this.props.filterFinal)}
            refresh={true}
            user={_this.props.userSettingsState.user}
            sectionId={_this.props.match.params.sectionId}
            options={_this.props.vectorState.vectorOptions}
            isQuadrant={[ALL_REPORTS.LIST_QUADRANT, ALL_REPORTS.LIST_QUADRANT_TIER].indexOf(_this.initialState.profitFormat) > -1 ? true : false}
            dataset={periodsObject.quarter}
            FY={_this.state.FY}
            dataTier={_this.props.vectorState.vectors[0]}
            order={_this.state.order}
            limit={getLimit}
            idToken={_this.state.idToken}
            report={ALL_REPORTS.LIST_TOP}
            dbDataSet={_this.state.dbDataSet}
            tablePrefix={_this.state.tablePrefix}
            project_id={_this.state.project_id}
            machine_name={_this.props.userSettingsState.machine_name}
            manageColumnsProfile={_this.state.manageColumnsProfile}
            fetchDataOnMount={false}
            scenarioNumber={_this.props.scenarioState.scenarioNumber}
            refreshDataLenght={_this.refreshDataLenght}
            toggleLoader={toggleLoader}
            setIsDrilling={this.props.setIsDrilling}
            isDrilling={this.props.isDrilling}
            profitFormat={ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.List}
            psFilterDisabled={_this.state.psFilterDisabled}
            scenario_id={_this.props.scenarioState.scenario}
            setDataLength={_this.props.setDataLength}
            checkIfMonthsValid={_this.checkIfMonthsValid}
            setLimit={_this.setLimit}
            getExportQueryFilter={this.getExportFilter()}
            checkForLimitAccessMessage={_this.props.checkForLimitAccessMessage}
            updateStateFromParent={_this.updateStateFromParent}
            records_limit={_this.state.records_limit}
            manageColsAccess={_this.props?.manageColsAccess}
            vectorObjects={_this.props.vectorState.vectorObjects}
            updateManageColumnsProfile={_this.state.bubbleListExpanded ? _this.updateManageColumnsProfileSelected :_this.updateManageColumnsProfile}
            selectedPeriods={periodsObject.periods}
            months={periodsObject.months}
            manageColumnsSelectionLimit={this.props.userSettingsState.manageColumnsSelectionLimit}
            dispatch={_this.props.dispatch}
            dataChunkLimit={this.props.userSettingsState?.dataChunkLimit}
            isDefaultProfileQT={_this.state.bubbleListExpanded ? false : true}
            isLandscapeBubble={true}
            isLandscapeBubbleDefault={_this.state.bubbleListExpanded ? false :true}
            landscapeBubbleListData={this.props.landscapeBubbleListData}
            reportExcelName = {MENU_ITEM.FIELDS.PROFIT_LANDSCAPE}
            rollingPeriod={rollingPeriod}
            parentComponent = {MENU_ITEM.FIELDS.PROFIT_LANDSCAPE}
            scenarioObject={_this.props.scenarioState.scenarioObjects[0]}
            setExportOptionsForUser={this.setExportOptionsForUser}
            fromExpanding
            profitStackSelectedEntities={profitStackSelectedEntities}
            saveChosenEntities={this.saveChosenEntities}
            saveDrillFilter={this.props.saveDrillFilter}
            drillFilter={this.props.drillFilter}
            viewOptions={this.state.viewOptions}
            manageColumnsDrillRef={this.props.manageColumnsDrillRef.current}
            exitDrill={this.props.exitDrill}
            hasDrillOption={true}
            updateColumnsOrder={_this.state.bubbleListExpanded ? _this.expandingSelectedListRef.current?.updateColumnsOrder :_this.expandingDefaultListRef.current?.updateColumnsOrder}
            useAppearanceList={this.props.userSettingsState.useAppearanceList}
            setSelectedEntities={this.props.setSelectedEntities}
            exportScopeFilter={this.props.exportScopeFilter}
          />
    )
  }

  setExportAll=(value, isExportExcel)=> {
    let _this = this;
    _this.setState({
        export_all: value
    },function(){
      if(!isExportExcel){
        _this.exportAll();
      }
    })
  }


  logExportData=(activityStatus)=> {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const path = API_URL.PROFIT_LANDSCAPE_EXPORT_ALL;
    var filter = this.props.filterFinal;

    var myUrl = baseUrl + path + "?";
    var query = "action=logExportData"
        // + "&ip_address=" +ipAddress
        + "&tier=" + this.props.vectorState.vectors[0]
        + "&quarter=" + this.props.datasetState.dataset
        + "&filter={'filter':" + encodeURIComponent(filter) + "}"
        + "&activity_status=" + activityStatus;
    setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
    $.ajax({
        url: myUrl + query,
        async: true,
        crossDomain: true,
        xhrFields: {withCredentials: true},
        dataType: 'json'
    });
  }

  startExport=()=>{
      this.setState({
          userAuthenticated_exportAll: true,
      })
      this.confirmPassRef.setState({
          userAuthenticated_exportAll: true,
      })
  }


  reauthenticateWithCredential=()=> {
    var email = this.props.userSettingsState.user.email;
    var password = $('#passwordConfirm').val();
    var obj = this;

    if(obj.props.userSettingsState.isClientSSO && !parseBoolean(obj.props.userSettingsState.user.is_system)){
        obj.startExport();
    }else{
    // Sign in with the newly linked credential
    signInWithEmailAndPassword(auth, email, password)
        .then(function () {
            obj.startExport();
        }).catch(function (error) {
            if (error.code === AuthErrorCodes.MFA_REQUIRED) {
                obj.startExport();
                return;
            }
            obj.logExportData(USER_ACTIVITY_USER_NOT_AUTHENTICATED);
            Popup.alert("The password you entered is incorrect.");
            return false;
        });
    }
  }
  /* #endregion */

  cancel(){
      this.confirmPassRef.setState({userAuthenticated_exportAll:false})
  }

  launchExportToast=()=>{
    $("#toastExportBubble").addClass("show");
    setTimeout(function(){
        $("#toastExportBubble").removeClass("show");
    }, 4000);
  }

  showError = () =>{
    let _this = this;
    _this.setState({
      error: lang.header.custom_text.error,
      toastMessage: lang.header.custom_text.error,
      isExporting: false
    },()=>{
        _this.launchExportToast();
    })
  }
  /* #region export all */
  exportAll=()=> {
    let _this = this;
    let useOutputService = _this.props?.userSettingsState.useOutputService;
    window._pi_initialiseTracking(UI_ACTIONS.EXPORT_ALL, this.props.userSettingsState.user.email, this.props.userSettingsState.machine_name, this.initialState.profitFormat, false);
    this.confirmPassRef.setState({
        userAuthenticated_exportAll: false,
    })
    this.setState({
        userAuthenticated_exportAll: false,
        toastMessage:lang.header.custom_text.download_start,
        error:undefined
    }, function () {
      try { //export
        _this.launchExportToast();
        let query = $("#export-all-form").serializeArray().reduce(function(obj, item) {
            obj[item.name] = item.value;
            return obj;
        });
        query.numericFormatName = window._format?.numeric_format_name
        let onThenCallback = function (blob) {
          if (blob?.size === 0) {
            showError();
            return;
          } 
          
          if(useOutputService) { // this means we are using output-service export logic
              $("#frame_export").attr('src', blob.signedUrl);
          } else {
              triggerExportData(blob, query, _this.props.userSettingsState);
          }
          _this.setState({
            isExporting: false
          })
        }

        let onErrorCallback = function () {
          _this.showError();
        }

        let options = {
            [FETCHAPI_PARAMS.funcName]: "exportAll",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.path]: API_URL.PROFIT_LANDSCAPE_EXPORT_ALL,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.onErrorCallback]: onErrorCallback,
            [FETCHAPI_PARAMS.useBlob]: !useOutputService,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.landscape.landscape_list.screen_name,
            [FETCHAPI_PARAMS.requestDescription]: (this.props.isDrilling? lang.observability.output.landscape.landscape_list.requests_description.export_drill : lang.observability.output.landscape.landscape_list.requests_description.export) + (this.state.export_all? lang.observability.output.list.requests_description.with_all_psls:""),
            [FETCHAPI_PARAMS.vector]: query.tier,
            [FETCHAPI_PARAMS.scopeFilterApplicable]: true,
            [FETCHAPI_PARAMS.periods]: "'"+query.selectedPeriods+"'",
        }
        fetchAPI(options);
      } catch (err) {
        _this.showError();
      }
      //removed because there is nothing else to do for this action, tracking continued on the API
      window._pi_removeTrackingData();
    });
  }

  onDrillOptionClick = (option, type) => {
    let _this = this;
    let cardsDataLength = this.pageComponent?.state?.cardsData?.length;
    this.props.profitStackSelectedEntities.current = [];
    // profitStackSelectedEntities = [];
    if (cardsDataLength > 0) {
    this.props.setSelectedEntities(0);
      _this.pageComponent.tabulatorDrill.current.drill(option.value, type);
    } else {
    this.props.setSelectedEntities(0);
      let defaultViewOptions = _this.expandingDefaultListRef.current?.getDefaultManageColumnsRef().state.viewOptions;
      let selectedViewOptions = _this.expandingSelectedListRef.current?.getSelectedManageColumnsRef().state.viewOptions;
      _this.setState({
        viewOptions: defaultViewOptions || selectedViewOptions,
      }, () => {
        _this.pageComponent.tabulatorList.current.drill(option.value, type);
      })
    }
  }

  render() {
    let _this = this
    let sectionExists = getSectionExists(_this.state.userAllowedMenuLinks, MENU_ITEM.FIELDS.MANAGE_STACKS);
    let oldSectionExists = getSectionExists(_this.state.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_STACK);
    sectionExists = sectionExists || oldSectionExists;
    let trackingData = window._pi_getTrackingDataObj();
    trackingData = trackingData === null ? "" : JSON.stringify(trackingData);

    let rowData = this.pageComponent?.tabulatorList?.current?.state?.cell?.getRow().getData() || this.pageComponent?.tabulatorDrill?.current?.state?.cell?.getRow().getData();
    let selectedVectorWithNoKeyHasNA = rowData && (!rowData[PSL_RETURN_NAMES.NAME] && !rowData[PSL_RETURN_NAMES.NUMBER] && (rowData[PSL_RETURN_NAMES.QUADRANT] === "N/A" || rowData[PSL_RETURN_NAMES.QUADRANT_TIER] === "N/A"));
    let atLeastOneVectorOtherThanSelectedHasNA = rowData && atLeastOneVectorHasNAInData(rowData, this.props.vectorState?.vectorOptions);
    let cardsDataLength = this.pageComponent?.state?.cardsData?.length;

    let drillLimitExceeded = cardsDataLength && (cardsDataLength >= Number(this.props.userSettingsState?.drillSelectionLimit));
    let isDrillDisabled = atLeastOneVectorOtherThanSelectedHasNA || selectedVectorWithNoKeyHasNA || drillLimitExceeded;
    let drillTooltip = isDrillDisabled ? drillLimitExceeded ? lang.drill_limit_exceeded : lang.COMMON.drill_not_supported_list_q : "";

    return (
      <div style={{height:"100%"}}>
        <Popup />
        <iframe name="frame" id="frame_export" style={{display:'none'}}></iframe> 
        <div id="toastExportBubble" className={!!this.state.error ? "toast toast-fail" :"toast toast-success"}>
            <div id="desc"><i className={"fa-lg fas uk-margin-small-right " + (!!this.state.error ? "fa-minus-circle uk-text-primary" : "fa-check-circle greenText")} aria-hidden="true"></i><span>{this.state.toastMessage}</span></div>
        </div>
        {/* <SessionTimeout ref={r => this.sessionTimeoutRef = r} isAuthenticated={this.state.user && this.headerRef && this.headerRef.props && this.headerRef.props.machine_name !== ""} logout={this.logout} session_timeout_ui={this.state.session_timeout_ui} session_timeout_api={this.state.session_timeout_api} /> */}
        {/* <div id="hm-third-header" className={"uk-flex justify-content-end mapping-header-stacks uk-padding-large-right uk-margin-xxsmall-bottom"}> */}
          {/* <div className='landscape-compare-btn'> */}
            {/* {sectionExists ?
              // <div id="landscape-compare-btn" className={"uk-margin-left compare-btn" + (_this.state.compareMode && _this.state.fullCompareSelection ? " full-selection-compare" : _this.state.compareMode ||  (this.headerRef.state.headerGoEnabled && !this.headerRef.state.goButtonDisabled) ? " disabled" : "")} onClick={() => _this.setCompareMode()}>
              //   <span className={'heatmap-configure-btn' + (_this.state.fullCompareSelection ? " compare-text" : "")}><i className="heatmap-configure-btn fas fa-retweet uk-margin-small-right"></i>Compare Segments</span>
              // </div>
              <Button 
              id="landscape-compare-btn" 
              variant={_this.state.fullCompareSelection ? BUTTON_VARIANT.PRIMARY : BUTTON_VARIANT.SECONDARY} 
              type={BUTTON_TYPE.DEFAULT} 
              size={SIZES.DEFAULT}
              disabled={((_this.state.compareMode && !_this.state.fullCompareSelection) || (this.headerRef.state.headerGoEnabled && !this.headerRef.state.goButtonDisabled))}
              label="Compare Segments"
              leftIcon={<i className="heatmap-configure-btn fas fa-retweet"></i>} 
              className={"uk-margin-left"}
              onBtnClick={() => _this.setCompareMode()} 
              />
              : ""
            } */}
            {/* {_this.state.compareMode ?
              <div className=''>
                <span className='select-segments-text compare-text-italic'>{_this.state.fullCompareSelection ? "" : lang.profit_landscape.select_segments} <span className='stop-compare-text' onClick={() => _this.setCompareMode(true)}>Stop Comparison</span></span>
              </div>
              : ""
            } */}
          {/* </div> */}
          {/* <ButtonDropdown
            id="landscape-configure-btn"
            ref={this.configDropdown}
            placeholder={lang.heatmap_configure.configure} 
            className="heatmap-configure-btn" 
            // onBtnClick={_this.showLandscapeConfigure}
            dropId={"configure-dropdown"}
            variant={BUTTON_DROPDOWN_VARIANT.BUTTON}
            size={SIZES.SMALL}
            renderConfigureBody={this.renderConfigureComponent}
            firstAttr={"#button-drop-landscape-configure-btn"}
            secondAttr={"#configure-landscape-bubble-container"}
          /> */}
        {/* </div> */}
        {/* {this.renderConfigureComponent()} */}
        <div id="bubble-chart-div" style={{height: "93%"}} className="bubble-chart-div"></div>
        <ExpandingList
            ref={_this.state.bubbleListExpanded ? this.expandingSelectedListRef : this.expandingDefaultListRef}
            screenId={"landscape-bubble"}
            mainComponentId="bubble-chart-div"
            tabulatorId="table-list"
            title={this.state.listTitle}
            isClosable={_this.state.bubbleListExpanded}
            isAdjustable={true}
            isExpanded={this.state.isListExpanded}
            isFullScreen={this.state.isListFullScreen}
            expandCollapseClick={this.expandCollapseList}
            maximizeMinimizeClick={this.minimizeMaximizeList}
            addModified={this.state.addModified}
            hasExport={true}
            exportParams={{
              options: _this.state.exportOptions,
              tablesToExcel: _this.props.tablesToExcel,
              records_limit: _this.props.userSettingsState.recordsLimit,
              setExportAll: _this.setExportAll
            }}
            hasManageColumns={_this.props?.manageColsAccess && _this.props.manageColsAccess[ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS]}
            manageColumnsParams={this.manageColumnsDefaultParams()}
            manageColumnsParamsSelected={this.manageColumnsSelectedParams()}
            bodyComponent={this.renderListComponent}
            closeClick={this.closeClick}
            filterProps={_this.filterParams()}
            savedFilterData={_this.state.savedFilterDataState}
            defaultList={_this.state.bubbleListExpanded ? undefined : true}
            isActiveBubble={isActiveBubble}
            showManagecolumnsTwo={true}
            selectedEntities={this.props.profitStackSelectedEntities.current}
            vectorState={_this.props.vectorState}
            isDrillDisabled={isDrillDisabled}
            drillTooltip={drillTooltip}
            onDrillOptionClick={this.onDrillOptionClick}
            isDrilling={this.props.isDrilling}
            scenarioState={this.props.scenarioState}
            pageComponent={this.pageComponent}
            userSettingsState={this.props.userSettingsState}
            dispatch={this.props.dispatch}
            manageColsAccess={this.props?.manageColsAccess}
            checkForLimitAccessMessage={this.props.checkForLimitAccessMessage}
            history={this.props.history}
            updateManageColumnsProfile={this.updateManageColumnsProfile}
            manageColumnsDrillRef={this.props.manageColumnsDrillRef}
            setColumnWidthState={this.pageComponent?.setColumnWidthState}
            setDrillListWidthState={this.pageComponent?.setDrillListWidthState}
            useNewReorderList={this.props.useNewReorderList}
            useNewAddColumn={this.props.useNewAddColumn}
        />
        <ConfirmPasswordModal
            ref={r => this.confirmPassRef = r}
            user={this.props.userSettingsState.user}
            drillFilter={this.props.drillFilter}
            isDrilling = {this.props.isDrilling}
            manageColumnsProfile={this.props.isDrilling? (this.props.manageColumnsDrillRef?.current?.state.appliedProfile || this.props.manageColumnsDrillRef?.current?.state.selectedProfile): this.state.manageColumnsProfile}
            trackingData={trackingData}
            exportRef={_this.state.bubbleListExpanded ? this.expandingSelectedListRef.current?.getSelectedExportRef() : this.expandingDefaultListRef.current?.getDefaultExportRef()}
            pssLines={_this.state.bubbleListExpanded ? this.expandingSelectedListRef.current?.getSelectedManageColumnsRef()?.state?.profitStackLineColumns : this.expandingDefaultListRef.current?.getDefaultManageColumnsRef()?.state?.profitStackLineColumns}
            export_all={_this.state.bubbleListExpanded ? (this.expandingSelectedListRef !== null ? this.state.export_all : false) : (this.expandingDefaultListRef !== null ? this.state.export_all : false)}
            dataLength={this.props.dataLength || ""} //userAuthenticated_exportAll={this.state.userAuthenticated_exportAll}
            pageComponent={this.pageComponent} getPeriodsObject={this.getPeriodsObject}
            isTempScenario={this.isTempScenario} match={this.props.match}
            vectors={this.props.vectorState.vectors || []}
            vectorOptions={this.props.vectorState.vectorOptions}
            profitFormat={this.initialState.profitFormat}
            scenarios={this.props.scenarioState.scenarios || []}
            filterDialRef={this.props.filterDialRef} cancel={this.cancel}
            reauthenticateWithCredential={this.reauthenticateWithCredential}
            exportAll={this.exportAll}
            filterFinal={this.state.bubbleListFilter || this.props.filterFinal}
            exportQueryFilter={this.getExportFilter()}
            isClientSSO={this.props.userSettingsState.isClientSSO}
            rollingSegment={ROLLING_SEGMENTS.R_12}
            exportScopeFilter={this.props.exportScopeFilter}
            />
      </div>
    );
  }
}

  function mapStateToProps(state) {
    return {
       
        profitStackByCostHierarchy: state.profitStackByCostHierarchy,
        profitFormats: state.profitFormats,
       
        landscapeBubbleObjBeforeBack: state.landscapeBubbleObjBeforeBack,
        landscapeBubbleListData: state.landscapeBubbleListData,
        landscapeCostHierarchy: state.landscapeCostHierarchy,
    };
  }
export default connect(mapStateToProps,null,null,{forwardRef: true})(LandscapeBubble);
