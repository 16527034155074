import 'jquery';
import 'jqueryui';
import React, { useEffect, useRef } from 'react';
import {useState} from 'react';
import { useHistory } from "react-router-dom";
import SelectClientDropDown from '../SelectClientDropDown';
import '../styles/common.css';
import '../styles/login.css';
import '../styles/popup.css';
import './Profile.css';
import '../templateLayout/styles/navigationHeader.css'
import {
    APPLY_SCOPE,
    BUTTON_TYPE,
    BUTTON_VARIANT,
    CLIENTS,
    IS_BUILD_RUNNING,
    LISTENER,
    SCOPE_FILTERS,
    SCOPE_OPTIONS,
    SELECTED_SCOPE,
    SIZES
} from '../class/constants';
import {lang} from '../language/messages_en';
import {extractInitials, readCookie} from '../class/jqueries';
import {capitalizeFirstLetter, parseBoolean, tryParse} from '../class/utils';
import {Box, ClickAwayListener} from '@mui/material';
import {logout, saveScopeFilter} from '../templateLayout/api/api';
import Button from '../newComponents/Button';
import {ReactComponent as Logout} from "../styles/images/icons/logout.svg";
import {ReactComponent as Arrow} from "../styles/images/icons/small-thick-arrow.svg";
import {emitEvent, useListener} from '../actions/CustomEventHandler';
import {getLocalStorageValueByParameter, setLocalStorageValueByParameter} from '../class/common';
import {ToggleTab} from "./elements";
import FilterDialog from '../sections/filter/FilterDialog';


const areEqual = (prevProps, nextProps) => {
    if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
        return true
    }
    return false;
}

function ScopeProfile(props) {

    useListener(LISTENER.PORFILE.LOGOUT_END, () => {
        logout();
    });

    const filterDialRef = useRef();
    const [selectedTab, setSelectedTab] = useState(parseBoolean(sessionStorage.getItem(SELECTED_SCOPE)));

    let displayName = props.user && props.user.first_name ? capitalizeFirstLetter(props.user.first_name) + " " + capitalizeFirstLetter(props.user.last_name) : props.user && props.user.displayName ? props.user.displayName : "";
    let initials = displayName !== "" ? extractInitials(displayName) : "";

    let clients = getLocalStorageValueByParameter(CLIENTS) ? JSON.parse(getLocalStorageValueByParameter(CLIENTS)) : [];
    let isBuildRunning = parseBoolean(sessionStorage.getItem(IS_BUILD_RUNNING));

    const [open, setOpen] = useState(false);
    let appliedScopeFilter = tryParse(sessionStorage.getItem(SCOPE_FILTERS));
    let options = SCOPE_OPTIONS;
    let defaultScope = selectedTab || (parseBoolean(sessionStorage.getItem(APPLY_SCOPE)) ? SCOPE_OPTIONS[0].value : SCOPE_OPTIONS[1].value);
    let scopeTitle = SCOPE_OPTIONS.filter( f => f.value === defaultScope)[0];
    if (!appliedScopeFilter?.length) {
        options = [options.find(e => e.value === SCOPE_OPTIONS[1].value)];// total
        defaultScope = [];
        scopeTitle = SCOPE_OPTIONS[1];
    }

    const history = useHistory();

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    useEffect(()=>{
        if(open){
            filterDialRef?.current?.onChangeSavedFilter(
                JSON.stringify(appliedScopeFilter),
                true
              );
        }
    },[open]);

    const handleSelectTab = (tab) => {
        setSelectedTab(tab);
        sessionStorage.setItem(SELECTED_SCOPE, tab);
        sessionStorage.setItem(APPLY_SCOPE, tab === SCOPE_OPTIONS[0].value);
        handleClickAway();
        
        let state = history.location.state;
        if(state?.isListDrilling || state?.isRedirectionFromStacks || state?.isRedirectionFromBubble || state?.isRedirectedFromBridge || state?.isBridgeReport) {
            goBackToPreviousReport()
        } else {
            emitEvent(LISTENER.APPLY);
        }
    }

    const goBackToPreviousReport = () => {
        let state = history.location.state;
        state.isScopeFilterChanged = true;
        history.push({
            state: state,
        });
    };

    const handleLogout = () => {
        if (document.getElementById("save-before-leave")) {
            emitEvent(LISTENER.PORFILE.LOGOUT_START);
        } else {
            logout();
        }
    }

    const saveFilter = (filter, apply, isCheckBoxChecked, isDeleteScope, callBack) =>{
        if(apply){
            setSelectedTab(SCOPE_OPTIONS[0].value);
            sessionStorage.setItem(SELECTED_SCOPE, SCOPE_OPTIONS[0].value);
            sessionStorage.setItem(APPLY_SCOPE, true);
        }
        saveScopeFilter(filter, apply, isCheckBoxChecked, callBack, isDeleteScope);
        handleClickAway();
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box sx={{position: 'relative'}}>
                <div className="profile_icon_name_scope" onClick={handleClick}>
                    <div id="profile_icon_scope" className="">
                        <span className="profile_icon_name">
                        {initials.toUpperCase()}
                        </span>
                    </div>
                    <div className="profile_name_scope">
                        <div className={"profile_name_arrow" + (open ? "_open" : "")}>
                            <div className="name_text">{displayName}</div>
                            <Arrow/>
                        </div>
                        <div className="scope_selection_text">{scopeTitle.label}</div>
                    </div>
                </div>
                {open ? (
                    <div>
                        <div id="profileContainer" className="profile_container-popover">
                            <div className='user_info_profile_container'>
                                <div className='avatar_name_container'>
                                    <div className="profile_icon_name_popover">
                                        {props.user && props.user.first_name && extractInitials(props.user.first_name + " " + props.user.last_name).toUpperCase()}
                                    </div>
                                    <div className="fs-16 uk-text-center name_email_container_avatar_profile">
                                        <div className='user_name_avatar_profile'>
                                            {displayName}
                                        </div>
                                        <div id={"email_profile"} className="fs-14 uk-text-center email_avatar_profile">
                                            {(props.user && props.user.email) || readCookie("user_email")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="add_scope_dropdown_container max_width">
                                <ToggleTab isDisabled={props.isSwitchDisabled || !appliedScopeFilter?.length} options={options} type="table"
                                           defaultValue={defaultScope} onSelectTab={(tab) => {handleSelectTab(tab)}}  />
                                <div className={"add_scope_text" + (props.isSwitchDisabled? " disabled" :"")} uk-toggle="target: #filterModal_1">{appliedScopeFilter?.length ? lang.edit_scope : lang.add_scope}</div>
                                <FilterDialog
                                    ref={filterDialRef}
                                    vectorOptions={props.vectors}
                                    useOutsideFilter={true}
                                    onSaveScopeFilter={saveFilter}
                                    scenario_id={props.scenario?.client_model_scenario_id}
                                    mustFetchDatasets={false}
                                    useCookies={false}
                                    compNumber={1}
                                    user={props.user}
                                    isDrilling={false}
                                    fromOptions={[]}
                                    quadrantsDisabled={true}
                                    quadrantDefault={false}
                                    filterDisplayEditable={true}
                                    hideFilterDisplay={true}
                                    filterFinal = {sessionStorage.getItem(SCOPE_FILTERS)}
                                    fromProfile = {true}
                                />
                            </div>
                            {clients.length > 1 &&
                                <div className="max_width popover_client_dropdown">
                                    <div className="">
                                        <SelectClientDropDown
                                            isDisabled={props.isSwitchDisabled}
                                            isProfile={true}
                                            clients={clients}
                                            textMessage={lang.switch_to_client + ":"}
                                            signIn={props.signIn}
                                            checkForUnsavedChanges={props.checkForUnsavedChanges}
                                            isFromAvatar={true}
                                            clientChangeCallback={handleClickAway}/>

                                    </div>
                                </div>
                            }
                            {clients.length > 1 &&
                                <div className="uk-display-inline max_width">
                                    {/* <button className={"text-blue text-link " + (isBuildRunning ? " uk-disabled disabled" : "")} onClick={isBuildRunning ? () => { } : props.goToMultiClients}>{lang.personal_settings}</button> */}
                                    <Button
                                        label={lang.preferences}
                                        // title={isBuildRunning ? "title:" + lang.build_is_running : null}
                                        variant={BUTTON_VARIANT.TERTIARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        style={{width: "100%", justifyContent: "start"}}
                                        onBtnClick={isBuildRunning ? () => {
                                        } : props.goToMultiClients}
                                        leftIcon={<i className="fal fa-cog font_weight_icon"/>}
                                    />
                                </div>
                            }

                            <Button
                                label={"Log out"}
                                variant={BUTTON_VARIANT.PRIMARY}
                                size={SIZES.LARGE}
                                type={BUTTON_TYPE.DEFAULT}
                                style={{width: "100%"}}
                                onBtnClick={handleLogout}
                            />
                        </div>
                    </div>
                ) : null}
            </Box>
        </ClickAwayListener>
    );
}

export default React.memo(ScopeProfile, areEqual);