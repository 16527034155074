import { Tooltip, tooltipClasses } from "@mui/material";
import "./styles/iconTitle.css";
import {useEffect, useRef, useState} from "react";
import { parseBoolean } from "../class/utils";

const IconTitle = (props) => {
  const titleRef = useRef(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    if (titleRef.current) {
      const { offsetWidth, scrollWidth } = titleRef.current;
      if (scrollWidth > offsetWidth) {
        setIsOverflow(true);
      } else {
        setIsOverflow(false);
      }
    }
  }, [props.title]);
  /**
   * Add hover class to the selected item.
   * This function is used to add the hover class when the item tooltip is open 
   * so that the item icon remains in its hover state even if we the cursor is inside the item tootltip.
   */
  const addHoverEffect = () => {
    if(props?.machine_name && props.tooltipOnHover) {
      $("." + props?.machine_name).addClass("hover-icon");
    }
  }

  /**
   * Remove the hover class
   * This fucntion is used to remove the hover state when we stop hovering over the tooltip.
   */
  const removeHoverEffect = () => {
    if(props?.machine_name && props.tooltipOnHover) {
      $("." + props?.machine_name).removeClass("hover-icon");
    }
  }

  /**
   * This function is triggered when the tooltip opens.
   */
  const onOpenTooltip = () => {
    addHoverEffect();
  }

    /**
   * This function is triggered when the tooltip closes.
   */
  const onCloseTooltip = () => {
    removeHoverEffect();
  }

  return (
    <Tooltip
      title={props.tooltipOnHover}
      // arrow={true}
      placement="right-start"
      onOpen={onOpenTooltip}
      onClose={onCloseTooltip}
      slotProps={{
        popper: {
          sx: {
            // this is how we catch the class that handles the placement of the tooltip
            [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: { 
              marginLeft: "-1.2vw", // to place the tooltip right next to the icon. Without this margin, the tooltip will be at the end of this container.
              marginTop: "0", //  to move the tooltip  bit higher than how it opens by default when have placement=right-start
            },
          },
        },
        tooltip: {
          sx: {
            bgcolor: "#FFFFFF",
            borderRadius: "0.73vw",
            border: "0.05vw solid rgba(0, 0, 0, 0.15)",
            boxShadow: "0vw 1.25vw 1.77vw 0vw rgba(0, 0, 0, 0.10)",
            fontFamily: "Lato, sans-serif",
            width: "16vw",
            padding: 0,
            mb: "1vw", // to elevate the tooltip from the bottom so it won't touch the bottom of the screen
          },
        },
        arrow: {
          sx: {
            color: "#FFFFFF",
            mt: "0", // chnage the arrow placement vertically
            fontSize: "0.789vw", // this controls the arrow size
            "&::before": {
              border: "0.05vw solid rgba(0, 0, 0, 0.15)", // this adds a border to the arrow
              borderRadius: "0.15vw",
            },
          },
        },
      }}
    >
      <div
        id={`${parseBoolean(props?.is_custom_report) ? "custom_report_" : ""}${props?.machine_name || ""}`}
        className={`icon-title-main-div ${props?.containerClassName || ""} ${props.hasHoverEffect ? "hover" : ""}`}
        type={props?.type}
        parent_machine_name={props?.parent_machine_name}
        machine_name={props?.machine_name}
        redirect_only={props?.redirect_only}
        is_custom_report={props?.is_custom_report}
        is_tableau={props?.is_tableau}
        menu_item_machine_name={props?.menu_item_machine_name}
        url={props?.url}
        onClick={props?.onClick}
        style={props.iconTitleContainerStyle}
        key={props?.menu_item_machine_name}
      >
        {/* This div is added to increase the place where we can hover on for the tooltip */}
        {props.hasHoverEffect && <div className={`empty-div-icon`} />}

        <div id={`icon-container-${props?.machine_name || ""}`} className={`icon-container ${props?.machine_name || ""}`} menu_item_category={props.menuItemCategory?props.menuItemCategory:""} style={props.svgStyle}>
          {props.icon}
        </div>

        <div id={`items-title-container-${props?.machine_name}`} className="items-title-container" style={props.titleContainerStyle}>
          {props.overtitle && <span className="item-overtitle">{props.overtitle}</span>}
          <div ref={titleRef} className="icon-title" style={props.titleStyle} {...(isOverflow && { 'uk-tooltip': `title:${props.title}; pos: top-left` })}>{props.title}</div>
          {props.subtitle && (
            <div style={props.subtitleStyle} className="icon-title-description">
              {props.subtitle}
            </div>
          )}
        </div>
      </div>
    </Tooltip>
  );
};

export { IconTitle };
