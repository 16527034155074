import React from 'react';
import './WaveLoader.css'; // CSS for the loader

const WaveLoader = () => {

  const bars = new Array(23).fill(null); // 23 bars total

  return (
    <div className="wave-loader">
      {bars.map((_, index) => (
        <div key={index} className={`bar bar-${index + 1}`}></div>
      ))}
    </div>
  );
};
export default WaveLoader;
