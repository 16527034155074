import PropTypes from "prop-types";
import { SearchableExpandableSections } from "../expandableSections/SearchableExpandableSections";

function VectorsList({ vectors, onVectorClick }) {
  
  /**
   * Formatting the data to be sent to <SearchableExpandableSections />.
   * The data is expected to be [{title: "Customer", items: [array of objects]}].
   * We are adding isClickable for the items in case some items are not clickable.
   * @param {*} data
   * @returns
   */
  const formatSections = (data) => {
    if (!data || data?.length === 0) {
      return [];
    }
    let formattedData = [];
    let sectionsTitles = data.filter((f) => f.isGroupTitle).map((m) => m.label.trim());

    for (let sectionTitle of sectionsTitles) {
      let items = data.filter((f) => f.vector_group === sectionTitle && !f.isGroupTitle);
      items = items.map((m) => {
        m.isClickable = true;
        return m;
      });
      formattedData.push({ title: sectionTitle, items: items });
    }

    return formattedData;
  };

  return (
    <SearchableExpandableSections
      sections={formatSections(vectors)}
      sectionsTitle={"Filter by"}
      sectionsTitleClassName="filter-by-label"
      onSectionItemClick={onVectorClick}
      searchCriterias={["label"]}
    />
  );
}

VectorsList.propTypes = {
  vectors: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      vector_group: PropTypes.string,
      isGroupTitle: PropTypes.bool,
      vector_description: PropTypes.string,
      generated: PropTypes.bool,
      client_scenario_vector_id: PropTypes.number,
      entityCount: PropTypes.number,
    })
  ).isRequired,
  onVectorClick: PropTypes.func.isRequired,
};

export { VectorsList };
