import 'jquery';
import 'jqueryui';
import React from 'react';
import { useState } from 'react';
import SelectClientDropDown from '../SelectClientDropDown';
import '../styles/common.css';
import '../styles/login.css';
import '../styles/popup.css';
import '../templateLayout/styles/navigationHeader.css'
import {BUTTON_TYPE, BUTTON_VARIANT, CLIENTS, IS_BUILD_RUNNING, LISTENER, SIZES} from '../class/constants';
import { lang } from '../language/messages_en';   
import { extractInitials, readCookie } from '../class/jqueries';
import { parseBoolean } from '../class/utils';
import { Box, ClickAwayListener } from '@mui/material';
import { logout } from '../templateLayout/api/api';
import Button from '../newComponents/Button';
import { ReactComponent as Logout } from "../styles/images/icons/logout.svg";
import { emitEvent, useListener } from '../actions/CustomEventHandler';
import { getLocalStorageValueByParameter } from '../class/common';


const areEqual = (prevProps, nextProps) => {
  if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
    return true
  }
  return false;
}

function Profile(props) {

    useListener(LISTENER.PORFILE.LOGOUT_END, () => {
        logout();
    });

  let displayName = props.user && props.user.first_name ? props.user.first_name + " " + props.user.last_name : props.user && props.user.displayName ? props.user.displayName : "";
  let initials = displayName !== "" ? extractInitials(displayName) : "";

  let clients = getLocalStorageValueByParameter(CLIENTS) ? JSON.parse(getLocalStorageValueByParameter(CLIENTS)) : [];
  let isBuildRunning = parseBoolean(sessionStorage.getItem(IS_BUILD_RUNNING));

  const [open, setOpen] = useState(false);
  
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

    const handleLogout = () => {
        if(document.getElementById("save-before-leave")){
             emitEvent(LISTENER.PORFILE.LOGOUT_START);
        } else {
            logout();
        }
  }

  return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box sx={{ position: 'relative' }}>
          <div id="profile-icon" className="round-icon avatar_background uk-cursor-pointer background-blue" onClick={handleClick}>
            <span className="text-white fs-18">
              {initials}
            </span>
          </div>
          {open ? (
            <div>
            <div id="profileContainer" className={`profileContainer-popover`}>
              <div className='user_info_container'>
              <div className='avatar_name_container'>
              <div className="round-icon avatar_background background-blue profileContainerChild">
                <span className="text-white fs-20 avatar_initials_dropdown">
                  {props.user && props.user.first_name && extractInitials(props.user.first_name + " " + props.user.last_name)}
                </span>
              </div>
              <div className="fs-16 uk-text-center name_email_container_avatar">
                <span className='user_name_avatar'>
                {displayName}
                </span>
                <div id={"email_profile"} className="fs-14 uk-text-center user_email_avatar">
                {(props.user && props.user.email) || readCookie("user_email")}
              </div>
              </div>
              </div>
              </div>
              {clients.length > 1 &&
                <div className="max_width">
                  <div className="">
                    <SelectClientDropDown
                      isDisabled={props.isSwitchDisabled}
                      isProfile={true}
                      clients={clients}
                      textMessage={lang.switch_to_client}
                      signIn={props.signIn}
                      checkForUnsavedChanges={props.checkForUnsavedChanges}
                      isFromAvatar={true}
                      clientChangeCallback={handleClickAway}
                    />
                  </div>
                </div>
              }
              {clients.length > 1 &&
                <div className="uk-display-inline max_width">
                  {/* <button className={"text-blue text-link " + (isBuildRunning ? " uk-disabled disabled" : "")} onClick={isBuildRunning ? () => { } : props.goToMultiClients}>{lang.personal_settings}</button> */}
                  <Button
                    label={lang.preferences}
                    // title={isBuildRunning ? "title:" + lang.build_is_running : null}
                    variant={BUTTON_VARIANT.TERTIARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    style={{width:"100%", justifyContent: "start"}}
                    onBtnClick={isBuildRunning ? () => { } : props.goToMultiClients}
                    leftIcon={<i className="fal fa-cog font_weight_icon" />}
                  />
                </div>
              }
              {/* <button className={"text-black text-link uk-display-inline uk-margin-small-top-bottom" + (clients.length > 1 ? "" : "")} onClick={logout}>
                Logout
              </button> */}
              <Button
                    label={"Log out"}
                    // title={isBuildRunning ? "title:" + lang.build_is_running : null}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.LARGE}
                    type={BUTTON_TYPE.DEFAULT}
                    style={{width:"100%"}}
                    onBtnClick={handleLogout}
                    leftIcon={<Logout/>}
                  />
            </div>
            </div>
          ) : null}
        </Box>
      </ClickAwayListener>
  );
}

export default React.memo(Profile, areEqual);