import {forwardRef, useEffect, useImperativeHandle, useState, useRef} from "react";
import { copyObjectValues, getTranslationFile, capitaliseFirstLetterAfterChar, capitalizeFirstLetter } from "../../../../class/utils";
import Card from "../Card";
import { ReactComponent as Branch } from "../../../../styles/images/icons/code-branch-regular-1.svg";
import {ReactComponent as CircleInfo} from "../../../../styles/images/icons/circle-info.svg";
import {ReactComponent as SuggestedInfo} from "../../../../styles/images/icons/sparkles-light-one.svg";
import {ReactComponent as SparklesSharp} from "../../../../styles/images/icons/sparkles-sharp.svg";
import {ReactComponent as HourGlassClock} from "../../../../styles/images/icons/hourglass-clock-regular.svg";
import {ReactComponent as ErrorIcon} from "../../../../styles/images/icons/error.svg";
import PaginationNextPrev from "../PaginationNextPrev";
import Button from "../../../../newComponents/Button";
import { BUTTON_TYPE, BUTTON_VARIANT, DIALOG_SIZE, GL_STRINGS_REVIEW, PS_MAPPING, SIZES } from "../../../../class/constants";
import {ReactComponent as Flipped} from "../../../../styles/images/icons/retweet-compare.svg";
import Container from "../../../manageColumns/Container";
import './setupProfitStack.css'
import { getEmbeddedChild, updateAllElements } from "../../../../class/array";
import NewBanner from "../../../NewBanner";
import CardBodyNavigation from "./CardBodyNavigation";
import ExistingAssignments from "./ExistingAssignments";
import Modal from "../../../../newComponents/Modal";




const lang = getTranslationFile();
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD', // Change to your desired currency code
    minimumFractionDigits: 0,
  });

const _assignedCombinations = "assigned_combinations";
const _excluded  = PS_MAPPING.PSS_FILTER_LABELS.EXCLUDED_LABEL;
const _unAssigned = PS_MAPPING.PSS_FILTER_LABELS.UNASSIGNED_LABEL;
const ASSIGNED_TYPE = {
                        MANUALLY:"MANUALLY",
                        AI_ASSIGNED:"AI_ASSIGNED"
                    };

const AssignGlStrings = (props, ref) => {

    const [selectedCard, setSelectedCard] = useState(0); 
    const [selectedPSL,setSelectedPSL] = useState({});
    const [showBanner,setShowBanner] = useState(false);
    const [activeCard,setActiveCard] = useState(0)
    const [isMultipleCards,setIsMultipleCards] = useState(false)
    const [pslCardWidth, setPSLCardWidth] = useState(null);
    const [isManualAssign,setIsManualAssign] = useState(false);
    const [assignedCombinationsData,setAssignedCombinationsData] = useState([]);
    const [assignedCombinationsColumns,setAssignedCombinationsColumns] = useState([]);
    const [existingAssignmentsDialogOpen,setExistingAssignmentsDialogOpen] = useState(false);
    const [existingAssignmentsPSLId,setExistingAssignmentsPSLId] = useState(0);
    
    const isMultipleCardsRef = useRef()

    const {
        isError,
        pslTree,
        flipSign,
        glStrings,
        setIsError,
        getPSLPath,
        linearizedPSLs,
        transitionDisplayMap,
        lstTransitionColumnNames,
    } = props;


    

    useImperativeHandle(ref, () => ({
        changeCard: (param) => {
            return changeCard(param);
        },
        onChangePSL: (node,selectedNodes) =>{
            return onChangePSL(node,selectedNodes);
        },
        setSelectedPSL:(psl) =>{
            return setSelectedPSL(psl);
        },
        setIsMultipleCards:(value) =>{
            return setIsMultipleCards(value)
        },
        setShowBanner:(value) => {
            return setShowBanner(value)
        },
        isMultipleCards:()=>{
            return isMultipleCards;
        },
        getPSLSiblings:(pssId)=>{
            return getPSLSiblings(pssId)
        },
        setIsManualAssign:(value)=> {
            return setIsManualAssign(value)
        }
    }));

    useEffect(() => {
        if(!glStrings?.unassignedCombinations[selectedCard]?.assigned_to.includes("Unassigned")  || glStrings?.unassignedCombinations[selectedCard]?.suggestedPSLs?.length === 1) {
            setIsMultipleCards(false);
            isMultipleCardsRef.current = false;
            $('.psl-card-layout').css({width: $("#pslAssign").outerWidth()-90});
        } else if(glStrings?.unassignedCombinations[selectedCard]?.suggestedPSLs?.length > 1 ){
            setIsMultipleCards(true);
            isMultipleCardsRef.current = true;
        } else {
            $('.psl-card-layout').css({width: $("#pslAssign").outerWidth()-90});
            setIsMultipleCards(false);
            isMultipleCardsRef.current = false;
        }
    }, [selectedCard]);

    useEffect(()=>{
        if(isMultipleCards){
            $('.psl-card-layout').css({width:"14vw"});
            $("#pslAssign .card-body").css({minHeight:"320px"})
        } else{
            $('.psl-card-layout').css({width: $("#pslAssign").outerWidth()-90});
            $("#pslAssign .card-body").css({minHeight:"340px"})
        }
        setPSLCardWidth($("#pslAssign").outerWidth());
    },[isMultipleCards,selectedPSL,isManualAssign])

    useEffect(()=> {
        addEventListener("resize", (event) => { 
            if(isMultipleCardsRef.current){
                $('.psl-card-layout').css({width:"14vw"});
                $("#pslAssign .card-body").css({minHeight:"320px"})
            } else{
                $('.psl-card-layout').css({width: $("#pslAssign").outerWidth()-90});
                $("#pslAssign .card-body").css({minHeight:"340px"})
            }
            setPSLCardWidth($("#pslAssign").outerWidth())});
    },[])
   
    const stringCardBody = () => {
        const body = [];
        const stringDetails = stringDetailsRender();
        const amount = amountRender();

        body.push(stringDetails);
        body.push(amount);
        return body;
    }

    const formatAssignedCombinationsData = (pssId) => {
        let combinationsFromPage  = getGLStringsOfPSL(pssId,"")
        let approvedCombinations = getGLStringsOfPSL(pssId,_assignedCombinations)
        let totalCombinations = combinationsFromPage.concat(approvedCombinations)
        totalCombinations = totalCombinations.flat(1);
        totalCombinations = totalCombinations.filter((value, index, self) =>
                     index === self.findIndex((t) => (
                     t.fields_combination === value.fields_combination
                ))
        )
        let data = [];
        for(let e in totalCombinations) {
            let row = {};
            let glString = totalCombinations[e];
            let transitionDisplayMap = glString.transitionDisplayMap;
            let amount = glString.total;
            let flipped = glString.flipped;
            row.total = amount;
            row.flipped = flipped;
            let combinationTransitionColumns = glString.combinationTransitionColumns;
            for( let f in combinationTransitionColumns) {
                let transitionColumnObj = combinationTransitionColumns[f];
                let keyList  = Object.keys(transitionColumnObj) 
                if(keyList.length){
                    let key = keyList[0];
                    row[key] = transitionColumnObj[key]
                    if(key in transitionDisplayMap && transitionDisplayMap[key] in glString){
                        row[transitionDisplayMap[key]] = glString[transitionDisplayMap[key]];
                    }
                } 
            }
            data.push(row)
        }
        return data;
    }
    const formatAssignedCombinationsColumns = () => {
        let combinations =  glStrings?.assignedCombinations?.length  ?  glStrings?.assignedCombinations[0] : glStrings?.unassignedCombinations[0];
        const transitionedColumns = combinations?.combinationTransitionColumns ? combinations?.combinationTransitionColumns.map((column) => {
            const key = Object.keys(column)[0];
            const title = capitalizeFirstLetter((key === "state" ?  glStrings?.clientCostCenterDisplayName : key).toLowerCase().replace(/_/g, " "), " ");  
            return {
            title: title,
            field: key,
            vertAlign: "middle",
            minWidth: 100
            };
        }) : [];
        const commonColumns = [ 
            {
                title: GL_STRINGS_REVIEW.COLUMNS.TITLES.AMOUNT,
                field: GL_STRINGS_REVIEW.COLUMNS.FIELDS.AMOUNT,
                headerHozAlign:"right",
                hozAlign:"right",
                cssClass:"full-width-toggle",
                vertAlign: "middle",
                minWidth: 100
            },
        ]
        return [...transitionedColumns,...commonColumns];
    }
   
    const initializeTabulator = (pssId,openDailog = false) => {
        setExistingAssignmentsPSLId(pssId)
        let columns = formatAssignedCombinationsColumns();
        let data = formatAssignedCombinationsData(pssId)
        setAssignedCombinationsColumns(columns);
        setAssignedCombinationsData(data);
        if(openDailog){
            setExistingAssignmentsDialogOpen(true);
        }
    }
    const onChangePSL = (node,selectedNodes) => {
        let psls = copyObjectValues(pslTree)
        if(selectedNodes.length){
            let pslObj  = getEmbeddedChild(psls,"children","pssId",node.pssId)
            updateAllElements(psls,"children","checked",false)
            pslObj["checked"] = true
            props.setPSLTree(psls)
            setSelectedPSL(node)
            initializeTabulator(node.pssId);
            
        } else {
            updateAllElements(psls,"children","checked",false)
            props.setPSLTree(psls)
            setSelectedPSL({})
        }
        
        if(selectedNodes.length > 0){  //Showing banner if there ps linkage
            let psls = getPSLSiblings(Number(node.pssId));
            if(psls.length > 1){
                setShowBanner(true)
            } else {
                setShowBanner(false)
            }
        } else {
            setShowBanner(false);
        }
        
        setIsError(false);
    }

    const renderPSLCard = (pslInfo, isLastSibling) => {
        let width = isMultipleCards ?  "14vw" : $("#pslAssign").outerWidth()-90 ;
        let style = {}
        if(!isNaN(width)){
            style = {width:width}
        } 
        return (
            <div key={pslInfo.pssId} className="psl-card-layout" style={style}>
                <span className="psl-label">{pslInfo.label}  {pslInfo.costCenter && pslInfo.costCenter !== "ALL"  &&  <Branch className="icon-legend"/> }</span>
                <span className="psl-path">{getPSLPath(pslInfo)}</span>
                {!isLastSibling && <div className="circle">&</div>}
            </div>
        )
    }

    const renderPSLInfo = (pslInfo) => {
        return (
            <div className="small-gap-flex" style={{marginBottom:"3vw"}}>
                <span id="psl-name" className="psl-label">{pslInfo.label} {pslInfo.costCenter && pslInfo.costCenter !== "ALL"  &&  <Branch className="icon-legend"/> } </span>
                <span className="psl-path">{getPSLPath(pslInfo)}</span>
            </div>
        )
    }

    const renderPSL = (pssId,pslInfo) => {
        let pslSiblings = getPSLSiblings(pssId);

        return (
            <>
                 {pslSiblings.length > 1 ? //When we change any psl in the drop or when submitting a psl line
                    <div style={{display:"flex",flexDirection:"column",gap:"0.5vw"}}>
                        <p className="distributed-between">{lang.monthly_build.setup_profit_stacks.distributed_between_stmt}</p>
                        <div className={"psl-siblings"}>
                            {renderPSLSiblings(pslSiblings)}
                        </div>
                    </div> :  renderPSLInfo(pslInfo)
                }
            </>
        )
    }

    const renderAssignedPSL = (pssId,pslInfo) => {
        const isManuallyAssigned = glStrings?.unassignedCombinations[selectedCard]?.assigned_type === ASSIGNED_TYPE.MANUALLY;
        const hasAiSuggestion = glStrings?.unassignedCombinations[selectedCard]?.ai_suggested;
        return (
            <div className="psl-card-container">
                <div style={{display:"flex",flexDirection:"column", gap:"0.5vw"}}>
                {(!isManuallyAssigned && hasAiSuggestion) &&
                    <div style={{width:"6vw"}} className="status-tag status-tag-suggested">
                            <SuggestedInfo/> {lang.monthly_build.setup_profit_stacks.suggested_tag_stmt}
                    </div>
                }
                 {renderPSL(pssId,pslInfo)}
                </div>
                
                 {renderSubmittedInfo()}
            </div>
        )
    }

    const renderExcludedInfo = () => {
        return (
            <div className="psl-card-container">
                <div style={{display:"flex",flexDirection:"column"}}>
                    {lang.monthly_build.setup_profit_stacks.excluded_stmt.split("\n").map( item => {
                        return <span className="exclude-stmt">{item}</span>
                    })}
                </div>
                
                 {renderSubmittedInfo()}
            </div>
        )
    }

    const renderNoPSLSuggestion = (dropDownStyle,isAssigned,isManualAssign) => {
        return (
            <div className="psl-card-container">
                <div className="psl-suggestion-layout">
                    {!isManualAssign && (
                        <>
                            <span className="no-suggestions-header">{lang.monthly_build.setup_profit_stacks.no_suggestions_header}</span>
                            <span className="no-suggestions-message">{lang.monthly_build.setup_profit_stacks.no_suggestions_message}</span>
                        </>
                    )}

                    <div style={dropDownStyle}>
                        {Object.keys(selectedPSL).length > 0 && (
                            <Button
                                id="existing-assignments-psl"
                                label={lang.monthly_build.setup_profit_stacks.existing_assignments_btn_name}
                                variant={BUTTON_VARIANT.TERTIARY}
                                size={SIZES.SMALL}
                                type={BUTTON_TYPE.DEFAULT}
                                onBtnClick={() => setExistingAssignmentsDialogOpen(true)}
                                leftIcon={<HourGlassClock/>}
                            />
                        )}
                        <Container
                            texts={{ placeholder: lang.heatmap_configure.select_ps_line }}
                            data={pslTree}
                            onChange={onChangePSL}
                            mode={"radioSelect"}
                            className={"dropdown-tree radio-drop-down"}
                        />
                    </div>

                    {renderPSLDistribution(Number(selectedPSL.pssId), selectedPSL, isAssigned, isManualAssign)}
                </div>

                <div className="psl-action-buttons">
                    <Button
                        label={"Submit"}
                        variant={BUTTON_VARIANT.PRIMARY}
                        size={SIZES.LARGE}
                        type={BUTTON_TYPE.DEFAULT}
                        className={"submit-button"}
                        onBtnClick={() => {
                            props.assignTo(selectedCard, selectedPSL, ASSIGNED_TYPE.MANUALLY);
                        }}
                    />
                    {isManualAssign && (
                        <Button
                            label={"Cancel"}
                            variant={BUTTON_VARIANT.TERTIARY}
                            size={SIZES.LARGE}
                            type={BUTTON_TYPE.DEFAULT}
                            onBtnClick={() => cancel()}
                        />
                    )}
                    {isError && (
                        <span className="error-text">
                        <ErrorIcon />
                        <span style={{ marginLeft: 5 }}>
                            {lang.monthly_build.setup_profit_stacks.validation}
                        </span>
                    </span>
                    )}
                </div>
            </div>
        )
    }
  
    const getPSLSiblings = (pssId) => {
        let pslSelected = linearizedPSLs.filter(e=>Number(e.pssId) ===pssId).length > 0 ? linearizedPSLs.filter(e=>Number(e.pssId) ===pssId)[0] : {};
        let pslLeadingLine = Number(pslSelected.pssLeadingId);
        let pslSiblings = [];
        if(pslLeadingLine){
            pslSiblings = linearizedPSLs.filter(e=>Number(e.pssLeadingId) === pslLeadingLine).sort((a,b) => a.rank - b.rank)
        }
        return pslSiblings.length > 1 ? pslSiblings : []
    }

    const renderPSLSiblings = (pslSiblings) => {
        return (
            pslSiblings.map((psl,index) => {
                return renderPSLCard(psl,index === pslSiblings.length - 1)
            })
        )
        
    }

    const createTag = (param,text) => {
        return (
            <div className={"status-tag " + ("status-tag-"+param)}>
                <i className="far fa-check-circle"></i>
                {text}
            </div>
        )
    }

    const renderSubmittedInfo = () => {
        const isManuallyAssigned = glStrings?.unassignedCombinations[selectedCard]?.assigned_type === ASSIGNED_TYPE.MANUALLY;
        const hasAiSuggestion = glStrings?.unassignedCombinations[selectedCard]?.ai_suggested;
        const isExcluded = glStrings?.unassignedCombinations[selectedCard]?.assigned_to.includes(_excluded);
        let tagStatus = isExcluded ? 'danger' : 'success';
        let tageName = isExcluded ? lang.monthly_build.setup_profit_stacks.excluded_tag_name : lang.monthly_build.setup_profit_stacks.submitted_tag_stmt;
         return (
            <div  className={"small-gap-flex"} style={{alignItems:"center"}}>
                {createTag(tagStatus,tageName)}
                <div>
                {isManuallyAssigned && hasAiSuggestion && !isExcluded?
                    <Button
                    label={lang.monthly_build.setup_profit_stacks.reassign_btn_name}
                    variant={BUTTON_VARIANT.TERTIARY}
                    size={SIZES.SMALL}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={()=>{reAssign()}}
                /> :
                    <Button
                        label={lang.monthly_build.setup_profit_stacks.discard_btn_name}
                        variant={BUTTON_VARIANT.TERTIARY}
                        size={SIZES.SMALL}
                        type={BUTTON_TYPE.DEFAULT}
                        onBtnClick={() => {
                            props.unAssign(selectedCard);
                        }}
                    /> 
                }
                </div>
            </div>
         )
    }


    const renderPSLPredictedCards = (suggestedPSLs,activeCard) => {
      
        let suggestedCards  = [];
        suggestedCards = suggestedPSLs.map((item,index)=> {
            return renderPredictedPSLCard(item,index,activeCard)
        })
        return suggestedCards;
    }

    const renderPredictedPSLCard = (item,index,activeCard) => {
        let pssId = Number(item.pssId);
        if(isMultipleCards) {
            return (
                <div  key={index} className={`card-body ${index === activeCard ? 'active' : ''}`} style={{ transform: `translateX(-${activeCard*14.5}vw)`,marginRight:"1vw"}}>
                    {renderPSLContainer(pssId,item,true)}
                </div>
            )
        } else {
            return (
                renderPSLContainer(pssId,item,false)
            )
        }
    }

    const renderPSLContainer = (pssId,item,addWidth) => {
        
       let style = addWidth ? {width:"15vw"} : {}
      
        return (
            <div className="psl-card-container" style={style}>
                <div style={{display:"flex",flexDirection:"column",gap:"0.5vw"}}>
                    <div className="status-tag status-tag-suggested" style={{width:"6vw"}}>
                            <SuggestedInfo/> {lang.monthly_build.setup_profit_stacks.suggested_tag_stmt}
                    </div>
                    <div>
                        <Button
                                id={"existing-assignements-psl"}
                                label={lang.monthly_build.setup_profit_stacks.existing_assignments_btn_name}
                                variant={BUTTON_VARIANT.TERTIARY}
                                size={SIZES.SMALL}
                                type={BUTTON_TYPE.DEFAULT}
                                onBtnClick={()=>{initializeTabulator(pssId,true)}}
                                leftIcon={<HourGlassClock/>}
                                style={{marginLeft:"-0.3vw",marginBottom:"-0.1vw"}}
                        />
                    </div>
                    {renderPSL(pssId,item)}
                </div>
              
                <div className={"submit-cancel"}>
                    <Button
                        id="assign-gl-accept-btn"
                        label={lang.monthly_build.setup_profit_stacks.ai_accept_btn_name}
                        variant={BUTTON_VARIANT.PRIMARY}
                        size={SIZES.LARGE}
                        type={BUTTON_TYPE.DEFAULT}
                        className={"submit-button"}
                        onBtnClick={() => {
                            props.assignTo(selectedCard, item, ASSIGNED_TYPE.AI_ASSIGNED);
                        }}
                    />
                    <Button
                        id="manually-assign-btn"
                        label={lang.monthly_build.setup_profit_stacks.manually_assign_btn_name}
                        variant={BUTTON_VARIANT.TERTIARY}
                        size={SIZES.LARGE}
                        type={BUTTON_TYPE.DEFAULT}
                        onBtnClick={()=>{manuallyAssign(item)}}
                    />
                </div>
            </div>
        )
    }

    const renderPSLDistribution = (pssId,pslInfo,isAssigned,isManualAssign) => {
        let pslSiblings = getPSLSiblings(pssId);
        return (
            <>
                 {
                    pslSiblings.length > 1 ? //When we change any psl in the drop or when submitting a psl line
                    <div style={{display:"flex",flexDirection:"column",gap:"0.5vw"}}>
                        <p className="distributed-between">{lang.monthly_build.setup_profit_stacks.distributed_between_stmt}</p>
                        <div className="psl-siblings">
                            {renderPSLSiblings(pslSiblings)}
                        </div>
                    </div> :
                    <div>
                        {isAssigned && !isManualAssign && renderPSLInfo(pslInfo)}
                    </div>
                }
            </>
        )
    }

    const renderAssignToPSL = () => {
        let isAssigned = ![_unAssigned,_excluded].includes(glStrings?.unassignedCombinations[selectedCard]?.assigned_to[0]);
        let isExcluded = glStrings?.unassignedCombinations[selectedCard]?.assigned_to.includes(_excluded)
        let dropDownStyle =  (!Object.keys(selectedPSL).length  || !getPSLSiblings(Number(selectedPSL.pssId)).length)? {width:"100%",marginBottom:"100px"} : {width:"100%"}
        let suggestedPSLs = glStrings.unassignedCombinations[selectedCard]?.suggestedPSLs;
        if(isAssigned && !isManualAssign) {
            let pssId = glStrings?.unassignedCombinations[selectedCard]?.assigned_to[0].pssId;
            let pslInfo = glStrings?.unassignedCombinations[selectedCard]?.assigned_to[0];
            return renderAssignedPSL(Number(pssId),pslInfo,isAssigned)
        } else if(suggestedPSLs?.length && !isManualAssign && !isExcluded) {
            let leftNavStyle = {position:"absolute",left:'-3.7%', top:'42%',zIndex:2};
            let rightNavStyle = {position:"absolute",left:(pslCardWidth - 60)+"px", top:'42%',zIndex:2};
            return<CardBodyNavigation key={activeCard} cards={renderPSLPredictedCards(suggestedPSLs,activeCard)} activeCard={activeCard} handleCardChange={handleCardChange} leftNavStyle = {leftNavStyle} rightNavStyle = {rightNavStyle}/>
        } else if(isExcluded){
            return renderExcludedInfo();
        } else {
            return renderNoPSLSuggestion(dropDownStyle,isAssigned,isManualAssign);
        }
    }
    
    const handleCardChange = (direction,cards) => {
        const nextCard = activeCard + direction;
        if (nextCard >= 0 && nextCard < cards.length) {
          setActiveCard(nextCard);
          let pssId = glStrings?.unassignedCombinations[selectedCard]?.suggestedPSLs[nextCard].pssId 
          let pslSiblings = getPSLSiblings(Number(pssId))
          if(pslSiblings.length > 1) {
                setShowBanner(true);
          } else {
                setShowBanner(false);
          }
        }
    };

    const amountRender = () => {
        const amount = glStrings?.unassignedCombinations[selectedCard]["total"];
        const cssClass = (Number(amount) < 0 )? "red" : "" ; // check if amount is negative to make the div red
        const flipped = glStrings?.unassignedCombinations[selectedCard]["flipped"];
        const amountObj = (
            
            <div className="amount-card fs-14 uk-text-bolder">
                <div>Amount:</div>
                <div className={cssClass}>{formatter.format(amount)}</div>
                {flipped && <div><Flipped/></div>}
                <div>
                    <Button
                        id="flip-sign"
                        label={lang.monthly_build.setup_profit_stacks.btns.flip}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.SMALL}
                        type={BUTTON_TYPE.DEFAULT}
                        onBtnClick={()=>flipSign(selectedCard)}
                    />
                </div>
            </div>
            );
            
        return amountObj;
    }

    const renderExistingAssigmentsGLStringBody = () => {
        const amount = glStrings?.unassignedCombinations[selectedCard]["total"];
        const cssClass = (Number(amount) < 0 )? "red" : "" ; // check if amount is negative to make the div red
        const flipped = glStrings?.unassignedCombinations[selectedCard]["flipped"];
        return (<div style={{display:"flex",flexDirection:"row",gap:"4vw"}}>
            <div style={{display:"flex",flexDirection:"column",gap:"6px"}}>
                <div className="amount-card fs-14 uk-text-bolder">
                    <div style={{display:"flex",direction:"row",gap:"0.5vw", marginLeft:"-1.9vw"}}>
                        <span>Amount: </span>
                        <span className={cssClass}>{formatter.format(amount)}</span>
                    </div>
                    {flipped && <span><Flipped/></span>}
                </div>
                <div>
                    <Button
                        id="existing-assignments-flip-sign"
                        label={lang.monthly_build.setup_profit_stacks.btns.flip}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.SMALL}
                        type={BUTTON_TYPE.DEFAULT}
                        onBtnClick={()=>flipSign(selectedCard)}
                    />
                </div>
            </div>
            {renderTransitionColumns()}
        </div>)
    }

    const stringDetailsRender = () => {
        const combinationTransitionColumns = glStrings?.unassignedCombinations[selectedCard]?.combinationTransitionColumns;
      
        return (
            <div className="no-gap-flex">
              {combinationTransitionColumns?.map((column, index) => (
                <div key={index} className={"xSmall-gap-flex string-detail" + (combinationTransitionColumns.length === (index+1) ? " string-detail-last" : "") } >
                  <div className="fs-12 uk-padding-large-left">
                    {capitaliseFirstLetterAfterChar((
                        Object.keys(column)[0] === "state" 
                        ? glStrings.clientCostCenterDisplayName // change state to the name set by client
                         : Object.keys(column)[0]).toLowerCase().replace(/_/g," "), " ")}
                    </div>
                  <div className="fs-16 uk-padding-large-left" >{Object.values(column)[0]}</div>
                  {
                      Object.keys(column)[0] in transitionDisplayMap
                      &&
                      <div className="fs-12 uk-padding-large-left"
                          {...(transitionDisplayMap[Object.keys(column)[0]] === "account_name" ? {id: 'gl-account-name'} : {})}>
                          { glStrings?.unassignedCombinations[selectedCard][transitionDisplayMap[Object.keys(column)[0]]]}
                      </div>
                  }
                </div>
              ))}
            </div>
        );
      };

    const changeCard = (param) => {
        setIsError(false);
        setIsManualAssign(false)
        let isAssigned = ![_unAssigned,_excluded].includes(glStrings?.unassignedCombinations[param-1]?.assigned_to[0]);
        if(isAssigned) {
             let selectedPSL = glStrings?.unassignedCombinations[param-1]?.assigned_to[0];
             onChangePSL(selectedPSL,[selectedPSL]); 
        } else if(glStrings?.unassignedCombinations[param-1].suggestedPSLs?.length) {
           let pssId =  glStrings.unassignedCombinations[param-1].suggestedPSLs[0].pssId;
           let pslSiblings = getPSLSiblings(Number(pssId))
           if(pslSiblings.length > 1) {
                setShowBanner(true)
           } else {
                setShowBanner(false)
           }
        }else {
             onChangePSL(selectedPSL,[]); 
        }
        setSelectedCard(param-1);
        setActiveCard(0);
        if(!isMultipleCards){
            $('.psl-card-layout').css({width: $("#pslAssign").outerWidth()-90});
        }
    }

    const manuallyAssign = (item) => {
        setIsManualAssign(true);
        onChangePSL(item,[item])
        setIsMultipleCards(false)
    }

    const cancel = () => { 
        setIsManualAssign(false);
        let isAssigned = ![_unAssigned,_excluded].includes(glStrings?.unassignedCombinations[selectedCard]?.assigned_to[0]);
        if(isAssigned) {
            setIsMultipleCards(false)
        } else if(glStrings?.unassignedCombinations[selectedCard]?.suggestedPSLs.length > 1){
            setIsMultipleCards(true)
        } else {
            setIsMultipleCards(false)
        }
    }

    const reAssign = () => {
        setIsManualAssign(true);
        let assignedPSL  = glStrings?.unassignedCombinations[selectedCard]?.assigned_to[0];
        onChangePSL(assignedPSL,[assignedPSL]);
    }

    const discardToSuggested = () => {
        props.unAssign(selectedCard);
    }

    const excludeGLString = () => {
        props.exclude(selectedCard);
    }

    const getGLStringsOfPSL = (pssId,type) => {
        let  pslSiblings = getPSLSiblings(Number(pssId))
        let glStringsPerPSL = [];
        let combinations = [];
        if(pslSiblings.length){
            for(let e in pslSiblings){
                let pslId = pslSiblings[e].pssId;
                combinations = type === _assignedCombinations ? glStrings?.assignedCombinations?.filter(f=> Number(f.assigned_to[0].pssId) === Number(pslId)) : glStrings?.unassignedCombinations?.filter(f=>!f.assigned_to.includes("Unassigned") && Number(f.assigned_to[0].pssId) === Number(pslId));
                if(combinations.length){
                    glStringsPerPSL.push(combinations);
                }
            }
        } else {
            combinations = type === _assignedCombinations ? glStrings?.assignedCombinations?.filter(e=> Number(e.assigned_to[0].pssId) === Number(pssId)) : glStrings?.unassignedCombinations?.filter(e=>!e.assigned_to.includes("Unassigned") && Number(e.assigned_to[0].pssId) === Number(pssId));
                if(combinations.length){
                    glStringsPerPSL.push(combinations);
                }
        }
        return glStringsPerPSL;
    }

    let button = {
        addButtonCondition: glStrings?.unassignedCombinations[selectedCard]?.ai_suggested && glStrings?.unassignedCombinations[selectedCard]?.assigned_type === ASSIGNED_TYPE.MANUALLY,
        onBtnClick: discardToSuggested,
        label: lang.monthly_build.setup_profit_stacks.discard_to_suggested_btn_name,
        icon : <SparklesSharp/>,
        variant: BUTTON_VARIANT.TERTIARY,
        size: SIZES.SMALL,
        type: BUTTON_TYPE.DEFAULT
    }

    let excludeButton = {
        addButtonCondition:true,
        onBtnClick: excludeGLString,
        label: lang.monthly_build.setup_profit_stacks.excluded_btn_name,
        variant: BUTTON_VARIANT.SECONDARY,
        size: SIZES.DEFAULT,
        type: BUTTON_TYPE.DANGER,
        disabled:glStrings?.unassignedCombinations[selectedCard]?.assigned_to.includes(_excluded),
        className:"exclude-button"
    }

    const modalDialogAction = () => {
       
            return (
            <span style={{display:"flex", alignItems: "center"}}>
                <Button 
                    label={"Ok"}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT} 
                    onBtnClick={() =>  setExistingAssignmentsDialogOpen(false)}
                />
            </span>
            )
        
    }

    const renderExistingAssignmentsTitle = (pssId) => {
        if(pssId !== 0) { //Initial Render
            let pslInfo = linearizedPSLs.filter(e=>Number(e.pssId) === Number(pssId))[0];
            let isSuggested = glStrings?.unassignedCombinations[selectedCard]?.suggestedPSLs.length > 0 && !isManualAssign
            let name = pslInfo?.name;
            return (
                <div style={{display:"flex",flexDirection:"column"}}>
                    <div style={{display:"flex",flexDirection:"row",gap:"0.5vw"}}>
                        <div>{"Existing assignments to "+name}</div>
                        {isSuggested && <div className="status-tag status-tag-suggested" style={{fontWeight:"normal",fontSize:"0.8vw"}}>
                            <SuggestedInfo/> {lang.monthly_build.setup_profit_stacks.suggested_tag_stmt}
                        </div>}
                    </div>
                    <span className="psl-path">{getPSLPath(pslInfo)}</span>
                </div>
            )
        }
    }

    const renderTransitionColumns = () => {
       let combinationTransitionColumns = glStrings?.unassignedCombinations[selectedCard]?.combinationTransitionColumns;
       let combinationTransitionColumnsJSX =  combinationTransitionColumns?.map((item,index) => {
            let transitionColumnName = Object.keys(item)[0];
            let transitionColumnValue = item[transitionColumnName];
            let transitionColumnDisplay = "";
            let transitionDisplayMap = glStrings?.unassignedCombinations[selectedCard]?.transitionDisplayMap;
            if(transitionColumnName in transitionDisplayMap) {
                let transitionColumnNameDisplay  = transitionDisplayMap[transitionColumnName];
                transitionColumnDisplay =  glStrings?.unassignedCombinations[selectedCard]?.[transitionColumnNameDisplay];
            }
            transitionColumnName = transitionColumnName === "state" ? glStrings?.clientCostCenterDisplayName : capitaliseFirstLetterAfterChar(transitionColumnName.toLowerCase().replace(/_/g," ")," ");
            return (
                <div className={"fs-14"} style={{display:"flex",flexDirection:"column",gap:"0.2vw",marginTop:"0.6vw",fontFamily:"Lato"}}>
                    {index !== 0 && <span style ={{position:"absolute",top:"0"}}className="vertical-line"></span>}
                    <span className="fs-12" style={{color:"#C6C6C6",marginTop:index !== 0 ? "0.2vw": "0vw"}}>{transitionColumnName}</span>
                    <span>{transitionColumnValue}</span>
                    <span className="fs-12">{transitionColumnDisplay}</span>
                </div>
            ) 
       })

       return (
            <div className="custom-line-hr" style = {{display:"flex",flexDirection:"row",gap:"3.5vw",position:"relative"}}>
                {combinationTransitionColumnsJSX}
            </div>
        )     
    }

    const renderExistingAssignmentsBody = () => {
        return (
            <div>
                   <Card  key={selectedCard} id="existing-assignment-glstring" isExistingAssignments = {true} body={renderExistingAssigmentsGLStringBody()} isMultipleCards = {false} />
                   <ExistingAssignments data = {assignedCombinationsData}  columns = {assignedCombinationsColumns} transitionDisplayMap={transitionDisplayMap} lstTransitionColumnNames={lstTransitionColumnNames}/>
            </div>
        )
    }
    let glStringHeight = $("#glString .card-body").outerHeight();
    let pslAssignHeight = $("#pslAssign .card-body").outerHeight();
    if(isMultipleCards) {
       if(glStringHeight > pslAssignHeight /2){
        $("#pslAssign .card-body").css({height:glStringHeight})
       } else {
        $("#glString .card-body").css({height: pslAssignHeight /2})
       }
    } else if (glStringHeight &&  pslAssignHeight){
        if(glStringHeight > pslAssignHeight){
            $("#pslAssign .card-body").css({height:glStringHeight}) 
        } else {
            $("#glString .card-body").css({height:pslAssignHeight}) 
        }
    }
    return (
        <div className="small-gap-flex p-text">
            <div>{lang.monthly_build.setup_profit_stacks.intro}</div>
            {showBanner &&
            <NewBanner
                        label={
                            <span> {lang.monthly_build.setup_profit_stacks.banner_psl_linkage_stmt} </span>
                        }
                        bannerClassName={"banner-linkage-info full-width"}
                        icon={<CircleInfo />}
                />}
            <div className="radio-button-flex" style={{display:"flex", flexDirection:"row"}}>
                <Card  key={selectedCard} id="glString" title={lang.monthly_build.setup_profit_stacks.cards.gl_string} body={stringCardBody()} isMultipleCards = {false} props={props} addButton={excludeButton} width={pslCardWidth}/>
                <Card  key={selectedCard+100} id="pslAssign" title={lang.monthly_build.setup_profit_stacks.cards.psl} body={renderAssignToPSL()} isMultipleCards = {isMultipleCards} addButton={button} width={pslCardWidth}/>
            </div>
            <div>
                <Branch className="icon-legend"/>
                <span>
                    {lang.monthly_build.setup_profit_stacks.cc_base}
                </span>
            </div>
            <PaginationNextPrev selected={selectedCard+1} total={glStrings.unassignedCombinations.length} changeCard={changeCard}/>
            <Modal
                key={"existing_assignments_dialog"}
                id={"existing_assignments_dialog"}
                title={renderExistingAssignmentsTitle(existingAssignmentsPSLId)}
                openDialog={existingAssignmentsDialogOpen}
                bodyContent={()=> renderExistingAssignmentsBody()}
                dialogActions={modalDialogAction}
                closeClick={() => setExistingAssignmentsDialogOpen(false)} 
                size={lstTransitionColumnNames?.length > 2 ? DIALOG_SIZE.XLARGE : DIALOG_SIZE.LARGE}
                />
        </div>
    );
};

export default forwardRef(AssignGlStrings);
