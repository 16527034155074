// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#searchable-checkable-list {
  height: 100%;
  width: 100%;
}

#searchable-checkable-list .searchable-checkable-list-body .MuiList-root {
  max-height: 100%;
}

.searchable-checkable-list-body {
  display: grid;
  grid-template-rows: 1fr 15fr;
  height: 94%;
  width: 100%;
}

.searchable-list-section {
  width: 100%;
  display: grid;
}

.recent-selected-entities-container {
  border-bottom: 2px solid #7678ED;
}

.checkable-list-checkbox-container {
  max-width: 86%;
  overflow: hidden;
}

.searchable-list-section .input-checkbox-default {
  transform: none;
  transform: initial;
}

#searchable-checkable-list .checkable-list-item {
  cursor: pointer;
}

.checkable-list-item .info-section {
  display: flex;
  max-width: 30%;
  flex: none;
  column-gap: 0.3vw;
  align-items: baseline;
  min-width: 15%;
  justify-content: flex-end;
}

.checkable-list-item #remove_entity_button svg {
  width: 0.515vw;
}

#searchable-checkable-list .checkable-list-checkbox-label {
  pointer-events: none;
}

#searchable-checkable-list .checkable-list-item:hover {
  background-color: #7678ED;
  color: #FFF
}
#searchable-checkable-list .checkable-list-item:hover .checkbox-container input:not(:checked) {
  background-color: #FFF;
}
`, "",{"version":3,"sources":["webpack://./src/components/querySearchableList/querySearchableList.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,4BAA4B;EAC5B,WAAW;EACX,WAAW;AACb;;AAEA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,eAAgB;EAAhB,kBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,UAAU;EACV,iBAAiB;EACjB,qBAAqB;EACrB,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB;AACF;AACA;EACE,sBAAsB;AACxB","sourcesContent":["#searchable-checkable-list {\n  height: 100%;\n  width: 100%;\n}\n\n#searchable-checkable-list .searchable-checkable-list-body .MuiList-root {\n  max-height: 100%;\n}\n\n.searchable-checkable-list-body {\n  display: grid;\n  grid-template-rows: 1fr 15fr;\n  height: 94%;\n  width: 100%;\n}\n\n.searchable-list-section {\n  width: 100%;\n  display: grid;\n}\n\n.recent-selected-entities-container {\n  border-bottom: 2px solid #7678ED;\n}\n\n.checkable-list-checkbox-container {\n  max-width: 86%;\n  overflow: hidden;\n}\n\n.searchable-list-section .input-checkbox-default {\n  transform: unset;\n}\n\n#searchable-checkable-list .checkable-list-item {\n  cursor: pointer;\n}\n\n.checkable-list-item .info-section {\n  display: flex;\n  max-width: 30%;\n  flex: none;\n  column-gap: 0.3vw;\n  align-items: baseline;\n  min-width: 15%;\n  justify-content: flex-end;\n}\n\n.checkable-list-item #remove_entity_button svg {\n  width: 0.515vw;\n}\n\n#searchable-checkable-list .checkable-list-checkbox-label {\n  pointer-events: none;\n}\n\n#searchable-checkable-list .checkable-list-item:hover {\n  background-color: #7678ED;\n  color: #FFF\n}\n#searchable-checkable-list .checkable-list-item:hover .checkbox-container input:not(:checked) {\n  background-color: #FFF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
