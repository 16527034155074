import React, { useEffect } from 'react';
import '../styles/toast.css'

const Toast = ({ message, onClose, permanent }) => {
  useEffect(() => {
    if(!permanent){
        const timeout = setTimeout(() => {
            handleClose();
          }, 4000); // Automatically close after 4 seconds
      
          return () => clearTimeout(timeout); // Cleanup on unmount
    }
  }, []);

  const handleClose = () => {
    const toastElement = document.querySelector('.new-toast');
    if (toastElement) {
      toastElement.classList.remove('show-toast'); // Trigger fade out
      setTimeout(onClose, 500); // Wait for fade-out to complete before closing
    }
  };

  return (
    <div className="new-toast show-toast fs-16"> {/* 'show' class triggers fade-in */}
      {message}
      <span className="toast-close" onClick={handleClose}>&times;</span>
    </div>
  );
};

export default Toast;