import { ReactComponent as Tick } from "../styles/images/icons/tick.svg";
import {ReactComponent as Indeterminate} from "../styles/images/icons/minus.svg";
const CHECKBOX = "checkbox";

/**
 * 
 * @param {string} id Id of the checkbox, if empty, add a random number to avoid conflict between checkboxes in a list of checkboxes
 * @param {function} onChange event to trigger when checking or unchecking the checkbox
 * @param {Boolean} checked If the checkbox is checked
 * @param {string} labelText the text next to the checkbox
 * @param {string} labelClassName external added className for the label (the text next to the checkbox)
 * @param {Boolean} disabled If the checkbox is disabled
 * @param {String} labelId Id of the label next to the checkbox
 * @param {String} inputAddedClassName external added className for the checkbox
 * @returns the rendered checkbox
 */
const CheckBox = ({
    id= Math.random(),
    onChange,
    checked,
    labelClassName,
    labelText,
    disabled,
    labelId,
    inputAddedClassName="",
    divAddedClassName="",
    showTooltip=false,
    ...props // other props that needs to be added. like: aria-label
  }) => {
    let newDesign = false;
    let containerDivClassName = "checkbox-container";
    let checkboxLabelClassName = "checkbox-label " + (props.indeterminate ? "checkbox-label-indeterminate" :"");
    let mainDivClassName = "uk-display-flex align_items_center "+ divAddedClassName;
    let inputClassName = "input-checkbox-default " +(inputAddedClassName) + (disabled?" input-checkbox-disabled":"");
    
    const onChangeHandler = (e) => {
      if(!disabled && onChange){
        onChange(e);
      }
    }

    return (
        <div className={mainDivClassName}>
            <div className={containerDivClassName} >
                <input disabled={disabled} className={inputClassName} type={CHECKBOX} id={id} onChange={onChangeHandler} checked={disabled ? false : checked} {...props}/>
                {newDesign?(checked || props.defaultChecked?
                  <label className={checkboxLabelClassName} for={id}>
                    <Tick></Tick>
                  </label>
                : props.indeterminate ? 
                  <label className={checkboxLabelClassName} for={id}>
                      <Indeterminate/> 
                  </label>:
                ""):""}
            </div>
            {labelText?<label htmlFor={id} id={labelId} title={showTooltip ? labelText : ""} className={"uk-margin-xsmall-left margin-top-3 uk-margin-xsmall-right " + labelClassName}>{labelText}</label>:""}
        </div>
    );
  };
  
export default CheckBox;
  