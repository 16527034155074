import React, { Component } from 'react';
import cookie from 'react-cookies';
import Popup from 'react-popup';
import { connect } from "react-redux";
import { Segment } from './components/Segment';
import ProfitMapList from './sections/ProfitMapList.js';
import { ALL_REPORTS, ALL_WIDGETS, COLORS_PALETTE, COLUMN_PROFILE, FILTER, FY_VALUES, HEADER_ELEMENT, MENU_ITEM, MENU_ITEM_URLS, PSL_RETURN_NAMES, SECTION, SELECTED_DRILL_LIST } from './class/constants.js';
import { checkPeriodAvailability, getDateFromPeriod, getValidPeriods, isPeriodBuilt } from './templateLayout/functions/periodFunctions';
import { checkIfSectionIdExists, getDefaultLocalStorageValueByParameter, getQuadrantName } from "./class/common";
import { logout } from './class/common.js';
import { extractFromFullQuarter, generatePeriods, getFullQuarterFromStartEndQuarters, getGeneratedQuarterRange, getLastBuiltPeriodForSegments, getPeriodFromDate, getPeriodQuarter, getQuarterFromDate, isValidDate, monthDiff } from './class/date.js';
import { convertPxToViewport } from './class/formatting';
import { deactivateHeaderElements, getDefaultCookieValue,getLimit, getPSTier, hideProfitStackLink, permitExport, removeCursor, removeTypePassword, saveCookie, setDrillRowsLimit, showProfitStackLink} from './class/jqueries.js';
import {
    returnObjectsInArray,
    copyObjectValues,
    deepCompareObjects,
    getDefaultQuadrantValues,
    getTranslationFile
} from './class/utils.js';
import { getNewQuadrantFilter } from './sections/filter/FilterHelperFunctions.js';
import { getItemFromStore } from './class/reduxStoreUtils';
import { updateDrillListData, updateListData, updateListObjBeforeBack } from './actions/listActions';

const $ = require('jquery');
const MESSAGES = getTranslationFile();
const _selectedListProfile = "selectedProfile_list";

const reportsHeaderElements = {
    profitMap: [HEADER_ELEMENT.SELECTION_RANGE, /*HEADER_ELEMENT.DATASET, HEADER_ELEMENT.FY,HEADER_ELEMENT.FORMAT,*/ HEADER_ELEMENT.VECTOR, HEADER_ELEMENT.GO ,HEADER_ELEMENT.XLS],
    list: [HEADER_ELEMENT.SELECTION_RANGE, /*HEADER_ELEMENT.DATASET, HEADER_ELEMENT.FY,HEADER_ELEMENT.FORMAT,*/ HEADER_ELEMENT.VECTOR, HEADER_ELEMENT.GO, HEADER_ELEMENT.XLS, HEADER_ELEMENT.CSV, HEADER_ELEMENT.ADD_COLUMNS],
    quadrant_list: [HEADER_ELEMENT.SELECTION_RANGE, /*HEADER_ELEMENT.DATASET, HEADER_ELEMENT.FY,HEADER_ELEMENT.FORMAT, */ HEADER_ELEMENT.VECTOR, HEADER_ELEMENT.GO, HEADER_ELEMENT.XLS, HEADER_ELEMENT.ADD_COLUMNS],
}
let profitStackSelectedEntities = [];

class Base extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.pageComponent = null;
        this.initialState = {
            drillVectorsOptions: null,
            drilling: false,
            drillRows: "",
            costHierarchy: [],
            toProfitStack: [],
            currency: process.env.REACT_APP_CLIENT_CURRENCY_UNIT,
            minRevenue: 0,
            minProfit: 0,
            refresh: false,
            addColumnsIsShowing: false,
            previousDataSet: "",
            version: "",
            drillFilter: "[]",
            isDrilling: sessionStorage.getItem(SELECTED_DRILL_LIST) !== null,
            cardsData: props?.history?.location?.state?.cardsData || [],
            current_saved_filter: null,
            current_saved_filter_id: getDefaultLocalStorageValueByParameter("saved_filter_id", 0),
            headerElements: [],
            goClicked:"",
            hasDefaultHeaderValues: true,
            FY: getDefaultCookieValue("fy", props?.location?.state ? props?.location?.state?.FY : FY_VALUES.M3),
            drillProfile: props?.history?.location?.state?.drillProfile,
            headerOptionChanged: false // to remove greyoverlay on back
        };

        //concat the parent state with Base's initial state and store resulting object in this.state
        this.state = Object.assign({}, this.state, this.initialState);

        // this.filterRef = React.createRef();
        
        this.logout = logout.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.go = this.go.bind(this);
        this.resizeHeader = this.resizeHeader.bind(this);
        this.isProfitMapEmpty = this.isProfitMapEmpty.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        // this.changeFormatCallback = this.changeFormatCallback.bind(this);
        this.filterChanged = this.filterChanged.bind(this);
        this.changeVectorCallback = this.changeVectorCallback.bind(this);
        this.saveChosenEntities = this.saveChosenEntities.bind(this);
        // this.getPeriodsObject = getPeriodsObject.bind(this);

        this.isMainReport = { isBase: true }
    }

    filterChanged(afterDrilling) {
        // if(this.state.profitFormat !== ALL_REPORTS.PROFIT_MAP && this.state.isDrilling) {
        //     this.pageComponent.submitDrill();
        // } else {
        this.go(afterDrilling);
        // }
    }

    userSettingsCallback() {
        if(!this.state.scenario){
            this.state.scenario = this.props.location.state ? this.props.location.state.scenario : this.state.scenario;
        }
        saveCookie('tablePrefix', this.state.tablePrefix);
        saveCookie('project_id', this.state.project_id);
    }

    /**
     * fetchListDataOnMount makes ProfitMapList fetch the data in didMount when
     * coming back from profitStack, otherwise go() is called and it should be false
     */
    fetchListsCallback(){
        let tempElements = reportsHeaderElements.profitMap;
        if([ALL_REPORTS.LIST_TOP, ALL_REPORTS.LIST_BOTTOM].includes(this.state.profitFormat)) {
            tempElements = reportsHeaderElements.list;
        } else if([ALL_REPORTS.LIST_QUADRANT, ALL_REPORTS.LIST_QUADRANT_TIER].includes(this.state.profitFormat)) {
            tempElements = reportsHeaderElements.quadrant_list;
        }

        this.setState({
            headerElements: tempElements
        },function(){
            if(!this.state.headerOptionChanged){
                this.go();
            }
        })
    }

    handleHeaderElementChangeCallback(elem, e) {
        switch(elem) {
            case HEADER_ELEMENT.VECTOR: {
                this.changeVectorCallback();
                break;
            }
            case HEADER_ELEMENT.SELECTION_RANGE:{
                hideProfitStackLink();
                this.headerRef.secondaryHeader.dropdownRef.current?.setDisabled(true);

                break;
            }
            // case HEADER_ELEMENT.FORMAT: {
            //     this.saveChosenEntities(true);
            // break;
            // }
        }
    }

    // override super callback
    onApplyManageColumnsProfileChanges(profile) {
        var _this = this;
      //  this.headerRef.resetLimit(function(){
            profitStackSelectedEntities = [];
            if (!this.state.isDrilling) {
                _this.fetchProfitInfo();
            }
            // _this.fetchProfitInfo();
     //   })
    }

    changeVectorCallback() {
        var tempState = {};
        
        tempState.drillVectorsOptions = this.getDrillVectors();
        hideProfitStackLink();
        this.headerRef.secondaryHeader.dropdownRef.current?.setDisabled(true);
        this.setState(tempState);
    }
    
    changeOrderCallback(order){
        this.saveChosenEntities(true)
        if(this.pageComponent) {
            this.pageComponent.setOrder(order);
        }
    }

    sendRoute(routePath) {
        this.setState({
            route: routePath,
        });

        cookie.save('report', routePath);
    }

    isProfitMapEmpty(data) {
        let quadrantIsEmpty = true;

        for (let element in data) {
            if (Object.keys(data[element].data).length) {
                quadrantIsEmpty = false;
                break;
            }
        }
        return quadrantIsEmpty;
    }

    fetchProfitInfo=(afterDrilling)=> {
        let _this = this;
        profitStackSelectedEntities=[];
        // hideProfitStackLink();
        if (!_this.props.userSettingsState.machine_name) {
            return;
        }
         if(_this.pageComponent){
            let pageComponent = _this.pageComponent;
            $("#after_drilling").text(afterDrilling)
             if(!_this.state.isDrilling) {
                 _this.pageComponent?.setTabulatorSortField(undefined);
             }
            // _this.pageComponent.setState({
            //     sorter: undefined,
            //     order: "desc"
            // }, () => {
                let obj = _this.pageComponent ? _this.pageComponent : pageComponent;
                obj.tabulatorList.current.tabulator.fromProfitInfo = true
                obj.tabulatorList.current.tabulator.setPageSize(100);
            // })
        }
    }

    /**
     * redirect the user to profit stacks: redirects to ExtendedProfitStacks if dropdownName = custom_view 
     * and to Stacks By Range if dropdownName = view_stack
     * @param {*} dropdownName 
     * @returns 
     */
    runExtendedProfitStack = (dropdownName) => {
        if (this.state.FY === FY_VALUES.ON && this.checkIfMonthsValid(this.state.dataSet) === false){
            var url = this.pageComponent.state.latestListParameters["url"];
            var urlParams = new URLSearchParams(url);
            this.resetFYOnInvalid(urlParams.get('quarter'),urlParams.get('fy'),urlParams.get('tier'));
            Popup.alert(MESSAGES.invalid_mo_selection);
            $(".chosenEntity").prop("checked",false);
            profitStackSelectedEntities = [];
            return;
        } 
        
        var profitStackItems = [];
        var isDrilling = this.state.isDrilling;
        const profitStackTier = this.state.vector;
        const profitStackDataSet = this.state.dataSet;
        const profitFormat = this.state.profitFormat;
        let drillProfile = {};
        if (isDrilling) {
            drillProfile = this.pageComponent.returnDrillColumnProfile();
        }
        var drilledPath = [];
        var entities = profitStackSelectedEntities;
        let nameIndex = Object.keys(entities[0]).indexOf(PSL_RETURN_NAMES.NAME);
        let numberIndex = Object.keys(entities[0]).indexOf(PSL_RETURN_NAMES.NUMBER);
        let quadrantIndex = Object.keys(entities[0]).indexOf(PSL_RETURN_NAMES.QUADRANT);
        let quadrantTierIndex = Object.keys(entities[0]).indexOf(PSL_RETURN_NAMES.QUADRANT_TIER);
        for(let i=0; i < entities.length; i++) {
            let entity = entities[i];
            var loopedItem = {};

            loopedItem.key = entity.key;
            loopedItem.number = entity.number;
            loopedItem.name = entity.name;
            loopedItem.lines = entity.lines;
            loopedItem.segment = nameIndex === -1 && numberIndex === -1 && quadrantTierIndex === - 1  && quadrantIndex > -1 ? entity.quadrant :
                 nameIndex === -1 && numberIndex === -1 && quadrantTierIndex > -1 ? entity.quadranttier : undefined;
            loopedItem.tier = getPSTier(profitStackTier, isDrilling);
            loopedItem.dataset = profitStackDataSet;
            loopedItem.profitFormat = profitFormat;
            loopedItem.color = COLORS_PALETTE[i]; // get the correspondant color based on entities order
            profitStackItems.push(loopedItem);
        }

        // get drilled parameters if drilling
        let cardsData = this.pageComponent.state.cardsData;
        for(let e in cardsData){
            let cardsDataDetails = cardsData[e]["data"][0];
            let cardKey = cardsDataDetails[PSL_RETURN_NAMES.KEY];
            let cardQT = cardsDataDetails[PSL_RETURN_NAMES.QUADRANT_TIER];
            let cardQuadrant = cardsDataDetails[PSL_RETURN_NAMES.QUADRANT];

            let tier = cardsDataDetails.tier;
            if (typeof tier !== typeof undefined && tier !== false) {
                if(cardKey) {
                    var number = cardsDataDetails.number;
                    var name = cardsDataDetails.name;
                    drilledPath.push(tier + ": " + (name ? name : "") + (name && number ?":" + number : number ? number : ""));
                } else if(cardQT) {
                    drilledPath.push(tier + ": " + new Segment().getSegmentObject(cardQT).label);
                } else {
                    drilledPath.push(tier + ": " + new Segment().getSegmentObject(cardQuadrant).label);
                }
            }
        }
        let isExtendedStack = dropdownName === HEADER_ELEMENT.CUSTOM_VIEW;
        let selectedStackVisibility = this.state.profitStackViews.find(e=>e.value === this.state.profitStackViewId).visibility;
        let access = checkIfSectionIdExists(this.state.userAllowedMenuLinks, 
            isExtendedStack? ALL_WIDGETS.FIELDS.PROFIT_STACK // extended
                : !selectedStackVisibility? MENU_ITEM.FIELDS.MANAGE_STACKS // for default profit stack
                    : selectedStackVisibility === COLUMN_PROFILE.VISIBILITY_OPTIONS.USER ? MENU_ITEM.FIELDS.MANAGE_STACKS : MENU_ITEM.FIELDS.VIEW_COMPANY_STACKS); // user or company stacks
        
        if (!access) {
            alert("No access");
            return;
        }

        if (profitStackSelectedEntities.length === 0 || profitStackSelectedEntities.length > 10) {
            Popup.alert("Make sure to select at least one item but not more than 10!");
        } else {
            sessionStorage.setItem("selectedProfile_list", JSON.stringify(this.state.manageColumnsProfile)); // save the selected list for back navigation
            if(isDrilling) {
                sessionStorage.setItem(SELECTED_DRILL_LIST, JSON.stringify(drillProfile)); // save the selected list for back navigation
            }

            let startDate = this.getValidPeriods().startDate;
            let endDate = this.getValidPeriods().endDate;
            var pathName = isExtendedStack ? "/ProfitLandscape/profitMap/profitStack/" + access[SECTION.ID] + "/" + this.state.custom_stack_id : MENU_ITEM_URLS.LIST_ENTITY_STACKS;
            this.props.history.push({
                pathname: pathName,
                search: "?",
                hash: "",
                state: {
                    origin: window.location.href,
                    origin_name: this.state.profitFormat,
                    profitFormat: this.state.profitFormat,
                    profitStackItems: profitStackItems,
                    drilledPath: drilledPath,
                    drillFilter: this.state.drillFilter,
                    drilling: isDrilling,
                    useFilterCookies: false,
                    savedFilterDataState: this.headerRef.filterDialRef.state.savedFilterData,
                    FY: this.state.FY,
                    scenarios: this.state.scenarios,
                    originalScenarios: this.state.scenarios,
                    dataset: this.state.dataSet,
                    mainVector: this.state.vectors[0], // main vector - from vector dropdown
                    vectorOptions: this.state.vectorOptions,
                    filter: this.state.filterFinal,
                    mainFilter: this.state.filterFinal,
                    outsideFilter: JSON.stringify({ filter: JSON.parse(this.state.filterFinal) }),
                    mainFilterId: this.headerRef.filterDialRef.state.savedFilterData.saved_filter_id,
                    order: this.props.location.state ? this.props.location.state.order : undefined,
                    startDate: startDate,
                    endDate: endDate,
                    profitStackViewId: this.state.profitStackViewId,
                    headerOptionChanged: false,
                    datasetOptions: this.state.datasetOptions,
                    periods: this.state.periods,
                    isRedirectionFromStacks: true, // if it is a redirection, we won't remove the selected profile from session
                    cardsData: cardsData,
                    drillRows: this.state.drillRows,
                    drillProfile: drillProfile,
                    submitDrill: this.pageComponent.state.submitDrill,
                    mainFilterFinalBasic: this.state.filterFinalBasic,
                    manageColumnsProfile: this.state.manageColumnsProfile
                }
            });
        }
    }


    getPeriodsObject =()=> {
        const _this = this;
        let periods = [];
        let periods_yoy = [];
        let periods_pa = [];
        let quarter = "";
        let quarterPre = "";
        let months = FY_VALUES.M3;
        let startDate = getValidPeriods(this.props.periodsStatusState, this.props.clientPeriodsState).startDate;
        let endDate = getValidPeriods(this.props.periodsStatusState, this.props.clientPeriodsState).endDate;
      
        if (isValidDate(startDate) && isValidDate(endDate) && startDate  && endDate) {
            let periodsCount = monthDiff(startDate, endDate) + 1;
            periods = generatePeriods(startDate, periodsCount);
            periods_yoy =  generatePeriods(startDate, periodsCount,false);
            periods_pa = generatePeriods(startDate, periodsCount,true);
        }
    
        if (!!_this.props.clientPeriodsState.periods && isValidDate(startDate) && isValidDate(endDate)) {
            let firstQuarter = getQuarterFromDate(startDate);
            let endQuarter = getQuarterFromDate(endDate);
    
            let firstPreQuarter = getPeriodQuarter(periods_pa[0]);
            let endPreQuarter = getPeriodQuarter(periods_pa[periods_pa.length-1]);
    
            let generatedRange = getGeneratedQuarterRange(this.props.datasetState.datasetOptions, firstQuarter, endQuarter);
            let generatedRangePre = getGeneratedQuarterRange(this.props.datasetState.datasetOptions, firstPreQuarter, endPreQuarter);
    
            let fullQuarter = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRange[0], generatedRange[1]));
            let fullQuarterPre = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRangePre[0], generatedRangePre[1]));
    
            quarter = fullQuarter.quarter;
            quarterPre = fullQuarterPre.quarter;
            months = fullQuarter.months;
        }
        return {periods: periods, quarter: quarter, months: months, periods_yoy: periods_yoy, periods_pa: periods_pa, preQuarter: quarterPre};
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate() {
        var ua = window.navigator.userAgent;
        if (ua.match(/Edg/) || ua.match(/Trident.*rv\:11\./)) {
            removeCursor('select-set');
            removeCursor('select-set-tier');
            removeCursor('formatSelect');
        }
    }

    componentDidMount() {
        if(this?.props?.history?.location?.state?.isRedirectionFromStacks || this?.props?.history?.location?.state?.callOnMount){
            if(this.props.history.location.state.headerOptionChanged === false){
                this.props.setHeaderChanged(false);
                this.props.setShowGreyOverLay(false);
                this.go();
            }
            this.props.history.push({
                state: {
                  isRedirectionFromStacks: false,
                  callOnMount: false,
                  scenarioState: this.props.history.location?.state?.scenarioState,
                  outsideFilter:this.props.history.location?.state?.outsideFilter,
                  mainFilterFinalBasic:this.props.history.location?.state?.mainFilterFinalBasic,
                  useFilterCookies: this.props.history.location?.state?.useFilterCookies,
                }
            });
        } else {
          this.props.dispatch(updateListObjBeforeBack([], "listObjBeforeBack", true));
					this.props.dispatch(updateListData([], "listData", true));
					this.props.dispatch(updateDrillListData([], "drillListData", true));

          sessionStorage.removeItem("selectedProfile_list"); //Remove from session on refresh
          sessionStorage.removeItem(SELECTED_DRILL_LIST); //Remove from session on refresh
        }
    }

    handleSubmit(e) {
        var refreshValue = this.state.refresh ? false : true;
        this.setState({refresh: refreshValue});

        e.preventDefault();
    }

    go=(afterDrilling)=> {
        var _this = this;
        // if(this.state.isDrilling) {
        //     this.pageComponent.setState({submitDrill: undefined}) // when we exit drill screen, set submitDrill to undefined
        // }
        this.setIsDrilling(sessionStorage.getItem(SELECTED_DRILL_LIST) !== null); // to set isDrilling to false when refetching data
        profitStackSelectedEntities = [];
        if(this.state.profitFormat === ALL_REPORTS.PROFIT_MAP && this.profitSummary){
             if (this.state.FY === FY_VALUES.ON && this.checkIfMonthsValid(this.state.dataSet) === false){
                var args =  this.profitSummary.state.latestProfitMapParameters["query"];
                this.resetFYOnInvalid( args["quarter"],args["FY"],args["vector"]);
                Popup.alert(MESSAGES.invalid_mo_selection);
                return;
            }
            this.profitSummary.refreshStacks();
        } else {
            if (this.state.FY === FY_VALUES.ON && this.checkIfMonthsValid(this.state.dataSet) === false){
                var url = this.pageComponent &&  this.pageComponent.state && this.pageComponent.state.latestListParameters ? this.pageComponent.state.latestListParameters["url"] : "";
                var urlParams = new URLSearchParams(url);
                this.resetFYOnInvalid( urlParams.get('quarter'),urlParams.get('fy'),urlParams.get('tier'));
                Popup.alert(MESSAGES.invalid_mo_selection);
                return;
            }
            let tempElements = this.state.headerElements;
            let manageColsIndex = tempElements.indexOf(HEADER_ELEMENT.ADD_COLUMNS);
            if(manageColsIndex === -1) {
                tempElements.push(HEADER_ELEMENT.ADD_COLUMNS);
            }
                
            this.setState({
                refresh: Date.now().valueOf(),
                headerElements: tempElements,
                drillFilter: afterDrilling?_this.state.drillFilter:"[]"
            }, function () {
                setDrillRowsLimit(this.state.records_limit);
                _this.uncheckPSEntities();
                if (!sessionStorage.getItem(SELECTED_DRILL_LIST)) {
                    this.props.toggleDropDownState(true);
                    _this.fetchProfitInfo(afterDrilling);
                } else {
                    _this.pageComponent.setDrillTabulatorPageSize()
                }
            });
        }
    }

    //@override
    setIsDrilling=(isDrilling)=> {
        let _this = this;

        _this.setState({
            isDrilling: isDrilling,
        },()=>{
            _this.props.setIsDrilling(isDrilling);
        });
    }

    updateDrillprofile = (profile) => {
        let _this = this;
        _this.pageComponent.updateDrillprofile(profile);
    }

    //@override
    onBackNavigation = () => {
        let _this = this;
        _this.saveChosenEntities(true);// clear profitStackSelectedEntities
        _this.setIsDrilling(false);
        _this.pageComponent.setState({
            submitDrill: undefined,
            drillColumns: undefined,
            sorter: "",
            cardsData: [],
            drillCardWidths: undefined,
            drillListWidths: undefined,
    }) // emptying sorter, drillColumn and submitDrill flag so they won't affect the request sent in profit list when we click on back 
        _this.setState({
            drillProfile: undefined, // remove drillProfile when exiting drill
            drillFilter: []
        });
        setDrillRowsLimit(_this.state.records_limit);
        sessionStorage.removeItem(SELECTED_DRILL_LIST); //Remove when changing profile
        $('#To_Scroll_Top').hide();
        $('#To_Scroll_List').show();
        $('#To_Scroll_List_Drill').hide();
        $('#DrillDown').hide();
        _this.pageComponent.tabulatorList.current.tabulator.clearFilter(true); //clears columns filter
        _this.props.history.location.state.submitDrill = undefined;
        _this.go(false);
        _this.props.reselectProfile();
    }

    resizeHeader() {
        if ($("#TopBottomContainer").length) {
            var containerTop = $("#filter-control-container").position().top;
            var containerHeight = $("#filter-control-container").height();
            var tbContainerTop = $("#TopBottomContainer").position().top;
            var tbContainerHeight = $("#TopBottomContainer").height();

            var tbMarginTop = 4;
            var headerHeight = containerTop + containerHeight + tbContainerHeight + tbMarginTop;

            if (containerTop + containerHeight <= tbContainerTop) {
                $('.header').css("height", convertPxToViewport(headerHeight));
                $('#TopBottomContainer').css("margin-top", convertPxToViewport(tbMarginTop));

                $('table').css("margin-top", convertPxToViewport(headerHeight + tbMarginTop));
            }
        }
    }

    handleInputChange() {
        var element = $("div");
        element.scrollTop("-100");
    }

    /**
     * this function is called after drilling, to refresh the datalenght in the confirmPasswordModel componenet
     */
    refreshDataLenght=(length)=>{
        let _this = this;
        if(_this.headerRef?.confirmPassRef) {
            _this.headerRef.confirmPassRef.setState({
                dataLength: length
            })
        }
    }


    uncheckPSEntities=()=> {
        let _this = this;
        profitStackSelectedEntities = [];
        _this.props.saveChosenEntities([]);
        _this?.pageComponent?.tabulatorList?.current?.addFooterText(_this?.pageComponent?.tabulatorList?.current?.tabulator?.getData(), []);
    }

    saveChosenEntities=(clearFlag, cell, isChecked)=> {
        let _this = this;
        if (clearFlag) {
            profitStackSelectedEntities = [];
            hideProfitStackLink();
            this.props.toggleDropDownState(true);

            return;
        }

        var entities = profitStackSelectedEntities;
        var currEntity = cell.getRow().getData();
        let ents = copyObjectValues(entities);
        let matchingEntity = returnObjectsInArray(ents, currEntity)
        if (!!matchingEntity) {
            entities.splice(matchingEntity.index, 1);
        }

        if (isChecked) {
            showProfitStackLink();
            this.props.toggleDropDownState(false);
            delete currEntity.checked;
            entities.push(currEntity);
        } else {
            if (!entities.length > 0) {
                hideProfitStackLink();
                this.props.toggleDropDownState(false);

            }
        }

        profitStackSelectedEntities = entities;
        _this.props.saveChosenEntities(entities);
        _this?.pageComponent?.tabulatorList?.current?.addFooterText(_this?.pageComponent?.tabulatorList?.current?.tabulator?.getData(),undefined, entities);
        _this?.pageComponent?.tabulatorDrill?.current?.addFooterText(_this?.pageComponent?.tabulatorDrill?.current?.tabulator?.getData(),undefined, entities);
    }

    saveDrillFilter = (filter, df) => {
        let _this = this;
        _this.props.saveDrillFilter(filter, df);
        _this.setState({
            drillFilter: filter
        },()=> {
        });
    }

    /** removed because it wasn't updating props.listData in ProfitMapList */
    // shouldComponentUpdate(nextProps, nextState) {
    //     if (JSON.stringify(this.props) !== JSON.stringify(nextProps) || JSON.stringify(nextState) !== JSON.stringify(this.state) 
    //       || (this.props.listData && this.props.listData.get("listData").length > 0)
    //       || (this.state.isDrilling && (this.props.drillListData instanceof Map) && this.props.drillListData.get("drillListData")?.length > 0)) {
    //         return true;
    //     }
    //     return false;
    // }

    render() {
        this.resizeHeader();
        $("#select-set-tier").attr("autocomplete", false);
        var comp = "";
        var _this = this;
        $("#select-set").val(this.props.datasetState.dataSet);
        $("#Profit_Summary_Go").off("click");
        $("#Profit_Summary_Go").on("click", function(){
            _this.setState({
                goClicked: true
            });
        });
        $("#drillButton").off("click");
        $("#drillButton").on("click", function(){
            profitStackSelectedEntities = [];
        });
        let periodsObject = this.getPeriodsObject();
        let periods = periodsObject.periods;
        let quarter = periodsObject.quarter;
        let months = periodsObject.months;
        let lastSelectedPeriod = periods[periods.length - 1];
        let builtPeriods = _this.props.periodsStatusState.actuallyBuiltPeriods?.map(m => m.label);
        let rollingPeriod = getLastBuiltPeriodForSegments(builtPeriods, lastSelectedPeriod, 12); 
        let startPeriod = getPeriodFromDate(new Date(this.props.periodsStatusState.customStartDate));
        let endPeriod = getPeriodFromDate(new Date(this.props.periodsStatusState.customEndDate));

        comp = <ProfitMapList ref={r => this.pageComponent = r} key={"list-report"}
            filter={this.props.filterFinal} //headerRef={this.headerRef}
            saveDrillFilter={this.saveDrillFilter}
            setSelectedEntities={this.props.setSelectedEntities}
            profitStackSelectedEntities={profitStackSelectedEntities}
            saveChosenEntities={this.saveChosenEntities} refresh={this.state.refresh}
            user={this.props.userSettingsState.user}
            isQuadrant={this.state.profitFormat === ALL_REPORTS.LIST_QUADRANT || this.state.profitFormat === ALL_REPORTS.LIST_QUADRANT_TIER ? true : false}
            dataset={quarter} FY={months} 
            dataTier={this.state.isDrilling  && this.props?.history?.location?.state?.drillTier ? this.props.history.location.state.drillTier : this.props.vectorState.vectors[0]}
            currency={this.state.currency}
            refreshDataLenght={this.refreshDataLenght}
            options={this.props.vectorState.vectorOptions}
            drillVectorsOptions={this.state.drillVectorsOptions}
            profitFormat={this.props.profitFormat}
            manageColumnsProfile={this.props.manageColumnsProfile}
            manageColumnsProfileFromBack={this.props?.history?.location?.state?.manageColumnsProfile}
            manageColumnsDefaultProfile = {this.props.manageColumnsDefaultProfile}
            setIsDrilling={this.setIsDrilling}
            isDrilling={this.state.isDrilling}
            scenario_id={this.props.scenarioState.scenario}
            scenarioNumber={this.props.scenarioState.scenarioObjects[0].scenario_number}
            scenarioObject={this.props.scenarioState.scenarioObjects[0]}
            setDataLength={this.props.setDataLength}
            fetchDataOnMount={false}
            resetFYOnInvalid={this.resetFYOnInvalid}
            checkIfMonthsValid={this.checkIfMonthsValid}
            machine_name={this.props.userSettingsState.machine_name}
            drillFilter={this.state.drillFilter}
            setLimit={this.setLimit}
            order={this?.props?.location?.state ? this?.props?.location?.state?.order : undefined}
            exportQueryFilter={this.props.exportQueryFilter}
            checkForLimitAccessMessage={this.props.checkForLimitAccessMessage}
            parentComp={this}
            selectedPeriods={periods}
            records_limit = {this.props.userSettingsState.records_limit}
            manageColsAccess={this.props.manageColsAccess}
            vectorObjects={this.props.vectorState.vectorObjects}
            updateManageColumnsProfile = {this.props.updateManageColumnsProfile}
            uncheckPSEntities={this.uncheckPSEntities}
            cardsData={this.state.cardsData}
            drillRows={this.state.drillRows}
            isRedirectionFromStacks={this.props?.history?.location?.state && this?.props?.history?.location?.state?.isRedirectionFromStacks}
            drillProfile={this.state.drillProfile}
            manageColumnsSelectionLimit={this.props.userSettingsState.manageColumnsSelectionLimit}
            dataChunkLimit={this.props.userSettingsState.dataChunkLimit}
            selectedRange={startPeriod + (startPeriod !== endPeriod ? " to " + endPeriod : "")}
            rollingPeriod={rollingPeriod}
            getExportQueryFilter={this.props.exportQueryFilter()}
            toggleDropDownState={this.props.toggleDropDownState}
            setExportOptionsForUser={this.props.setExportOptionsForUser}
            displayDefaultColumns={this.props?.displayDefaultColumns}
            listData={this.props.listData}
            drillListData={this.props.drillListData}
            dispatch={this.props.dispatch}
            updateColumnsOrder={_this.props.updateColumnsOrder}
            manageColsRef={_this.props.manageColsRef}
            vectorsSelectionLimit={Number(this.props.userSettingsState.vectorSelectionLimit.trim())}
            selectedDropdownVector = {this.props.vectorState.nextVectors[0]}
            manageColumnsDrillRef={this.props.manageColumnsDrillRef}
            drillSelectionLimit={this.props?.drillSelectionLimit}
            exitDrill={this.props.exitDrill}
            hasDrillOption={this.props.hasDrillOption}
            useNewReorderList={this.props.useNewReorderList}
            useAppearanceList={this.props.userSettingsState.useAppearanceList}
            saveDrillVectorType={this.props.saveDrillVectorType}
            exportScopeFilter={this.props.exportScopeFilter}
        />
        return ( comp );
    }
}

function mapStateToProps(state) {
    return {
        tiers: state.tiers,
        customViewData: state.customViewData,
        profileColumns:state.profileColumns,
        profitFormats:state.profitFormats,
        // drillListData: state.drillListData,
        // listData: state.listData,
        listObjBeforeBack: state.listObjBeforeBack,
    };
}
export default connect(mapStateToProps,null,null,{forwardRef: true})(Base); 