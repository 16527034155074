import { Button, Icon, Popover, Typography } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { BUTTON_DROPDOWN_VARIANT, BUTTON_VARIANT, SIZES } from "../class/constants";
import "../styles/filterBy.css";
import { ReactComponent as MultipleFilters } from "../styles/images/icons/multiplefilters.svg";
import { lang } from "../language/messages_en";
import SearchableDropdown from "./SearchableDropdown";
import ButtonDropdown from "../newComponents/ButtonDropdown";
import { convertPxToViewport } from "../class/formatting";

// TODO: to be removed when we remove useNewQuickFilters flag

const FilterByDropdown = forwardRef(({ sectionsData, handleCheckboxChange, clearClick, isClearBtnDisabled, basicFilterCountLimit, switchBasicToAdvanced, disabled },ref) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [number, setNumber] = useState(0);
  const [limitReached, setLimitReached] = useState(false);
  const open = Boolean(anchorEl);

  useImperativeHandle(ref, () => ({
    handleClose:()=>{
        return handleClose()
    }
  }));

  /**
   * Opens filter dialog
   * @param {*} event
   */
  const handleClick = (event) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
      $("#button-drop-filter-by-btn").attr('aria-expanded', String(true));
    } else {
      handleClose();
    }
  };

  /**
   * Closes filter dialog
   */
  const handleClose = () => {
    setAnchorEl(null);
    $("#button-drop-filter-by-btn").attr('aria-expanded', String(false));
  };

  /**
   * On checkbox change function
   * @param {*} item 
   */
  const handleCheckboxClick = (item) => {
    if (handleCheckboxChange) {
      handleCheckboxChange(item);
    }

    handleClose();
  };

  /**
   * On clicking on Clear buttom
   */
  const onClearClick = () => {
    if(clearClick) {
      clearClick();
    }

    handleClose();
  }
  
  useEffect(() => {
    closePopover();
  }, [anchorEl]);

  useEffect(()=>{
    setLimitReached(false);
    for(let e in sectionsData){
      if (sectionsData[e].sectionTitle === lang.all) {
        setNumber(sectionsData[e]?.itemsList?.filter(f=>f.checked).length);
        if(sectionsData[e]?.itemsList?.filter(f=>f.checked).length === basicFilterCountLimit){
          setLimitReached(true)
        }
      }
    }

  },[sectionsData])

  const closePopover = () => {
    $(document).on("mouseover click", function (event) {
      if (
        $("#filter-by-popover:hover").length === 0 &&
        $("#button-drop-filter-by-btn:hover").length === 0
      ) {
        if (event.type === "click") {
          handleClose();
        }
      }
    });
  };

  return (
    <>
      {/* <Button
        id="filter-by-btn"
        onClick={handleClick}
        className={number ? "filter-basic-selected" : "filter-by-button"}
        variant="contained"
        disableElevation
        disableRipple
      >
        <Icon className={"far fa-filter uk-margin-small-right"} sx={{fontSize: "1.3rem"}} />
        {lang.basic_filter.filter_by}
        {number?
          <Typography className="number-selection">{number}</Typography>
        :""}
        <Icon className={"far fa-chevron-down uk-margin-small-left"} />
      </Button> */}
      <ButtonDropdown  
        id="filter-by-btn"
        placeholder={lang.basic_filter.filter_by}
        className="heatmap-configure-btn" 
        onBtnClick={handleClick}
        appliedNumber={number}
        size={SIZES.DEFAULT}
        variant={BUTTON_DROPDOWN_VARIANT.BUTTON}
        placeholderIcon={<i className={"far fa-filter"} style={{fontSize: convertPxToViewport(16)}} aria-hidden="true" />}
        dropId={"filter-by-popover"}
        disabled={disabled}
      />
      <Popover
        id="filter-by-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        TransitionProps={{ timeout: 0 }} // remove transition animation
        PaperProps={{sx: {minWidth: "18.23vw", top: "12vh", position: "fixed"}}}
        classes={{ paper: "popover-filterby", root:"popover-root-filterby" }}
        disableScrollLock // disable putting overflox hidden on body
      >
        <SearchableDropdown
          sectionsData={sectionsData}
          handleCheckboxChange={handleCheckboxClick}
          headerInfoText={!number ? lang.filters_not_selected.replace("[X]", basicFilterCountLimit) : lang.filters_selected.replace("[X]", + number + "/" + basicFilterCountLimit)}
          headerInfoTextSize={"0.65vw"}
          searchCriterias={["label"]}
          clearClick={onClearClick}
          isClearBtnDisabled={isClearBtnDisabled}
          isFilterBy = {true}
          basicFilterCountLimit={basicFilterCountLimit} 
          limitReached={limitReached}
          primaryButtonProps={{
            className: "mui-secondary-button",
            icon: <MultipleFilters className="uk-margin-small-right" />,
            text: lang.modal.buttons.advanced_filter,
            variant: BUTTON_VARIANT.TERTIARY,
            clickEvent: switchBasicToAdvanced,
            ukToggle: "target: #filterModal",
            id: "advanced-filter-button"
          }}
        />
      </Popover>
    </>
  );
});

export default FilterByDropdown;
