import React from "react";
import Popup from "react-popup";
import { Component } from "react";

import Feature from "../../components/manageAccess/Feature";
import ProfitStackAccess from "../../components/manageAccess/ProfitStackAccess";
import VectorAccess from "../../components/manageAccess/VectorAccess";
import RoleAccess from "../../components/RoleAccess";
import { Tabs } from "../../form/elements.js";
import Button from "../../newComponents/Button";
import ButtonDropdown from "../../newComponents/ButtonDropdown";
import Input from "../../newComponents/Input";
import ManageAccessTabulator from "../../tables/ManageAccessTabulator";
import FilterDialog from "../filter/FilterDialog";
import Backdrop from "../../components/sidePanelNew/Backdrop";
import SidePanelNew from "../../components/sidePanelNew/SidePanelNew";
import { FormComponent } from "../../form/FormElements";
import DropDown from "../../newComponents/DropDown";
import Modal from "../../newComponents/Modal";
import SwitchToggle from "../../newComponents/SwitchToggle";

import { getObjectValues } from "../../class/array";
import { getLocalStorageValueByParameter, setLocalStorageValueByParameter, toggleLoader } from "../../class/common";
import {
  API_URL,
  BUTTON_DROPDOWN_VARIANT,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  CLIENT_ID_STORAGE,
  DATATYPE_ENUM,
  DIALOG_SIZE,
  DROPDOWN_TYPE,
  ENGINE_FILTER,
  FILTER,
  MANAGE_ACCESS,
  ROLES_INPUTS,
  ROLE_ACCESS,
  SIZES,
  VECTOR_STAGING_ATTRIBUTES, SCOPE_FILTERS
} from "../../class/constants";
import { convertPxToViewport } from "../../class/formatting";
import { alertAndLogError, isValidEmailAddress, isValidName } from "../../class/jqueries";
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI, logUITracking } from "../../class/networkUtils";
import { findOptionByKey, tryParse, updateSelected } from "../../class/utils";

import "../../styles/manageAccess.css";
import "../../styles/manageColumns.css";

import { lang } from "../../language/messages_en";


const $ = require("jquery");
var Inflector = require("inflected");


const _vector = "vector";
const _vectorLabel = "vectorLabel";
const _add = "Add ";
const _edit = "Edit ";
const _fieldDataType = ENGINE_FILTER.KEYS.FIELD_DATA_TYPE;
const _function = ENGINE_FILTER.KEYS.FUNCTION;
const _field = ENGINE_FILTER.KEYS.FIELD;
const _values = FILTER.KEYS.ENTITIES;
const _filterRowType = FILTER.KEYS.FILTER_ROW_TYPE;
const alpha = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
const numbers = "0123456789";
const symbols = "!@#$%^&*_-+=";

// * @author [Rafah Cheaib]
class ManageAccess extends Component {
  constructor(props) {
    super(props);
    var reportTitle = lang.manage_access.manage_access;
    this.initialState = {
      showScenariosTable: false,
      tabLabels: lang.manage_access.tab_labels,
      accessGroupTabLabels: lang.manage_access.access_group_tab_labels,
      tabSelected: lang.manage_access.tab_labels[0],
      accessGroupTab: lang.manage_access.access_group_tab_labels[0],
      columns: [],
      zIndex: 1,
      usersClassName: "fal fa-eye fa-lg",
      counter: 0,
      dataNum: 0,
      expandButtonLabel: lang.manage_access.add_user,
      inactiveButtonLabel: lang.manage_access.show_inactive + " " + lang.manage_access.tab_labels[0],
      usersVisibity: lang.manage_access.users_visibility,
      inactiveUsers: lang.manage_access.inactive_users,
      blockedUsers: lang.manage_access.blocked_users,
      systemUsers: lang.manage_access.system_users,
      systemUsersChecked: false,
      inactiveUsersChecked: false,
      blockedUsersChecked: false,
      data: [],
      activeUsers: [],
      activeRoles: [],
      activeAccessGroups: [],
      showBanner: false,
      filteredText: "",
      roleData: [],
      selectedOption: "",
      alwaysAllowPSS: false,
      always_allow_customer: false,
      always_allow_product: false,
      always_allow_operations: false,
      hideInactiveRoles: true,
      hideInactiveAccessGroups: true,
      email: "",
      first_name: "",
      last_name: "",
      mobile: "",
      password: "",
      hidden: true,
      confirm_reset: [],
      confirm_deactivate: [],
      confirm_block: [],
      confirm: "",
      notifyUser: true,
      displayPanel: false,
      limitExceeded: false,
      drawerOpen: false,
    };
    this.accessGroup = { [MANAGE_ACCESS.FIELDS.MANAGE_ACCESS]: "", [MANAGE_ACCESS.FIELDS.DESCRIPTION]: "" };
    this.role = { [MANAGE_ACCESS.FIELDS.ROLE]: "", [MANAGE_ACCESS.FIELDS.DESCRIPTION]: "" };
    this.state = Object.assign({}, this.state, this.initialState);
    this.isMainReport = { isManageAccess: true };
    this.fetchData = this.fetchData.bind(this);
    this.changeColumns = this.changeColumns.bind(this);
    this.onToggleBoard = this.onToggleBoard.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.fillUsersInputs = this.fillUsersInputs.bind(this);
    this.fillRolesInputs = this.fillRolesInputs.bind(this);
    this.fillAccessGroupInputs = this.fillAccessGroupInputs.bind(this);
    this.showHide = this.showHide.bind(this);
    this.showHideInactiveData = this.showHideInactiveData.bind(this);
    this.countActiveUsers = this.countActiveUsers.bind(this);
    this.countActiveRoles = this.countActiveRoles.bind(this);
    this.deactivate = this.deactivate.bind(this);
    this.launchToast = this.launchToast.bind(this);
    this.resetPasswordUser = this.resetPasswordUser.bind(this);
    this.showHideBanner = this.showHideBanner.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.saveAccessGroup = this.saveAccessGroup.bind(this);
    this.updateAccessTabsData = this.updateAccessTabsData.bind(this);
    this.updateToggleState = this.updateToggleState.bind(this);
    this.updateUserFields = this.updateUserFields.bind(this);
    this.getAllowedDomains = this.getAllowedDomains.bind(this);
    this.executeAddUser = this.executeAddUser.bind(this);
    this.edit = this.edit.bind(this);
    this.inactiveUsersData = this.inactiveUsersData.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.confirmResetPassword = this.confirmResetPassword.bind(this);
    this.confirmDeactivate = this.confirmDeactivate.bind(this);
    this.setFilterRef = this.setFilterRef.bind(this);
    this.fetchAPI = fetchAPI.bind(this);

  }

  componentDidMount() {
    $(document).on("click", "#overlay-manage-columns", this.cancel);
    this.fetchData();
    this.getAllowedDomains();
  }


  getAllowedDomains() {
    let _this = this;
    var query = {
      action: "getAllowedDomains",
      url: window.location.href, // send clientId
      domainUrl: window.location.href,
      clientId: getLocalStorageValueByParameter(CLIENT_ID_STORAGE),
    };

    const path = "/UserSettings";
    let onThenCallback = (data) => {
      var domains = [];
      data.map(function (item, key) {
        domains.push({ value: item, label: item });
      });
      this.setState({
        domains: domains,
      });
    };
    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "getAllowedDomains",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: false,
      [FETCHAPI_PARAMS.path]: path,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_access.screen_name,
			[FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_access.request_description.get_allowed_domains,
    };
    _this.fetchAPI(fetchOptions);
  }

  generatePassword = (length, characters) => {
    let password = "";
    for (let i = 0; i < length; i++) {
      password += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return password;
  };

  createNewUser(email, firstName, lastName, mobile, roles) {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const path = "/ManageAccess";
    var _this = this;
    var uid = this.state.uid;
    if(uid && uid === Number(this.props.userSettingsState.userId)){
      sessionStorage.setItem(SCOPE_FILTERS, JSON.stringify(this.state.scopeFilter))
    }
    var query = {
      action: "save",
      addUserToFirebase: this.state.emailExists?.code === -2, //if =-2, then it is completely new
      firstName: firstName,
      lastName: lastName,
      email: email,
      user_password: this.generatePassword(8, alpha + symbols + numbers),
      mobile_number: mobile,
      domainUrl: window.location.href,
      roles: JSON.stringify(roles),
      id: uid ? uid : "",
      limit_access:
        !this.state.filter || this.state.filter.length === 0 ? "" : '{"filter":' + JSON.stringify(this.state.filter) + "}",
      notifyUser: this.state.notifyUser,
      isClientSSO: this.state.isClientSSO,
      scopeFilter: this.state.scopeFilter,
      isDeleteScope: this.state.scopeFilter.length === 0,
    };
    this.drawerToggleClickHandler();
    let onThenCallback = (data) => {
      if (data) {
        if(!data.newUserAdded){
          _this.setState(
              {
                message: lang.manage_access.user.error_occured,
                toastIsError: true
              })
        }else{
          _this.setState(
              {
                userData: data.user_data,
                message: uid ? lang.manage_access.user.user_edited : lang.manage_access.user.user_created,
                counter: uid ? _this.state.counter : _this.state.counter + 1,
                toastIsError: false
              },
              function () {
                _this.changeTab(lang.manage_access.access_group_tab_labels[0], true);
                _this.inactiveUsersData(_this.state.inactiveUsersChecked, undefined, true);

                if (_this.state.tabSelected === MANAGE_ACCESS.ACCESS_GROUP_SECTION)
                  _this.showHide(_this.state.hideInactiveAccessGroups, true);
                if (_this.state.tabSelected === MANAGE_ACCESS.ROLE) _this.showHide(_this.state.hideInactiveRoles, true);
                _this.forceUpdate();
                _this.fetchData(false);
                _this.countActiveUsers();
              }
          );
        }
        setTimeout(function () {
          _this.launchToast();
        }, 0.1);
      }
    };
    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "save",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: false,
      [FETCHAPI_PARAMS.path]: path,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_access.screen_name,
			[FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_access.request_description.save_user,
    };
    _this.fetchAPI(fetchOptions);
  }

  applyScope=(filter)=> {
    var title = [];
    var newFilter = JSON.parse(filter);
    for (var e in newFilter) {
      var values = [];
      typeof newFilter[e][_values] === "object"
          ? newFilter[e][_values].map(function (item) {
            values.push(item.value);
          })
          : values.push(newFilter[e][_values]);
      title.push(newFilter[e][_vectorLabel] + " " + newFilter[e][_field] + " " + newFilter[e][_function] + " " + values.join(", "));
      newFilter[e][_filterRowType] = FILTER.VALUES.FILTER_ROW_TYPE.SCOPE;
    }
    this.setState({
      scopeTitle: filter.length === 0 ? lang.manage_access.define : title.join(", "),
      scopeFilter: newFilter,
    });
  }

  validateFields(formObject, validateEmail, validateNames) {
    var valid = 1;
    var array = getObjectValues(formObject);

    for (var field in array) {
      if (array[field] === "" || !array[field] || !array[field].length) {
        valid = -1;
        break;
      }
    }

    if (validateNames && formObject.firstName) {
      if (!isValidName(formObject.firstName)) {
        valid = -5;
      }
    }

    if (validateNames && formObject.lastName) {
      if (!isValidName(formObject.lastName)) {
        valid = -6;
      }
    }

    if (validateEmail === true && valid === 1) {
      if (!isValidEmailAddress(formObject.email)) {
        valid = -2;
      }
    }

    // if(validateMobileNumber === true && valid === 1){
    //     if(!isValidMobileNumber(formObject.mobile)) {
    //         valid = -3;
    //     }
    // }

    // if(formObject.password) {
    //     if(!isValidPassword(formObject.password)) {
    //         valid = -4;
    //     }
    // }
    return valid;
  }

  validateField(callback) {
    if (typeof callback === "function") {
      callback();
    }
  }

  executeAddUser() {
    var firstName = this.state.first_name;
    var lastName = this.state.last_name;
    var emailAdded = this.state.email + "@" + this.state.domain.value;
    var password = this.state.password;
    var mobile = this.state.mobile_number;
    var roles = this.state.roles;

    var isValid = "";
    //always validate first and last name, and email
    var toValidateObj = {
      firstName: firstName,
      lastName: lastName,
      email: emailAdded,
      roles: roles,
    };
    var validateEmail = true;
    var validateMobile = false;
    var validateNames = true;

    //if MFA is active or user entered a mobile #, always validate mobile regardless if user is new or not, or create or edit
    // if(mobile !== ""){
    //     toValidateObj.mobile = mobile;
    //     validateMobile = true;
    // }
    // if(!this.state.uid){
    //     toValidateObj.password = password;
    // }

    //only if the user is being created, not edited
    //validate password only if email is completely new to all environments
    // if(this.state.userData && this.state.userData.code !== -2){
    //     delete toValidateObj.password;
    // }
    if (this.state.emailExists && this.state.emailExists.code === 0) {
      this.setErrorDialogOpen(true, lang.manage_access.user.user_validations.existing_email);
      return;
    }
    this.emptyValid = undefined;
    this.isEmailValid = undefined;
    this.emptyValid = undefined;
    this.isNumberValid = undefined;
    this.isPasswordValid = undefined;
    this.isFirstNameValid = undefined;
    this.isLastNameValid = undefined;

    isValid = this.validateFields(toValidateObj, validateEmail, validateNames);
    var valid = false;
    if (isValid === -1) {
      this.emptyValid = lang.manage_access.user.user_validations.no_missing_fields_are_allowed;
    } else if (isValid === -2) {
      this.isEmailValid = lang.manage_access.user.user_validations.email_bad_format;
    } else if (isValid === -3) {
      this.isNumberValid = lang.manage_access.user.user_validations.mobile_invalid;
    } else if (isValid === -4) {
      this.isPasswordValid = lang.manage_access.user.user_validations.password_validation;
    } else if (isValid === -5) {
      this.isFirstNameValid = lang.manage_access.user.user_validations.firstname_validation;
    } else if (isValid === -6) {
      this.isLastNameValid = lang.manage_access.user.user_validations.lastname_validation;
    } else {
      valid = true;
      this.createNewUser(emailAdded, firstName, lastName, mobile.replace(/ /g, ""), roles);
    }
    if (!valid) this.forceUpdate();
  }

  setFilterRef(filterRef) {
    this.filterDialRef = filterRef;
  }

  setScopeFilterRef(filterRef) {
    this.scopeFilter = filterRef;
  }

  checkEmailExists(email, domain) {
    var email_appened = email + "@" + domain.value;
    let _this = this;
    if (isValidEmailAddress(email_appened)) {
      const path = "/ManageAccess";

      var query = {
        action: "checkEmailExists",
        email: email_appened,
      };
      let onThenCallback = (data) => {
        if (data && data.code === -2) {
          _this.state.emailExists = data;
          logUITracking("email doesn't exist ###### log for creation of user issue #####" + data+"");
        } else {
          _this.state.emailExists = data;
          logUITracking("email exists ###### log for creation of user issue #####" + data+"");
        }

        _this.setState(_this.state); //re-render
      };
      let fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "checkEmailExists",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
        [FETCHAPI_PARAMS.showLoader]: false,
        [FETCHAPI_PARAMS.path]: path,
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_access.screen_name,
        [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_access.request_description.check_email_exists,
      };
      _this.fetchAPI(fetchOptions);
    } else {
      this.isEmailValid = lang.manage_access.user.user_validations.email_bad_format;
    }
  }

  extractLeafNodes(vectors, result = []) {
    for (var i = 0, length = vectors.length; i < length; i++) {
      if (!vectors[i].children && vectors[i].checked) {
        result.push(vectors[i]);
      } else {
        if (vectors[i].children) this.extractLeafNodes(vectors[i].children, result);
      }
    }
  }

  resetMFA = (firstName, email, userId) => {
    const _this = this;
    let query = {
      action: "resetMFA",
      email: email,
      firstName: firstName,
      domainUrl: window.location.href,
      user_id: userId,
    };
    let onThenCallback = (data) => {
      if (data) {
        _this.setState({ message: "Email sent successfully" }, () => {
          setTimeout(function () {
            _this.launchToast();
          }, 0.1);
        });
      }
      // _this.resetMFAOnFireBase();
    };
    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "resetMFA",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: false,
      [FETCHAPI_PARAMS.path]: API_URL.MANAGE_ACCESS,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_access.screen_name,
			[FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_access.request_description.reset_mfa,
    };
    this.setWarningDialogOpen(false);
    _this.fetchAPI(fetchOptions);
  };

  edit(tabSelected, row) {
    var _this = this;
    this.resetInputs();
    this.drawerToggleClickHandler(true);
    if (tabSelected === MANAGE_ACCESS.ACCESS_GROUP_SECTION) {
      this.accessGroup[MANAGE_ACCESS.FIELDS.ACCESS_GROUP] = row[MANAGE_ACCESS.FIELDS.ACCESS_GROUP];
      this.accessGroup[MANAGE_ACCESS.FIELDS.DESCRIPTION] = row[MANAGE_ACCESS.FIELDS.DESCRIPTION];
      document.getElementById(lang.manage_access.NAME_ID).value = row[MANAGE_ACCESS.FIELDS.ACCESS_GROUP];
      document.getElementById(lang.manage_access.DESCRIPTION_ID).value = row[MANAGE_ACCESS.FIELDS.DESCRIPTION];
      var restrictions = updateSelected(
        this.state.restrictions,
        row[MANAGE_ACCESS.FIELDS.RESTRICTIONS] ? row[MANAGE_ACCESS.FIELDS.RESTRICTIONS].split(",") : []
      );
      var ids = [];
      for (var e in restrictions) {
        if (restrictions[e].checked) {
          ids.push({ id: restrictions[e].restriction_id.toString() });
        }
      }
      var vectors = updateSelected(
        this.state.vectorsAccess,
        row[MANAGE_ACCESS.FIELDS.VECTORS] ? row[MANAGE_ACCESS.FIELDS.VECTORS].split(",") : [],
        row[MANAGE_ACCESS.FIELDS.SHOW_NAME] ? row[MANAGE_ACCESS.FIELDS.SHOW_NAME].split(",") : [],
        row[MANAGE_ACCESS.FIELDS.SHOW_NUMBER] ? row[MANAGE_ACCESS.FIELDS.SHOW_NUMBER].split(",") : []
      );
      let result = [];
      this.extractLeafNodes(vectors, result);
      this.setState(
        {
          restrictions: restrictions,
          checkedPss: ids,
          title: _edit + Inflector.singularize(this.state.tabSelected),
          features: updateSelected(
            this.state.features,
            row[MANAGE_ACCESS.FIELDS.ACCESS_GROUPS_MACHINE_NAMES]
              ? row[MANAGE_ACCESS.FIELDS.ACCESS_GROUPS_MACHINE_NAMES].split(",")
              : []
          ),
          vectorsAccess: vectors,
          vectorInfo: this.joinNameAndNumber(result),
          always_allow_customer: row[MANAGE_ACCESS.FIELDS.ALLOW_CUSTOMER] ? row[MANAGE_ACCESS.FIELDS.ALLOW_CUSTOMER] : false,
          always_allow_operations: row[MANAGE_ACCESS.FIELDS.ALLOW_OPERATIONS]
            ? row[MANAGE_ACCESS.FIELDS.ALLOW_OPERATIONS]
            : false,
          always_allow_product: row[MANAGE_ACCESS.FIELDS.ALLOW_PRODUCT] ? row[MANAGE_ACCESS.FIELDS.ALLOW_PRODUCT] : false,
          alwaysAllowPSS: row[MANAGE_ACCESS.FIELDS.ALLOW_PSS] ? row[MANAGE_ACCESS.FIELDS.ALLOW_PSS] : false,
          access_group_id: row[MANAGE_ACCESS.FIELDS.ID],
        },
        function () {
          this.forceUpdate();
          this.featureRef.setData(this.state.features);
          this.featureRef.extractSectionIds(this.state.features);
        }
      );
    } else if (tabSelected === MANAGE_ACCESS.USER) {
      document.getElementById(lang.manage_access.FIRST_NAME_ID).value = row[MANAGE_ACCESS.FIELDS.FIRST_NAME];
      document.getElementById(lang.manage_access.LAST_NAME_ID).value = row[MANAGE_ACCESS.FIELDS.LAST_NAME];
      document.getElementById(lang.manage_access.ROLE_ID).value = row[MANAGE_ACCESS.FIELDS.ROLE];
      // document.getElementById(lang.manage_access.MOBILE_NUMBER_ID).value = row[MANAGE_ACCESS.FIELDS.MOBILE_NUMBER];
      let email = row[MANAGE_ACCESS.FIELDS.EMAIL].split("@")[0];
      let domain = row[MANAGE_ACCESS.FIELDS.EMAIL].split("@")[1];
      document.getElementById(lang.manage_access.EMAIL_ID).value = email;
      let roles = row[MANAGE_ACCESS.FIELDS.ROLE].split(",");
      let options = [];
      for (var i = 0; i < roles.length; i++) {
        var obj = _this.state.roleData.filter((e) => e.role === roles[i])[0];
        options.push({ value: obj.role, label: obj.role, id: obj.id, status: obj.status });
      }
      this.setState(
        {
          roles: options,
          domain: { value: domain, label: domain },
          first_name: document.getElementById(lang.manage_access.FIRST_NAME_ID).value,
          last_name: document.getElementById(lang.manage_access.LAST_NAME_ID).value,
          email: document.getElementById(lang.manage_access.EMAIL_ID).value,
          uid: row[MANAGE_ACCESS.FIELDS.ID],
          filter: row[MANAGE_ACCESS.FIELDS.LIMITED_ACCESS] ? tryParse(row[MANAGE_ACCESS.FIELDS.LIMITED_ACCESS]).filter : [],
          title: _edit + Inflector.singularize(this.state.tabSelected),
          scopeFilter: this.state.allScopeFilters ? this.state.allScopeFilters.filter(f => f.userId === row.id) : [],
        },
        function () {
          this.forceUpdate();
          if (this.state.filter.length !== 0) {
            this.filterDialRef.onChangeSavedFilter(
              JSON.stringify(JSON.parse(row[MANAGE_ACCESS.FIELDS.LIMITED_ACCESS]).filter),
              true
            );
          }

          if (this.state.scopeFilter.length !== 0 && this.props.userSettingsState.addScope) {
            this.scopeFilter?.onChangeSavedFilter(
              JSON.stringify(this.state.scopeFilter),
              true
            );
          }
        }
      );
    } else if (tabSelected === MANAGE_ACCESS.ROLE) {
      this.role[MANAGE_ACCESS.FIELDS.ROLE] = row[MANAGE_ACCESS.FIELDS.ROLE];
      this.role[MANAGE_ACCESS.FIELDS.DESCRIPTION] = row[MANAGE_ACCESS.FIELDS.DESCRIPTION];
      document.getElementById(lang.manage_access.NAME_ID).value = row[MANAGE_ACCESS.FIELDS.ROLE];
      document.getElementById(lang.manage_access.DESCRIPTION_ID).value = row[MANAGE_ACCESS.FIELDS.DESCRIPTION];
      let rowAccessGroups = row[MANAGE_ACCESS.FIELDS.ACCESS_GROUP].split(",");
      let accessGroupsData = this.state.accessGroupsData;
      for (e in accessGroupsData) {
        if (rowAccessGroups.includes(accessGroupsData[e].access_group)) {
          accessGroupsData[e].checked = true;
        }
      }
      this.setState(
        {
          title: ROLES_INPUTS.EDIT_ROLE_TITLE,
          role_id: row[MANAGE_ACCESS.FIELDS.ID],
        },
        function () {
          this.forceUpdate();
        }
      );
    }
  }

  confirmResetPassword(firstName, lastName, emailAddress, rowData) {
    let _this = this;
    let fullName = firstName + " " + lastName;
    this.setState(
      {
        confirm: MANAGE_ACCESS.PASSWORD,
        confirm_reset: [fullName, emailAddress],
        rowData: rowData,
      },
      function () {
        _this.setWarningDialogOpen(true);
        return;
      }
    );
  }

  confirmResetMFA = (firstName, email, lastName, userId) => {
    let _this = this;
    let resetMFAObj = {};
    resetMFAObj.firstName = firstName;
    resetMFAObj.email = email;
    resetMFAObj.lastName = lastName;
    resetMFAObj.userId = userId;
    _this.setState(
      {
        confirm: MANAGE_ACCESS.MFA,
        resetMFAObj: resetMFAObj,
      },
      function () {
        _this.setWarningDialogOpen(true);
        return;
      }
    );
  };

  resetPasswordUser(rowData) {
    let _this = this;
    let email = rowData[MANAGE_ACCESS.FIELDS.EMAIL];
    let firstName = rowData[MANAGE_ACCESS.FIELDS.FIRST_NAME];
    let userId = rowData[MANAGE_ACCESS.FIELDS.ID];
    const path = API_URL.USER;

    let url = window.location.href;
    let url_array = url.split("//");
    let protocol = url_array[0];
    let domainUrl = url_array[1].split("/")[0];
    let fullUrl = protocol + "//" + domainUrl;
    var query = {
      action: "resetPassword",
      firstName: firstName,
      email: email,
      user_id: userId,
      domainUrl: fullUrl,
    };
    let onThenCallback = (data) => {
      if (data) {
        _this.setState({ message: "Email sent successfully" }, () => {
          setTimeout(function () {
            _this.launchToast();
          }, 0.1);
        });
      }
    };
    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "resetPassword",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: false,
      [FETCHAPI_PARAMS.path]: path,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_access.screen_name,
			[FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_access.request_description.reset_password,
    };
    _this.fetchAPI(fetchOptions);
    _this.setWarningDialogOpen(false);
  }

  showHideBanner() {
    let userData = this.state.userData;
    let roleData = this.state.roleData;
    let accessData = this.state.accessGroupsData;
    let showBanner = false;

    userData = userData.filter((e) => e.status === MANAGE_ACCESS.ACTIVE);

    for (let e in userData) {
      let roles = userData[e][MANAGE_ACCESS.FIELDS.ROLE].split(",");
      let accessGroups = userData[e][MANAGE_ACCESS.FIELDS.ACCESS_GROUP].split(",");
      let roleStatus = false;
      let accessStatus = false;
      for (let elem in roles) {
        let status = roleData.filter((elt) => elt[MANAGE_ACCESS.FIELDS.ROLE] === roles[elem]).length
          ? roleData.filter((elt) => elt[MANAGE_ACCESS.FIELDS.ROLE] === roles[elem])[0][MANAGE_ACCESS.FIELDS.STATUS]
          : "";
        if (status === MANAGE_ACCESS.ACTIVE) {
          roleStatus = true;
          break;
        }
      }
      for (let elem in accessGroups) {
        let status = accessData.filter((elt) => elt[MANAGE_ACCESS.FIELDS.ACCESS_GROUP] === accessGroups[elem]).length
          ? accessData.filter((elt) => elt[MANAGE_ACCESS.FIELDS.ACCESS_GROUP] === accessGroups[elem])[0][
              MANAGE_ACCESS.FIELDS.STATUS
            ]
          : "";
        if (status === MANAGE_ACCESS.ACTIVE) {
          accessStatus = true;
          break;
        }
      }
      if (!roleStatus || !accessStatus) {
        showBanner = true;
        break;
      }
    }
    this.setState({
      showBanner: showBanner,
    });
  }

  handleChange(event) {
    this.setState({
      selectedOption: event,
    });
  }

  updateToggleState(name, value) {
    this.setState({ ["always_allow_" + name]: value });
  }

  updateUserFields(e, attr) {
    var state = {};
    var _this = this;
    // if((e=== null && attr === lang.manage_access.SELECT_ROLE && this.manageColsOverlay.boardRef.classList.contains("is-board-open")) || e!==null  && this.manageColsOverlay.boardRef.classList.contains("is-board-open")){
    switch (attr) {
      case lang.manage_access.FIRST_NAME: {
        var first_name = $(e.currentTarget).val();
        state.first_name = first_name;
        this.validateField(() => {
          _this.isFirstNameValid = undefined;
          if (!isValidName(first_name) && first_name !== "") {
            _this.isFirstNameValid = lang.manage_access.user.user_validations.firstname_validation;
          }
        });
        break;
      }
      case lang.manage_access.LAST_NAME: {
        var last_name = $(e.currentTarget).val();
        state.last_name = last_name;
        this.validateField(() => {
          _this.isLastNameValid = undefined;
          if (!isValidName(last_name) && last_name !== "") {
            _this.isLastNameValid = lang.manage_access.user.user_validations.lastname_validation;
          }
        });
        break;
      }
      case lang.manage_access.EMAIL: {
        var email = $(e.currentTarget).val();
        state.email = email;
        this.validateField(() => {
          if (_this.state.domain) {
            _this.isEmailValid = undefined;
            if (!isValidEmailAddress(email + "@" + _this.state.domain.value) && email !== "") {
              _this.isEmailValid = lang.manage_access.user.user_validations.email_bad_format;
            } else {
              _this.checkEmailExists(email, _this.state.domain);
            }
          }
        });

        break;
      }
      // case lang.manage_access.PASSWORD:{
      //     var password = $(e.currentTarget).val();
      //     state.password = password;
      //     this.validateField(()=> {
      //         _this.isPasswordValid = undefined;
      //         if (!isValidPassword(password) && password !== ""){
      //             _this.isPasswordValid = lang.manage_access.user.user_validations.password_validation;
      //         }
      //     });
      //     break;
      // }
      case lang.manage_access.DOMAIN: {
        state.domain = e;
        var email = this.state.email ? this.state.email : "";
        if (state.domain) {
          this.checkEmailExists(email, state.domain);
        }
        break;
      }
      // case lang.manage_access.MOBILE_NUMBER:{
      //     var mobile_number = $(e.currentTarget).val();
      //     state.mobile_number = mobile_number;
      //     this.validateField(()=> {
      //         _this.isNumberValid = undefined;
      //         if (!isValidMobileNumber(mobile_number) && mobile_number !== ""){
      //             _this.isNumberValid = lang.manage_access.user.user_validations.mobile_invalid;
      //         }
      //     });
      //     break;
      // }
      case lang.manage_access.SELECT_ROLE: {
        state.roles = e;
        break;
      }
      case lang.manage_access.NOTIFY_USER: {
        state.notifyUser = e.target.checked;
        break;
      }
    }
    this.setState(state);
    // }
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  applyFilter(filter) {
    var title = [];
    var newFilter = JSON.parse(filter);
    for (var e in newFilter) {
      //.ref.current.filterObj[]
      var values = [];
      typeof newFilter[e][_values] === "object"
        ? newFilter[e][_values].map(function (item) {
            values.push(item.value);
          })
        : values.push(newFilter[e][_values]);
      title.push(newFilter[e][_vectorLabel] + " " + newFilter[e][_field] + " " + newFilter[e][_function] + " " + values.join(", "));
      newFilter[e][_filterRowType] = FILTER.VALUES.FILTER_ROW_TYPE.USER_LIMIT_ACCESS;
    }
    this.setState({
      limitTitle: filter.length === 0 ? lang.manage_access.define : title.join(", "),
      filter: newFilter,
    });
  }

  fillUsersInputs() {
    let options = [];
    let roles =
      this.props.userSettingsState.user.is_system === "true" ? this.state.roleData : this.state.roleData.filter((e) => e.is_system == false);
    for (var i = 0; i < roles.length; i++) {
      if (roles[i].status === MANAGE_ACCESS.ACTIVE) {
        options.push({
          label: roles[i][MANAGE_ACCESS.FIELDS.ROLE],
          value: roles[i][MANAGE_ACCESS.FIELDS.ROLE],
          id: roles[i][MANAGE_ACCESS.FIELDS.ID],
          status: roles[i].status,
        });
      }
    }
    var vectors = [];
    var vectorsAccess = this.state.vectorsAccess;
    for (var e in vectorsAccess) {
      vectors.push({
        [VECTOR_STAGING_ATTRIBUTES.ID]: vectorsAccess[e][VECTOR_STAGING_ATTRIBUTES.ID],
        value: vectorsAccess[e][VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME] + "header",
        label: vectorsAccess[e][VECTOR_STAGING_ATTRIBUTES.NAME],
        [_fieldDataType]: DATATYPE_ENUM.STRING,
        isDisabled: true,
      });
      if (vectorsAccess[e].children && vectorsAccess[e].children.length > 0) {
        for (var elt in vectorsAccess[e].children) {
          vectors.push({
            [VECTOR_STAGING_ATTRIBUTES.ID]: vectorsAccess[e].children[elt][VECTOR_STAGING_ATTRIBUTES.ID],
            value: vectorsAccess[e].children[elt][VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME],
            label: vectorsAccess[e].children[elt][VECTOR_STAGING_ATTRIBUTES.NAME],
            [_fieldDataType]: DATATYPE_ENUM.STRING,
          });
        }
      }
    }

    const customStyles = {
      option: (styles) => ({
        ...styles,
        fontSize: convertPxToViewport(12),
        padding: convertPxToViewport(8) + " " + convertPxToViewport(12),
      }),
      control: (provided) => ({
        ...provided,
        minHeight: convertPxToViewport(38),
        fontSize: convertPxToViewport(12),
        borderRadius: convertPxToViewport(4),
        borderWidth: convertPxToViewport(1),
      }),
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          color: "#C6CCD7",
          marginLeft: convertPxToViewport(2),
          marginRight: convertPxToViewport(2),
        };
      },
    };

    const fileOptionLabel = ({ label, value, id, status }) =>
      status === MANAGE_ACCESS.ACTIVE ? (
        <div title={label}>{label}</div>
      ) : (
        <div title={label} style={{ textDecoration: "line-through", color: "red" }}>
          {label}
        </div>
      );

    return (
      <div className="uk-margin-medium-top">
        <div className="uk-margin-default-bottom">
          {this.emptyValid ? <span className="fs-12 red italic">{this.emptyValid}</span> : ""}
          <div className="uk-flex-inline uk-margin-default-bottom">
            <div className="uk-margin-default-right">
              <label htmlFor="FirstName" className="uk-text-xmedium">
                {lang.manage_access.FIRST_NAME}
                <span className="input_required_text">{"(Required)"}</span>
              </label>
              <Input
                autoComplete="new-password"
                id={lang.manage_access.FIRST_NAME_ID}
                className="uk-input"
                placeholder={lang.manage_access.FIRST_NAME}
                onBlur={(e) => this.updateUserFields(e, lang.manage_access.FIRST_NAME)}
              ></Input>
              {this.isFirstNameValid ? <span className="fs-12 red italic">{this.isFirstNameValid}</span> : ""}
            </div>
            <div className="uk-margin-default-left">
              <label htmlFor="LastName" className="uk-text-xmedium">
                {lang.manage_access.LAST_NAME}
                <span className="input_required_text">{"(Required)"}</span>
              </label>
              <Input
                autoComplete="new-password"
                id={lang.manage_access.LAST_NAME_ID}
                className="uk-input"
                placeholder={lang.manage_access.LAST_NAME}
                onBlur={(e) => this.updateUserFields(e, lang.manage_access.LAST_NAME)}
              ></Input>
              {this.isLastNameValid ? <span className="fs-12 red italic">{this.isLastNameValid}</span> : ""}
            </div>
          </div>
          <div className="uk-flex-inline uk-width-1-1">
            <div className="uk-width-1-1">
              <label htmlFor="Email" className="uk-text-xmedium">
                {lang.manage_access.EMAIL}
                <span className="input_required_text">{"(Required)"}</span>
              </label>
              {this.state.uid !== undefined ? (
                <div>
                  <Input
                    id={lang.manage_access.EMAIL_ID}
                    autoComplete="new-password"
                    disabled
                    placeholder={lang.manage_access.EMAIL}
                    className="uk-input"
                    onBlur={(e) => this.updateUserFields(e, lang.manage_access.EMAIL)}
                  ></Input>
                </div>
              ) : (
                <div>
                  <Input
                    id={lang.manage_access.EMAIL_ID}
                    autoComplete="new-password"
                    placeholder={lang.manage_access.EMAIL}
                    className="uk-input"
                    onBlur={(e) => this.updateUserFields(e, lang.manage_access.EMAIL)}
                  ></Input>
                </div>
              )}
            </div>
            <span className="fs-20 text-grey uk-flex uk-flex-middle uk-margin-xmedium-top uk-padding-xxsmall-left-right text_before_domain">
              @
            </span>
            <div className="uk-width-1-1">
              <label htmlFor="Domain" className="uk-text-xmedium">
                {lang.manage_access.DOMAIN}
                <span className="input_required_text">{"(Required)"}</span>
              </label>
              {/* <Select
                            id = {lang.manage_access.DOMAIN_ID}
                            className = {this.state.uid !== undefined ? "disabled" : ""}
                            classNamePrefix = {"access-dropdown"}
                            value={findOptionByKey(this.state.domains, this.state.domain)}
                            onChange = {(e)=> this.updateUserFields(e,lang.manage_access.DOMAIN)}
                            options={this.state.domains}
                            styles={customStyles}
                            placeholder={lang.select_domain}
                            maxMenuHeight= {convertPxToViewport(190)}
                            /> */}
              <DropDown // to remove comment and comment <Select
                id={lang.manage_access.DOMAIN_ID}
                className={"input__dropdown " + (this.state.uid !== undefined ? "disabled" : "")}
                // classNamePrefix = {"access-dropdown"}
                value={findOptionByKey(this.state.domains, this.state.domain)}
                onChange={(e) => this.updateUserFields(e, lang.manage_access.DOMAIN)}
                options={this.state.domains}
                type={DROPDOWN_TYPE.INPUT}
                placeholder={lang.select_domain}
                maxMenuHeight={convertPxToViewport(190)}
              />
            </div>
          </div>
          {this.isEmailValid ? <span className="fs-12 red italic">{this.isEmailValid}</span> : ""}
          {/* <div className="uk-flex-inline uk-margin-default-top">
                        {this.state.uid === undefined?
                        <div id="password-container" className="uk-width-1-1 uk-margin-default-right">
                            <label htmlFor="Password" className="uk-text-xmedium">{lang.manage_access.PASSWORD}<sup className="red">*</sup></label>
                            <input  autoComplete="new-password" type={this.state.hidden ? "password" : "text"} id={lang.manage_access.PASSWORD_ID} className="uk-input" placeholder={lang.password} onBlur = {(e)=> this.updateUserFields(e,lang.manage_access.PASSWORD)}></input>
                            <span className={this.state.hidden ? "fa-lg fal fa-eye" : "fa-lg fal fa-eye-slash" } id="togglePassword" onClick={this.toggleShow}></span>
                            {this.isPasswordValid ? <span  className="fs-12 red italic">{this.isPasswordValid}</span> : ""}
                        </div> :""}
                        <div className={"uk-width-1-1 "+(!this.state.uid ? "uk-margin-default-left":"")}>
                            <label htmlFor="MobileNumber" className="uk-text-xmedium">{lang.manage_access.MOBILE_NUMBER}</label>
                            <input autoComplete="new-password" id={lang.manage_access.MOBILE_NUMBER_ID} className="uk-input" placeholder={lang.manage_access.MOBILE_NUMBER} onBlur = {(e)=> this.updateUserFields(e,lang.manage_access.MOBILE_NUMBER)}></input>
                            {this.isNumberValid ? <span  className="fs-12 red italic">{this.isNumberValid}</span> : ""}
                        </div>
                    </div> */}
          <label htmlFor="Role" className="uk-margin-default-top uk-text-xmedium uk-width-1-1">
            {lang.manage_access.SELECT_ROLE}
            <span className="input_required_text">{"(Required)"}</span>
          </label>
          {/* <Select
                        id = {lang.manage_access.ROLE_ID}
                        classNamePrefix = {"access-dropdown"}
                        value={this.state.roles}
                        placeholder={lang.manage_access.select_role_placeholder}
                        onChange = {(e)=> this.updateUserFields(e,lang.manage_access.SELECT_ROLE)}
                        isMulti={true}
                        options={options}
                        styles={customStyles}
                        formatOptionLabel={fileOptionLabel}
                        maxMenuHeight= {convertPxToViewport(190)}
                        /> */}
          <DropDown // to remove comment and comment <Select
            id={lang.manage_access.ROLE_ID}
            // classNamePrefix = {"access-dropdown"}
            className="input__dropdown"
            value={this.state.roles}
            placeholder={lang.manage_access.select_role_placeholder}
            onChange={(e) => this.updateUserFields(e, lang.manage_access.SELECT_ROLE)}
            isMulti={true}
            options={options}
            type={DROPDOWN_TYPE.INPUT}
            // formatOptionLabel={fileOptionLabel}
            maxMenuHeight={convertPxToViewport(190)}
          />
        </div>
        <div className="uk-margin-default-bottom">
          <span className="uk-text-xmedium uk-text-bold">{lang.manage_access.limit_access}</span>
          <i
            className="fal fa-info-circle uk-margin-small-left uk-cursor-pointer"
            uk-tooltip={lang.manage_access.limit_access_tooltip}
          />
          {/* <div className="text-link" uk-toggle="target: #filterModal">{this.state.limitTitle ? this.state.limitTitle : lang.manage_access.define}</div> */}
          <Button
            label={this.state.limitTitle ? this.state.limitTitle : lang.manage_access.define}
            variant={BUTTON_VARIANT.SECONDARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            className=""
            uk-toggle="target: #filterModal"
          />
          <div id="engine_filter_modal">
            <FilterDialog
              ref={(el) => this.setFilterRef(el)}
              vectorOptions={vectors}
              useOutsideFilter={true}
              onChangeFilter={this.applyFilter}
              scenario_id={this.props.scenarioState?.scenario}
              mustFetchDatasets={false}
              useCookies={false}
              user={this.props.userSettingsState.user}
              isDrilling={false}
              fromOptions={[]}
              quadrantsDisabled={true}
              quadrantDefault={false}
              filterDisplayEditable={true}
              hideFilterDisplay={true}
              fromManageAccess
            />
          </div>
        </div>
        {this.props.userSettingsState.addScope &&
            <>
              <div>
                <span className="uk-text-xmedium uk-text-bold">{lang.manage_access.define_scope}</span>
                {/*<i className="fal fa-info-circle uk-margin-small-left uk-cursor-pointer" uk-tooltip={lang.manage_access.define_scope_tooltip} />*/}
                <Button
                    label={this.state.scopeTitle ? this.state.scopeTitle : lang.manage_access.define}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    className=""
                    uk-toggle="target: #filterModal_manage_access"
                />
              </div>
              <div id="engine_filter_modal">
              <FilterDialog
                  ref={(el) => this.setScopeFilterRef(el)}
                  vectorOptions={vectors}
                  useOutsideFilter={true}
                  onChangeFilter={this.applyScope}
                  scenario_id={this.props.scenarioState?.scenario}
                  mustFetchDatasets={false}
                  useCookies={false}
                  componentName={"manage_access"}
                  user={this.props.userSettingsState.user}
                  isDrilling={false}
                  fromOptions={[]}
                  quadrantsDisabled={true}
                  quadrantDefault={false}
                  filterDisplayEditable={true}
                  hideFilterDisplay={true}
                  filterFinal={this.state.scopeFilter}
                  fromManageAccess
                  isManageAccessScope
              />
              </div>
            </>
        }
      </div>
    );
  }

  fillRolesInputs() {
    return (
      <div className="uk-margin-medium-top">
        <label htmlFor="Name" className="uk-text-xmedium">
          {lang.manage_access.NAME}
          <span className="input_required_text">{"(Required)"}</span>
        </label>
        <Input
          id={lang.manage_access.NAME_ID}
          className="uk-input"
          placeholder={lang.manage_access.ROLE_NAME}
          onBlur={(e) => {
            this.updateFields(e, MANAGE_ACCESS.FIELDS.ROLE);
          }}
        ></Input>
        {this.isNameValid && this.isNameValid !== "" ? (
          <div id="calc-col-warn" className="fs-12 red italic">
            {this.isNameValid}
          </div>
        ) : (
          ""
        )}
        <label htmlFor="Description" className="uk-text-xmedium uk-margin-default-top">
          {lang.manage_access.DESCRIPTION}
        </label>
        {/* <Input id={lang.manage_access.DESCRIPTION_ID} className="uk-input" onBlur={(e)=>{this.updateFields(e,MANAGE_ACCESS.FIELDS.DESCRIPTION)}} placeholder={lang.manage_access.ROLE_DESCRIPTION}></Input> */}
        <FormComponent
          tag="textarea"
          id={lang.manage_access.DESCRIPTION_ID}
          ref={(r) => (this.descriptionInputRef = r)}
          className="uk-textarea"
          value={this.role[MANAGE_ACCESS.FIELDS.DESCRIPTION]}
          placeholder={lang.manage_access.ROLE_DESCRIPTION}
          onChange={(e) => {
            this.updateFields(e, MANAGE_ACCESS.FIELDS.DESCRIPTION);
          }}
        />
        <label htmlFor="SelectAccessGroup" className="uk-text-xmedium uk-margin-default-top">
          {lang.manage_access.SELECT_ACCESS_GROUP}
          <span className="input_required_text">{"(Required)"}</span>
        </label>
        <RoleAccess
          ref={(el) => (this.roleRef = el)}
          accessGroupsData={
            this.props.userSettingsState.user.is_system === "true"
              ? this.state.accessGroupsData
              : this.state.accessGroupsData.filter((e) => e.is_system == false)
          }
        />
      </div>
    );
  }

  checkIsNameUnique(value) {
    var obj = this;
    if (this.state.tabSelected === MANAGE_ACCESS.ACCESS_GROUP_SECTION) {
      if (
        this.state.accessGroupsData.filter(
          (e) =>
            e[MANAGE_ACCESS.FIELDS.ACCESS_GROUP].toLowerCase() === value.toLowerCase() &&
            Number(e[MANAGE_ACCESS.FIELDS.ID]) !== Number(obj.state.access_group_id ? obj.state.access_group_id : 0)
        ).length > 0
      ) {
        return false;
      }
    } else if (this.state.tabSelected === MANAGE_ACCESS.ROLE) {
      if (
        this.state.roleData.filter(
          (e) =>
            e[MANAGE_ACCESS.FIELDS.ROLE].toLowerCase() === value.toLowerCase() &&
            Number(e[MANAGE_ACCESS.FIELDS.ID]) !== Number(obj.state.role_id ? obj.state.role_id : 0)
        ).length > 0
      ) {
        return false;
      }
    }
    return true;
  }

  updateFields(eventOrValue, attr) {
    var value = $(eventOrValue.currentTarget).val();
    var formattedValue = value;
    var isUnique = this.checkIsNameUnique(formattedValue);
    var message = "";
    if (this.state.tabSelected === MANAGE_ACCESS.ROLE) {
      this.role[attr] = formattedValue;
    } else if (this.state.tabSelected === MANAGE_ACCESS.ACCESS_GROUP_SECTION) {
      this.accessGroup[attr] = formattedValue;
    }
    if (attr === MANAGE_ACCESS.FIELDS.ACCESS_GROUP || attr === MANAGE_ACCESS.FIELDS.ROLE) {
      if (message === "") {
        if (!isUnique) {
          message = lang.manage_access.name_already_used;
        }
      }
      this.isNameValid = message;
      this.forceUpdate();
      return;
    }
  }
  updateAccessTabsData(data, allowed, isFeature, isCustomReport) {
    if (this.state.accessGroupTab === lang.manage_access.access_group_tab_labels[1]) {
      this.state.checkedPss = data;
      this.state.alwaysAllowPSS = allowed;
    } else if (this.state.accessGroupTab === lang.manage_access.access_group_tab_labels[0]) {
      if (isFeature) {
        this.state.featureIds = data;
      } else if (isCustomReport) {
        this.state.customReportIds = data;
      } else {
        this.state.sectionIds = data;
      }
    } else {
      this.state.vectorsInfo = this.joinNameAndNumber(data);
    }
  }

  fillAccessGroupInputs() {
    return (
      <div className="access-group uk-margin-medium-top">
        <label htmlFor="Name" className="uk-text-xmedium">
          {lang.manage_access.NAME}
          <span className="input_required_text">{"(Required)"}</span>
        </label>
        <Input
          id={lang.manage_access.NAME_ID}
          className="uk-input"
          placeholder={lang.manage_access.access_group_name}
          defaultValue={this.accessGroup[MANAGE_ACCESS.FIELDS.ACCESS_GROUP]}
          onBlur={(e) => {
            this.updateFields(e, MANAGE_ACCESS.FIELDS.ACCESS_GROUP);
          }}
        ></Input>
        {this.isNameValid && this.isNameValid !== "" ? (
          <div id="calc-col-warn" className="fs-12 red italic">
            {this.isNameValid}
          </div>
        ) : (
          ""
        )}
        <label htmlFor="Description" className="uk-text-xmedium uk-margin-default-top">
          {lang.manage_access.DESCRIPTION}
        </label>
        {/* <Input id={lang.manage_access.DESCRIPTION_ID} className="uk-input" placeholder={lang.manage_access.access_group_description} defaultValue={this.accessGroup[MANAGE_ACCESS.FIELDS.DESCRIPTION]} onBlur={(e)=>{this.updateFields(e,MANAGE_ACCESS.FIELDS.DESCRIPTION)}}></Input> */}
        <FormComponent
          tag="textarea"
          id={lang.manage_access.DESCRIPTION_ID}
          ref={(r) => (this.descriptionInputRef = r)}
          className="uk-textarea"
          placeholder={lang.manage_access.access_group_description}
          value={this.accessGroup[MANAGE_ACCESS.FIELDS.DESCRIPTION]}
          onChange={(e) => {
            this.updateFields(e, MANAGE_ACCESS.FIELDS.DESCRIPTION);
          }}
        />
        <Tabs
          ref={(el) => (this.tabAccessRef = el)}
          activeTab={this.state.accessGroupTab}
          onChange={this.changeTab}
          tabs={this.state.accessGroupTabLabels}
        />
        {this.state.accessGroupTab === lang.manage_access.access_group_tab_labels[1] ? (
          <ProfitStackAccess
            ref={(el) => (this.PSRef = el)}
            alwaysAllowPSS={this.state.alwaysAllowPSS}
            restrictions={this.state.restrictions}
            updateParent={this.updateAccessTabsData}
          />
        ) : this.state.accessGroupTab === MANAGE_ACCESS.FEATURE ? (
          <Feature
            ref={(el) => (this.featureRef = el)}
            features={this.state.features}
            selectedFeatures={this.state.selectedFeatures}
            updateParent={this.updateAccessTabsData}
          />
        ) : (
          <VectorAccess
            ref={(el) => (this.vectorRef = el)}
            vectors={this.state.vectorsAccess}
            always_allow_customer={this.state.always_allow_customer}
            always_allow_operations={this.state.always_allow_operations}
            always_allow_product={this.state.always_allow_product}
            updateParent={this.updateAccessTabsData}
            updateToggleState={this.updateToggleState}
          />
        )}
      </div>
    );
  }

  body(tabSelected) {
    switch (tabSelected) {
      case lang.manage_access.tab_labels[0]: {
        return this.fillUsersInputs();
      }
      case lang.manage_access.tab_labels[1]: {
        return this.fillRolesInputs();
      }
      case lang.manage_access.tab_labels[2]: {
        return this.fillAccessGroupInputs();
      }
    }
  }

  refreshManageAccessTable = () => {
    this.pageComponent.refreshManageAccessTable();
  };

  setTabsData(data, isDeactivate = false) {
    var _this = this;
    if (this.state.tabSelected === lang.manage_access.tab_labels[0]) {
      _this.pageComponent.updateTableColumns(
        data.users_columns,
        this.state.userData,
        this.state.userData,
        this.state.accessGroupsData,
        this.state.roleData,
        this.state.tabSelected
      );
    } else if (this.state.tabSelected == lang.manage_access.tab_labels[1]) {
      _this.pageComponent.updateTableColumns(
        data.roles_columns,
        this.state.roleData,
        this.state.userData,
        this.state.accessGroupsData,
        this.state.roleData,
        this.state.tabSelected
      );
    } else {
      _this.pageComponent.updateTableColumns(
        data.access_groups_columns,
        this.state.accessGroupsData,
        this.state.userData,
        this.state.accessGroupsData,
        this.state.roleData,
        this.state.tabSelected
      );
    }
    _this.showHideBanner();
    if (!isDeactivate) {
      _this.countActiveUsers();
      _this.countActiveRoles();
    }
  }

  launchToast() {
    $("#toastManageAccess").addClass("show");
    setTimeout(function () {
      $("#toastManageAccess").removeClass("show");
    }, 4000);
  }

  callSave = () => {
    let _this = this;
    let cb= ()=>{
      this.save();
    }
    _this.changeTab(lang.manage_access.access_group_tab_labels[0], true, cb);
  }

  save(checked, callback) {
    if (this.state.tabSelected === MANAGE_ACCESS.ACCESS_GROUP_SECTION) {
      if (this.PSRef) {
        this.PSRef.extractCheckedItems();
      } else if (this.featureRef) {
        let ids = [];
        let sectionIds = [];
        let customReportIds = [];
        let features = this.featureRef.state.features.length > 0 ? this.featureRef.state.features : this.featureRef.props.features;
        this.featureRef.extractSectionIds(features, sectionIds);
        this.featureRef.extractFeaturesIds(features, ids);
        this.featureRef.extractCustomReportIds(features, customReportIds);
        this.updateAccessTabsData(sectionIds);
        this.updateAccessTabsData(ids,undefined, true);
        this.updateAccessTabsData(customReportIds, undefined, false, true);

      } else {
        this.updateAccessTabsData(this.vectorRef.extractLeafNodes(this.vectorRef.state.vectors));
      }
      this.setState(this.state, function () {
        this.saveAccessGroup();
      });
    } else if (this.state.tabSelected === MANAGE_ACCESS.ROLE) {
      this.state.rolesAccessIds = this.roleRef.extractIds();
      this.setState(this.state, function () {
        this.saveRole();
      });
    } else {
      this.setState(this.state, function () {
        this.executeAddUser();
      });
    }
    //  if(typeof callback === "function"){
    //     callback();
    //  }
  }

  joinNameAndNumber(data) {
    var finalArray = [];
    for (var e in data) {
      var parentId = data[e]["parent_id"];
      var machineName = data[e]["machine_name"];

      var tempArray = data.filter((e) => e.parent_id === parentId);
      if (finalArray.filter((e) => e.parent_id === parentId).length !== 0) continue;
      if (tempArray.length == 2 && finalArray.filter((e) => e.parent_id === parentId).length === 0) {
        var obj = { display_name: "Number,Name", machine_name: machineName, parent_id: parentId };
        finalArray.push(obj);
      } else {
        finalArray.push(data[e]);
      }
    }
    return finalArray;
  }

  cancel(callback) {
    // this.onToggleBoard();
    this.changeTab(lang.manage_access.access_group_tab_labels[0], true);
    if (typeof callback === "function") {
      callback();
    }
  }

  validateCheckBoxes(accessGroupsids) {
    var containPS = false;
    var containVector = false;
    var containFeature = false;
    var data = this.state.accessGroupsData;
    for (var e in data) {
      if (accessGroupsids.filter((elem) => elem.id == data[e].id).length > 0) {
        if (data[e].type) {
          if (data[e].type.includes(ROLE_ACCESS.FEATURES)) {
            containFeature = true;
          }
          if (data[e].type.includes(ROLE_ACCESS.PROFITSTACK1) || data[e].type.includes(ROLE_ACCESS.PROFITSTACK2)) {
            containPS = true;
          }
          if (data[e].type.includes(ROLE_ACCESS.VECTOR)) {
            containVector = true;
          }
        }
      }
    }
    if (containPS && containVector && containFeature) {
      return true;
    }
    return false;
  }

  saveRole() {
    const _this = this;
    var rolesAccessIds = this.state.rolesAccessIds || [];
    if (!this.role[MANAGE_ACCESS.FIELDS.ROLE] || this.accessGroup[MANAGE_ACCESS.FIELDS.ROLE] === "") {
      this.isNameValid = lang.manage_access.name_empty;
      this.forceUpdate();
      return;
    } else if (this.isNameValid && this.isNameValid !== "") {
      return;
    } else if (!this.validateCheckBoxes(rolesAccessIds)) {
      _this.setInfoDialogOpen(true, lang.failedToSave);
      return;
    }
    let query = {
      action: "save",
      sectionIds: JSON.stringify(rolesAccessIds),
      role_id: this.state.role_id,
      access_group_name: this.role[MANAGE_ACCESS.FIELDS.ROLE],
      description: this.role[MANAGE_ACCESS.FIELDS.DESCRIPTION],
      tabSelected: this.state.tabSelected,
      email: this.props.userSettingsState.user.email,
      domainUrl: window.location.href,
    };
    var message = this.state.role_id ? lang.manage_access.role_edited : lang.manage_access.role_created;
    _this.changeTab(lang.manage_access.access_group_tab_labels[0], true);
    _this.drawerToggleClickHandler();
    let onThenCallback = (data) => {
      this.setState(
        {
          accessGroupsData: data.access_group_data,
          userData: data.user_data,
          roleData: data.role_data,
          message: message,
        },
        function () {
          if (_this.pageComponent) {
            _this.setTabsData(data, true);
          }

          _this.inactiveUsersData(_this.state.inactiveUsersChecked, undefined, true);
          if (_this.state.tabSelected === MANAGE_ACCESS.ACCESS_GROUP_SECTION)
            _this.showHide(_this.state.hideInactiveAccessGroups, true);
          if (_this.state.tabSelected === MANAGE_ACCESS.ROLE) _this.showHide(_this.state.hideInactiveRoles, true);
          setTimeout(function () {
            _this.launchToast();
          }, 0.1);
          _this.forceUpdate();
        }
      );
    };
    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "saveRole",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: false,
      [FETCHAPI_PARAMS.path]: API_URL.MANAGE_ACCESS,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_access.screen_name,
			[FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_access.request_description.save_role,
    };
    this.fetchAPI(fetchOptions);
  }

  saveAccessGroup() {
    var isUnique = this.checkIsNameUnique(this.accessGroup[MANAGE_ACCESS.FIELDS.ACCESS_GROUP]);
    if (!this.accessGroup[MANAGE_ACCESS.FIELDS.ACCESS_GROUP] || this.accessGroup[MANAGE_ACCESS.FIELDS.ACCESS_GROUP] === "") {
      this.isNameValid = lang.manage_access.name_empty;
      this.forceUpdate();
      return;
    } else if (this.isNameValid && this.isNameValid !== "") {
      return;
    } else if (!isUnique) {
      this.isNameValid = lang.VECTOR_MAPPING.VECTOR_NAME_ALREADY_USED;
      this.forceUpdate();
      return;
    }
    const _this = this;
    var sectionIds = this.state.sectionIds || [];
    var featureIds = this.state.featureIds || [];
    var customReportIds = this.state.customReportIds || [];
    var restrictions = this.state.checkedPss || [];
    let vectorsInfo = this.state.vectorsInfo
    if (!this.state.vectorsInfo && !this.state.always_allow_customer && !this.state.always_allow_product && !this.state.always_allow_operations) {
      vectorsInfo = this.state.vectorInfo;
    }
    let query = {
      action: "save",
      sectionIds: JSON.stringify(sectionIds),
      featureIds: JSON.stringify(featureIds),
      customReportIds: JSON.stringify(customReportIds),
      access_group_name: this.accessGroup[MANAGE_ACCESS.FIELDS.ACCESS_GROUP],
      description: this.accessGroup[MANAGE_ACCESS.FIELDS.DESCRIPTION],
      tabSelected: this.state.tabSelected,
      email: this.props.userSettingsState.user.email,
      restrictions: JSON.stringify(restrictions),
      alwaysAllowPSS: this.state.alwaysAllowPSS.toString(),
      always_allow_customer: this.state.always_allow_customer,
      always_allow_product: this.state.always_allow_product,
      always_allow_operations: this.state.always_allow_operations,
      vectorInfo: JSON.stringify(vectorsInfo),
      access_group_id: this.state.access_group_id ? this.state.access_group_id : "",
      domainUrl: window.location.href,
    };
    var message = this.state.access_group_id ? lang.manage_access.access_group_edited : lang.manage_access.access_group_created;
    this.drawerToggleClickHandler();
    let onThenCallback = (data) => {
      _this.isNameValid = "";
      this.setState(
        {
          accessGroupsData: data.access_group_data,
          userData: data.user_data,
          roleData: data.role_data,
          message: message,
        },
        function () {
          if (_this.pageComponent) {
            _this.setTabsData(data, true);
          }
          _this.changeTab(lang.manage_access.access_group_tab_labels[0], true);
          _this.inactiveUsersData(_this.state.inactiveUsersChecked, undefined, true);
          if (_this.state.tabSelected === MANAGE_ACCESS.ACCESS_GROUP_SECTION)
            _this.showHide(_this.state.hideInactiveAccessGroups, true);
          if (_this.state.tabSelected === MANAGE_ACCESS.ROLE) _this.showHide(_this.state.hideInactiveRoles, true);
          setTimeout(function () {
            _this.launchToast();
          }, 0.1);
          _this.forceUpdate();
        }
      );
    };
    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "saveAccessGroup",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: false,
      [FETCHAPI_PARAMS.path]: API_URL.MANAGE_ACCESS,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_access.screen_name,
			[FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_access.request_description.save_access_group,
    };
    this.fetchAPI(fetchOptions);
  }

  confirmDeactivate(tabSelected, status, id, firstName, lastName, email, role, accessGroup, isUnBlock) {
    let _this = this;
    let fullName = firstName + " " + lastName;
    _this.setState(
      {
        confirm: MANAGE_ACCESS.DEACTIVATE,
        isUnBlock:isUnBlock,
        confirm_deactivate: [tabSelected, status, id, fullName, email, role, accessGroup],
      },
      function () {
        _this.setWarningDialogOpen(true);
        return;
      }
    );
  }

  confirmUnBlock=(tabSelected, status, id, firstName, lastName, email, role, accessGroup)=> {
    let _this = this;
    let fullName = firstName + " " + lastName;
    _this.setState(
      {
        confirm: MANAGE_ACCESS.UNBLOCK,
        confirm_block: [tabSelected, status, id, fullName, email, role, accessGroup],
      },
      function () {
        _this.setWarningDialogOpen(true);
        return;
      }
    );
  }

  /**
   * this function set the failed_attempts_number to 0 in the users table in order to unblock the user
   * @param {*} email 
   */
  unBlock=(email)=>{
    let _this = this;
      let query = {
        action: "setFailedAttempts",
        email: email,
        isResetFailedAttempts: true
      };
      let onThenCallback = (data) => {
        var message = lang.manage_access.user.user_unblocked;
        this.setState({
          message: message
        }, function(){
          _this.launchToast();
        })
        this.fetchData();
        this.setWarningDialogOpen(false);
      };
      let options = {
        [FETCHAPI_PARAMS.funcName]: "setFailedAttempts",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
        [FETCHAPI_PARAMS.showLoader]: true,
        [FETCHAPI_PARAMS.path]: API_URL.USER,
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_access.screen_name,
        [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_access.request_description.failed_attempts,
      };
      fetchAPI(options);
  }

  deactivate(tabSelected, status, id) {
    const _this = this;
    let query = {
      action: MANAGE_ACCESS.DEACTIVATE,
      status: status,
      id: id,
      tabSelected: tabSelected,
      email: this.props.userSettingsState.user.email,
      domainUrl: window.location.href,
    };
    var message =
      Inflector.singularize(tabSelected) +
      " has been " +
      (status === MANAGE_ACCESS.ACTIVE ? "deactivated " : "activated") +
      " successfully.";
    let onThenCallback = (data) => {
      this.setState(
        {
          tabSelected: tabSelected,
          accessGroupsData: data.access_group_data,
          userData: data.user_data,
          roleData: data.role_data,
          message: message,
        },
        function () {
          if (_this.pageComponent) {
            _this.setTabsData(data, true);
          }
          _this.launchToast();
          _this.inactiveUsersData(this.state.inactiveUsersChecked, undefined, true);
          if (_this.state.tabSelected === MANAGE_ACCESS.ACCESS_GROUP_SECTION)
            _this.showHide(this.state.hideInactiveAccessGroups, true);
          if (_this.state.tabSelected === MANAGE_ACCESS.ROLE) _this.showHide(this.state.hideInactiveRoles, true);
          _this.countActiveUsers();
          _this.countActiveRoles();
        }
      );
    };
    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "deactivate",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: false,
      [FETCHAPI_PARAMS.path]: API_URL.MANAGE_ACCESS,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_access.screen_name,
			[FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_access.request_description.deactivate + (status === MANAGE_ACCESS.ACTIVE ? " deactivates " : " activates")  + " " + Inflector.singularize(tabSelected),
    };
    this.fetchAPI(fetchOptions);
    this.setWarningDialogOpen(false);
  }

  fetchData(showLoader = true) {
    const _this = this;
    let query = {
      action: "fetchManageAccessData",
      email: this.props.userSettingsState.user.email,
      scenario_id: this.props.scenariostate?.scenario,
    };
    let onThenCallback = (data) => {
      this.setState(
        {
          usersColumns: data.users_columns,
          rolesColumns: data.roles_columns,
          accessGroupsColumns: data.access_groups_columns,
          tabSelected: this.state.tabSelected,
          accessGroupsData: data.access_group_data,
          userData: data.user_data,
          max_active_users_allowed: JSON.parse(data.max_active_users_allowed.client_max_active_users),
          roleData: data.role_data,
          features: data.sections,
          vectorsAccess: data.vectors,
          restrictions: data.restrictions,
          title: _add + Inflector.singularize(this.state.tabSelected),
          allScopeFilters: data.allScopeFilters,
        },
        function () {
          if (_this.pageComponent) {
            _this.setTabsData(data);
          }
          _this.inactiveUsersData(_this.state.inactiveUsersChecked, undefined, true);
          if (_this.state.tabSelected === MANAGE_ACCESS.ACCESS_GROUP_SECTION) _this.showHide(this.state.hideInactiveAccessGroups);
          if (_this.state.tabSelected === MANAGE_ACCESS.ROLE) _this.showHide(this.state.hideInactiveRoles);
        }
      );
    };

    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "fetchManageAccessData",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: showLoader,
      [FETCHAPI_PARAMS.path]: API_URL.MANAGE_ACCESS,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_access.screen_name,
			[FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_access.request_description.data,

    };
    this.fetchAPI(fetchOptions);
  }

  changeColumns(tab) {
    let columns = [];
    let data = [];
    let expandButtonLabel = "";
    let inactiveButtonLabel = "";

    if (tab === lang.manage_access.tab_labels[0]) {
      columns = this.state.usersColumns;
      data = this.state.userData.filter((e) => e[MANAGE_ACCESS.FIELDS.STATUS] === MANAGE_ACCESS.FIELDS.ACTIVE);
      expandButtonLabel = lang.manage_access.add_user;
      inactiveButtonLabel = lang.manage_access.show_inactive + " " + lang.manage_access.tab_labels[0];
    } else if (tab == lang.manage_access.tab_labels[1]) {
      columns = this.state.rolesColumns;
      data = this.state.roleData.filter((e) => e[MANAGE_ACCESS.FIELDS.STATUS] === MANAGE_ACCESS.FIELDS.ACTIVE);
      expandButtonLabel = lang.manage_access.add_role;
      inactiveButtonLabel = lang.manage_access.show_inactive + " " + lang.manage_access.tab_labels[1];
    } else {
      columns = this.state.accessGroupsColumns;
      data = this.state.accessGroupsData.filter((e) => e[MANAGE_ACCESS.FIELDS.STATUS] === MANAGE_ACCESS.FIELDS.ACTIVE);
      expandButtonLabel = lang.manage_access.add_access_group;
      inactiveButtonLabel = lang.manage_access.show_inactive + " " + lang.manage_access.tab_labels[2];
    }
    this.state.usersClassName = "fal fa-eye fa-lg";
    this.setState(
      {
        tabSelected: tab,
        expandButtonLabel: expandButtonLabel,
        inactiveButtonLabel: inactiveButtonLabel,
        hideInactiveUsers: true,
        systemUsersChecked: false,
        hideInactiveRoles: true,
        hideInactiveAccessGroups: true,
        title: _add + Inflector.singularize(tab),
        inactiveUsersChecked: false,
      },
      function () {
        if (this.state.tabSelected === MANAGE_ACCESS.ACCESS_GROUP_SECTION)
          this.showHide(this.state.hideInactiveAccessGroups, true);
        if (this.state.tabSelected === MANAGE_ACCESS.ROLE) this.showHide(this.state.hideInactiveRoles, true);
        if (this.state.tabSelected === MANAGE_ACCESS.USER)
          this.inactiveUsersData(this.state.inactiveUsersChecked, undefined, true);
        this.resetInputs();
      }
    );
  }

  changeTab(tab, finalize = false, callback) {
    this.setState(this.state);
    this.setState(
      {
        accessGroupTab: tab,
      },
      function () {
        if (this.PSRef) {
          //for Bassem
          this.PSRef.setState({
            allowed: this.state.alwaysAllowPSS,
          });
        }
        if (this.featureRef) {
          this.featureRef.setState({
            selection: lang.manage_access.select_all,
          });
        }
        if (this.roleRef) {
          //for Bassem
          this.roleRef.setState({
            accessGroupsData: this.state.accessGroupsData,
          });
        }
        if (callback && typeof callback === "function") {
          callback();
        }
      }
    );
  }


  inactiveUsersData(show, showSystem, fromFunc = false, blockedChecked) {
    var newUsers = [];
    var users = this.state.userData;

    for (var e in users) {
      if (((users[e].status === "inactive" || users[e].status === "active") && show) || (users[e].status === "active" && !show)) {
        if ((this.state.systemUsersChecked && showSystem === undefined) || showSystem) {
          if(!(!blockedChecked && users[e][MANAGE_ACCESS.FIELDS.FAILED_ATTEMPTS_NUMBER] >= this.props.userSettingsState.failedAttemptsNumberLimit)){// if show blocked users in not checked, do not render the blocked users
            newUsers.push(users[e]);
          }
        } else {
          if (users[e].is_system === false) {
            if(!(!blockedChecked && users[e][MANAGE_ACCESS.FIELDS.FAILED_ATTEMPTS_NUMBER] >= this.props.userSettingsState.failedAttemptsNumberLimit)){
              newUsers.push(users[e]);
            }
          }
        }
      }
    }
    this.setState(
      {
        inactiveUsersChecked: !fromFunc ? show : this.state.inactiveUsersChecked,
        systemUsersChecked: showSystem !== undefined ? showSystem : this.state.systemUsersChecked,
        blockedUsersChecked: blockedChecked !== undefined? blockedChecked: this.state.blockedUsersChecked,
        data: newUsers,
      },
      function () {
        this.pageComponent.updateTableColumns(
          this.state.usersColumns,
          newUsers,
          this.state.userData,
          this.state.accessGroupsData,
          this.state.roleData,
          this.state.tabSelected
        );
      }
    );
  }

  showHide(hide, fromFunc = false) {
    if (this.state.tabSelected === lang.manage_access.tab_labels[1]) {
      this.showHideInactiveData(true, false, hide !== undefined ? hide : !this.state.hideInactiveRoles, fromFunc);
    } else if (this.state.tabSelected === lang.manage_access.tab_labels[2]) {
      this.showHideInactiveData(false, true, hide !== undefined ? hide : !this.state.hideInactiveAccessGroups, fromFunc);
    } else if (this.state.tabSelected === lang.manage_access.tab_labels[0]) {
      this.showHideInactiveUsers(this.state.inactiveUsersChecked);
    }
  }

  showHideInactiveUsers(hide) {
    let tempState = {};
    let _this = this;
    if (hide) {
      tempState.usersClassName = "fal fa-eye fa-lg";
      tempState.inactiveButtonLabel = lang.manage_access.show_inactive + " " + lang.manage_access.tab_labels[0];
    } else {
      tempState.inactiveButtonLabel = lang.manage_access.hide_inactive + " " + lang.manage_access.tab_labels[0];
      tempState.usersClassName = "fal fa-eye-slash fa-lg";
    }

    this.setState(tempState, () => _this.inactiveUsersData(!hide, false));
  }

  showHideInactiveData(isRole, isAccess, hide, fromFunc) {
    var tempState = {};
    var _this = this;
    if (isRole) {
      if (hide) {
        tempState.usersClassName = "fal fa-eye fa-lg";
        tempState.inactiveButtonLabel = lang.manage_access.show_inactive + " " + lang.manage_access.tab_labels[1];
      } else {
        tempState.inactiveButtonLabel = lang.manage_access.hide_inactive + " " + lang.manage_access.tab_labels[1];
        tempState.usersClassName = "fal fa-eye-slash fa-lg";
      }
      if (!fromFunc) {
        tempState.hideInactiveRoles = !this.state.hideInactiveRoles;
      }
    } else if (isAccess) {
      if (hide) {
        tempState.inactiveButtonLabel = lang.manage_access.show_inactive + " " + lang.manage_access.tab_labels[2];
        tempState.usersClassName = "fal fa-eye fa-lg";
      } else {
        tempState.inactiveButtonLabel = lang.manage_access.hide_inactive + " " + lang.manage_access.tab_labels[2];
        tempState.usersClassName = "fal fa-eye-slash fa-lg";
      }
      if (!fromFunc) {
        tempState.hideInactiveAccessGroups = !this.state.hideInactiveAccessGroups;
      }
    }
    this.setState(tempState, function () {
      if (isRole) {
        if (hide) {
          var roles = this.state.roleData;
          var newRoles = [];
          tempState.inactiveButtonLabel = lang.manage_access.show_inactive + " " + lang.manage_access.tab_labels[1];
          for (var e in roles) {
            if (roles[e].status === MANAGE_ACCESS.ACTIVE) {
              newRoles.push(roles[e]);
            }
          }
          _this.pageComponent.updateTableColumns(
            this.state.rolesColumns,
            newRoles,
            this.state.userData,
            this.state.accessGroupsData,
            this.state.roleData,
            this.state.tabSelected
          );
        } else {
          _this.pageComponent.updateTableColumns(
            this.state.rolesColumns,
            this.state.roleData,
            this.state.userData,
            this.state.accessGroupsData,
            this.state.roleData,
            this.state.tabSelected
          );
        }
      } else if (isAccess) {
        var accessData = this.state.accessGroupsData;
        var newAccessData = [];
        if (hide) {
          for (var e in accessData) {
            if (accessData[e].status === MANAGE_ACCESS.ACTIVE) {
              newAccessData.push(accessData[e]);
            }
          }
          _this.pageComponent.updateTableColumns(
            this.state.accessGroupsColumns,
            newAccessData,
            this.state.userData,
            this.state.accessGroupsData,
            this.state.roleData,
            this.state.tabSelected
          );
        } else {
          _this.pageComponent.updateTableColumns(
            this.state.accessGroupsColumns,
            this.state.accessGroupsData,
            this.state.userData,
            this.state.accessGroupsData,
            this.state.roleData,
            this.state.tabSelected
          );
        }
      }
    });
  }

  countActiveUsers() {
    if (this.state.userData) {
      let activeUsers = this.state.userData.filter(
        (user) => user.status === lang.manage_access.active && user.is_system === false
      );
      let activeRoles = this.state.roleData.filter((role) => role.status === lang.manage_access.active);
      let activeAccessGroups = this.state.accessGroupsData.filter(
        (access_group) => access_group.status === lang.manage_access.active
      );
      let counter = activeUsers.length;
      this.setState({
        counter: counter,
        dataNum: this.state.userData.length,
        activeRoles: activeRoles,
        activeAccessGroups: activeAccessGroups,
        limitExceeded: counter >= this.state.max_active_users_allowed && this.props.userSettingsState.user.is_system === "false" ? true : false,
      });
    }
  }

  countActiveRoles() {
    if (this.state.roleData) {
      let activeRoles = this.state.roleData.filter((role) => role.status === lang.manage_access.active);
      let counter = activeRoles.length;
      this.setState({
        roleCounter: counter,
        roleDataNum: this.state.roleData.length,
        activeRoles: activeRoles,
      });
    }
  }

  resetFeaturesToDefault(features) {
    for (var e in features) {
      features[e].indeterminate = false;
      features[e].checked = false;
      features[e].visible = false;
      $("#checkbox_" + features[e].id).prop("indeterminate", false);
      if (features[e].children && features[e].children.length > 0) {
        this.resetFeaturesToDefault(features[e].children);
      }
    }
    return features;
  }

  resetInputs() {
    $("#" + lang.manage_access.FIRST_NAME_ID).val("");
    $("#" + lang.manage_access.LAST_NAME_ID).val("");
    $("#" + lang.manage_access.EMAIL_ID).val("");
    $("#" + lang.manage_access.PASSWORD_ID).val("");
    $("#" + lang.manage_access.MOBILE_NUMBER_ID).val("");
    $("#" + lang.manage_access.NAME_ID).val("");
    $("#" + lang.manage_access.DESCRIPTION).val("");
    if (this.descriptionInputRef) {
      this.descriptionInputRef?.reset();
      this.descriptionInputRef.state.value = undefined;
    }
    $("#" + lang.manage_access.DESCRIPTION_ID).val("");
    $("#" + lang.manage_access.SELECT_ACCESS_GROUP_ID).val("");
    this.state.restrictions.map(function (item) {
      item.checked = false;
      item.visible = false;
    });
    this.state.accessGroupsData.map(function (item) {
      item.checked = false;
      item.visible = false;
    });
    this.state.features = this.resetFeaturesToDefault(this.state.features); //for Bassem
    this.state.accessGroupTab = lang.manage_access.access_group_tab_labels[0];
    this.state.sectionIds = [];
    this.state.featureIds = [];
    this.state.customReportIds = [];
    this.state.checkedPss = [];
    this.state.always_allow_product = false;
    this.state.alwaysAllowPSS = false;
    this.state.always_allow_customer = false;
    this.state.always_allow_operations = false;
    this.state.vectorsAccess = this.resetFeaturesToDefault(this.state.vectorsAccess);
    this.state.message = "";
    this.accessGroup[MANAGE_ACCESS.FIELDS.ACCESS_GROUP] = "";
    this.accessGroup[MANAGE_ACCESS.FIELDS.DESCRIPTION] = "";
    this.role[MANAGE_ACCESS.FIELDS.DESCRIPTION] = "";
    this.role[MANAGE_ACCESS.FIELDS.ROLE] = "";
    this.state.access_group_id = undefined;
    this.isNameValid = "";
    this.state.filter = [];
    this.state.scopeFilter = [];
    this.state.email = "";
    this.state.first_name = "";
    this.state.last_name = "";
    this.state.mobile_number = "";
    this.state.domain = "";
    this.state.password = "";
    this.state.roles = [];
    this.state.uid = undefined;
    this.state.role_id = undefined;
    this.state.limitTitle = undefined;
    this.state.scopeTitle = undefined;
    this.emptyValid = undefined;
    this.isEmailValid = undefined;
    this.emptyValid = undefined;
    this.isNumberValid = undefined;
    this.isPasswordValid = undefined;
    this.isFirstNameValid = undefined;
    this.isLastNameValid = undefined;
    this.state.title = _add + Inflector.singularize(this.state.tabSelected);
  }

  onToggleBoard(isEdit = false, close) {
    var hide = false;
    if (this.state.displayPanel) {
      this.manageColsOverlay.toggleBoard(close);
      hide = true;
    }
    this.setState(
      {
        displayPanel: !hide,
      },
      function () {
        if (!isEdit) this.resetInputs();
        if (this.roleRef) this.roleRef.setState({ filteredText: "" });
        if (this.featureRef) this.featureRef.setState({ filteredText: "" });
        if (!hide) {
          this.manageColsOverlay.toggleBoard(close);
        }
        this.state.displayPanel = !hide;
        this.forceUpdate();
      }
    );
  }

  errorDialogContent = () => {
    let _this = this;
    return <h4>{_this.state.errorMsg}</h4>;
  };

  errorDialogActions = () => {
    return (
      <Button
        label={lang.modal.buttons.ok}
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setErrorDialogOpen(false, "")}
      />
    );
  };

  setErrorDialogOpen = (isOpen, errorMsg) => {
    let _this = this;
    _this.setState({
      openErrorDialog: isOpen,
      errorMsg: errorMsg,
    });
  };

  warningDialogContent = () => {
    let nameInfo = "";
    let emailInfo = "";
    let warning = "";
    let name = "";
    let email = "";
    if (this.state.tabSelected === MANAGE_ACCESS.USER) {
      name = MANAGE_ACCESS.NAME;
      email = MANAGE_ACCESS.EMAIL;
      nameInfo = this.state.confirm === MANAGE_ACCESS.UNBLOCK?
          this.state.confirm_block[3]
          :this.state.confirm === MANAGE_ACCESS.MFA
          ? this.state.resetMFAObj.firstName + " " + this.state.resetMFAObj.lastName
          : this.state.confirm === MANAGE_ACCESS.DEACTIVATE
          ? this.state.confirm_deactivate[3]
          : this.state.confirm_reset[0];
      emailInfo =
          this.state.confirm === MANAGE_ACCESS.UNBLOCK?
          this.state.confirm_block[4]
          :this.state.confirm === MANAGE_ACCESS.MFA
          ? this.state.resetMFAObj.email
          : this.state.confirm === MANAGE_ACCESS.DEACTIVATE
          ? this.state.confirm_deactivate[4]
          : this.state.confirm_reset[1];
      warning =
        this.state.confirm === MANAGE_ACCESS.UNBLOCK?
        lang.manage_access.unblocked_user_warning
          :this.state.confirm === MANAGE_ACCESS.DEACTIVATE
          ? this.state.confirm_deactivate[1] === MANAGE_ACCESS.ACTIVE
            ? lang.manage_access.deactivate_user_warning
            : lang.manage_access.activate_user_warning
          : this.state.confirm === MANAGE_ACCESS.MFA
          ? lang.manage_access.send_mfa_reset_email
          : lang.manage_access.send_password_reset_email;
    } else if (this.state.tabSelected === MANAGE_ACCESS.ROLE) {
      name = MANAGE_ACCESS.ROLE_NAME;
      nameInfo = this.state.confirm_deactivate[5];
      warning =
        this.state.confirm_deactivate[1] === MANAGE_ACCESS.ACTIVE
          ? lang.manage_access.deactivate_role_warning
          : lang.manage_access.activate_role_warning;
    } else if (this.state.tabSelected === MANAGE_ACCESS.ACCESS_GROUP_SECTION) {
      name = MANAGE_ACCESS.ACCESS_GROUP_NAME;
      nameInfo = this.state.confirm_deactivate[6];
      warning =
        this.state.confirm_deactivate[1] === MANAGE_ACCESS.ACTIVE
          ? lang.manage_access.deactivate_access_group_warning
          : lang.manage_access.activate_access_group_warning;
    }

    return (
      <>
        <div className="uk-display-flex pi-warning-background uk-border-rounded uk-padding-xsmall">
          <i className="fa-2x fal fa-exclamation-triangle uk-margin-default-right" />
          <div className="fs-16">{warning}</div>
        </div>
        <div className="uk-padding-large fs-14">
          <div>
            <div className="uk-margin-small-bottom">
              <span className="text-grey uk-flex-inline uk-margin-default-right">{name}</span>
              <span>{nameInfo}</span>
            </div>
            {emailInfo && (
              <div>
                <span className="text-grey uk-flex-inline uk-margin-default-right">{email}</span>
                <span>{emailInfo}</span>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  warningDialogActions = () => {
    return (
      <>
        {this.state.confirm === MANAGE_ACCESS.DEACTIVATE ? (
          <Button
            id="deactivte-btn"
            label={
              this.state.confirm_deactivate[1] === MANAGE_ACCESS.ACTIVE
                ? lang.modal.buttons.deactivate
                : lang.modal.buttons.activate
            }
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={() =>
              this.deactivate(
                this.state.confirm_deactivate[0],
                this.state.confirm_deactivate[1],
                this.state.confirm_deactivate[2]
              )
            }
          />
        ) : (
          this.state.confirm === MANAGE_ACCESS.UNBLOCK?
          <Button
            id="deactivte-btn"
            label={lang.modal.buttons.unblock}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={() =>
              this.unBlock(
                this.state.confirm_block[4]
              )
            }
          />
          :
          <Button
            id="confirm-btn"
            label={lang.modal.buttons.send}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={() =>
              this.state.confirm === MANAGE_ACCESS.MFA
                ? this.resetMFA(this.state.resetMFAObj.firstName, this.state.resetMFAObj.email, this.state.resetMFAObj.userId)
                : this.resetPasswordUser(this.state.rowData)
            }
          />
        )}
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setWarningDialogOpen(false)}
        />
      </>
    );
  };

  setWarningDialogOpen = (isOpen) => {
    let _this = this;
    _this.setState({ openWarningDialog: isOpen });
  };

  renderConfigureBody = () => {
    let _this = this;
    return (
      <div id="user-visibility-dropdown">
        <div className="uk-button-icon no-hover">
          <SwitchToggle
            id={"inactive-users"}
            onChange={() => this.inactiveUsersData(!this.state.inactiveUsersChecked, undefined, undefined, this.state.blockedUsersChecked)}
            checked={this.state.inactiveUsersChecked}
          />
          <span className="fs-14 uk-margin-xsmall-left">{this.state.inactiveUsers}</span>
        </div>
        {this.props.userSettingsState?.user[MANAGE_ACCESS.FIELDS.IS_SYSTEM] === "true"?
          <div className="uk-button-icon no-hover">
            <SwitchToggle
              id={"system-users"}
              checked={this.state.systemUsersChecked}
              onChange={() => this.inactiveUsersData(this.state.inactiveUsersChecked, !this.state.systemUsersChecked, undefined, this.state.blockedUsersChecked)}
            />
            <span className="fs-14 uk-margin-xsmall-left">{this.state.systemUsers}</span>
          </div>
          :""
        }
        <div className="uk-button-icon no-hover">
          <SwitchToggle
            id={"blocked-users"}
            onChange={() => this.inactiveUsersData(this.state.inactiveUsersChecked, undefined, undefined, !this.state.blockedUsersChecked)}
            checked={this.state.blockedUsersChecked}
          />
          <span className="fs-14 uk-margin-xsmall-left">{this.state.blockedUsers}</span>
        </div>
      </div>
    );
  };
  backdropClickHandler = () => {
    let _this = this;
    _this.setState(
      {
        drawerOpen: false,
      },
      () => {
        _this.resetInputs();
        _this.forceUpdate();
      }
    );
  };

  drawerToggleClickHandler = (isEdit) => {
    let _this = this;
    _this.setState(
      {
        drawerOpen: !_this.state.drawerOpen,
      },
      () => {
        if (!isEdit) {
          _this.resetInputs();
        }
        _this.forceUpdate();
      }
    );
  };

  setInfoDialogOpen = (isOpen, infoMsg) => {
    let _this = this;
    _this.setState({
      openInfoDialog: isOpen,
      infoMsg: infoMsg,
    });
  };

  openInfoDialogActions = () => {
    return (
      <Button
        label={lang.modal.buttons.ok}
        variant={BUTTON_VARIANT.PRIMARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setInfoDialogOpen(false, "")}
      />
    );
  };

  render() {
    let title = "";
    if (this.state.tabSelected === MANAGE_ACCESS.USER) {
      if (this.state.confirm === MANAGE_ACCESS.PASSWORD) {
        title = lang.manage_access.reset_password;
      } else if (this.state.confirm === MANAGE_ACCESS.MFA) {
        title = lang.manage_access.reset_mfa;
      } else if(this.state.confirm === MANAGE_ACCESS.UNBLOCK){
        title = lang.manage_access.unblock_user;
      } else {
        title =
          this.state.confirm_deactivate[1] === MANAGE_ACCESS.ACTIVE
            ? lang.manage_access.deactivate_user
            : lang.manage_access.activate_user;
      }
    } else if (this.state.tabSelected === MANAGE_ACCESS.ROLE) {
      title =
        this.state.confirm_deactivate[1] === MANAGE_ACCESS.ACTIVE
          ? lang.manage_access.deactivate_role
          : lang.manage_access.activate_role;
    } else if (this.state.tabSelected === MANAGE_ACCESS.ACCESS_GROUP_SECTION) {
      title =
        this.state.confirm_deactivate[1] === MANAGE_ACCESS.ACTIVE
          ? lang.manage_access.deactivate_access_group
          : lang.manage_access.activate_access_group;
    }
    let buttons = [];
    buttons.push(
      { button: lang.modal.buttons.save, isSubmit: true, isDisabled: false },
      { button: lang.modal.buttons.cancel, isSubmit: false, isDisabled: false, onClick: this.cancel }
    );
    let backdrop = "";
    if (this.state.drawerOpen) {
      backdrop = <Backdrop close={this.backdropClickHandler} />;
    }

    return (
      <div style={{height: "100%"}}>
        <Popup></Popup>
        <div id="toastManageAccess" className={this.state.toastIsError ? "toast toast-fail" :"toast toast-success"}>
          <div id="desc">
            <i className={"fa-lg fas uk-margin-small-right " + (this.state.toastIsError ? "fa-minus-circle uk-text-primary" : "fa-check-circle greenText")} aria-hidden="true"></i>
            {this.state.message}
          </div>
        </div>
        {this.state.drawerOpen ? (
          <div>
            {/* <div id="overlay-manage-columns"></div> */}
            {/* <SidePanel ref={r=>this.manageColsOverlay=r} onToggleBoard={this.onToggleBoard} body={this.body(this.state.tabSelected)}
                            title={Inflector.singularize(this.state.title)} tab={this.state.tabSelected} onSave={this.save} onCancel={this.cancel}
                            notifyUser={this.state.notifyUser} updateUserFields={this.updateUserFields} uid={this.state.uid} fromManageAccess={true}/> */}

            <SidePanelNew
              body={this.body(this.state.tabSelected)}
              title={Inflector.singularize(this.state.title)}
              // tab={this.state.tabSelected}
              onSubmit={this.callSave}
              show={this.state.drawerOpen}
              close={this.backdropClickHandler}
              buttons={buttons}
              addNotify={this.state.tabSelected === MANAGE_ACCESS.USER}
              disableCheckbox={true}
              columnButtonsDisplay={true}
              notifyMsg={lang.manage_access.notify_by_email}
              notifyTooltip="Choose to be notified by email"
            />
            {backdrop}
          </div>
        ) : (
          ""
        )}
        <div style={{height: "8%"}}>
          <Tabs
            ref={(r) => (this.tabsRef = r)}
            activeTab={this.state.tabSelected}
            onChange={this.changeColumns}
            tabs={this.state.tabLabels}
          />
        </div>
        {this.state.showBanner && this.state.tabSelected === MANAGE_ACCESS.USER ? (
          <div className="uk-flex uk-flex-middle uk-padding-xsmall-top-bottom uk-padding-large-right uk-padding-large-left pi-background-light-red">
            <i className="fa-lg fas fa-minus-circle uk-margin-small-right uk-text-primary" aria-hidden="true"></i>
            <span className="fs-14">{lang.manage_access.failed_sign_in}</span>
          </div>
        ) : (
          ""
        )}
        <div style={{height: "6%"}}>
          <div className="uk-background-default uk-flex uk-flex-between uk-padding-xxsmall-top-bottom uk-padding-small-left uk-padding-small-right">
            {this.state.limitExceeded && this.state.tabSelected === MANAGE_ACCESS.USER ? (
              <div uk-tooltip={lang.manage_access.MAX_LIMIT_EXCEEDED}>
                <Button
                  label={this.state.expandButtonLabel}
                  variant={BUTTON_VARIANT.SECONDARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  className="uk-button-icon"
                  disabled
                  leftIcon={<i className="far fa-plus-circle fa-lg" aria-hidden="true" />}
                />
              </div>
            ) : (
              <Button
                label={this.state.expandButtonLabel}
                variant={BUTTON_VARIANT.SECONDARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                className="uk-button-icon"
                leftIcon={<i className="far fa-plus-circle fa-lg" aria-hidden="true" />}
                // onBtnClick={()=>this.onToggleBoard()}
                onBtnClick={() => this.drawerToggleClickHandler(false)}
              />
            )}
            <div className="uk-flex">
              {this.state.tabSelected === MANAGE_ACCESS.USER/* && this.props.userSettingsState?.user[MANAGE_ACCESS.FIELDS.IS_SYSTEM] === "true"*/ ? (
                <div id="users-visibility-btn">
                  <ButtonDropdown
                    id={"users-visibility-button"}
                    placeholder={this.state.usersVisibity}
                    className=""
                    // onBtnClick={()=>this.showHide()}
                    variant={BUTTON_DROPDOWN_VARIANT.BUTTON}
                    size={SIZES.DEFAULT}
                    firstAttr={"#button-drop-users-visibility-button"}
                    secondAttr={"#user-visibility-dropdown"}
                    placeholderIcon={<i className={this.state.usersClassName} aria-hidden="true" />}
                    renderContainerBody={this.renderConfigureBody}
                  />
                </div>
              ) : (
                <Button
                  label={this.state.inactiveButtonLabel}
                  variant={BUTTON_VARIANT.SECONDARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  className="uk-button-icon"
                  onBtnClick={() => this.showHide()}
                  leftIcon={<i style={{ fontWeight: "300" }} className={this.state.usersClassName} aria-hidden="true" />}
                />
              )}
              {this.state.tabSelected == lang.manage_access.tab_labels[0] ? (
                <span className={"uk-flex uk-flex-middle uk-margin-default-left uk-text-xmedium"}>
                  <span className="uk-text-bold uk-padding-xxsmall-right">
                    {lang.manage_access.Active + " " + lang.manage_access.tab_labels[0] + ": "}
                  </span>
                  {this.state.counter + "/" + this.state.max_active_users_allowed}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <ManageAccessTabulator
          id={this.state.tabSelected}
          getRow={this.getRow}
          ref={(r) => (this.pageComponent = r)}
          columns={this.state.columns}
          data={this.state.data}
          returnMenuDropDown={this.returnMenuDropDown}
          appendDropDown={this.appendDropDown}
          createDropDownElement={this.createDropDownElement}
          tabSelected={this.state.tabSelected}
          confirmResetPassword={this.confirmResetPassword}
          confirmDeactivate={this.confirmDeactivate}
          edit={this.edit}
          loggedUser={this.props.userSettingsState.user}
          limitExceeded={this.state.limitExceeded}
          clientMFA={this.state.clientMFA}
          resetMFA={this.confirmResetMFA}
          isClientSSO={this.state.isClientSSO}
          failed_attempts_number={this.props.userSettingsState.failedAttemptsNumberLimit}
          confirmUnBlock={this.confirmUnBlock}
        />

        <Modal
          id={"manage-access-error-dialog"}
          openDialog={this.state.openErrorDialog}
          bodyContent={this.errorDialogContent}
          dialogActions={this.errorDialogActions}
          closeClick={() => this.setErrorDialogOpen(false, "")}
          size={DIALOG_SIZE.MEDIUM}
        />
        <Modal
          id={"manage-access-warning-dialog"}
          title={title}
          openDialog={this.state.openWarningDialog}
          bodyContent={this.warningDialogContent}
          dialogActions={this.warningDialogActions}
          closeClick={() => this.setWarningDialogOpen(false)}
          size={DIALOG_SIZE.MEDIUM}
        />
        <Modal
          id={"info-dialog"}
          openDialog={this.state.openInfoDialog}
          bodyContent={() => <h4>{this.state.infoMsg}</h4>}
          dialogActions={this.openInfoDialogActions}
          closeClick={() => this.setInfoDialogOpen(false)}
          size={DIALOG_SIZE.MEDIUM}
        />
      </div>
    );
  }
}

export default ManageAccess;
