import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Button from ".//Button";
import CheckBox from ".//CheckBox";
import { lang } from '../language/messages_en';
import { BUTTON_TYPE, BUTTON_VARIANT, SIZES } from '../class/constants';
import { ReactComponent as CloseIcon } from "../styles/images/icons/close.svg";
import { ReactComponent as ChevronLeft } from "../styles/images/icons/chevron-left-regular.svg";
import { ReactComponent as ChevronRight } from "../styles/images/chevron-right.svg";
import { useEffect } from 'react';
import { useRef } from 'react';

function Carousel({ openPopup, images }) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images?.length;
    const imgContainerRef = useRef(null);

    useEffect(() => {
        preloadNextImage(images, activeStep);
        if (imgContainerRef.current) {
            imgContainerRef.current.scrollTop = 0;
        }
    }, [images, activeStep]);
    
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    function preloadNextImage(images, activeStep) {
        const nextStep = activeStep + 1;
      
        if (nextStep < images.length) {
          const img = new Image(); // to be removed after checking behavior on UAT
          img.src = images[nextStep].url;
        }
      }

    return (
        <Box sx={{ flexGrow: 1, position: "absolute", backgroundColor:"white", borderRadius:"0.3125vw", zIndex: 2}}>
            <Paper
                square
                elevation={0}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "end",
                    // height: 50,
                    // pl: 2,
                    // bgcolor: 'background.default',
                    padding:"1vw 1.5625vw",
                    borderRadius: "0.3125vw 0.3125vw 0 0" 
                }}
            >
            <Button
              variant={BUTTON_VARIANT.TERTIARY}
              size={SIZES.ICON}
              type={BUTTON_TYPE.DEFAULT}
              leftIcon={<CloseIcon />}
              aria-label="close"
              onBtnClick={() => openPopup(false)}
            />
            </Paper>
            <div ref={imgContainerRef}  style={{ maxHeight: "70vh", minHeight:"70vh", height:"100%", position: "relative", minWidth: "36vw", maxWidth: "36vw", overflowY: "auto"}}>
                {/* <div> */}
                    <Box
                        component="img"
                        sx={{
                            // height: "100%",
                            display: 'block',
                            // maxHeight: "60vh",
                            // maxWidth: 400,
                            // overflow: 'hidden',
                            width: '100%',
                            background: "white",
                            padding: "0 1.5625vw",
                            objectFit: "cover"
                        }}
                        src={images?.length > 0 && images[activeStep].url}
                        alt={images?.length > 0  && images[activeStep].name}
                    />
                {/* </div> */}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: "white", padding: "1.5625vw", borderRadius: "0 0 0.3125vw 0.3125vw", textWrap: "nowrap", gap:"0.41667vw" }}>
                <Button
                    label={lang.modal.buttons.got_it}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    style={{padding:"0 2vw"}}
                    // className="release_banner_button"
                    onBtnClick={() => openPopup(false)}
                />
            <MobileStepper
                variant="text"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                sx={{ justifyContent: "end", width: "100%", gap: "0.41667vw", fontSize: "0.8vw" }}
                nextButton={
                    <Button
                        onBtnClick={() => handleNext()}
                        disabled={activeStep === maxSteps - 1}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.ICON}
                        type={BUTTON_TYPE.DEFAULT}
                        leftIcon={<ChevronRight style={{ width: "0.75vw", height: "0.75vw", marginBottom: "0.19vw" }} />}
                    />
                }
                backButton={
                    <Button
                        onBtnClick={() => handleBack()}
                        disabled={activeStep === 0}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.ICON}
                        type={BUTTON_TYPE.DEFAULT}
                        leftIcon={<ChevronLeft style={{ width: "0.72917vw", height: "0.72917vw",marginBottom: "0.19vw" }} />}
                    />
                }
            />
            </div>
        </Box>
    );
}
export default Carousel;