import { useState } from "react";
import SearchBar from "../SearchBar";
import PropTypes from "prop-types";
import { ExpandableSections } from "./ExpandableSections";

function SearchableExpandableSections({
  sections,
  sectionsTitle,
  sectionsTitleClassName = "",
  searchCriterias,
  searchPlaceholder = "Search",
  onSectionItemClick,
}) {
  const [searchTerm, setSearchTerm] = useState("");

  /**
   * Returns filtered items based on search term and search criterias
   * @param {*} items
   * @param {*} searchTerm
   * @returns
   */
  const filterItemsBasedOnSearch = (items, searchTerm) => {
    if (searchTerm === "") {
      return items;
    }

    const matchesSearchTerm = (vectorObj) => {
      return searchCriterias.some((criteria) => vectorObj[criteria].toLowerCase().includes(searchTerm.toLowerCase()));
    };

    return items.map((section) => ({
      ...section,
      items: section.items.filter(matchesSearchTerm),
    }));
  };

  return (
    <div id="searchable-expandable-sections">
      <SearchBar onInputChange={setSearchTerm} placeholderText={searchPlaceholder} />
      <ExpandableSections
        sections={filterItemsBasedOnSearch(sections, searchTerm)}
        title={sectionsTitle}
        titleClassName={sectionsTitleClassName}
        onSectionItemClick={onSectionItemClick}
      />
    </div>
  );
}

SearchableExpandableSections.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  sectionsTitle: PropTypes.string,
  sectionsTitleClassName: PropTypes.string,
  searchCriterias: PropTypes.arrayOf(PropTypes.string).isRequired,
  searchPlaceholder: PropTypes.string,
  onSectionItemClick: PropTypes.func,
};

export { SearchableExpandableSections };
