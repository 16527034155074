import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { BUTTON_TYPE, BUTTON_VARIANT, MENU_ITEM, SIZES } from "../../class/constants";
import Button from "../../newComponents/Button";
import { ReactComponent as ClearFilterIcon } from "../../styles/images/clear-filter-icon.svg";
import {
  addRecentEntities,
  formatInheritedFilters,
  formatQuickFiltersArray,
  uncheckVectorEntitiesInStorage,
  uncheckVectorsEntitiesInStorage,
} from "../../templateLayout/functions/filterFunctions";
import { AddFilterComponent } from "./AddFilterComponent";
import { AddFilterButton, AddFilterIconButton, VectorButton } from "./filterButtons";

function QuickFilters({
  vectorOptions,
  profitFormat,
  userSettings,
  scenarioState,
  filterFinal,
  setQuickFilters,
  switchBasicToAdvanced,
  clearAdvancedFilters,
  filterDialRef,
  disabled,
}) {
  const numberOfFilterVectors = userSettings?.basicFilterCountLimit;
  const filterFinalObject = typeof filterFinal === "object" ? filterFinal : JSON.parse(filterFinal);
  const isAdvancedFiltersApplied = (filterFinal && filterFinal !== "[]" && filterFinalObject?.filter((e) => !e.isBasicFilter).length > 0) || false;

  const controllerRef = useRef(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [shouldForgetAddedFilters, setShouldForgetAddedFilters] = useState(false);

  /**
   * This will set shouldForgetAddedFilters to true only if we are in Bridge report.
   * This state helps us to forget the filter added in bridge when go back to erosion.
   */
  useEffect(() => {
    if (profitFormat === MENU_ITEM.FIELDS.BRIDGE_REPORT) {
      setShouldForgetAddedFilters(true);
    }
  }, [profitFormat]);

  /**
   * This useEffect handles the filter when switching between screens.
   * If a filter is inherited to another screen, filterFinal will not be null.
   * We are using isEdited because if we add a new filter/change a filter, we don't want this useEffect to be triggered.
   * This useEffect will be triggered when we have an inherited or saved filter selected.
   */
  useEffect(() => {
    // this helps us know if the filter is inheritted or we edited it because the filterFinal changes when we edit/add a filter.
    const isFilterInherited = !selectedFilters?.find((f) => f.isEdited);
    const savedFilter =
      Object.keys(filterDialRef?.current?.state?.savedFilterData).length > 0 && filterDialRef?.current?.state?.savedFilterData?.saved_filter_id;

    // if the screen is not bridge report but we were in bridge report, we should update the filter
    const isBackFromBridgeReport = profitFormat !== MENU_ITEM.FIELDS.BRIDGE_REPORT && shouldForgetAddedFilters;

    if (!isAdvancedFiltersApplied && (savedFilter || isFilterInherited || isBackFromBridgeReport)) {
      let formattedFilter = formatInheritedFilters(filterFinalObject, vectorOptions, isAdvancedFiltersApplied);
      setSelectedFilters(formattedFilter);
      addEntitiesToStorage(formattedFilter);
    }
  }, [filterFinal]);

  const addEntitiesToStorage = (filters) => {
    filters.map((filter) => {
      let vector = filter.vector;
      filter.entities.map((entity) => {
        addRecentEntities(entity, vector, userSettings?.filterEntitySelectionLimit);
      });
    });
  };

  /**
   * This useEffect is triggered when we add/change/edit a filter.
   * isEdited condition is used to prevent setting filterFinal unnecessarily when we set the filters in the above useEffect.
   * The condition added does not contain selectedFilters.length > 0, because if it was added, we will have a loop because in this useEffect, we update the filter final
   * and in the above useEffect we check if filter final is changed.
   * The above one is needed for when we inherit or select a saved filter and this one is needed to updated the filter final when we add/edit a filter
   */
  useEffect(() => {
    if (selectedFilters?.find((f) => f.isEdited)) {
      // if we found a filter with isEdited=true, it means that the user is changing/adding/editing the filter. This helps to know how to format/read the filters
      updateFilterFinal(selectedFilters);
    }
  }, [selectedFilters]);

  const updateFilterFinal = (newFilters) => {
    let appliedFilters = formatQuickFiltersArray(newFilters);
    setQuickFilters(appliedFilters);
    resetSavedFilterData();
  };

  /**
   * Remove data for the saved filter
   */
  function resetSavedFilterData() {
    if (filterDialRef?.current) {
      filterDialRef?.current?.setState({
        savedFilterData: {},
      });

      if (filterDialRef?.current?.savedFilterNameInput) {
        filterDialRef.current.savedFilterNameInput.value = "";
      }
    }
  }

  /**
   * This function will be triggered when clicking on the 'x' next to the vector dropdown.
   * The filter will be removed from selectedFilters.
   */
  const removeVectorFromFilter = (vectorName) => {
    if (controllerRef.current) {
      controllerRef.current.abort(); // if we remove a filter while its entities are fetching, abort request
    }

    const removeFilterFromArr = (filters) => {
      let updatedFilters = structuredClone(filters) || [];
      updatedFilters = updatedFilters.filter((f) => f.vector.label !== vectorName);
      updatedFilters = updatedFilters.map((item) => ({ ...item, isEdited: true }));
      return updatedFilters;
    };

    setSelectedFilters((prevFilters) => removeFilterFromArr(prevFilters));
    clearAdvancedFilters(removeFilterFromArr(selectedFilters).length === 0); // if we have only 1 filter vector selected and we removed it, we should clear all
    uncheckVectorEntitiesInStorage(vectorName);

    /* this is needed because when we have only 1 selectes vector filter and we remove it, selectedFilters state will be empty.
     * Since we have a condition in useEffect to update the filter final when selectedFilters.length > 0, the filter will not be updated if selectedFilters became empty.
     * The below code should be triggered only when selectedFilters is empty
     */
    let updatedFiltersArr = removeFilterFromArr(selectedFilters);
    if (updatedFiltersArr.length === 0) {
      updateFilterFinal(updatedFiltersArr);
    }
  };

  const clearAllFilters = () => {
    uncheckVectorsEntitiesInStorage(selectedFilters.map((m) => m.vector));
    setSelectedFilters([]);
    clearAdvancedFilters(true);
    resetSavedFilterData();
  };

  const onAdvancedBtnClick = () => {
    switchBasicToAdvanced(formatQuickFiltersArray(selectedFilters));
  };

  const forgetBasicFilter = () => {
    filterDialRef?.current?.handleOpenForgetFilterDialog();
  };

  const savedFilterName = filterFinalObject?.[0]?.filter_display_name || "Advanced";
  const isFilterEditable = filterDialRef?.current?.state?.savedFilterData?.editable || false;

  return (
    <div className={`quick-filters-container ${disabled ? "disabled" : ""}`}>
      {!isAdvancedFiltersApplied && selectedFilters?.length === 0 && (
        <AddFilterComponent
          btnId={"filter-by-btn"}
          FilterDropdownButton={AddFilterButton}
          setSelectedFilters={setSelectedFilters}
          vectorOptions={vectorOptions}
          userSettings={userSettings}
          selectedFilters={selectedFilters}
          onAdvancedBtnClick={onAdvancedBtnClick}
          scenarioState={scenarioState}
          profitFormat={profitFormat}
        />
      )}
      {!isAdvancedFiltersApplied &&
        selectedFilters?.map((filter) => {
          return (
            <AddFilterComponent
              key={filter.vector.label}
              btnId={"vector-filter-btn-" + filter?.vector?.label?.replace(/ /g, "_")}
              FilterDropdownButton={VectorButton}
              setSelectedFilters={setSelectedFilters}
              filter={filter}
              vectorOptions={vectorOptions}
              userSettings={userSettings}
              selectedFilters={selectedFilters}
              onAdvancedBtnClick={onAdvancedBtnClick}
              scenarioState={scenarioState}
              profitFormat={profitFormat}
              onVectorRemove={() => removeVectorFromFilter(filter.vector.label)}
            />
          );
        })}
      {!isAdvancedFiltersApplied && selectedFilters?.length > 0 && selectedFilters?.length < numberOfFilterVectors && (
        <AddFilterComponent
          btnId={"filter-by-btn-icon"}
          FilterDropdownButton={AddFilterIconButton}
          setSelectedFilters={setSelectedFilters}
          vectorOptions={vectorOptions}
          userSettings={userSettings}
          selectedFilters={selectedFilters}
          onAdvancedBtnClick={onAdvancedBtnClick}
          scenarioState={scenarioState}
          profitFormat={profitFormat}
        />
      )}
      {!isAdvancedFiltersApplied && selectedFilters?.length >= numberOfFilterVectors && (
        <Button
          id="quick-filters-advanced-filters-btn"
          className="button-drop-filter-by-btn"
          label="Advanced"
          type={BUTTON_TYPE.DEFAULT}
          size={SIZES.DEFAULT}
          variant={BUTTON_VARIANT.SECONDARY}
          uk-toggle={"target: #filterModal"}
          onBtnClick={onAdvancedBtnClick}
        />
      )}
      {isAdvancedFiltersApplied && (
        <Button
          id="quick-filters-advanced-filters-btn-with-label"
          className="button-drop-filter-by-btn"
          label={savedFilterName}
          type={BUTTON_TYPE.DEFAULT}
          size={SIZES.DEFAULT}
          variant={BUTTON_VARIANT.SECONDARY}
          rightIcon={<span className="filters-label">{"+ " + filterFinalObject.length}</span>}
          uk-toggle={"target: #filterModal"}
        />
      )}
      {(isAdvancedFiltersApplied || selectedFilters?.length > 1) && (
        <Button
          id="clear-all-filters-btn"
          className="button-drop-filter-by-btn"
          type={BUTTON_TYPE.DEFAULT}
          size={SIZES.ICON}
          leftIcon={<ClearFilterIcon style={{ width: "100%", height: "100%" }} />}
          variant={BUTTON_VARIANT.SECONDARY}
          onBtnClick={clearAllFilters}
          uk-tooltip={"Clear"}
        />
      )}
      {!isAdvancedFiltersApplied && selectedFilters?.[0]?.filter_display_name && isFilterEditable && (
        <Button
          id="forget-basic-filter-button"
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.ICON}
          type={BUTTON_TYPE.DEFAULT}
          leftIcon={<i className="fal fa-trash" style={{ color: "#7678ED" }} />}
          onBtnClick={forgetBasicFilter}
        />
      )}
    </div>
  );
}

QuickFilters.propTypes = {
  vectorOptions: PropTypes.array,
  profitFormat: PropTypes.string.isRequired,
  userSettings: PropTypes.object,
  scenarioState: PropTypes.object,
  setQuickFilters: PropTypes.func,
  switchBasicToAdvanced: PropTypes.func,
  clearAdvancedFilters: PropTypes.func,
  filterDialRef: PropTypes.object,
  disabled: PropTypes.bool,
};

export { QuickFilters };

