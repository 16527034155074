// ChatBotWrapper.js
import React from 'react';
import CustomChatBot from "./customChatBot";
import "./ChatBotWrapper.css";

function ChatBotWrapper(props) {
    return (
        <div className="chat-wrapper">
            <CustomChatBot userSettings={props.userSettings} showChatbot={props.showChatbot}/>
        </div>
    );
}

export default ChatBotWrapper;
