import { List, ListSubheader } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import CheckBox from "../newComponents/CheckBox";
import { ReactComponent as RemoveItem } from "../styles/images/remove-item-x.svg";
import {convertToSafeString} from "../class/utils";


const CheckableList = ({ itemsList, handleCheckboxChange, extraInfo, // to show extra info next to list labe (ie: vector number)
checkAll, scrollProps, limitReached, removeEntityFromRecent, ...props }, ref) => {
  useImperativeHandle(ref, () => ({
    setLimitReached: (isReached) => {
      setIsLimitReached(isReached);
     }
  }));

  const [isLimitReached, setLimitReached] = useState(limitReached);

  /**
   * Setting limitReached in a ref from props instead of state to avoid re-rendering the component to avoid having scrolling issues.
   * Setting it in a state in this component because I want to rerender the checkbox element without having scrolling issues.
   */
  const setIsLimitReached = (isReached) => {
    setLimitReached(isReached);
    if(props.setLimitReached) {
      props.setLimitReached(isReached)
    }
  }

  const [checked, setChecked] = useState([0]);
  let containerRef = useRef({});
  const [scroll, setScroll] = useState(scrollProps || 0)
  
  const handleToggle = (value) => {
    let currentIndex = checked.indexOf(value);
    let newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);

    if (handleCheckboxChange) {
      handleCheckboxChange(value, containerRef.current.scrollTop);
    }
  };

  useEffect(()=>{
    if (containerRef.current) {
      containerRef.current.scrollTop = scroll;
    }
  },[])

  let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
  return (
    <List className="checkable-list" sx={{ width: "100%", p: 0, maxHeight: zoom > 109 ? "35vh" : "38vh", overflow: "auto" }} ref={containerRef}>
      {itemsList?.map((item) => {
        const labelId = `checkbox-list-label-${item.value || ""}`;
        if (item.isGroupTitle) {
          return (
            <ListSubheader
              key={Math.random()}
              sx={{
                fontSize: "0.8vw",
                fontWeight: "bold",
                lineHeight: 3,
                backgroundColor: "#F3F3F3",
                fontFamily: "'Open Sans', Arial, sans-serif",
                letterSpacing: "normal",
              }}
            >
              {item.label}
            </ListSubheader>
          );
        }
        return (
          <div key={Math.random()} className={"checkable-list-item " + (isLimitReached && !item.checked ? "disabled" : "") + (item.isRemovable? convertToSafeString(item.label):"")}  onClick={() => handleToggle(item)}>
            <CheckBox
              id={Math.random()}
              disabled={isLimitReached && !item.checked}
              checked={checkAll ? checkAll : item.checked}
              labelClassName={"uk-margin-small-left uk-text-overflow-ellipsis checkable-list-checkbox-label"}
              labelId={labelId}
              labelText={item.label}
              divAddedClassName={"checkable-list-checkbox-container"}
              showTooltip
            />
            <div className="info-section">
              {extraInfo &&
                <div key={Math.random()} className="extra-info-text">
                  {item[extraInfo] !== item.label ? item[extraInfo] : ""}
                </div>
              }
              {item.isRemovable && <span id="remove_entity_button" onClick={(event)=>removeEntityFromRecent(event,item)}><RemoveItem/></span>}
            </div>
          </div>
        );
      })}
    </List>
  );
};

export default forwardRef(CheckableList);
