import { FILTER, RECENT_ENTITIES } from "../../class/constants";
import { lang } from "../../language/messages_en";
import { getNewEntityFilter } from "../../sections/filter/FilterHelperFunctions";
import { getLocalStorageValueByParameter, setLocalStorageValueByParameter } from "../../class/common";
import { tryParse } from "../../class/utils";

function addCorrespondingParenthesisToFilter(filters) {
  const _parenthesisBefore = FILTER.KEYS.PARENTHESIS_BEFORE;
  const _parenthesisAfter = FILTER.KEYS.PARENTHESIS_AFTER;
  const _logicalOperator = FILTER.KEYS.LOGICAL_OPERATOR;
  const _OR = lang.ui_filter.dropdowns.buttons.OR;

  if (filters === "string") {
    filters = JSON.parse(filters);
  }

  filters.map((filter, index) => {
    let operator = "";
    operator = filter[_logicalOperator];

    filter[_parenthesisBefore] = ""; //reset parenthesis
    filter[_parenthesisAfter] = ""; //reset parenthesis

    filters[0][_parenthesisBefore] = "(";
    filters[filters.length - 1][_parenthesisAfter] = ")";

    if (operator === _OR && index > 0) {
      filters[index - 1][_parenthesisAfter] = ")";
      filter[_parenthesisBefore] = "(";
    }
  });

  return filters;
}

const formatEntities = (entities, field) => {
  return entities.map((entity) => {
    entity.value = entity[field];
    entity.label = entity[field];
    return entity;
  });
};

/**
 * Format the quick filters array of objects to have the attributes needed for the filter to be processed
 * @param {*} selectedFilters
 * @returns
 */
const formatQuickFiltersArray = (selectedFilters) => {
  let appliedFilters = structuredClone(selectedFilters);
  appliedFilters = appliedFilters.map((m) => {
    let checkedEntities = structuredClone(m.entities).filter((f) => f.checked);
    checkedEntities = formatEntities(checkedEntities, "number");

    let emptyFilter = getNewEntityFilter(
      m.vector.value,
      FILTER.VALUES.FIELD.NUMBER,
      checkedEntities,
      FILTER.VALUES.FILTER_ROW_TYPE.FILTER,
      lang.ui_filter.dropdowns.and
    );
    emptyFilter.vectorLabel = m.vector.label;
    emptyFilter.isBasicFilter = true;
    return emptyFilter;
  });

  let formattedAppliedFilters = structuredClone(appliedFilters);
  appliedFilters.forEach((filter) => {
    if (filter.entities.filter((f) => f.name !== "").length > 0) {
      filter.field = "name";
      filter.entities = formatEntities(
        filter.entities?.filter((f) => f.name !== ""),
        "name"
      );
      formattedAppliedFilters.push(filter);
    }
  });

  formattedAppliedFilters = addCorrespondingParenthesisToFilter(formattedAppliedFilters);
  return formattedAppliedFilters;
};

/**
 * Format the inherited filters array to be readable as quick filters if isBasicFilter=true.
 * Else it won't need to be formatted because it will be read by advanced filter.
 * @param {*} selectedFilters
 * @returns
 */
const formatInheritedFilters = (selectedFilters, vectorOptions = [], isAdvancedFiltersApplied = false) => {
  if (!selectedFilters || selectedFilters.length === 0) {
    return [];
  }

  // Clone the filters to prevent mutation
  let inheritedFilters = structuredClone(selectedFilters);

  // If there are non-basic filters, return immediately
  if (inheritedFilters.some((filter) => !filter.isBasicFilter)) {
    return inheritedFilters;
  }

  // Remove duplicate vectors (only keep the first occurrence of each vector)
  inheritedFilters = [
    ...inheritedFilters
      .reduce((map, filter) => {
        if (!map.has(filter.vector)) {
          map.set(filter.vector, filter);
        }
        return map;
      }, new Map())
      .values(),
  ];

  // Map vector options to replace the vector value with the corresponding object
  inheritedFilters = inheritedFilters.map((filter) => ({
    ...filter,
    vector: vectorOptions?.find((option) => option.value === filter.vector) || filter.vector,
    isBasicFilter: !isAdvancedFiltersApplied,
  }));

  return inheritedFilters;
};

const addNewVectorEntityToStorage = (localStorageObj, entity, vector) => {
  localStorageObj[vector.label] = [entity];
  setLocalStorageValueByParameter(RECENT_ENTITIES, JSON.stringify(localStorageObj));
};

const addEntityWhenLimitExceeded = (localStorageObj, entity, vector) => {
  localStorageObj[vector.label].pop();
  localStorageObj[vector.label].unshift(entity);
};

// const unshiftExistingEntityInStorageOnCheck = (localStorageObj, entity, vector) => {
//   let filteredRecentEntities = localStorageObj[vector.label].filter(f => f.label !== entity.label);
//   filteredRecentEntities.unshift(entity);
//   localStorageObj[vector.label] = filteredRecentEntities;
//   setLocalStorageValueByParameter(RECENT_ENTITIES, JSON.stringify(localStorageObj))
// }

const addNewEntityToSameVector = (localStorageObj, entity, vector, recentSelectedEntitiesLimit) => {
  if (localStorageObj[vector.label].length === recentSelectedEntitiesLimit) {
    addEntityWhenLimitExceeded(localStorageObj, entity, vector);
  } else {
    localStorageObj[vector.label].unshift(entity);
  }
  setLocalStorageValueByParameter(RECENT_ENTITIES, JSON.stringify(localStorageObj));
};

const addDistinctEntitiesToSameVectorInStorage = (localStorageObj, entity, vector, recentSelectedEntitiesLimit) => {
  if (localStorageObj[vector.label].filter((f) => f.key === entity.key).length === 0) {
    addNewEntityToSameVector(localStorageObj, entity, vector, recentSelectedEntitiesLimit);
  }
  // else {
  //   if (entity?.checked) {
  //     unshiftExistingEntityInStorageOnCheck(localStorageObj, entity, vector)
  //   }
  // }
};

const addVectorEntitiesIfStorageExist = (entity, vector, recentSelectedEntitiesLimit) => {
  let localStorageObj = getLocalStorageValueByParameter(RECENT_ENTITIES);
  if (localStorageObj) {
    localStorageObj = tryParse(localStorageObj);
    if (localStorageObj[vector.label]) {
      addDistinctEntitiesToSameVectorInStorage(localStorageObj, entity, vector, recentSelectedEntitiesLimit);
    } else {
      addNewVectorEntityToStorage(localStorageObj, entity, vector);
    }
  }
};

const addVectorEntitiesIfStorageDoesNotExist = (vectorEntitiesMainObj, entity, vector) => {
  vectorEntitiesMainObj[vector.label] = [entity];
  setLocalStorageValueByParameter(RECENT_ENTITIES, JSON.stringify(vectorEntitiesMainObj));
};

const addRecentEntities = (entity, vector, recentSelectedEntitiesLimit) => {
  let vectorEntitiesMainObj = {};
  let storage = getLocalStorageValueByParameter(RECENT_ENTITIES);
  if (storage !== null && storage !== "null") {
    addVectorEntitiesIfStorageExist(entity, vector, recentSelectedEntitiesLimit);
  } else {
    addVectorEntitiesIfStorageDoesNotExist(vectorEntitiesMainObj, entity, vector);
  }
};

const removeVectorFromStorageWhenEmpty = (localStorageEntitiesObj, vector) => {
  if (localStorageEntitiesObj[vector.label].length === 0) {
    delete localStorageEntitiesObj[vector.label];
  }
};

const removeRecentEntitiesStorageWhenEmpty = (localStorageEntitiesObj) => {
  if (Object.keys(localStorageEntitiesObj).length === 0) {
    localStorage.removeItem(RECENT_ENTITIES);
  }
};

const removeEntityFromRecentStorage = (event, item, vector, setLocalStorageVectorEntities) => {
  event.stopPropagation();
  let localStorageEntities = getLocalStorageValueByParameter(RECENT_ENTITIES);
  let localStorageVectorEntitiesArray;
  let localStorageEntitiesObj;
  if (localStorageEntities) {
    localStorageEntitiesObj = tryParse(localStorageEntities);
    localStorageVectorEntitiesArray = tryParse(localStorageEntities)[vector.label];
    localStorageVectorEntitiesArray = localStorageVectorEntitiesArray.filter((f) => f.key !== item.key);
    localStorageEntitiesObj[vector.label] = localStorageVectorEntitiesArray;
    removeVectorFromStorageWhenEmpty(localStorageEntitiesObj, vector);
    setLocalStorageValueByParameter(RECENT_ENTITIES, JSON.stringify(localStorageEntitiesObj));
    removeRecentEntitiesStorageWhenEmpty(localStorageEntitiesObj);
    setLocalStorageVectorEntities(localStorageEntitiesObj[vector?.label]);
  }
};

const getLocalStorageEntities = () => {
  let stringifiedSelectedEntities = getLocalStorageValueByParameter(RECENT_ENTITIES);
  let allEntitiesFromStorage = tryParse(stringifiedSelectedEntities, {});
  return allEntitiesFromStorage;
};

const uncheckVectorEntitiesInStorage = (vectorLabel) => {
  let allEntitiesFromStorage = getLocalStorageEntities();
  let vectorEntitiesFromStorage = allEntitiesFromStorage?.[vectorLabel];
  if(vectorEntitiesFromStorage) {
    vectorEntitiesFromStorage?.map((m) => (m.checked = false));
    setLocalStorageValueByParameter(RECENT_ENTITIES, JSON.stringify(allEntitiesFromStorage));
  }
};

const uncheckVectorsEntitiesInStorage = (vectors) => {
  let allEntitiesFromStorage = getLocalStorageEntities();
  vectors.forEach(vector => {
    let vectorEntitiesFromStorage = allEntitiesFromStorage?.[vector?.label];
    vectorEntitiesFromStorage.map((m) => (m.checked = false));
  });

  if(!allEntitiesFromStorage || allEntitiesFromStorage === null) {
    localStorage.removeItem(RECENT_ENTITIES);
  } else {
    setLocalStorageValueByParameter(RECENT_ENTITIES, JSON.stringify(allEntitiesFromStorage));
  }
};

export {
  addCorrespondingParenthesisToFilter,
  formatEntities,
  formatQuickFiltersArray,
  formatInheritedFilters,
  addRecentEntities,
  removeEntityFromRecentStorage,
  getLocalStorageEntities,
  uncheckVectorEntitiesInStorage,
  uncheckVectorsEntitiesInStorage
};
