import LogoPI from "./LogoPI";

import { lang } from "./language/messages_en";
import SelectClientDropDown from "./SelectClientDropDown";
import { useRef, useState, useEffect, useMemo } from "react";
import { fetchAPI, fetchAPIAjax, FETCHAPI_PARAMS, FETCH_METHOD } from "./class/networkUtils";
import { API_URL, BUTTON_TYPE, BUTTON_VARIANT, CLIENT_ID_STORAGE, DEFAULT_CLIENT_ID, SIZES } from "./class/constants";
import {getLocalStorageValueByParameter, setLocalStorageValueByParameter} from './class/common.js'
import SessionTimeout from "./SessionTimeout";
import Button from "./newComponents/Button";
import { logout } from "./templateLayout/api/api.js";
import { Loader } from "./form/elements.js";
const SELECT_SESSION_TIMEOUT = "selectSessionTimeout"
const SelectClient = (props) => {
    const selectClientDropDownRef = useRef(null);
    const [defaultClientId, setDefaultClientId] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const sessionTimeoutRef = useRef();
    const email =  useMemo(()=> { return props.email },[props.email]) ;
    const isLogin =  useMemo(()=> { return props.isLogin },[props.isLogin]) ;
    const isFromPreferences =  useMemo(()=> { return props.isFromPreferences },[props.isFromPreferences]) ;
    const clients =  useMemo(()=> { return props.clients },[props.clients]) ;

    const onCancelClick = () =>{
        props.closeMultiClient();
        if(props.showHideProfile) {
          props.showHideProfile();
        }
    }

    const setUserDefaultClientFunc = (clientId) => {
        let query = {
          action: "setUserDefaultClient",
          clientId: clientId,
          email: email
        }
        let onThenCallback = function (data) {
            setLocalStorageValueByParameter(DEFAULT_CLIENT_ID,clientId);
            setDefaultClientId(clientId);
        }
    
        let options = {
          [FETCHAPI_PARAMS.funcName]: "setUserDefaultClientFunc",
          [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
          [FETCHAPI_PARAMS.showLoader]: true,
          [FETCHAPI_PARAMS.path]: API_URL.USER,
          [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
          [FETCHAPI_PARAMS.query]: query,
          [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
          [FETCHAPI_PARAMS.requestDescription]: lang.observability.platform.login.set_user_default_client,
          [FETCHAPI_PARAMS.screenName]: isLogin ? lang.observability.platform.login.screen_name : lang.observability.platform.login.preferences ,
        };
        fetchAPI(options);
    }

    useEffect(() => {
        const keyDownHandler = event => {
          if ((event.key === 'Enter' || event.keyCode == 13)) {
            login();
          }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
      }, []);

    const login =()=>{
        let clientId = selectClientDropDownRef.current?.selectedClientId();
        if(isLogin){
            if(clientId){
                sessionTimeoutRef?.current?.clearTimeOut();
                if(selectClientDropDownRef.current.isCheckBoxChecked() && Number(selectClientDropDownRef.current?.tempDefaultClient()).toString() !== Number(getLocalStorageValueByParameter(DEFAULT_CLIENT_ID)).toString()){
                    setUserDefaultClientFunc(selectClientDropDownRef.current?.tempDefaultClient());
                }
                props.signIn(clientId);
            }else{
                setErrorMessage(lang.choose_a_client);
            }
        }else{
            if(!clientId && clientId !== 0){
                setErrorMessage(lang.choose_a_client);
            }else{
                sessionTimeoutRef?.current?.clearTimeOut();
                launchToast()
                setUserDefaultClientFunc(selectClientDropDownRef.current?.tempDefaultClient());
                onCancelClick();
            }
        }
        if(props.showHideProfile) {
          props.showHideProfile();
        }

    }

    const launchToast = () => {
        $("#toastSelectClient").addClass("show");
        setTimeout(function(){
            $("#toastSelectClient").removeClass("show");
        }, 4000);
    }
    let src = "/images/login-logo.png";
    let message = isFromPreferences? lang.choose_default_destination : lang.select_login_destination;
    return (
        <div className="main-body" >
            <header className="login-header">
                <LogoPI />
            </header>
            {!isFromPreferences && <SessionTimeout ref={sessionTimeoutRef} isAuthenticated={true} logout={logout} session_timeout_ui={Number(getLocalStorageValueByParameter(SELECT_SESSION_TIMEOUT))} skipApiTimeout={true} skipShowDialog={true}/>}
            <main id="body">
                <Loader newLoader />
                <div className="select-client">
                    <img
                        className="logo_select_client"
                        src={src}
                    />
                    <span className="select_login_destination_text">{message}</span>
                    <div className="select_client_dropdown_container">
                        <SelectClientDropDown ref={selectClientDropDownRef} clients={clients} defaultClientId={defaultClientId || getLocalStorageValueByParameter(DEFAULT_CLIENT_ID)?Number(defaultClientId || getLocalStorageValueByParameter(DEFAULT_CLIENT_ID)):""}  
                            textMessage={""} isFromPreferences={isFromPreferences} isLogin={isLogin} setErrorMessage={setErrorMessage} errorMessage={errorMessage}/>
                    </div>
                    <div className="destination_save_cancel_container">
                    {isLogin ?
                        <div className="change_selection_text">{lang.you_can_change_your_selection}</div>
                        : ""}
                       
                        <div className="select_destination_buttons_margin"> 
                            <Button
                                label={isLogin ? "Continue" : "Save"}
                                variant={BUTTON_VARIANT.PRIMARY}
                                size={SIZES.LARGE}
                                type={BUTTON_TYPE.DEFAULT}
                                className={"max_width uk-margin-small-top"}
                                onBtnClick={login}
                            />
                        </div>
                    {isLogin ? ""
                        :
                        <div className="select_destination_buttons_margin">
                            <Button
                                label={"Cancel"}
                                variant={BUTTON_VARIANT.SECONDARY}
                                size={SIZES.LARGE}
                                type={BUTTON_TYPE.DEFAULT}
                                className={"max_width cancel_destination_button"}
                                onBtnClick={()=>{onCancelClick()}}
                            />
                        </div>}
                    </div>

                        {isLogin && <div className="select_destination_buttons_margin">
                        <center>
                            {/* <span className={"fs-12 uk-text-decoration-underline logout-btn"} onClick={props.returnToLogin} >{lang.log_out}</span> */}
                            <Button
                                label={lang.log_out}
                                variant={BUTTON_VARIANT.TERTIARY}
                                size={SIZES.LARGE}
                                type={BUTTON_TYPE.DEFAULT}
                                className={"max_width"}
                                onBtnClick={() => { props.returnToLogin() }}
                            />
                        </center>
                        </div>}
                </div>
            </main>
        </div>
    );
}

export default SelectClient;
