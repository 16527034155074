import { Box, Skeleton } from "@mui/material";
import PropTypes from 'prop-types';

const CheckableListSkeleton = ({ numberOfItems }) => {
  return (
    <div style={{ width: "100%", padding: "0 0.84vw 0 0.84vw" }}>
      {/* Checkboxes Skeleton */}
      {Array.from(new Array(numberOfItems)).map((_, index) => (
        <Box key={index} sx={{ display: "flex", alignItems: "center", marginTop: 2.1 }}>
          <Skeleton variant="rounded" width={18} height={18} />
          <Skeleton variant="text" width="80%" sx={{ marginLeft: 2 }} />
        </Box>
      ))}
    </div>
  );
};

CheckableListSkeleton.propTypes = {
  numberOfItems: PropTypes.number.isRequired
};

export { CheckableListSkeleton };
