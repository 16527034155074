import PropTypes from "prop-types";
import React from "react";
import { ReactComponent as ExpandArrow } from "../../styles/images/solid-arrow-up.svg";

// Section component - modular, reusable, and memoized for performance optimization
const Section = React.memo(({ title, items, expanded, onToggle, onItemClick }) => {
  return (
    <div className="section">
      <button className="section-header" onClick={onToggle} aria-expanded={expanded}>
        {title}
        <ExpandArrow className={expanded ? "expand-arrow" : "collapse-arrow"} />
      </button>
      {expanded && (
        <ul className="section-content">
          {items.map((item) =>
            item.isClickable ? (
              <button className="section-content-row" key={item.value} onClick={() => onItemClick(item)}>
                {item.label}
              </button>
            ) : (
              <li className="section-content-row" key={item.value}>
                {item.label}
              </li>
            )
          )}
        </ul>
      )}
    </div>
  );
});

Section.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  expanded: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onItemClick: PropTypes.func
};

export { Section };
