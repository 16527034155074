import React from 'react';
import ReactDOM from 'react-dom';
import Toast from '../newComponents/Toast';


const ToastPortal = ({ message, show, onClose, permanent }) => {
  if (!show) return null; // Don't render the portal if not showing the toast

  return ReactDOM.createPortal(
    <Toast message={message} onClose={onClose} permanent={permanent} />, // Render the Toast inside the portal
    document.body
  );
};

export default ToastPortal;