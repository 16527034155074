import { Button, Icon, Popover, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { BUTTON_VARIANT } from "../class/constants";
import "../styles/filterBy.css";
import { lang } from "../language/messages_en";
import SearchableDropdown from "./SearchableDropdown";

// TODO: to be removed when we remove useNewQuickFilters flag

const AppliedBasicFilter = ({ filterObject, key, handleCheckboxChange, sectionsData, userAllowedVectors, basicFilterCountLimit, applyChanges, filterFinal, vectors }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [checkAll, setCheckAll] = useState(false);
    const appliedCheckedData = useRef(sectionsData[filterObject.vector]?sectionsData[filterObject.vector].filter(f => f.checked):[]);
    // const [checkedData, setCheckedData] = useState(appliedCheckedData.current || sectionsData);
    const [scroll, setScroll] = useState(0);
    const [number, setNumber] = useState("");
    let checkedNumbers = filterFinal?.filter(e=>e.vector === filterObject.vector && e.field === "number")[0]?.entities?.map((val)=>{return val.number});

    const [selectedNumber, setSelectedNumber] = useState(checkedNumbers?.length || "");
    const open = Boolean(anchorEl);

     /**
   * Opens filter dialog
   * @param {*} event
   */
  const handleClick = (event) => {
    if(anchorEl === null){
      setAnchorEl(event.currentTarget);
    } else {
      handleClose();
    }
  };

  const onClickEvent = () =>{
    let checkedData = sectionsData[filterObject.vector].filter(f => f.checked);
    appliedCheckedData.current = checkedData;
    applyChanges(filterObject.vector,checkedData);
    handleClose();
  }

  useEffect(()=>{
    setSelectedEntitiesNumber();
  },[sectionsData[filterObject.vector]])

  /**
   * Closes filter dialog
   */
  const handleClose = () => {
    setAnchorEl(null);
    // onclose, check applied entities, and clear all others
      sectionsData[filterObject.vector] = sectionsData[filterObject.vector]?.map(e=>{e.checked = appliedCheckedData.current.find(data=>data.name === e.name)?true:false; return e});
    // setCheckedData(appliedCheckedData.current);
    setCheckAll(undefined);
    setScroll(0);
  };

  const handleCheckboxClick = (entity, scroll) => {
    if (handleCheckboxChange) {
      if (!entity.checked) {
        entity.checked = true;
      } else {
        entity.checked = false;
      }
      setCheckAll(undefined);
      setScroll(scroll);
      setSelectedEntitiesNumber();
    }

  };

  const selectAllClick = () => {
    if (handleCheckboxChange) {
      let allEntities =[];
      for(let e in sectionsData[filterObject.vector]){
        sectionsData[filterObject.vector][e].checked = true
      }
      setCheckAll(true);
      allEntities = sectionsData[filterObject.vector]
      setSelectedEntitiesNumber();
    }
  };

  const clearClick = () => {
    if (handleCheckboxChange) {
      let allEntities =[];
      for(let e in sectionsData[filterObject.vector]){
        sectionsData[filterObject.vector][e].checked = false
      }
      setCheckAll(false);
      allEntities = sectionsData[filterObject.vector]
      setSelectedEntitiesNumber()
    }
  };

  useEffect(() => {
    closeBasicFiltereDropdown();
  }, [anchorEl])

  const closeBasicFiltereDropdown = () => {
    $(document).on("mouseover click", function (event) {
      if (($("#applied-filter-" + filterObject.vector + ":hover").length === 0) && ($("#applied-filter-button-" + filterObject.vector + ":hover").length === 0)) {
        if (event.type === "click") {
          handleClose();
        }
      }
    });
  }

  const setSelectedEntitiesNumber = () =>{
    let allEntities = sectionsData[filterObject.vector]?.length;
    let checkedEntitiesNumber = sectionsData[filterObject.vector] ? sectionsData[filterObject.vector].filter(f => f.checked).length : "";
    let checkedNumberText = (checkedEntitiesNumber === allEntities) && allEntities > 0 ? lang.all : checkedEntitiesNumber > 9 ? lang.multiple : checkedEntitiesNumber;
    setNumber(checkedEntitiesNumber + " of " + allEntities);
    setSelectedNumber(checkedNumberText);
  }
  let alwaysAllowGroup = userAllowedVectors && userAllowedVectors["always_allow_"+vectors?.find(e=>e.value === filterObject.vector)?.vector_group?.toLowerCase()+"_group"];
  let nameAccess = alwaysAllowGroup || (userAllowedVectors && userAllowedVectors[filterObject.vector]?.show_name);
  let numberAccess = alwaysAllowGroup || (userAllowedVectors && userAllowedVectors[filterObject.vector]?.show_number);
  return (
    <span className={"uk-margin-left basic_filter_applied"+(!sectionsData[filterObject.vector] || sectionsData[filterObject.vector].length===0?" disabled uk-disabled":"")}>
    <Button
      id={"applied-filter-button-" + filterObject.vector}
      onClick={handleClick}
      className={"basic-applied-filter"}
      variant="contained"
      disableElevation
    >
    <span className="text-button-filter-basic" title={filterObject.vectorLabel}>{filterObject.vectorLabel}</span>
        {selectedNumber ?
          <Typography className={(typeof selectedNumber === "string" ? "text-" : "") + "number-selection dropdowns-number-selection"}>{selectedNumber}</Typography>
          : ""}
    <Icon className={"far fa-chevron-down uk-margin-small-left"} />
    </Button>
    {/* <ButtonDropdown  
      id={"applied-filter-button-" + filterObject.vector}
      placeholder={filterObject.vectorLabel}
      title={filterObject.vectorLabel}
      onBtnClick={handleClick}
      appliedNumber={selectedNumber}
      size={SIZES.LARGE}
      variant={BUTTON_DROPDOWN_VARIANT.INPUT}
      dropId={"applied-filter-" + filterObject.vector}
    /> */}
    <Popover
      id={"applied-filter-" + filterObject.vector}
      open={open}
      anchorEl={anchorEl}
      PaperProps={{sx: {minWidth: "18.23vw", top: "19.4vh", position: "fixed"}}}
      classes={{ paper: "popover-applied-basic", root:"root-applied-basic" }}
      onClose={handleClose}
      sx={{ top: 5 }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      TransitionProps={{ timeout: 0 }} // remove transition animation
      disableScrollLock
    >
    <SearchableDropdown
      sectionsData={[{itemsList:(sectionsData?sectionsData[filterObject.vector]?.map(e=>{e.label = nameAccess?e.name:e.number; e.value = nameAccess?e.name:e.number, e.key = numberAccess?e.number:""; return e}):[])}]}
      handleCheckboxChange={handleCheckboxClick}
      headerInfoText={number}
      headerInfoTextSize={"0.8vw"}
      selectAllText = {true}
      selectAllClick = {selectAllClick}
      isSelectAllDisabled = {sectionsData[filterObject.vector]?.filter(f => f.checked).length === sectionsData[filterObject.vector]?.length}
      clearClick = {clearClick}
      isApplyBtnDisabled={(sectionsData[filterObject.vector]?.filter(f => f.checked).length) ? false : true}
      searchCriterias={["label", "key"]}
      searchPlaceholder={"Search " + filterObject?.vectorLabel?.toLowerCase()}
      primaryButtonProps={{
        className: "max_width",
        text: lang.modal.buttons.apply,
        variant: BUTTON_VARIANT.PRIMARY,
        clickEvent: onClickEvent,
        ukToggle: undefined,
        id: "apply-basic-filter-button"
        // sx: { m: 1 }
      }}
      extraInfo = {"key"}
      checkAll = {checkAll}
      basicFilterCountLimit = {basicFilterCountLimit}
      scroll={scroll}
      filterFinal={filterFinal}
      filterObject={filterObject}
    />
  </Popover>
  </span>  );
};

export default AppliedBasicFilter;
