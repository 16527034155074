import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from '@amcharts/amcharts4/maps';
import { Box, Paper } from "@mui/material";
import { AuthErrorCodes, signInWithEmailAndPassword } from "firebase/auth";
import Gradient from 'javascript-color-gradient';
import React, { Component } from 'react';
import Popup from 'react-popup';

import { linearizeHierarchy } from "./class/array";
import { generateColorGradient, interpolateColor } from "./class/colorInterpolation";
import { checkIfSectionIdExists, formatAdvancedFilter, formatBasicFilter, getOutputScreenName, hexToRgb, setLocalStorageValueByParameter, toggleLoader, triggerExportData } from "./class/common";
import { ALL_REPORTS, ALL_WIDGETS, API_URL, BOUNDARIES, BUTTON_TYPE, BUTTON_VARIANT, COLORS_PALETTE, COLUMN_PROFILE, DIALOG_SIZE, FILTER, FY_VALUES, FormatTypes, MAP_COORDINATES, MENU_ITEM, MENU_ITEM_URLS, PSL_RETURN_NAMES, RED_GREEN_COLORS, ROLLING_SEGMENTS, SECTION, SIZES, UI_ACTIONS, costtype } from './class/constants';
import { extractFromFullQuarter, generatePeriods, getFullQuarterFromStartEndQuarters, getGeneratedQuarterRange, getLastBuiltPeriodForSegments, getPeriodQuarter, getQuarterFromDate, monthDiff } from './class/date';
import { formatValString } from './class/format';
import { convertPxToViewport } from "./class/formatting";
import { formatAttributes, formatPslLines } from "./class/helpers";
import { alertAndLogError, getLimit } from "./class/jqueries";
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from './class/networkUtils';
import { getItemFromStore } from "./class/reduxStoreUtils";
import { copyObjectValues, findOptionByKey, getSectionExists, getTranslationFile, groupBy, isValidDate, parseBoolean, tryParse, updateAllData, updateData } from './class/utils';
import Configure from './components/configure/Configure';
import ConfirmPasswordModal from "./components/ConfirmPasswordModal";
import ExpandingList from './components/ExpandingList';
import Container from './components/manageColumns/Container';
import { auth } from "./firebase/firebase";
import { ToggleTab } from './form/elements';
import MapKeyLegend from './geographyConcentration/MapKeyLegend';
import Button from "./newComponents/Button";
import Modal from "./newComponents/Modal";
import { getNewEntityFilter } from "./sections/filter/FilterHelperFunctions";
import ProfitMapList from './sections/ProfitMapList.js';
import './styles/geography.css';
import { ReactComponent as CurvedBack } from "./styles/images/icons/curved-back.svg";
import { getValidPeriods } from "./templateLayout/functions/periodFunctions";
import { updateGeographyCostHierarchy, updateGeographyListData, updateGeographyObjBeforeBack } from "./actions/geographyConcentrationActions";
import { lang } from './language/messages_en';
import { getAllItemsFromIndexedDB } from "./indexedDB";


const MESSAGES = getTranslationFile();
const UNKNOWN = "Unknown";
const defaultPath = API_URL.PROFIT_LANDSCAPE;
const _LIST = "List";
const Alaska = "Alaska";
const Hawaii = "Hawaii";
var statesMapShaper;
var zipCodes;
const toggleTabOptions = [
    { label: "Value", value: "value" },
    { label: "%Tot", value: "percentage_total" },
];
const defaultToggleValue = "value";
let initialGeoConfig = {
  psl: {},
  value: {},
}

let confObjPSLines = {
    xAxisPSLines: {},
  }

let selectedConfObjPSLines = {
    xAxisPSLines: {},
}

let mapDataObj = {
  state1:{
    id: '39',
    value: '200',
    color: '#617A59'
  },
  state2:{
    id: '31',
    value: '300',
    color: '#FF6565'
  }

}

let selectGlobalPolygon;
let previousSelectedPolygon;
let comparePolygons = [];
let mapHash;
let NAME = "name";
let NUMBER = "number";

let isActiveBubble = false;
class GeographyConcentration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFilter: true,
      data: [],
      profitFormat: ALL_WIDGETS.GEOGRAPHY_CONCENTRATION,
      geoConfigObj: initialGeoConfig,
      mapData: [],
      compareMode: false,
      selectedBoundary: [],
      listTitle: _LIST,
      isListExpanded: false,
      addModified: false,
      isListFullScreen: false,
      mapListExpanded:false,
      filterDisplayEditable: false,
      psFilterDisabled: false,
      boundary:BOUNDARIES.STATE,
      boundaryListFilter:[],
      sameZipCodeClicked: false,
      scenario: props.location.state && props.location.state.scenarios ? props.location.state.scenarios[0] : "",
      readFromStore: false

    };
    this.expandingDefaultListRef = React.createRef();
    this.expandingSelectedListRef = React.createRef();
    this.configDropdown = React.createRef();
    this.fetchAPI = fetchAPI.bind(this);
  }

  /** async recursive function that keeps fetching data from indexed DB until it's filled */
  fetchCoordinatesFromStore() {
    let _this = this;
    var getItems = async () => {
      let allItems = await getAllItemsFromIndexedDB();
      if (allItems.length !== 7) {
        _this.fetchCoordinatesFromStore();
        return;
      }
      statesMapShaper = allItems.filter(e=>e.id === MAP_COORDINATES.US_STATES)[0].name;
      zipCodes = allItems.filter(e=>e.id.includes(MAP_COORDINATES.ZIPCODES));
      toggleLoader(false, "getAllItemsFromIndexedDB");
    }
    getItems();
  }

  componentDidUpdate(prevProps, prevState) {
    let _this = this;
    if (_this.state.callGo !== prevState.callGo && _this.state.callGo && !_this.props.showGreyOverLay) {
      _this.getListData();
    }

    // when changing scope, we need to refetch chart and list data afte recalling profitStackHierarchy request
    if(JSON.stringify(this.state.geoConfigObj) !== JSON.stringify(prevState.geoConfigObj) && _this.props.history?.location?.state?.isScopeFilterChanged) {
      let state = this.props.history?.location?.state || {};
      delete state.isScopeFilterChanged;
      this.props.history.push({
        state: state
      });

      this.props.setShowGreyOverLay(false);
      this.go(undefined, true);
    }
  }

  componentDidMount() {
    let _this = this;
    _this.setExportOptions();
    toggleLoader(true, "getAllItemsFromIndexedDB");
    _this.fetchCoordinatesFromStore();
    if(!_this.props.history?.location?.state?.isRedirectionFromStacks) { 
      // clear store when comming from menu and not from back in entity stacks
      _this.props.dispatch(updateGeographyListData([], "geographyListData", true));
      _this.props.dispatch(updateGeographyObjBeforeBack([], "geographyObjBeforeBack", true));
      _this.props.dispatch(updateGeographyCostHierarchy([], "geographyCostHierarchy", true));

      // on refresh, set headerOptionCHnaged to true to display gray overlay
      let state = this.props.history?.location?.state || {};
      state.isRedirectionFromStacks = false;
      this.props.history.push({
        state: state
      });

      this.setState({
        readFromStore: false
      },()=>{
        this.getProfitStackHierarchy(0);
      })
    }
    // after redirection from stacks to landscpae, set isRedirection false so that when refreshing, we set headerOptionCHnaged to true
    
    let dataFromStore = getItemFromStore("geographyObjBeforeBack", this, "geographyObjBeforeBack");    
    if (_this.props.history?.location?.state?.isRedirectionFromStacks && !_this.props.history?.location?.state?.isScopeFilterChanged) {
      this.props.history.push({
        state: {
          isRedirectionFromStacks: false,
          scenarioState: this.props.history.location?.state?.scenarioState,
          outsideFilter:this.props.history.location?.state?.outsideFilter,
          mainFilterFinalBasic:this.props.history.location?.state?.mainFilterFinalBasic,
          selectedBoundaryName: this.props.history.location?.state?.selectedBoundaryName,
          useFilterCookies: this.props.history.location?.state?.useFilterCookies,
        }
      });
      this.setState({
        readFromStore: true
      },()=>{
        this.getProfitStackHierarchy(0);
        this.go(undefined, true);
        this.props.setShowGreyOverLay(false);
      })
      
    }

     // when changing scope we need to reftech the cost hierarchy - isScopeFilterChanged is true when in drill or entity stacks screen
     if(_this.props.history?.location?.state?.isScopeFilterChanged) {
      _this.setState({
        readfromStore:false
      },()=>{
        this.getProfitStackHierarchy(0);
      })
    }

    // _this.closeConfigureDropdown();
    $(window).on("resize", () => {
      let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
      $("#configure-dropdown .configure-container").css("height", zoom > 124 ? "35vh" : zoom > 109 ? "32vh" : "16vw");
      // $(".chart-container").height(zoom > 124 ? "63vh" : zoom > 109 ? "68vh" : "71vh");
    })
  }

  componentWillUnmount(){
    if (this.chart) {
      this.chart.dispose();
    }
  }

  closeConfigureDropdown = () => {
    let _this = this;
    $(document).on("mouseover click", function (event) {
      if (($("#configure-dropdown:hover").length === 0) && ($("#landscape-configure-btn:hover").length === 0)) {
        if (event.type === "click" && _this.state.showGeographyConfigure) {
          _this.setState({
            showGeographyConfigure: false
          })
        }
      }
    });
  }


  go(afterDrilling, returnToState,fromCompare){
    let _this = this;
    // let defaultRevenue = _this.state.geoConfigObj.psl.find(f=>f.value === PSL_RETURN_NAMES.NET_REVENUE);
    let dataFromStore = _this.state.readFromStore ? getItemFromStore("geographyObjBeforeBack", this, "geographyObjBeforeBack") : undefined;
 
    if (dataFromStore) {
      let tempState = _this.getGeographyDataBeforeBackObj(dataFromStore);
      if (tempState.isListExpanded) { 
        if(_this.expandingDefaultListRef.current) {
          _this.expandingDefaultListRef.current.expandClick(true); 
        } 
      }
      this.setState(tempState, () => {
        _this.renderMap();
        _this.getListData(); // setSize will trigger ajaxRequestFunc, which will call fetchProfitInfo()
        this.props.dispatch(updateGeographyObjBeforeBack([], "geographyObjBeforeBack", false));////////////
        // this.props.dispatch(updateLandscapeBubbleObjBeforeBack([], "landscapeBubbleObjBeforeBack", true)); // remove heatmapBeforeBackObj from store after redirection from entity stacks to heatmap
      })
    } else {
      selectedConfObjPSLines = copyObjectValues(confObjPSLines);
      isActiveBubble = false;
      let tempState = {};
      tempState.geographyListExpanded= false;
      if(!fromCompare){
        tempState.compareMode = false;
      }
      if (returnToState) {
        tempState.boundary= BOUNDARIES.STATE;
        tempState.selectedBoundaryCode="";
        tempState.zipCodeSelected=false;
        tempState.boundaryListFilter = [];
        tempState.isClosableList = false;
        selectGlobalPolygon = undefined;
      }
      _this.setState(tempState,()=>{
        comparePolygons = [];
        _this.getBoundariesData(_this.props.scenarioState.scenarios[0]);
      })
    }
  }
  

  getGeographyDataBeforeBackObj = (data) => {
    let tempState = {};
    tempState.mapData = data.mapData;
    tempState.expandedListTitle = data.expandedListTitle;
    tempState.isListExpanded = data.isListExpanded;
    tempState.listTitle = data.listTitle;
    tempState.addModified = data.addModified;
    tempState.manageColumnsProfile = data.manageColumnsProfile;
    tempState.tableColumns = data.tableColumns;
    tempState.vectorObjects = data.vectorObjects;
    tempState.vectorOptions = data.vectorOptions;
    tempState.filterFinal = data.filterFinal;
    tempState.boundary = data.boundary;
    tempState.selectedBoundaryCode = data.selectedBoundaryCode;
    tempState.unknownBoundaries = data.unknownBoundaries;
    tempState.value = data.value;
    return tempState;
  }

  checkDefaultPsl = (pslLines, pslQuadrant) => { // tODO rename function name to checkDEfaultPSLs 
    let _this = this;
    return pslLines.map(psl => {
      if (psl.value === pslQuadrant || !pslQuadrant) {
        updateData(pslLines, psl);
          _this.setState({
            psl: psl,
          })
      }
      if (psl.children) {
        _this.checkDefaultPsl(psl.children, pslQuadrant)
      }
    })
  }



  formatBoundaryData = (data) => {// move to landscapebubbleHelpers
    let _this = this
    let finalData = [];
    let linearizedPSL = _this.state.linearizedHierarchy;
    let pslSelected =linearizedPSL.find(f=>f.checked).label;
    data.map(row => {
      let xFormat = formatValString(row.value, FormatTypes.NUMERIC);
      if (xFormat.length == 1) {
        xFormat = xFormat.replace("-","0") //replace "-" by "0" after formatValString converts small values into "-" causing errors
      }
      let formatData = {
        value: xFormat,
        originalValue:row.value,
        color: row.color, 
        tooltip:lang.profit_landscape.tooltips.show_entities,
        id:row.id,
        pslSelected: pslSelected,
        boundary:row.boundary,
        combinations: row.combinations
      }
      finalData.push(formatData)
    })
    return finalData;
  }


  getPeriodsObject = () => { // move to Date 
    let _this = this;
    let periods = [];
    let periods_yoy = [];
    let periods_pa = [];
    let quarter = "";
    let quarterPre = "";
    let segmentPeriod = "";
    let months = FY_VALUES.M3;
    // let startDate = (_this.props.location.state && _this.props.location.state.startDate) || this.state.customStartDate;
    // let endDate = (_this.props.location.state && _this.props.location.state.endDate) || this.state.customEndDate;

    let startDate = getValidPeriods(this.props.periodsStatusState, this.props.clientPeriodsState, this.props.periodsStatusState.customStartDate, this.props.periodsStatusState.customEndDate).startDate;
    let endDate = getValidPeriods(this.props.periodsStatusState, this.props.clientPeriodsState, this.props.periodsStatusState.customStartDate, this.props.periodsStatusState.customEndDate).endDate;

    if (isValidDate(startDate) && isValidDate(endDate) && startDate && endDate) {
      let periodsCount = monthDiff(startDate, endDate) + 1;
      periods = generatePeriods(startDate, periodsCount);
      periods_yoy = generatePeriods(startDate, periodsCount, false);
      periods_pa = generatePeriods(startDate, periodsCount, true);

      let firstQuarter = getQuarterFromDate(startDate);
      let endQuarter = getQuarterFromDate(endDate);

      let firstPreQuarter = getPeriodQuarter(periods_pa[0]);
      let endPreQuarter = getPeriodQuarter(periods_pa[periods_pa.length - 1]);

      let generatedRange = getGeneratedQuarterRange(_this.props.datasetStatusState.datasetOptions, firstQuarter, endQuarter);
      let generatedRangePre = getGeneratedQuarterRange(_this.props.datasetStatusState.datasetOptions, firstPreQuarter, endPreQuarter);

      let fullQuarter = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRange[0], generatedRange[1]));
      let fullQuarterPre = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRangePre[0], generatedRangePre[1]));

      quarter = fullQuarter.quarter;
      quarterPre = fullQuarterPre.quarter;

      months = fullQuarter.months;
      let lastSelectedPeriod = periods[periods.length - 1];
      let builtPeriods = _this.props.periodsStatusState?.actuallyBuiltPeriods?.map(m => m.label)
      segmentPeriod = getLastBuiltPeriodForSegments(builtPeriods, lastSelectedPeriod, 12);
    }

    return { periods: periods, segmentPeriod: segmentPeriod, quarter: quarter, months: months, periods_yoy: periods_yoy, periods_pa: periods_pa, preQuarter: quarterPre };
  }

  getProfitStackHierarchy = (viewId, scenario) => {
    let _this = this;
    var query = {
      action: "getCostHierarchy",
      scenario_id: _this.props.scenarioState.scenario || scenario,
      view_id: viewId
    }

    let dataFromStore = _this.state.readFromStore ? getItemFromStore("geographyCostHierarchy", _this, "geographyCostHierarchy") : undefined;

    var onThenCallback = (data) => {
      let tempState = {};
      if(dataFromStore) {
        // let pslLines = formatPslLines(data.firstLevelPsl.filter(e=>e.costtype === costtype.calculated));
        tempState.geoConfigObj = data.geoConfigObj;
        tempState.psl = data.psl;
        tempState.value = data.value;
        confObjPSLines.xAxisPSLines = data.confObjPSLines.xAxisPSLines;
        selectedConfObjPSLines = copyObjectValues(data.confObjPSLines);
        tempState.linearizedHierarchy = linearizeHierarchy((confObjPSLines.xAxisPSLines));
        this.props.dispatch(updateGeographyCostHierarchy([], "geographyCostHierarchy", false));
      } else {
        let tempConfObj = copyObjectValues(_this.state.geoConfigObj);
        if (data.firstLevelPsl) {
          let defaultRevenue = data.firstLevelPsl.find(f=>f.returnname === PSL_RETURN_NAMES.NET_REVENUE) ;
          let revenueCostKey = defaultRevenue.id;
          // this.setState({
          tempState.revenueCostKey = revenueCostKey;
          // })
          let pslLines = formatPslLines(data.firstLevelPsl);
          let pslQuadrant = data.pslQuadrant ? data.pslQuadrant[1] : undefined;
          this.checkDefaultPsl(pslLines, pslQuadrant, true);
          tempConfObj.value = defaultToggleValue;
          tempConfObj.psl = _this.state.psl;
          confObjPSLines.xAxisPSLines = pslLines;
          selectedConfObjPSLines = copyObjectValues(confObjPSLines);
          tempState.linearizedHierarchy = linearizeHierarchy((pslLines));
        }
        if (data.attributes) {
          let attributes = formatAttributes(data.attributes)
        }
        tempState.geoConfigObj = tempConfObj;
      }

      this.setState(tempState);

    };

    var fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "getCostHierarchy",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: defaultPath,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.email]: this.props.userSettingsState.user.email,
      [FETCHAPI_PARAMS.machine_name]: this.props.userSettingsState.machine_name,
      [FETCHAPI_PARAMS.profitFormat]: this.props.userSettingsState.profitFormat,
      [FETCHAPI_PARAMS.useStore]: dataFromStore !== undefined,
      [FETCHAPI_PARAMS.scenarioId]: "geographyCostHierarchy",
      [FETCHAPI_PARAMS.dataKey]: "geographyCostHierarchy",
      [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.configurations.get_cost_hierarchy,
      [FETCHAPI_PARAMS.screenName]: getOutputScreenName(window.location.href),

    }
    this.fetchAPI(fetchOptions);
  }

  getBoundariesData = (scenario) => {
    let _this = this;
    let periodsObject = this.getPeriodsObject();
    let selectedPeriods = periodsObject.periods;
    let quarter = periodsObject.quarter;
    let months = periodsObject.months;
    let selectedBoundaryValue = "";
    let selectedBoundaryCode = "";
    let selectedBoundaryName = "";
    let USAStates = statesMapShaper;
    USAStates = USAStates.features;
    if (_this.state.mapData.length > 0 && _this.state.boundary === BOUNDARIES.ZIPCODE) {
      selectedBoundaryValue = _this.state.selectedBoundaryValue;
      selectedBoundaryCode = USAStates.filter(e=>e.properties.id === _this.state.selectedBoundaryCode)[0]?.properties.abbrv;
      selectedBoundaryName = USAStates.filter(e=>e.properties.id === _this.state.selectedBoundaryCode)[0]?.properties.name;
    }
    var query = {
      action: "getBoundariesData",
      geoConfig: _this.state.geoConfigObj,
      quarter: quarter,
      scenario_id: _this.props.scenarioState.scenario,
      selectedPeriods: selectedPeriods,
      filter: "{'filter':" + encodeURIComponent((_this.props.filterFinal ? (typeof _this.props.filterFinal === "object" ? JSON.stringify(_this.props.filterFinal) : _this.props.filterFinal) : "[]")) + "}",
      FY: months,
      boundary: _this.state.boundary,
      selecetedBoundaryValue: selectedBoundaryValue, //state name in client's data
      selectedBoundaryCode:selectedBoundaryCode || "", //abbreviation of state
      stateVector: _this.props.userSettingsState.stateVector,
      zipCodeVector: _this.props.userSettingsState.zipCodeVector,
      rollingPeriod: periodsObject.segmentPeriod,
      rollingSegment: ROLLING_SEGMENTS.R_12,
    }

    var onThenCallback = (data) => {
      let _this = this;
      let stateVector = _this.props.userSettingsState.stateVector;
      let zipCodeVector = _this.props.userSettingsState.zipCodeVector;
      if (_this.props.vectorsStatusState.vectorOptions.filter(e=>e.value.toLowerCase()+NAME === stateVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === stateVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === stateVector?.toLowerCase()).length === 0 
       || _this.props.vectorsStatusState.vectorOptions.filter(e=>e.value.toLowerCase()+NAME === zipCodeVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === zipCodeVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === zipCodeVector?.toLowerCase()).length === 0){
        _this.setState({
          mapData:[],
          notValidScenario: true
        },()=>{
          _this.chart = am4core.create("chart_map", am4maps.MapChart);
          _this.chart.logo.disabled = true;
          _this.pageComponent?.tabulatorList?.current?.tabulator?.setData([]) // setSize will trigger ajaxRequestFunc, which will call fetchProfitInfo()
          _this.setOpenWarning();
        })
          return;
      }
        
      let vector = _this.getBoundaryVectorObject();
      let finalData = _this.formatBoundaryData(data.data);
      let ref = _this.expandingDefaultListRef?.current
      let profile = ref && ref.getDefaultManageColumnsRef()? ref.getDefaultManageColumnsRef().simplifyProfileReturnNames(ref.getDefaultManageColumnsRef().state.selectedProfile) : _this.state.manageColumnsProfile
      let listTitle = vector[0]?.label + " - " + (profile?.label || _LIST); //replace by boundary
      let dataHashMap = groupBy(finalData ,e => e.id);
      mapHash = dataHashMap;
      _this.setState({
        mapData : finalData,
        fullCompareSelection: false,
        compareMode: _this.props.compareMode,
        colorCodes:data.colorCodes,
        expandedListTitle: listTitle,
        listTitle: _this.state.isListExpanded ? listTitle : _LIST,
        addModified: _this.state.manageColumnsProfile?.is_applied || _this.state.manageColumnsProfile?.is_modified,
        manageColumnsProfile: profile,
        notValidScenario: false,
        unknownBoundaries: data.unknown,
        isUnknownSelected: false,
        selectedBoundaryName:selectedBoundaryName
      }, () => {
        this.props.history.push({
          state: {
            isRedirectionFromStacks: false,
            scenarioState: this.props.history?.location?.state?.scenarioState,
            outsideFilter:this.props.history.location?.state?.outsideFilter,
            mainFilterFinalBasic:this.props.history.location?.state?.mainFilterFinalBasic,
          }
        });
        _this.props.renderHeader();
        _this.props.setCompareMode(false);
        _this.renderMap();
        _this.getListData()// setSize will trigger ajaxRequestFunc, which will call fetchProfitInfo()
      })

    };

    let vector = "";
    if(_this.state.boundary === BOUNDARIES.STATE){
      vector = _this.props.vectorsStatusState.vectorOptions?.find(e=>e.value.toLowerCase()+"name" === _this.props.userSettingsState.stateVector?.toLowerCase() || e.value.toLowerCase()+"number" === _this.props.userSettingsState.stateVector?.toLowerCase())?.label;
    }else{
      vector = _this.props.vectorsStatusState.vectorOptions?.find(e=>e.value.toLowerCase()+"name" === _this.props.userSettingsState.zipCodeVector?.toLowerCase() || e.value.toLowerCase()+"number" === _this.props.userSettingsState.zipCodeVector?.toLowerCase())?.label;
    }
    var fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "getBoundariesData",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: defaultPath,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.email]: this.props.userSettingsState.user.email,
      [FETCHAPI_PARAMS.machine_name]: this.props.userSettingsState.machine_name,
      [FETCHAPI_PARAMS.profitFormat]: this.props.profitFormat,
      [FETCHAPI_PARAMS.screenName]: lang.observability.output.geography.screen_name,
      [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.geography.requests_description.apply,
      [FETCHAPI_PARAMS.vector]: vector,
      [FETCHAPI_PARAMS.scopeFilterApplicable]: true,
      [FETCHAPI_PARAMS.periods]: selectedPeriods
    }
    this.fetchAPI(fetchOptions);
  }


  handlePslChange = (node) => {
    let _this = this
    let xProfitStackLines = copyObjectValues(selectedConfObjPSLines.xAxisPSLines);
    let tempState = {};
    updateAllData(xProfitStackLines, false); // uncheck all checked lines
    updateData(xProfitStackLines, node);
    tempState.psl = node;
    selectedConfObjPSLines.xAxisPSLines = xProfitStackLines
    _this.setState(tempState);
  };

  handleValue = (selected) => {
    let _this = this
    _this.setState({
      value: selected
    })
  };

  onApplyClick = () => {
    let _this = this
    let tempConfObj = copyObjectValues(_this.state.geoConfigObj);
    tempConfObj.value = _this.state.value || defaultToggleValue;
    if(_this.state.psl){
      tempConfObj.psl = _this.state.psl;
    }
    confObjPSLines.xAxisPSLines = selectedConfObjPSLines.xAxisPSLines;
    _this.setState({
      geoConfigObj: tempConfObj,
      boundaryListFilter:[],
      boundary: BOUNDARIES.STATE,
      selectedBoundaryCode:"",
      zipCodeSelected:false,
      isClosableList:false
    }, () => {
      isActiveBubble = false;
      _this.closeClick(true);
      if (!_this.props.showGreyOverlay) {
        _this.getBoundariesData(_this.props.scenarioState.scenarios[0]);
      }
      this.props.configDropdownRef.current.setConfigDropdownOpen(false);    
    })
  }

  renderConfigureComponent = () => {
      return (
        <div id='configure-dropdown'>
          <Configure id={"configure-geography-container"} className={"configure-container geography configure_body"} fromLandscape={true} body={this.renderConfigureBody()} buttonText={lang.modal.buttons.apply} classNameButtonText={"button-configure-landscape pointer-event-auto"} onButtonClick={this.onApplyClick} 
          />
        </div>
      )
  }

  renderConfigureBody = () => {
    return (
      <div className='configure-dropdown'>
        <div className='label-configure-landscape'>{lang.profit_stack_line_single}</div>
        <Container
          data={selectedConfObjPSLines.xAxisPSLines}
          onChange={(e) => this.handlePslChange(e, true)}
          mode={"radioSelect"}
          className={
            "x-axis landscape-configure heatmap-configure dropdown-tree uk-margin-small-top"
          }
        />
        <div className="configure-geography-toggle">
              <ToggleTab
                options={toggleTabOptions}
                onSelectTab={(tab) => this.handleValue(tab)}
                defaultValue={this.state.value || defaultToggleValue}
              />
        </div>
      </div>
    )
  }
  showGeographyConfigure = (e, wasZipCode) => {
    let _this = this;
    _this.setState({
      showGeographyConfigure: !_this.state.showGeographyConfigure
    })
  }

  setCompareMode = (fromStop, fromState, fromWrapper) => {
    let _this = this;
    var callback = ()=>{
      let tempState = {};
      let isUnknownSelected = _this.state.isUnknownSelected;

      if (fromStop) {
        tempState.compareMode = false;
        tempState.fullCompareSelection = false;
        isActiveBubble = false;
        tempState.selectedBubble = [];
      } else {
        tempState.compareMode = true;
      }
      comparePolygons = [];
      tempState.listTitle =  _LIST;
      tempState.isListExpanded = false;
      // tempState.isUnknownSelected = false;
      tempState.isListFullScreen = false;
      tempState.mapListExpanded = false;
      // tempState.boundaryListFilter = "";
      let data = _this.state.mapData;
      tempState.mapData = data;
      tempState.boundary = _this.state.boundary;
     
        _this.setState(tempState, () => {
          if (!fromWrapper) {
            _this.props.renderHeader();
          }
        if(!fromState && _this.state.zipCodeSelected){
          _this.setZipCodeView(_this.state.selectedBoundaryCode, true, undefined, !fromStop);
          _this.renderMap();
        }else if (!fromWrapper){
          _this.renderMap(true);
        }
        if(isUnknownSelected){
          _this.prepareUnknownBoundariesFilter(true);
        }
        _this.expandingDefaultListRef.current.collapseClick();
        _this.props.setCompareMode(tempState.compareMode);
      })
    }
    if(!(!fromState && _this.state.zipCodeSelected) && !(_this.state.fullCompareSelection && !fromStop) && !(_this.state.isUnknownSelected)){
      if(!fromWrapper) {
        toggleLoader(true, 'renderMap',undefined, undefined, callback);
      }
    }else  if(_this.state.fullCompareSelection && !fromStop){ 
      _this.compareSegments();
    }else{
      callback();
    }
  }

  getGradientColors = (startColor, endColor, midpoint) => {
    return new Gradient().setColorGradient(startColor, endColor).setMidpoint(midpoint).getColors();
}

  generateColorsForPositiveValues = (startColor, endColor, colorVal) => {
    let _this = this;
    let colors = _this.getGradientColors(endColor, startColor, 3);
    let color = "";
    if (parseFloat(colorVal) < 0.02) {
      color = colors[0];
    }
    else if (parseFloat(colorVal) < 0.05) {
      color = colors[1];
    }
    else {
      color = colors[2];
    }

    return color;
  }

  generateColorsForNegativeValues = (startColor, endColor, colorVal) => {
    let _this = this;
    let colors = _this.getGradientColors(endColor, startColor, 3);
    let color = "";

    if (parseFloat(colorVal) > -0.02) {
      color = colors[0];
    }
    else if (parseFloat(colorVal) > -0.05) {
      color = colors[1];
    }
    else {
      color = colors[2];
    }

    return color;
  }

  getTableCell = (value, isCalculated, minValue) => {
    var _this = this;
    var value = value === undefined ? undefined : value ? value : 0;     //show the value of the chosen psl, or if it is undefined, show the value of the default psl
    var color = _this.props.userSettingsState.geographyNeutralColorCode;
    var colorVal = Math.abs(parseFloat(value)) > 100 ? parseFloat(value)/Math.abs(parseFloat(value)) : parseFloat(value)/100;
    if(!isCalculated) { // standard lines
      if(value === undefined){
        color = color;
      }else{
        let rgbStartColor = hexToRgb(_this.props.userSettingsState.geographyStandardStartColorCode); // translate the hash color to RGB
        let rgbEndColor = hexToRgb(_this.props.userSettingsState.geographyStandardEndColorCode);
        color = interpolateColor(colorVal, minValue || 0, 1, [rgbStartColor.r,rgbStartColor.g,rgbStartColor.b], [rgbEndColor.r,rgbEndColor.g,rgbEndColor.b]);
      }
    } else { // calculated
      if(value === undefined || value === 0){
          color = color;
      } else {
        if (parseFloat(value) > 0) {
            let startPositiveColor = _this.props.userSettingsState.geographyPositiveStartColorCode;
            let endPositiveColor = _this.props.userSettingsState.geographyPositiveEndColorCode;
            
            color = _this.generateColorsForPositiveValues(startPositiveColor, endPositiveColor, colorVal);
        } else {
            let startNegativeColor = _this.props.userSettingsState.geographyNegativeStartColorCode;
            let endNegativeColor = _this.props.userSettingsState.geographyNegativeEndColorCode;
            color = _this.generateColorsForNegativeValues(startNegativeColor, endNegativeColor, colorVal);
        }
      }
    }
    return color;
  }


  /*
  Function that calls the back functionality on click to restore the map to its default view
  */
  onBackClick = () => {
    let _this = this;
        _this.setState({
          boundary: BOUNDARIES.STATE,
          selectedBoundaryCode:[],
          boundaryListFilter : [],
          compareMode: false,
          isClosableList: false,
          zipCodeSelected: false
          // listTitle: listTitle,
          // expandedListTitle: listTitle,
        },()=>{
          _this.props.renderHeader();
          selectGlobalPolygon = undefined;
          comparePolygons = [];
          _this.go();
        })
  }

  setZipCodeView = (stateCode, fromUnselect, fromApply, fromCompare) => {
    let _this = this;
    let stateVector = _this.props.userSettingsState.stateVector;
    let stateFunction = MESSAGES.ui_filter.dropdowns.functions.equals.value;
    let logOperator = FILTER.VALUES.LOGICAL_OPERATOR.AND;
    let stateVectorObj = _this.props.vectorsStatusState.vectorOptions?.filter(e=>e.value.toLowerCase()+NAME === stateVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === stateVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === stateVector?.toLowerCase());
    let selectedBoundaryValue = fromUnselect ? _this.state.selectedBoundaryValue : _this.state.mapData.filter(e=>e.id === stateCode)[0]?.boundary;
    var stateFilter = getNewEntityFilter(stateVectorObj[0]?.value, stateVector?.toLowerCase().endsWith(NAME) ? NAME : NUMBER, selectedBoundaryValue, FILTER.VALUES.FILTER_ROW_TYPE.HEATMAP, logOperator, stateFunction);
    var filter = [stateFilter];
    let vector = _this.getBoundaryVectorObject();
    let listTitle = vector[0]?.label + " - " + (_this.state.manageColumnsProfile?.label || _LIST);
    filter = filter.filter(e=>!(e.function === "NEQ" && e.entities.length ===0));// if we removed all the entities and we still have the grouped entities toggle turned on
    var filterFinalOriginal = copyObjectValues(tryParse(_this.props.filterFinal));
    var filterFinal = copyObjectValues(tryParse(_this.props.filterFinal));
    filter.forEach(e => filterFinal.push(e));
    selectGlobalPolygon = undefined;
    _this.setState({
      boundary: fromApply ? BOUNDARIES.STATE : BOUNDARIES.ZIPCODE,
      selectedBoundaryCode:stateCode,
      boundaryListFilter: fromApply ? "[]" : JSON.stringify(filterFinal),
      listTitle: listTitle,
      expandedListTitle: listTitle,
      zipCodeSelected:false,
      selectedBoundaryValue: selectedBoundaryValue,
      isClosableList: false,
      sameZipCodeClicked:false,
      compareMode: fromCompare,
      isUnknownSelected: false
    },()=>{
      _this.props.renderHeader();
      if (fromUnselect) {
        _this.getListData() // setSize will trigger ajaxRequestFunc, which will call fetchProfitInfo()
      }
      if (fromUnselect) {
        _this.renderMap();
      }
      if (!_this.props.showGreyOverlay && !fromCompare && !fromUnselect) {
      _this.go(undefined,undefined,fromCompare);
      }
    })
  }

  setZipCodeVectorView=(zipcode)=>{
    let _this = this;
    let zipCodeVector = _this.props.userSettingsState.zipCodeVector;
    let zipCodeFunction = MESSAGES.ui_filter.dropdowns.functions.equals.value;
    let logOperator = FILTER.VALUES.LOGICAL_OPERATOR.AND;
    let zipCodeVectorObj = _this.props.vectorsStatusState.vectorOptions?.filter(e=>e.value.toLowerCase()+NAME === zipCodeVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === zipCodeVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === zipCodeVector?.toLowerCase());
    var zipCodeFilter = getNewEntityFilter(zipCodeVectorObj[0].value, zipCodeVector.toLowerCase().endsWith(NAME) ? NAME : NUMBER, zipcode, FILTER.VALUES.FILTER_ROW_TYPE.HEATMAP, logOperator, zipCodeFunction);
    var filter = [zipCodeFilter];
    let listTitle = findOptionByKey(_this.props.vectorsStatusState?.vectorOptions, _this.props.vectorsStatusState.vectors[0])?.label + " - " + (_this.state.manageColumnsProfile?.label || _LIST);
    filter = filter.filter(e=>!(e.function === "NEQ" && e.entities.length ===0));// if we removed all the entities and we still have the grouped entities toggle turned on
    var filterFinal = copyObjectValues(tryParse(_this.props.filterFinal));
    filter.forEach(e => filterFinal.push(e));   
    _this.setState({
      boundary: BOUNDARIES.ZIPCODE,
      boundaryListFilter : JSON.stringify(filterFinal),
      listTitle: listTitle,
      expandedListTitle: listTitle,
      zipCodeSelected:true,
      isClosableList: true,
      isUnknownSelected: false
      // isListExpanded: true
    },()=>{
      _this.getListData()// setSize will trigger ajaxRequestFunc, which will call fetchProfitInfo()
      if(!_this.state.isListExpanded){
        _this.expandingDefaultListRef?.current.expandClick();
      } 

    })
  }

  prepareStatesData=()=>{
    let _this = this;
    let states = copyObjectValues(statesMapShaper);
    let featureCollection = states.features; 
    let data = copyObjectValues(featureCollection);
    let alaskaId = statesMapShaper?.features?.find(e=>e.properties.name === Alaska).properties.id;
    let hawaiId = statesMapShaper?.features?.find(e=>e.properties.name === Hawaii).properties.id;
    data = data.filter(e=>e.properties.id !== alaskaId);
    data = data.filter(e=>e.properties.id !== hawaiId);
    
    for (var e in data) {
      data[e].id = data[e].properties.id; 
    }
    states.features = data;
    return states
  }

  prepareZipcodes=()=>{
    let _this = this
    var data = [];
    for (var i=0; i<=5; i++) {
      let zipcodesFeatures = zipCodes[i].name.features;
      let zipCodesData = zipcodesFeatures.filter(e=>e.properties.state === _this.state.selectedBoundaryCode || e.properties.state_code === _this.state.selectedBoundaryCode);
      data = data.concat(zipCodesData);
    }
   
    for (var e in data) {
      data[e].id = data[e].properties.id; 
    }
    var finalZipCodes = {type:"FeatureCollection",features:data};
    return finalZipCodes;
  }

  /**
   * get the value of each color between 0 and 1
   * @param {*} value 
   * @param {*} isCalculated 
   * @returns 
   */
  calculateProjectedValue = (value, isCalculated, min, max) => {
    let _this = this;
    if(isCalculated){
      value = max ===0? 0: (value/Math.abs(max))*100;//get the percentage of the value of this boundary per the max value
    }else{
      // translate the values to be positive =====>  "min" on x axis should take the place of 0 ( min + |min| ) ===> value of "max" shoul be added to |min| ===> max = max + |min| 
      value = Math.abs(max+Math.abs(min)) ===0? 0 :((value+Math.abs(min))/Math.abs(max+Math.abs(min)))*100;//get the percentage of the value of this boundary per the max value
    }
    return value;
  }

  renderMap = (isLoaderShowing, renderHomeOnly) => { // move to seperate component
    let _this = this;
    if (!isLoaderShowing && !renderHomeOnly) {
      toggleLoader(true,'renderMap');
    }
    let minValue = 0;
    let linearizedPSL = _this.state.linearizedHierarchy;
    let isCalculated = linearizedPSL?.find(f=>f.value === _this.state?.geoConfigObj?.psl?.value).costtype === costtype.calculated;
    let max = Math.max(..._this.state.mapData.filter(e=> e.id !== UNKNOWN).map(obj =>  obj.originalValue ? parseFloat(obj.originalValue):0));// get the boundary with the highest value
    let min = Math.min(..._this.state.mapData.filter(e=> e.id !== UNKNOWN).map(obj =>  obj.originalValue ? parseFloat(obj.originalValue):0));// get the boundary with the highest value
   
    var chart = this.chart;
    if (!renderHomeOnly) {
      if(_this.chart){
        _this.chart.dispose();
      }
       // Create map instance
      _this.chart = am4core.create("chart_map", am4maps.MapChart);
      chart = this.chart; 
      if (chart.logo) {
        chart.logo.disabled = true;
      }
      //chart style
      chart.maxZoomLevel = 115;
      chart.projection = new am4maps.projections.Miller();
      chart.deltaLongitude =-260; 
      chart.seriesContainer.wheelable = false;

      // Create map polygon series for USA states and zipcodes
      if (_this.state.boundary === BOUNDARIES.STATE) {
        chart.minZoomLevel =  _this.state.isListExpanded ? 1 : 1 
        var USASeries = chart.series.push(new am4maps.MapPolygonSeries()); // creating states series
        USASeries.tooltip.getFillFromObject = false;
        USASeries.tooltip.background.fill = am4core.color("#000000");
        USASeries.useGeodata = true;
        USASeries.reverseGeodata =true;
        USASeries.geodata = this.prepareStatesData();
        USASeries.exclude = ["AQ"];
        var USAPolygon = USASeries.mapPolygons.template;
        let polygon = USAPolygon;
        polygon.events.on("ready", function(event) { // on map init 
          var series = event.target.dataItem.component;
          let data = _this.state.mapData;
          for (var et in data) {
            if (data[et]!== UNKNOWN) {
              let polygonCurr = series.getPolygonById(data[et].id);
              if (data[et].originalValue && polygonCurr) {
                let value = !data[et].hasOwnProperty('originalValue') ? undefined : parseFloat(data[et].originalValue);
                if(value !== undefined){
                  minValue = _this.calculateProjectedValue(min, isCalculated, min, max);
                  minValue = minValue/100;
                  value = _this.calculateProjectedValue(value, isCalculated, min, max);// get value between 0 and 1 based on the type
                    // the max value will have 100% as percentage, so it takes the darkest color, all the other states will take lighter colors based on their values
                }
                polygonCurr.fill = am4core.color(_this.getTableCell(value, isCalculated, minValue)); // Set your desired color here
              }
            }
            if (Number(et) === data.length -1 && _this.chart?.series?.getIndex(0)?._mapPolygons?._values[0]?._visualLatitude !== 0 ) {
              toggleLoader(false, 'renderMap');
            }
          }
          if (_this.chart?.series?.getIndex(0)?._mapPolygons?._values[0]?._visualLatitude === 0) {
            _this.renderMap();
          }
        })

        //on state click event
        USAPolygon.events.on("hit", function(ev) { //setting on click event
          let availablePolygon = _this.state.mapData.filter(f => f.id === ev.target.dataItem.dataContext.id);
          if (availablePolygon.length > 0) {            
            let value = !availablePolygon[0].hasOwnProperty('originalValue') ? undefined : parseFloat(availablePolygon[0].originalValue);
            minValue = _this.calculateProjectedValue(min, isCalculated, min, max);
            minValue = minValue/100;
            value = _this.calculateProjectedValue(value, isCalculated, min, max);// get value between 0 and 1 based on the type
              // the max value will have 100% as percentage, so it takes the darkest color, all the other states will take lighter colors based on their values
          if(_this.state.compareMode){
            if (comparePolygons.length < 2) {
              if(comparePolygons.length > 0) {
                if(comparePolygons[0].target.dataItem.dataContext?.id !== ev.target.dataItem.dataContext.id){
                  let polygonTarget = {};
                  polygonTarget.target = ev.target;
                  polygonTarget.fill = ev.target.fill;
                  comparePolygons.push(polygonTarget);
                  ev.target.fill = am4core.color("#0F61FD")
                }else{
                  comparePolygons.pop();
                  ev.target.fill = am4core.color(_this.getTableCell(value, isCalculated, minValue));
                }
              }else{
                let polygonTarget = {};
                polygonTarget.target = ev.target;
                polygonTarget.fill =  ev.target.fill;
                comparePolygons.push(polygonTarget);
                ev.target.fill = am4core.color("#0F61FD");
              }
              _this.setState({
                fullCompareSelection: false
              },()=>{
              })
            }else{
              _this.setState({
                fullCompareSelection: true
              },()=>{
                _this.props.renderHeader();
              })
              if(comparePolygons.map(m=>m.target).find(f=>f.dataItem.dataContext.id === ev.target.dataItem.dataContext.id)){
                const index = comparePolygons.map(e=>e.target).indexOf(ev.target);
                comparePolygons.splice(index, 1);
                ev.target.fill = am4core.color(_this.getTableCell(value, isCalculated, minValue));
              }else{
                comparePolygons[1].target.fill = comparePolygons[1].fill
                comparePolygons[1].target = ev.target;
                comparePolygons[1].fill = ev.target.fill;
                ev.target.fill = am4core.color("#0F61FD");
              }
            }
            _this.setState({
              fullCompareSelection: comparePolygons.length > 1
            },()=>{
              _this.props.renderHeader();
            })
          }
          else{
            if (_this.state.mapData.filter(f => f.id === ev.target.dataItem.dataContext.id).length > 0) {
              ev.target.series.chart.zoomToMapObject(ev.target);
              _this.setZipCodeView(ev.target.dataItem.dataContext.id);
            }
          }
        }
        });
        USAPolygon.nonScalingStroke = true;
        USAPolygon.strokeOpacity = 0.5;
        USAPolygon.tooltipHTML = JSON.stringify(_this.state);
        USAPolygon.adapter.add("tooltipHTML", function(state, ev) { //setting tooltip
          let comp = tryParse(state)
          let value = comp.mapData.filter(e=>e.id === ev.dataItem.dataContext.id);
          value = value.length > 0 ? value[0].originalValue : undefined;
          value = value? formatValString(value, !comp.geoConfigObj.value || comp.geoConfigObj.value === defaultToggleValue ?FormatTypes.AMOUNT : FormatTypes.PERCENTAGE):undefined;
          return  `
          <table>
          <tr>
            <th align="left">`+comp.boundary+`:&nbsp</th>
            <td>{name}</td>
          </tr>
          <tr>`+(value ?`<th align="left">`+comp.geoConfigObj.psl.label+`:&nbsp</th>
          <td>`+value+`</td>
        </tr>
        </tr></table>` :`</table>`)
        })
      }else {
        chart.minZoomLevel = 1;
        var zipcodesSeries = chart.series.push(new am4maps.MapPolygonSeries()); // creating zipcodesSeries
        zipcodesSeries.tooltip.getFillFromObject = false;
        zipcodesSeries.tooltip.background.fill = am4core.color("#000000");
        zipcodesSeries.useGeodata = true;
        zipcodesSeries.reverseGeodata = true;
        zipcodesSeries.geodata = this.prepareZipcodes();
        
        var zipcodesPolygon = zipcodesSeries.mapPolygons.template;
        let polygon = zipcodesPolygon;
        chart.events.on('ready', function () {
          if (_this.state.boundary === BOUNDARIES.ZIPCODE) {
            let data = zipcodesSeries._mapPolygons._values;
            for (var et in data) {
              if (data[et] !== UNKNOWN) {
                let polygonCurr = data[et];
                let varData = mapHash.get(polygonCurr._dataItem.dataContext.id);
                if (varData?.length > 0 && polygonCurr) {
                  let value = !varData[0].hasOwnProperty('originalValue') ? undefined : parseFloat(varData[0].originalValue);
                  if(value !== undefined){
                    minValue = _this.calculateProjectedValue(min, isCalculated, min, max);
                    minValue = minValue/100;
                    value = _this.calculateProjectedValue(value, isCalculated, min, max);// get value between 0 and 1 based on the type
                      // the max value will have 100% as percentage, so it takes the darkest color, all the other states will take lighter colors based on their values
                  }
                  if(selectGlobalPolygon?.dataItem.dataContext.id === polygonCurr?.dataItem.dataContext.id && _this.state.isListExpanded && !_this.state.sameZipCodeClicked && _this.state.zipCodeSelected){
                    polygonCurr.fill = am4core.color("#0F61FD"); // Set your desired color here
                  }else{
                    polygonCurr.fill = am4core.color(_this.getTableCell(value, isCalculated, minValue)); // Set your desired color here
                  }
                }
              }
            }
          }
          if (_this.chart?.series?.getIndex(0)?._mapPolygons?._values[0]?._visualLatitude === 0) {
              toggleLoader(false, 'renderMap');
              _this.renderMap();
            }else{
              toggleLoader(false, 'renderMap');
            }
        })
      //on zipcode click event
        polygon.events.on("hit", function (ev) { //setting on click event
          let availablePolygon = _this.state.mapData.filter(f => f.id === ev.target.dataItem.dataContext.id)
          if (availablePolygon.length > 0) {            
            let value = !availablePolygon[0].hasOwnProperty('originalValue') ? undefined : parseFloat(availablePolygon[0].originalValue);
            minValue = _this.calculateProjectedValue(min, isCalculated, min, max);
            minValue = minValue/100;
            value = _this.calculateProjectedValue(value, isCalculated, min, max);// get value between 0 and 1 based on the type
              // the max value will have 100% as percentage, so it takes the darkest color, all the other states will take lighter colors based on their values
            if(_this.state.compareMode){
              selectGlobalPolygon = undefined;
              if (comparePolygons.length < 2) {
                if(comparePolygons.length>0){
                  if(comparePolygons[0].target.dataItem.dataContext.id !== ev.target.dataItem.dataContext.id){
                    let polygonTarget = {};
                    polygonTarget.target = ev.target;
                    polygonTarget.fill = ev.target.fill;
                    comparePolygons.push(polygonTarget);
                    ev.target.fill = am4core.color("#0F61FD")
                  }else{
                    comparePolygons.pop();
                    ev.target.fill = am4core.color(_this.getTableCell(value, isCalculated, minValue));
                  }
                }else{
                  let polygonTarget = {};
                  polygonTarget.target = ev.target;
                  polygonTarget.fill =  ev.target.fill;
                  comparePolygons.push(polygonTarget);
                  ev.target.fill = am4core.color("#0F61FD");
                }
                _this.setState({
                  fullCompareSelection: false
                },()=>{
                })
              }else{
                _this.setState({
                  fullCompareSelection: true
                },()=>{
                  _this.props.renderHeader()
                })
                if(comparePolygons.map(m=>m.target).find(f=>f.dataItem.dataContext.id === ev.target.dataItem.dataContext.id)){
                  const index = comparePolygons.map(e=>e.target).indexOf(ev.target);
                  comparePolygons.splice(index, 1);
                  ev.target.fill = am4core.color(_this.getTableCell(value, isCalculated, minValue));
                }else{
                  comparePolygons[1].target.fill = comparePolygons[1].fill
                  comparePolygons[1].target = ev.target;
                  comparePolygons[1].fill = ev.target.fill;
                  ev.target.fill = am4core.color("#0F61FD");
                }
              }
              _this.setState({
                fullCompareSelection: comparePolygons.length > 1
              },()=>{
                _this.props.renderHeader()
              })
            }else{
              previousSelectedPolygon = selectGlobalPolygon;
              var series = ev.target.dataItem.component
              if (previousSelectedPolygon) {
                let poly = _this.state.mapData.filter(f => f.id === previousSelectedPolygon.dataItem.dataContext.id);
                if(poly.length > 0) {
                  let prevValue = !poly[0].hasOwnProperty('originalValue') ? undefined : parseFloat(poly[0].originalValue);
                  if(prevValue !== undefined){
                    minValue = _this.calculateProjectedValue(min, isCalculated, min, max);
                    minValue = minValue/100;
                    prevValue = _this.calculateProjectedValue(prevValue, isCalculated, min, max);// get value between 0 and 1 based on the type
                      // the max value will have 100% as percentage, so it takes the darkest color, all the other states will take lighter colors based on their values
                  }
                  let prevpolygon = series.getPolygonById(previousSelectedPolygon.dataItem.dataContext.id);
                  prevpolygon.fill = am4core.color(_this.getTableCell(prevValue, isCalculated, minValue));
                }
              }
              selectGlobalPolygon = ev.target;
              if(selectGlobalPolygon?.dataItem.dataContext.id === previousSelectedPolygon?.dataItem.dataContext.id){
                _this.setState({
                  sameZipCodeClicked: true
                },()=>{
                  selectGlobalPolygon.fill = am4core.color(_this.getTableCell(value, isCalculated, minValue));
                  _this.setZipCodeView(_this.state.selectedBoundaryCode, true);
                })
              }else{
                ev.target.fill = am4core.color("#0F61FD");
                _this.setZipCodeVectorView(ev.target.dataItem.dataContext.id);
              }
            }
          }
        });
      zipcodesPolygon.tooltipHTML = JSON.stringify(_this.state); //setting tooltip
      zipcodesPolygon.adapter.add("tooltipHTML", function(state, ev) {
        let comp = tryParse(state)
        let value = comp.mapData.filter(e=>e.id === ev.dataItem.dataContext.id);
        value = value.length > 0 ? value[0].originalValue : "-";
        value = formatValString(value, !comp.geoConfigObj.value || comp.geoConfigObj.value === defaultToggleValue ?FormatTypes.AMOUNT : FormatTypes.PERCENTAGE);
        return  `
        <table>
        <tr>
          <th align="left">`+comp.boundary+`:&nbsp</th>
          <td>{id}</td>
        </tr>
        <tr>
          <th align="left">`+comp.geoConfigObj.psl.label+`:&nbsp</th>
          <td>`+value+`</td>
        </tr>
        </tr>
        </table>`;
      });
      
      zipcodesPolygon.nonScalingStroke = true;
      zipcodesPolygon.strokeOpacity = 0.5;
      zipcodesPolygon.fill = am4core.color("#eee");
      }
      
    // Zoom control
      chart.zoomControl = new am4maps.ZoomControl();
      chart.zoomControl.plusButton.scale = 1; // Adjust the scale value as per your preference
      chart.zoomControl.minusButton.scale = 1;
      chart.zoomControl.plusButton.padding(1, 2, 1, 2);
      chart.zoomControl.minusButton.padding(1, 2, 1, 2);
      if (_this.state.mapData.length === 0 ) toggleLoader(false, 'renderMap');
      chart.zoomControl.marginBottom = 50
      chart.zoomControl.plusButton.fontSize = 20;
      chart.zoomControl.minusButton.fontSize = 20;
      chart.zoomControl.marginRight = 5;
      chart.zoomControl.plusButton.background.fill = am4core.color("#ffffff");
      chart.zoomControl.plusButton.background.stroke = am4core.color("#E4E4E4");
      chart.zoomControl.plusButton.background.strokeWidth = "1px"; // Set the border width
      chart.zoomControl.minusButton.background.fill = am4core.color("#ffffff");
      chart.zoomControl.minusButton.background.stroke = am4core.color("#E4E4E4");
      chart.zoomControl.plusButton.background.strokeWidth = "1px"; // Set the border width
      if (_this.state.mapData.length === 0) toggleLoader(false,'renderMap');
        let hasBanner = _this.props.isScenarioBannerVisible; //_this.headerRef.state.scenarioObject && _this.headerRef.state.scenarioObject.scenario_status === SCENARIO_STATUS.REVIEW && _this.headerRef.state.showScenarioBanner;
        let filter = typeof _this.props.filterFinalBasic === "string" ? tryParse(_this.props.filterFinalBasic) : _this.props.filterFinalBasic;
        let filterFinal = typeof _this.props.filterFinal === "string" ? tryParse(_this.props.filterFinal) : _this.props.filterFinal;
        // if (hasBanner && (filter?.length > 0 || filterFinal?.length > 0)) {
        //   $(".chart-container").height("58vh");
        // } else if (hasBanner) {
        //   $(".chart-container").height("63vh");
        // } else if (filter.length > 0 || filterFinal.length > 0) {
        //   $(".chart-container").height("72vh");
        // } else {
        //   $(".chart-container").height("69vh");
        // }
      }
    }

  runExtendedProfitStack = (selectedBoundary) => {
    let _this = this;
    let isDrilling = false;
    var profitStackItems = [];
    const profitStackDataSet = this.props.datasetStatusState.dataSet;
    const profitFormat = this.props.profitFormat;
    var drilledPath = [];
    var entities = selectedBoundary;
    let vector =_this.state.boundary === BOUNDARIES.ZIPCODE?_this.props.userSettingsState.zipCodeVector: _this.props.userSettingsState.stateVector;
    let boundaryVector = _this.props.vectorsStatusState.vectorOptions?.filter(e=>e.value.toLowerCase()+NAME === vector?.toLowerCase() || e.value.toLowerCase()+NUMBER === vector?.toLowerCase() || e.value.toLowerCase()+NUMBER === vector?.toLowerCase());
    for(let i=0; i < entities.length; i++) {
      let entity = entities[i];
      var loopedItem = {};
      loopedItem.key = entity.value;
      if(vector.toLowerCase().endsWith(NUMBER)){
        loopedItem.number = entity.value;
      }else if(vector.toLowerCase().endsWith(NAME)){
        loopedItem.name = entity.value;
      }
      loopedItem.lines = entity.lines;
      loopedItem.tier = boundaryVector[0].value;
      loopedItem.dataset = profitStackDataSet;
      loopedItem.profitFormat = profitFormat;
      loopedItem.color = COLORS_PALETTE[i]; // get the correspondant color based on entities order
      profitStackItems.push(loopedItem);
    }
    let selectedStackVisibility = this.props.PSViewsState.profitStackViews.find(e => e.value === 0).visibility;
    let access = selectedStackVisibility && selectedStackVisibility === COLUMN_PROFILE.VISIBILITY_OPTIONS.COMPANY?
    checkIfSectionIdExists(this.props.userAllowedMenuLinks, isExtendedStack? ALL_WIDGETS.FIELDS.PROFIT_STACK : MENU_ITEM.FIELDS.VIEW_COMPANY_STACKS) : true;  
      if (!access) {
      alert("No access");
      return;
    }

    if(this.pageComponent) {
        this.props.dispatch(updateGeographyListData([this.fillGeographyDefaultListBeforeBackObj()], "geographyListData", false));
    }
    this.props.dispatch(updateGeographyObjBeforeBack([this.fillGeographyMapBeforeBackObj()], "geographyObjBeforeBack", false));////////////
    this.props.dispatch(updateGeographyCostHierarchy([this.fillGeoConfigureBeforeBackObj()], "geographyCostHierarchy", false));

    

    sessionStorage.setItem("selectedProfile_list", JSON.stringify(this.state.manageColumnsProfile)); // save the selected list for back navigation
    let startDate = getValidPeriods().startDate;
    let endDate = getValidPeriods().endDate;
    var pathName = MENU_ITEM_URLS.PROFIT_PATTERNS_ENTITY_STACKS;
    this.props.history.push({
      pathname: pathName,
      search: "?",
      hash: "",
      state: {
        scenarioState:this.props.history.location.state.scenarioState,
        origin: window.location.href,
        origin_name: this.props.profitFormat,
        profitFormat: this.props.profitFormat,
        profitStackItems: profitStackItems,
        drilledPath: drilledPath,
        drillFilter: this.state.drillFilter,
        drilling: isDrilling,
        useFilterCookies: false,
        savedFilterDataState: this.props.filterDialRef.state.savedFilterData,
        FY: this.state.FY,
        scenarios: this.props.scenarioState.scenarios,
        originalScenarios: this.props.scenarioState.scenarios,
        dataset: this.props.datasetStatusState.dataSet,
        mainVector: boundaryVector[0].value, // main vector - from vector dropdown
        vectorOptions: this.props.vectorsStatusState.vectorOptions,
        filter: copyObjectValues(this.props.filterFinal),
        mainFilter: copyObjectValues(this.props.filterFinal),
        outsideFilter: JSON.stringify({ filter: copyObjectValues(tryParse(this.props.filterFinal)) }),
        mainFilterId: this.props.filterDialRef.state.savedFilterData.saved_filter_id,
        order: this.props.location.state ? this.props.location.state.order : undefined,
        startDate: startDate,
        endDate: endDate,
        profitStackViewId: this.props.PSViewsState.profitStackViewId,
        headerOptionChanged: false,
        datasetOptions: this.props.datasetStatusState.datasetOptions,
        periods: this.props.clientPeriodsStatusState.periods,
        isRedirectionFromBubble: true, // if it is a redirection, we won't remove the selected profile from session
        bubbleReportTitle: lang.compare_stacks+" - " + boundaryVector[0].label,
        drillRows: this.state.drillRows,
        submitDrill: this.pageComponent?.state?.submitDrill,
        mainFilterFinalBasic: copyObjectValues(this.props.filterFinalBasic),
        selectedBoundaryName: this.state.selectedBoundaryName
      }
    });
  }

   /**
   * fill object of Configure Dialog states and data that are needed when pressing on back from entity stacks
   * @returns 
   */
  fillGeoConfigureBeforeBackObj = () => {
    let _this = this;
    let geoObj = {};
    geoObj.geoConfigObj = _this.state.geoConfigObj;
    geoObj.confObjPSLines = {
      xAxisPSLines: confObjPSLines.xAxisPSLines,
    }
    geoObj.selectedConfObjPSLines = confObjPSLines;
    geoObj.linearizedHierarchy = _this.state.linearizedHierarchy;
    return geoObj;
  }

  /**
   * fill object of Chart states and data that are needed when pressing on back from entity stacks
   * @returns 
   */
  fillGeographyMapBeforeBackObj = () => {
    let _this = this;
    let geographyObj = {};
    geographyObj.mapData = _this.state.mapData;
    geographyObj.isListExpanded = _this.state.isListExpanded;
    geographyObj.expandedListTitle = _this.state.expandedListTitle;
    geographyObj.listTitle = _this.state.listTitle;
    geographyObj.addModified = _this.state.addModified;
    geographyObj.manageColumnsProfile = _this.state.manageColumnsProfile;
    geographyObj.tableColumns = _this.state.tableColumns;
    geographyObj.vectorObjects = _this.props.vectorsStatusState.vectorObjects;
    geographyObj.vectorOptions = _this.props.vectorsStatusState.vectorOptions;
    geographyObj.filterFinal = copyObjectValues(this.props.filterFinal);
    geographyObj.boundary = _this.state.boundary;
    geographyObj.selectedBoundaryCode = _this.state.selectedBoundaryCode;
    geographyObj.linearizedHierarchy = _this.state.linearizedHierarchy;
    geographyObj.unknownBoundaries = _this.state.unknownBoundaries;
    geographyObj.zipCodeSelected = _this.state.zipCodeSelected;
    geographyObj.value = _this.state.value;
    return geographyObj;
  }

  /**
   * fill object of List states and data that are needed when pressing on back from entity stacks
   * @returns 
   */
  fillGeographyDefaultListBeforeBackObj = () => {
    let _this = this;
    let listData = copyObjectValues(_this.pageComponent.state.dataFinal);
    listData.orderer = _this.pageComponent?.state?.sorter;
    listData.order = _this.pageComponent?.state?.order.toLowerCase();
    listData.columnsWidths = _this.pageComponent?.state.columnsWidths;

    return listData;
  }
  

  compareSegments = () => {
    let comparePolygonsArray = comparePolygons.map(e=>e.target.dataItem.dataContext.id);
    let selectedBoundary = [];
    for(let polygonId of comparePolygonsArray){
      for (let mapObject of this.state.mapData){
        if(mapObject.id === polygonId){
          selectedBoundary.push(mapObject);
        }
      }
    }
    let arr = [];
    for (var e in selectedBoundary) {
      let comb = selectedBoundary[e].combinations.split(",");
      for (var et in comb) {
        arr.push({label: comb[et], value:comb[et]})
      }
    }
    // selectedBoundary.map(e => {
    //     e.label = e;
    //     e.value = e;
    //   });
    this.runExtendedProfitStack(arr);
  }

  minimizeMaximizeList = () => {
    let _this = this;
    _this.setState({
      isListFullScreen:! _this.state.isListFullScreen,
    },()=>{
      if (_this.state.boundary === BOUNDARIES.ZIPCODE || _this.state.isListExpanded) {
        _this.chart.minZoomLevel = 1;
      } else{
        _this.chart.minZoomLevel = 1;
      }
      _this.props.renderHeader();
      _this.renderMap(false,true)
    })
  }

  expandCollapseList = () => {
    let _this = this;
    _this.setState({
      listTitle: !_this.state.isListExpanded ? _this.state.expandedListTitle : _LIST,
      isListExpanded: !_this.state.isListExpanded,
      isListFullScreen: false,
    },()=>{
      if (_this.state.boundary === BOUNDARIES.ZIPCODE || _this.state.isListExpanded) {
        _this.chart.minZoomLevel = 1;
      } else{
        _this.chart.minZoomLevel = 1;
      }
      _this.renderMap(false,true);
      _this.props.renderHeader();
    })
  }

  onApplyManageColumnsProfileChanges(profile) {
    let _this = this;
    let ref = _this.expandingDefaultListRef.current ? _this.expandingDefaultListRef.current : _this.expandingSelectedListRef.current;
    let vector = _this.getBoundaryVectorObject();
   let listTitle = vector[0]?.label + " - " + (profile?.label || _LIST); //replace by boundary
    _this.setState({
      listTitle: listTitle,
      expandedListTitle: listTitle,
      manageColumnsProfile: _this.expandingDefaultListRef.current ? ref.getDefaultManageColumnsRef().simplifyProfileReturnNames(ref.getDefaultManageColumnsRef().state.selectedProfile) : _this.expandingSelectedListRef.current ? ref.getSelectedManageColumnsRef().simplifyProfileReturnNames(ref.getSelectedManageColumnsRef().state.selectedProfile) : _this.state.manageColumnsProfile,
      addModified: _this.expandingDefaultListRef.current ? ref.getDefaultManageColumnsRef().simplifyProfileReturnNames(ref.getDefaultManageColumnsRef().state.selectedProfile).is_modified : _this.expandingSelectedListRef.current ? ref.getSelectedManageColumnsRef().simplifyProfileReturnNames(ref.getSelectedManageColumnsRef().state.selectedProfile).is_modified : _this.state.manageColumnsProfile.is_modified,
    }, () => {
      _this.getListData()
    })
  }

  getListData = () => {
    let _this = this;
    _this.pageComponent?.tabulatorList?.current?.tabulator?.setPageSize(100);
    _this.setState({
      callGo: false
    })
  }


  closeClick = (fromApply) =>{
    let _this = this;
    let ref = _this.expandingDefaultListRef.current ? _this.expandingDefaultListRef.current : _this.expandingSelectedListRef.current;
    let profile = ref.getDefaultManageColumnsRef().state.selectedProfile ? ref.getDefaultManageColumnsRef().simplifyProfileReturnNames(ref.getDefaultManageColumnsRef().state.selectedProfile) : _this.state.manageColumnsProfile;
    let vector = _this.getBoundaryVectorObject();
   let listTitle = vector[0]?.label + " - " + (profile?.label || _LIST); //replace by boundary
    _this.setState({
      mapListExpanded: false,
      boundaryListFilter:[],
      manageColumnsProfile: profile,
      expandedListTitle: listTitle,
      isClosableList:false,
      isUnknownSelected:false
    },()=>{
      isActiveBubble = false;
      if (!fromApply) {
        _this.expandingDefaultListRef.current.expandClick();
        selectGlobalPolygon = undefined;
      }
      _this.setZipCodeView(_this.state.selectedBoundaryCode, true, fromApply);
      if (!_this.props.showGreyOverlay) {
        _this.getListData()
      }
    })
  }

  setMapFilter(filterString) {
    let _this = this;
    let ref = _this.expandingDefaultListRef.current ? _this.expandingDefaultListRef.current : _this.expandingSelectedListRef.current;
    let vector = _this.getBoundaryVectorObject();
    let listTitle = vector[0]?.label + " - " + (profile?.label || _LIST); //replace by boundary
    var filter = filterString ? filterString : [];
    filter = filter.filter(e=>!(e.function === "NEQ" && e.entities.length ===0));// if we removed all the entities and we still have the grouped entities toggle turned on
    var filterFinal = copyObjectValues(tryParse(_this.props.filterFinal));
    filter.forEach(e => filterFinal.push(e));
    _this.setState({
    boundaryListFilter : JSON.stringify(filterFinal),
    listTitle: listTitle,
    expandedListTitle: listTitle,
    },()=>{
      _this.getListData()
    });
  }

  onSelectDefaultProfile = (profile, callback) => {
    let _this = this;
    profile.is_modified = false;
    _this.updateManageColumnsProfile(profile, callback);
  }

  onChangeProfileData = (profile, callback) => { 
    let _this = this;
    _this.updateManageColumnsProfile(profile, callback);
    _this.setState({
      callGo: true
    })
  }

  updateManageColumnsProfile = (profile, callback) => {
    let _this = this;
      let manageColumnsProfile = copyObjectValues(profile);
      manageColumnsProfile.is_modified = profile.is_modified;
      manageColumnsProfile.is_applied = profile.is_applied;
      manageColumnsProfile.name = profile.name;
      manageColumnsProfile.label = profile.name;
      manageColumnsProfile.simplified_columns = profile.simplified_columns || profile.columns;
      let vector = _this.getBoundaryVectorObject();
      let profileLabel = _this.state.manageColumnsProfile
      let listTitle = vector[0]?.label + " - " + (profileLabel?.label || _LIST); 
      _this.setState({
        manageColumnsProfile:manageColumnsProfile,
        listTitle: listTitle,
        addModified:manageColumnsProfile.is_modified
      },()=>{
        if (callback) callback();
      })
  }

  approveBulkChange = (key, element) => {
    if(key === PROFILE_COLUMN.IS_CHECKED) {
        let elementCosttype = element[PSS.DUPLICATE_KEYS.COSTTYPE];
        if(elementCosttype === costtype.calculated) {
            //if calculated, allow only if not percentage
            return ALL_FORMATS[element[PSS.FORMAT_TYPE_ID]] !== FormatTypes.PERCENTAGE;
        } else {
            return ![costtype.attribute, costtype.medians, costtype.ratios, costtype.totalratios, costtype.count].includes(elementCosttype);
        }
    }
  }

  manageColumnsDefaultParams = () => {
    let _this = this;
    let stateVector = _this.props.userSettingsState.stateVector;
    let zipCodeVector = _this.props.userSettingsState.zipCodeVector;
    let stateVectorObj = _this.props.vectorsStatusState?.vectorOptions?.filter(e=>e.value.toLowerCase()+NAME === stateVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === stateVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === stateVector?.toLowerCase());
    let zipCodeVectorObj = _this.props.vectorsStatusState?.vectorOptions?.filter(e=>e.value.toLowerCase()+NAME === zipCodeVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === zipCodeVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === zipCodeVector?.toLowerCase());

    return {
      scenarioId: _this.props.scenarioState?.scenario,
      profitFormat: ALL_WIDGETS.FIELDS.LIST,
      approveBulkChange: _this.approveBulkChange,
      user: _this.props.userSettingsState?.user,
      onSelectProfile: _this.updateManageColumnsProfile,
      onSelectDefaultProfile: _this.onSelectDefaultProfile,
      onChangeProfileData: _this.onChangeProfileData,
      manageColsAccess: _this.props?.manageColsAccess,
      vectorObjects: _this.state.zipCodeSelected ? _this.props.vectorsStatusState.vectorObjects :_this.state.boundary === BOUNDARIES.STATE ? stateVectorObj : zipCodeVectorObj,
      vector: _this.state.zipCodeSelected ? _this.props.vectorsStatusState.vectorObjects[0].value :_this.state.boundary === BOUNDARIES.STATE ? stateVectorObj && stateVectorObj.length > 0 ? stateVectorObj[0]?.value :"" : zipCodeVectorObj && zipCodeVectorObj.length > 0 ? zipCodeVectorObj[0]?.value : "",
      sectionsProfilesAreValidFor: _this.manageColumnsProps?.manageColumnsUpdateSections,
      hideDropdown: false,
      vectorOptions: _this.props.vectorsStatusState?.vectorOptions,
      updateManageColumnsProfile: _this.updateManageColumnsProfile,
      dispatch: _this.props?.dispatch,
      profileColumns: _this.props?.profileColumns,
      customGroups: _this.props?.customGroups,
      profiles: _this.props?.profiles,
      stateProfiles: _this.props?.stateProfiles,
      columnProfiles: _this.props?.columnProfiles,
      manageColumnsSelectionLimit: _this.props.userSettingsState?.manageColumnsSelectionLimit,
      isDefaultProfileQT: false,
      characterSizeLimit: _this.props?.userSettingsState.characterSizeLimit,
      useAppearanceList: _this.props?.userSettingsState.useAppearanceList
    };
  }


  filterParams = () => {
    let _this = this;

    return {
      section_id:_this.props.match.params.sectionId,
      tier:_this.props.vectorsStatusState.vectors[0],
      vectorOptions:_this.props.vectorsStatusState.vectorOptions,
      filter: _this.state.boundaryListFilter ? _this.state.boundaryListFilter : copyObjectValues(this.props.filterFinal),
      REPORT_FORMAT_LIST_TOP:ALL_REPORTS.LIST_TOP,
      REPORT_FORMAT_LIST_QUADRANT_TIER:ALL_REPORTS.LIST_QUADRANT_TIER,
      psFilterDisabled:_this.state.psFilterDisabled,
      quadrantsDisabled:_this.state.quadrantsDisabled,
      refresh:_this.refreshFilter,
      useCookies:false,
      compNumber:1 , //changes the id of the filter comp because in heatmap we have two filters
      filterLastUpdate:_this.state.dimensionFilterLastUpdate,
      filterDisplayEditable:_this.state.filterDisplayEditable,
      quarterOptions:_this.state.optionsListDataSet,
      profitFormat:_this.props.profitFormat,
      fromOptions:_this.state.optionsListDataSet,
      dataSet:_this.props.datasetStatusState.dataset,
      checkIfMonthsValid:_this.checkIfMonthsValid,
      user:_this.props.userSettingsState.user,
      psLinesOptions: _this.props.psLinesOptions,
    };
  }

  getExportFilter = () => {
    let _this = this;
    if (_this.state.notValidScenario) return;
    let boundaryListFilter = _this.state.boundaryListFilter? typeof _this.state.boundaryListFilter === "string" ? (_this.state.boundaryListFilter === "" ? [] : tryParse(_this.state.boundaryListFilter)) : _this.state.boundaryListFilter:[];
    let exportQueryFilter = _this.props?.getExportQueryFilter(boundaryListFilter);
    let filter = typeof _this.props.filterFinal === "string" ? _this.props.filterFinal === "" ? [] : copyObjectValues((tryParse(_this.props.filterFinal))) : copyObjectValues(_this.props.filterFinal);
		if((!exportQueryFilter || exportQueryFilter === "None") && filter?.length > 0) {
			exportQueryFilter = filter.find(f => !f.isBasicFilter) ? formatAdvancedFilter(filter, _this.props.userSettingsState.user.user_allowed_vectors, _this.props.datasetStatusState.datasetOptions, _this.props.vectorsStatusState.vectorOptions, _this.props.psLinesOptions) : formatBasicFilter(filter, _this.props.userSettingsState.user.user_allowed_vectors);
		}
    return exportQueryFilter;
  }

  renderListComponent = () => {
    let _this = this; 
    let periodsObject = _this.getPeriodsObject();
    let stateVector = _this.props.userSettingsState.stateVector;
    let periods = periodsObject.periods;
    let zipCodeVector = _this.props.userSettingsState.zipCodeVector;
    let stateVectorObj = _this.props.vectorsStatusState.vectorOptions?.filter(e=>e.value.toLowerCase()+NAME === stateVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === stateVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === stateVector?.toLowerCase());
    let zipCodeVectorObj = _this.props.vectorsStatusState.vectorOptions?.filter(e=>e.value.toLowerCase()+NAME === zipCodeVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === zipCodeVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === zipCodeVector?.toLowerCase());
    let lastSelectedPeriod = periods[periods.length - 1];
    let builtPeriods = _this.props.periodsStatusState?.actuallyBuiltPeriods?.map(m => m.label);
    let rollingPeriod = getLastBuiltPeriodForSegments(builtPeriods, lastSelectedPeriod, 12);
      return (
      <>
        {!_this.state.mapListExpanded ?
          <ProfitMapList ref={r => _this.pageComponent = r} key={"bubble-default-list"}
            headerRef={_this.headerRef}
            manageColumnsRef={_this.expandingDefaultListRef.current?.getDefaultManageColumnsRef()}
            filter={tryParse(_this.state.boundaryListFilter)?.length > 0 ? _this.state.boundaryListFilter : copyObjectValues(this.props.filterFinal)}
            refresh={true}
            user={_this.props.userSettingsState.user}
            sectionId={_this.props.match.params.sectionId}
            options={_this.props.vectorsStatusState.vectorOptions}
            isQuadrant={false}
            dataset={periodsObject.quarter}
            FY={_this.props.userSettingsState.FY}
            dataTier={_this.state.zipCodeSelected ? _this.props.vectorsStatusState.vectorObjects[0].value :_this.state.boundary === BOUNDARIES.STATE ? stateVectorObj && stateVectorObj.length > 0 ? stateVectorObj[0]?.value : "" : zipCodeVectorObj && zipCodeVectorObj.length > 0 ? zipCodeVectorObj[0]?.value : ""}
            order={_this.state.order}
            limit={getLimit}
            idToken={_this.state.idToken}
            report={ALL_REPORTS.LIST_TOP}
            dbDataSet={_this.props.userSettingsState.dbDataSet}
            tablePrefix={_this.props.userSettingsState.tablePrefix}
            project_id={_this.props.userSettingsState.project_id}
            machine_name={_this.props.userSettingsState.machine_name}
            manageColumnsProfile={_this.state.manageColumnsProfile}
            fetchDataOnMount={false}
            scenarioNumber={_this.props.scenarioState.scenarioNumber}
            scenarioObject={_this.props.scenarioState.scenarioObjects[0]}
            toggleLoader={toggleLoader}
            isDrilling={false}
            profitFormat={ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.List}
            psFilterDisabled={_this.state.psFilterDisabled}
            scenario_id={_this.props.scenarioState.scenario}
            setDataLength={_this.setDataLength}
            checkIfMonthsValid={_this.checkIfMonthsValid}
            setLimit={_this.setLimit}
            getExportQueryFilter={this.getExportFilter()}
            updateStateFromParent={_this.updateStateFromParent}
            records_limit={_this.props.userSettingsState.records_limit}
            manageColsAccess={_this.props.manageColsAccess}
            vectorObjects={_this.props.vectorsStatusState.vectorObjects}
            updateManageColumnsProfile={_this.updateManageColumnsProfile}
            selectedPeriods={periodsObject.periods}
            months={periodsObject.months}
            manageColumnsSelectionLimit={this.props.userSettingsState.manageColumnsSelectionLimit}
            dispatch={_this.props.dispatch}
            dataChunkLimit={this.props.userSettingsState?.dataChunkLimit}
            isDefaultProfileQT={false}
            isLandscapeBubble={true}
            isGeography={true}
            isLandscapeBubbleDefault={true}
            geographyListData={this.props.geographyListData}
            landscapeBubbleListData={this.props.landscapeBubbleListData}
            reportExcelName = {ALL_REPORTS.GEOGRAPHY_CONCENTRATION}
            rollingPeriod={rollingPeriod}
            parentComponent = {ALL_WIDGETS.GEOGRAPHY_CONCENTRATION}
            checkForLimitAccessMessage={this.props.checkForLimitAccessMessage}
            setExportOptionsForUser={this.setExportOptionsForUser}
            isListExpanded = {this.state.isListExpanded}
            fromExpanding
            updateColumnsOrder={_this.expandingDefaultListRef.current?.updateColumnsOrder}
            useAppearanceList={this.props.userSettingsState.useAppearanceList}
            exportScopeFilter={this.props.exportScopeFilter}
          />
          :""}

      </>
    )
  }

  setExportAll=(value, isExportExcel)=> {
    let _this = this;
    _this.setState({
        export_all: value
    },function(){
      if(!isExportExcel){
        _this.exportAll();
      }
    })
  }


  logExportData=(activityStatus)=> {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const path = API_URL.PROFIT_LANDSCAPE_EXPORT_ALL;
    var filter = copyObjectValues(this.props.filterFinal);

    var myUrl = baseUrl + path + "?";
    var query = "action=logExportData"
        // + "&ip_address=" +ipAddress
        + "&tier=" + this.props.vectorsStatusState.vectors[0]
        + "&quarter=" + this.props.datasetStatusState.dataset
        + "&filter={'filter':" + encodeURIComponent(filter) + "}"
        + "&activity_status=" + activityStatus;
    setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
    $.ajax({
        url: myUrl + query,
        async: true,
        crossDomain: true,
        xhrFields: {withCredentials: true},
        dataType: 'json'
    });
  }

  startExport=()=>{
      this.setState({
          userAuthenticated_exportAll: true,
      })
      this.confirmPassRef.setState({
          userAuthenticated_exportAll: true,
      })
  }


  reauthenticateWithCredential=()=> {
    var email = this.props.userSettingsState.user.email;
    var password = $('#passwordConfirm').val();
    var obj = this;

    if(obj.props.userSettingsState.isClientSSO && !parseBoolean(obj.props.userSettingsState.user.is_system)){
        obj.startExport();
    }else{
    // Sign in with the newly linked credential
    signInWithEmailAndPassword(auth, email, password)
        .then(function () {
            obj.startExport();
        }).catch(function (error) {
            if (error.code === AuthErrorCodes.MFA_REQUIRED) {
                obj.startExport();
                return;
            }
            obj.logExportData(USER_ACTIVITY_USER_NOT_AUTHENTICATED);
            Popup.alert("The password you entered is incorrect.");
            return false;
        });
    }
  }
  /* #endregion */

  cancel(){
      this.confirmPassRef.setState({userAuthenticated_exportAll:false})
  }

  launchExportToast=()=>{
    $("#toastExportBubble").addClass("show");
    setTimeout(function(){
        $("#toastExportBubble").removeClass("show");
    }, 4000);
  }

  showError = () =>{
    let _this = this;
    _this.setState({
      error: lang.header.custom_text.error,
      toastMessage: lang.header.custom_text.error,
      isExporting: false
    },()=>{
        _this.launchExportToast();
    })
  }

  /* #region export all */
  exportAll=()=> {
    let _this = this;
    let useOutputService = _this.props?.userSettingsState.useOutputService;
    window._pi_initialiseTracking(UI_ACTIONS.EXPORT_ALL, this.props.userSettingsState.user.email, this.props.userSettingsState.machine_name, this.props.profitFormat, false);
    this.confirmPassRef.setState({
        userAuthenticated_exportAll: false,
    })
    this.setState({
        userAuthenticated_exportAll: false,
        toastMessage:lang.header.custom_text.download_start,
        error:undefined
    }, function () {
      try { //export
        _this.launchExportToast();
        let query = $("#export-all-form").serializeArray().reduce(function(obj, item) {
            obj[item.name] = item.value;
            return obj;
        });
        query.numericFormatName = window._format?.numeric_format_name

        let onThenCallback = function (blob) {
          if (blob?.size === 0) {
            showError();
            return;
          }
          
          if(useOutputService) { // this means we are using output-service export logic
              $("#frame_export").attr('src', blob.signedUrl);
          } else {
              triggerExportData(blob, query, _this.props.userSettingsState);
          }
          _this.setState({
            isExporting: false
          })
        }

        let onErrorCallback = function () {
          _this.showError();
        }

        let options = {
            [FETCHAPI_PARAMS.funcName]: "exportAll",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.path]: API_URL.PROFIT_LANDSCAPE_EXPORT_ALL,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.onErrorCallback]: onErrorCallback,
            [FETCHAPI_PARAMS.useBlob]: !useOutputService,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.geography.geography_list.screen_name,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.geography.geography_list.requests_description.export + (this.state.export_all? lang.observability.output.list.requests_description.with_all_psls:""),
            [FETCHAPI_PARAMS.vector]: query.tier,
            [FETCHAPI_PARAMS.scopeFilterApplicable]: true,
            [FETCHAPI_PARAMS.periods]: "'"+query.selectedPeriods+"'",
        }
        fetchAPI(options);
      } catch (err) {
        _this.showError();
      }
        //removed because there is nothing else to do for this action, tracking continued on the API
        window._pi_removeTrackingData();
    });
  }
  
  warningModal = () => {
    return (
        <div className="uk-border-rounded">
            <h5 className="uk-text-large">{lang.incompatible_vectors}</h5>
        </div>
    )
  }

  dialogActions = () => {
      return (
            <Button 
              label={lang.modal.buttons.ok}
              variant={BUTTON_VARIANT.SECONDARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              className="uk-padding-small-right uk-margin-default-right"
              onBtnClick={this.setCloseWarning}
          />
      )
  }

  

  setOpenWarning = () => {
      this.setState({
          openWarning:true
      })
  }

  setCloseWarning = () => {
      this.setState({
          openWarning:false
      })
  }

  /**
   * build the unknown boundaries filter object
   * @returns 
   */
  buildUnknownBoundariesFilter = (isUnknownSelected) =>{
    // if we are selecting unknown card, call this.getBoundaryVectorObject(), else always use state vector, when unselecting the card we have to go back to the state filter
    let vector = isUnknownSelected?this.getBoundaryVectorObject(): this.props.vectorsStatusState.vectorOptions.filter(e=>e.value.toLowerCase()+NAME === this.props.userSettingsState.stateVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === this.props.userSettingsState.stateVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === this.props.userSettingsState.stateVector?.toLowerCase());
    // if we are selecting unknown card, translate unknownBoundaries from list of Strings to list of objects having label and value, else use this.state.selectedBoundaryValue
    let unknownEntities = isUnknownSelected?this.state.unknownBoundaries.map(e=> {return ({label:e , value:e})}):this.state.selectedBoundaryValue;
    let type = this.state.boundary === BOUNDARIES.STATE || !isUnknownSelected ? (this.props.userSettingsState.stateVector.toLowerCase().endsWith(NAME) ? NAME : NUMBER) : (this.props.userSettingsState.zipCodeVector.toLowerCase().endsWith(NAME) ? NAME : NUMBER);
    let unknownFilter = getNewEntityFilter(vector[0].value, type, unknownEntities , FILTER.VALUES.FILTER_ROW_TYPE.HEATMAP, FILTER.VALUES.LOGICAL_OPERATOR.AND, MESSAGES.ui_filter.dropdowns.functions.equals.value);
    return [unknownFilter];// return unknownFilter as array 
  }

  /**
   * get state or zipcode object based on boundary selection
   * @returns 
   */
  getBoundaryVectorObject = () =>{
    let _this = this;
    let stateVector = _this.props.userSettingsState.stateVector;
    let zipCodeVector = _this.props.userSettingsState.zipCodeVector;
    let vector = _this.state.boundary === BOUNDARIES.STATE ? _this.props.vectorsStatusState.vectorOptions.filter(e=>e.value.toLowerCase()+NAME === stateVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === stateVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === stateVector?.toLowerCase())
       : _this.props.vectorsStatusState.vectorOptions.filter(e=>e.value.toLowerCase()+NAME === zipCodeVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === zipCodeVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === zipCodeVector?.toLowerCase());
    return vector;
  }

  /**
   * call list request
   */
  triggerListRequest = (isUnknownSelected) => {
    this.getListData();
    if(!this.state.isListExpanded && isUnknownSelected){
      this.expandingDefaultListRef?.current.expandClick();
    }else if(this.state.isListExpanded && !isUnknownSelected){
      this.expandingDefaultListRef?.current.expandClick();
    } 
  }

  /**
   * prepare the unknown boundaries filter to be added to the filterFinal state and saved in state.boundaryListFilter, and call list request with new filter
   */
  prepareUnknownBoundariesFilter = (fromSetCompare) =>{
    let _this = this;
    let isUnknownSelected = !_this.state.isUnknownSelected;
    let unknownFilter = [];
    if(!(this.state.boundary === BOUNDARIES.STATE && !isUnknownSelected)){// if we are unselecting the card in states view, clear filter to return to default list view
      unknownFilter = _this.buildUnknownBoundariesFilter(isUnknownSelected);
    }
    let vector = _this.getBoundaryVectorObject();
    let ref = _this.expandingDefaultListRef?.current
    let profile = ref && ref.getDefaultManageColumnsRef() ? ref.getDefaultManageColumnsRef().simplifyProfileReturnNames(ref.getDefaultManageColumnsRef().state.selectedProfile) : _this.state.manageColumnsProfile
    let listTitle = vector[0]?.label + " - " + (profile?.label || _LIST); //replace by boundary
    var filterFinal = copyObjectValues(tryParse(_this.props.filterFinal));
    unknownFilter.forEach(e => filterFinal.push(e));   
    _this.setState({
      isUnknownSelected: isUnknownSelected,
      boundaryListFilter: JSON.stringify(filterFinal),
      isClosableList: false,
      zipCodeSelected: false,
      listTitle: listTitle,
      expandedListTitle:listTitle
    },()=>{
      _this.triggerListRequest(_this.state.isUnknownSelected);
      // if(!_this.state.isListExpanded && !fromSetCompare){
      //   _this.expandingDefaultListRef?.current.expandClick();
      // }
      comparePolygons = [];
      _this.renderMap();
    })
  }

  setExportOptions = () => {
    let _this = this;
    let exportOptions = [{ value:"export_excel" ,label:"Export Excel", tooltip: lang.header.tooltips.export_all_excel}];
    var hasExportCSVAccess = getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.EXPORT_DATA_TO_CSV);    
    if (hasExportCSVAccess) {
        exportOptions.push({value:ALL_WIDGETS.EXPORT_ALL , label:MESSAGES.header.titles.csv, tooltip: lang.header.tooltips.csv} );
        exportOptions.push({value:ALL_WIDGETS.EXPORT_CSV_PSL , label:MESSAGES.header.titles.csv_all_psl, tooltip: lang.header.tooltips.csv_all});
    }
    _this.setState({
      exportOptions: exportOptions
    })
  }

  setExportOptionsForUser = (hideExportOnList) => {
    let _this = this;
    let exportOptions = hideExportOnList ? [] : [{
      value: "export_excel",
      label: lang.header.titles.export_to_excel,
      tooltip: lang.header.tooltips.export_all_excel,
      description:lang.header.titles.export_selected_visible_rows,
      click: () => {_this.props.tablesToExcelWrapper(); this.setExportAll(false, true);}
    }];
    var hasExportCSVAccess = getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.EXPORT_DATA_TO_CSV);  ;
    if (hasExportCSVAccess) {
      exportOptions.push({
        value: ALL_WIDGETS.EXPORT_ALL,
        label: lang.header.titles.export_to_csv,
        tooltip: lang.header.tooltips.csv,
        description:lang.header.titles.export_selected_rows_csv,
        click: () => {this.setExportAll(false)}
      });
      exportOptions.push({
        value: ALL_WIDGETS.EXPORT_CSV_PSL,
        label: lang.header.titles.csv_all_psl,
        tooltip: lang.header.tooltips.csv_all,
        description:lang.header.titles.export_all_csv,
        click: () => {this.setExportAll(true)}
      });
    }
    _this.setState({
      exportOptions: exportOptions
    })}

  render() {
    let _this = this;
    let alaskaId = statesMapShaper?.features?.find(e=>e.properties.name === Alaska).properties.id;
    let hawaiId = statesMapShaper?.features?.find(e=>e.properties.name === Hawaii).properties.id;
   
    let trackingData = window._pi_getTrackingDataObj();
    trackingData = trackingData === null ? "" : JSON.stringify(trackingData);
    
    let linearizedPSL = _this.state.linearizedHierarchy;
    let isRevenue = _this.state.revenueCostKey ? linearizedPSL?.find(f=>f.value === _this.state?.geoConfigObj?.psl?.value).path.startsWith(_this.state.revenueCostKey + ",") : false;
    let isCalculated = linearizedPSL?.find(f=>f.value === _this.state?.geoConfigObj?.psl?.value)?.costtype === costtype.calculated;
    let isStandard = !isCalculated;
    let isRevenueOrCalculated = isRevenue || isCalculated;

    let highest = _this.state.mapData && _this.state.mapData?.length > 0 && _this.state.mapData.filter(e=>e.id !== UNKNOWN && e.id !== alaskaId && e.id !== hawaiId).length > 0 ? _this.state.mapData.filter(e=>e.id !== UNKNOWN && e.id !== alaskaId && e.id !== hawaiId)?.reduce((previous, current) => {
      return Number(current?.originalValue) > Number(previous?.originalValue) && current.id !== UNKNOWN && previous.id !== UNKNOWN? current : previous;
    }) : 0;
    let highestVal = highest && highest?.id  !== UNKNOWN ? highest?.originalValue : 0;
    highest = formatValString(highest && highest?.id  !== UNKNOWN ? highest?.originalValue : 0, !_this.state.geoConfigObj.value || _this.state.geoConfigObj.value === defaultToggleValue ?FormatTypes.AMOUNT : FormatTypes.PERCENTAGE);

    let lowest = _this.state.mapData && _this.state.mapData?.length > 0 && _this.state.mapData.filter(e=>e.id !== UNKNOWN  && e.id !== alaskaId && e.id !== hawaiId).length > 0 ? 
    _this.state.mapData.filter(e=>e.id !== UNKNOWN && e.id !== alaskaId && e.id !== hawaiId)?.reduce((previous, current) => {
      return Number(current?.originalValue) < Number(previous?.originalValue) && current.id !== UNKNOWN && previous.id !== UNKNOWN ? current : previous;
    }) : 0;
    let lowestVal = lowest && lowest?.id !== UNKNOWN ? lowest?.originalValue : 0;
    lowest = formatValString(lowest && lowest?.id  !== UNKNOWN ? lowest?.originalValue : 0, !_this.state.geoConfigObj.value || _this.state.geoConfigObj.value === defaultToggleValue ?FormatTypes.AMOUNT : FormatTypes.PERCENTAGE);

    let unknownValue = _this.state.mapData && _this.state.mapData?.length > 0 ? _this.state.mapData.reduce((accumulator, object) => {
      if (object.id === UNKNOWN ||  object.id === alaskaId ||  object.id === hawaiId) return accumulator + Number(object.originalValue);
       return accumulator;
    }, 0) : undefined;
    unknownValue = unknownValue ? formatValString(unknownValue, !_this.state.geoConfigObj.value || _this.state.geoConfigObj.value === defaultToggleValue ?FormatTypes.AMOUNT : FormatTypes.PERCENTAGE) : undefined;
    let stateVector = _this.props.userSettingsState.stateVector;
    let zipCodeVector = _this.props.userSettingsState.zipCodeVector;
    let stateVectorObj = _this.props.vectorsStatusState.vectorOptions?.filter(e=>e.value.toLowerCase()+NAME === stateVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === stateVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === stateVector?.toLowerCase());
    let zipCodeVectorObj = _this.props.vectorsStatusState.vectorOptions?.filter(e=>e.value.toLowerCase()+NAME === zipCodeVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === zipCodeVector?.toLowerCase() || e.value.toLowerCase()+NUMBER === zipCodeVector?.toLowerCase());
    let vector = _this.state.zipCodeSelected ? _this.props.vectorsStatusState.vectorObjects[0].value :_this.state.boundary === BOUNDARIES.STATE ? stateVectorObj && stateVectorObj.length > 0 ? stateVectorObj[0]?.value :"" : zipCodeVectorObj && zipCodeVectorObj.length > 0 ? zipCodeVectorObj[0]?.value : this.props.vectorsStatusState.vectors[0];
    
    return (
      <div style={{height:"100%"}}>
        <Popup />
        <Modal 
          openDialog={this.state.openWarning}
          closeClick={this.setCloseWarning}
          bodyContent={this.warningModal}
          dialogActions={this.dialogActions}
          size={DIALOG_SIZE.SMALL}
        />
        <iframe name="frame" id="frame_export" style={{display:'none'}}></iframe> 
        <div id="toastExportBubble" className={!!this.state.error ? "toast toast-fail" :"toast toast-success"}>
            <div id="desc"><i className={"fa-lg fas uk-margin-small-right " + (!!this.state.error ? "fa-minus-circle uk-text-primary" : "fa-check-circle greenText")} aria-hidden="true"></i><span>{this.state.toastMessage}</span></div>
        </div>
  
        {/* {this.renderConfigureComponent()} */}
        <div className={"chart-container" + (_this.state.isListExpanded && _this.state.isListFullScreen ? " map-chart-fullview" : _this.state.isListExpanded && !_this.state.isListFullScreen ? " chart-container-half-view" : "")}>
          <div id="chart_map" className={"chart_map"+ (this.state.isListFullScreen ? " uk-hidden " : "")}></div>
          {this.state.isListFullScreen || this.props.showGreyOverlay || _this.state.compareMode?
          ""
          :
          <Button variant={BUTTON_VARIANT.SECONDARY} type={BUTTON_TYPE.DEFAULT} size={SIZES.ICON} leftIcon={<CurvedBack />} className="back-button" onBtnClick={() => {this.onBackClick() }} />
          }
          {unknownValue ?
            <div className={"unknown-container" + (this.state.isListFullScreen ? " uk-hidden " : "")}>
              <Box
                // onClick = {()=>{this.state.compareMode? {}:this.prepareUnknownBoundariesFilter()}}
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  '& > :not(style)': {
                    m: 1,
                    width: 128,
                    padding: '0.5vw',
                  },
                }}
              >
                <Paper elevation={4} >
                <span className="unknown-text uk-margin-bottom">{UNKNOWN}</span>
                <div className="" style={{ width: convertPxToViewport(85)}}>{_this.state.boundary + ": " + UNKNOWN}</div>
                <div className="" style={{ width: convertPxToViewport(85) }}>{_this.state?.geoConfigObj?.psl?.label + ": " + unknownValue}</div>
                  <Button
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    label={_this.state.isUnknownSelected ? lang.geography_concentration.hide_list : lang.geography_concentration.see_list}
                    className={"see_list_button"+ (this.state.compareMode?" cursorNotAllowed":"")}
                    disabled={this.state.compareMode}
                    onBtnClick={()=>{this.state.compareMode? {}:this.prepareUnknownBoundariesFilter()}}
                  />
                
              </Paper>
            </Box>
            </div>
            : ""}
          <div className={"legend-unknown-container" + ((this.state.isListFullScreen || this.props.showGreyOverlay) ? " uk-hidden" : "")}>
          <MapKeyLegend psl={_this.state?.geoConfigObj?.psl?.label} highestValue={highest} 
            lowestValue={lowest} isRevenueOrCalculated={isRevenueOrCalculated} 
            highestVal={parseFloat(highestVal)} isCalculated={isCalculated}
            lowestVal={parseFloat(lowestVal)}  isStandard={isStandard}
            colors={isCalculated? RED_GREEN_COLORS : 
              generateColorGradient(_this.props.userSettingsState.geographyStandardStartColorCode, _this.props.userSettingsState.geographyStandardEndColorCode, 6)
        }
          />
          </div>
        </div>
        
        {!_this.state.mapListExpanded ?
        <>
          <ExpandingList
            ref={this.expandingDefaultListRef}
            screenId={"landscape-bubble"}
            mainComponentId="chart-container"
            tabulatorId="table-list"
            title={this.state.listTitle}
            isClosable={this.state.isClosableList}
            isAdjustable={true}
            isExpanded={this.state.isListExpanded}
            isFullScreen={this.state.isListFullScreen}
            expandCollapseClick={this.expandCollapseList}
            maximizeMinimizeClick={this.minimizeMaximizeList}
            addModified={this.state.addModified}
            hasExport={true}
            exportParams={{ options: _this?.state?.exportOptions, tablesToExcel: _this.props.tablesToExcelWrapper, records_limit: _this.props.userSettingsState.records_limit, setExportAll: _this.setExportAll }}
            hasManageColumns={this.props.manageColsAccess && this.props.manageColsAccess[ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS]}
            manageColumnsParams={this.manageColumnsDefaultParams()}
            bodyComponent={this.renderListComponent}
            closeClick={this.closeClick}
            filterProps={_this.filterParams()}
            savedFilterData={_this.state.savedFilterDataState}
            defaultList={true}
            showFilter={true}
            isGeographyConcentration={true}
            checkForLimitAccessMessage={this.props.checkForLimitAccessMessage}
            filterFinal={this.props.filterFinal}
            filterFinalBasic={this.props.filterFinalBasic}
            isScenarioBannerVisible={this.props.isScenarioBannerVisible}
            setColumnWidthState={this.pageComponent?.setColumnWidthState}
            setDrillListWidthState={this.pageComponent?.setDrillListWidthState}
            useNewReorderList={this.props.useNewReorderList}
            useNewAddColumn={this.props.useNewAddColumn}
            scenarioState={this.props.scenarioState}
          />
          <ConfirmPasswordModal ref={r=> this.confirmPassRef =r} user={this.props.userSettingsState.user} manageColumnsProfile={this.state.manageColumnsProfile} isDrilling={false}
          trackingData={trackingData} dataLength={this.state.dataLength ||""} //userAuthenticated_exportAll={this.state.userAuthenticated_exportAll} 
          pageComponent={this.pageComponent} getPeriodsObject={this.getPeriodsObject} isTempScenario={this.isTempScenario} match={this.props.match}
          vectors={[vector]} vectorOptions={this.props.vectorsStatusState.vectorOptions} profitFormat={this.props.profitFormat} scenarios={this.props.scenarioState.scenarios || []}
          filterDialRef={this.props.filterDialRef} cancel={this.cancel} reauthenticateWithCredential={this.reauthenticateWithCredential} 
          exportAll={this.exportAll} filterFinal={this.state.boundaryListFilter.length > 0 ? this.state.boundaryListFilter : copyObjectValues(this.props.filterFinal)} 
          exportRef= {this.expandingDefaultListRef.current?.getDefaultExportRef()}  exportQueryFilter={this.getExportFilter()}
          pssLines={this.expandingDefaultListRef.current?.getDefaultManageColumnsRef()?.state?.profitStackLineColumns}
          export_all={this.expandingDefaultListRef !== null ?this.state.export_all : false} isClientSSO={this.props.userSettingsState.isClientSSO}
          rollingSegment = {ROLLING_SEGMENTS.R_12} periodsStatusState={this.props.periodsStatusState}
          clientPeriodsState = {this.props.clientPeriodsState} datasetStatusState={this.props.datasetStatusState} exportScopeFilter={this.props.exportScopeFilter} />
          </>
         
          :""}

      </div>
    );
  }
}


export default GeographyConcentration;
